import React from "react";
import {useSelector} from "react-redux";

const Dashboard = () => {
    const {userInfo} = useSelector((state) => state.auth);

    return (<>
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1>Bienvenido {userInfo.userName}</h1>
                </div>
            </main>
        </>);
};

export default Dashboard;
