import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const origenesEntradasApi = createApi({
    reducerPath: 'origenesEntradasApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "origenes/entradas",
        prepareHeaders: (headers, {getState}) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        obtenerOrigenEntrada: build.query({
            query: (idOrigenEntrada) => `/${idOrigenEntrada}`,
        }),
        obtenerOrigenesEntradas: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        guardarOrigenEntrada: build.mutation({
            query: (origenEntrada) => ({
                url: '',
                method: origenEntrada.idOrigenEntrada ? 'PATCH' : 'POST',
                body: origenEntrada,
            }),
        }),
        eliminarOrigenEntrada: build.mutation({
            query: (origenEntrada) => ({
                url: '',
                method: 'DELETE',
                body: origenEntrada,
            }),
        }),
    }),
});
export const {
    useObtenerOrigenEntradaQuery,
    useObtenerOrigenesEntradasQuery,
    useLazyObtenerOrigenesEntradasQuery,
    useGuardarOrigenEntradaMutation,
    useEliminarOrigenEntradaMutation
} = origenesEntradasApi;
