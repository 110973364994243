import React from 'react';
import Spinner from "./Spinner";
import {useSelector} from "react-redux";

const Loader = () => {
    const {loading} = useSelector((state) => state.global);
    return (
        loading > 0 &&
        <div className="overlay">
            <div className="loader-container">
                <Spinner/>
            </div>
        </div>
    );
};

export default Loader;
