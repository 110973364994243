import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const categoriasProductosApi = createApi({
    reducerPath: 'categoriasProductosApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "categorias/productos",
        prepareHeaders: (headers, {getState}) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        obtenerCategoriaProducto: build.query({
            query: (idCategoriaProducto) => `/${idCategoriaProducto}`,
        }),
        obtenerCategoriasProductos: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        guardarCategoriaProducto: build.mutation({
            query: (categoriaProducto) => ({
                url: '',
                method: categoriaProducto.idCategoriaProducto ? 'PUT' : 'POST',
                body: categoriaProducto,
            }),
        }),
        eliminarCategoriaProducto: build.mutation({
            query: (categoriaProducto) => ({
                url: '',
                method: 'DELETE',
                body: categoriaProducto,
            }),
        }),
    }),
});
export const {
    useObtenerCategoriaProductoQuery,
    useObtenerCategoriasProductosQuery,
    useLazyObtenerCategoriasProductosQuery,
    useGuardarCategoriaProductoMutation,
    useEliminarCategoriaProductoMutation
} = categoriasProductosApi;
