import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const inventariosApi = createApi({
    reducerPath: 'inventariosApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "inventarios",
        prepareHeaders: (headers, {getState}) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        obtenerInventario: build.query({
            query: (idInventario) => `/${idInventario}`,
        }),
        obtenerInventarios: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        guardarInventario: build.mutation({
            query: (inventario) => ({
                url: '',
                method: 'PATCH',
                body: inventario,
            }),
        }),
    }),
});
export const {
    useObtenerInventarioQuery,
    useObtenerInventariosQuery,
    useLazyObtenerInventariosQuery,
    useGuardarInventarioMutation,
} = inventariosApi;
