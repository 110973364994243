import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const procesoEtapaDocumentoApi = createApi({
    reducerPath: 'procesosEtapasDocumentosApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "procesos/etapas/documentos",
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (build) => ({
        obtenerDocumentosArchivos: build.query({
            query: ([idProceso, idEtapa, idOrigen]) => `/${idProceso}/${idEtapa}/${idOrigen}`,
        }),
        guardarProcesoEtapaDocumento: build.mutation({
            query: (procesoEtapaDocumento) => ({
                url: '',
                method: procesoEtapaDocumento.idProcesoEtapaTipoDocumento ? 'PATCH' : 'POST',
                body: procesoEtapaDocumento,
            })
        })
    })
});

export const { useObtenerDocumentosArchivosQuery, useGuardarProcesoEtapaDocumentoMutation } = procesoEtapaDocumentoApi;
