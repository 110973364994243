import {
    useObtenerPrioridadesQuery,
    useEliminarPrioridadMutation,
    useLazyObtenerPrioridadesQuery
} from '../../../services/prioridades.service';
import React, { useState } from "react";
import { Button, Row, Col, Card } from "react-bootstrap";
import SeverPagination from "../../../components/base/SeverPagination";
import PrioridadesForm from './PrioridadesForm';
import BootstrapTable from "../../../components/base/BootstrapTable";
import ConfirmDialog from "../../../components/base/ConfirmDialog";
import { ExportToExcel } from "../../../components/base/ExportToExcel";

const Prioridades = () => {
    const [pageNumber, setPageNumber] = useState(1);
    const [elementsPerPage, setElementsPerPage] = useState(10);

    const [formPrioridades, setFormPrioridades] = useState(null);
    const [confirmDialog, setConfirmDialog] = useState({});

    const {
        data: prioridades, refetch
    } = useObtenerPrioridadesQuery([pageNumber, elementsPerPage], { refetchOnMountOrArgChange: true });
    const [trigger] = useLazyObtenerPrioridadesQuery();

    const [eliminarPrioridad] = useEliminarPrioridadMutation();

    return (<div id="main" className="main">
        <section className="section">
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Body>
                            <div className="btn-toolbar justify-content-between" role="toolbar">
                                <div className="pagetitle">
                                    <h1>Prioridades</h1>
                                </div>
                            </div>
                            {prioridades &&
                                <BootstrapTable striped
                                    toolbar={({ columns }) => (
                                        <div>
                                            <Button href="#" className="me-2" variant="primary" onClick={(e) => {
                                                setFormPrioridades({})
                                            }}>
                                                <i className="bi bi-plus-square"></i>&nbsp;Nuevo
                                            </Button>
                                            <ExportToExcel columns={columns} onClick={trigger} fileName={'prioridades'} />
                                        </div>
                                    )}
                                    data={prioridades.data}
                                    columns={[{
                                        header: 'Prioridad', accessorKey: 'prioridad'
                                    }, {
                                        header: 'Color', accessorKey: 'color'
                                    }, {
                                        header: 'Orden', accessorKey: 'orden'
                                    }, {
                                        header: 'Estatus', accessorKey: 'estatus.estatus', enableGlobalFilter: false
                                    }, {
                                        header: 'Acciones',
                                        enableGlobalFilter: false,
                                        cell: ({ row: { original } }) => (
                                            <div className="w-100 d-grid d-sm-block gap-1">
                                                <Button variant="warning" className="me-2" size="sm"
                                                    onClick={e => setFormPrioridades(original) }>
                                                    <i className="bi bi-pencil-square"> </i>
                                                    Editar
                                                </Button>
                                                <Button variant="danger" className="me-2" size="sm" onClick={e => setConfirmDialog({
                                                    message: '¿Desea eliminar este registro?', onConfirm: async () => {
                                                        await eliminarPrioridad({ idPrioridad: original.idPrioridad });
                                                        refetch();
                                                        setConfirmDialog({});

                                                    }
                                                })}>
                                                    <i className="bi bi-trash"> </i>
                                                    Eliminar
                                                </Button>
                                            </div>)
                                    }]}
                                    onGlobalFilterChange={(e => {
                                        setPageNumber(1);
                                        setElementsPerPage(e ? -1 : 10);
                                    })}
                                />}
                            {prioridades && <SeverPagination
                                data={prioridades.data}
                                currentPage={pageNumber}
                                totalElements={prioridades.totalElements}
                                elementsPerPage={elementsPerPage}
                                onPageChange={setPageNumber}
                                onElementsPerPageChange={setElementsPerPage}
                            />}
                        </Card.Body>
                        <ConfirmDialog {...confirmDialog} onCancel={() => setConfirmDialog({})}/>
                    </Card>
                </Col>
            </Row>
        </section>
        {formPrioridades && <PrioridadesForm show={formPrioridades} onHide={(e) => {
            setFormPrioridades(false);
        }} onSave={refetch}/>}
    </div>)
}

export default Prioridades;