import React, { useState } from "react";
import { Card, Button } from "react-bootstrap";
import ConfirmDialog from "../base/ConfirmDialog";
import MantenimientosForm from '../../views/mantenimientos/mantenimientos/MantenimientosForm'

const MantenimientoCard = ({ mantenimiento, refetch, eliminarMantenimiento }) => {
    const [formMantenimientos, setFormMantenimientos] = useState(null)
    const [confirmDialog, setConfirmDialog] = useState({});


    return (
        <Card className={`mb-0 mt-3`}>
            <Card.Header className={`pb-0 mb-0 fw-bold alert alert-primary`}>
                <h5 className="card-title pt-1 pb-0 text-primary">
                    Tipo de transporte:{' '}
                    {mantenimiento.transporte.tipoTransporte.tipoTransporte}
                </h5>
            </Card.Header>
            <div className="row card-body pt-2">
                <p className="card-text mb-1">
                    <span className="text-muted">
                        Fecha de mantenimiento: <b>{mantenimiento.fechaMantenimiento?.split(" ")[0]}</b>
                        {" "} | Estatus: <b >{mantenimiento.estatus.estatus}</b>
                    </span>
                </p>

                <div className="col-md-12 mb-2 pb-0">
                    <p className="card-text mb-1">
                        <strong>Clase: </strong>
                        {mantenimiento.transporte.claseTransporte.claseTransporte}
                        <span className="mx-4"></span>
                        <strong>Modelo: </strong>
                        {mantenimiento.transporte.modelo}
                    </p>

                    <p className="card-text mb-1" >
                        <strong>Año de fabricación: </strong>
                        {mantenimiento.transporte.anioFabricacion}
                        <span className="mx-4"></span>
                        <strong>Número de placa/Matrícula: </strong>
                        {mantenimiento.transporte.numeroPlaca}
                    </p>
                </div>

                <div>
                    <Button
                        variant="outline-warning"
                        className="me-2"
                        size="sm"
                        onClick={(e) => setFormMantenimientos(mantenimiento)}
                    >
                        <i className="bi bi-pencil-square"> </i> Editar
                    </Button>

                    <Button
                        variant="outline-danger"
                        className="me-2" size="sm"
                        onClick={(e) => setConfirmDialog({
                            message: '¿Desea eliminar este registro?',
                            onConfirm: async () => {
                                await eliminarMantenimiento({ idMantenimiento: mantenimiento.idMantenimiento });
                                refetch();
                                setConfirmDialog({});
                            },
                        })
                        }
                    >
                        <i className="bi bi-trash"> </i> Eliminar
                    </Button>
                </div>
                <ConfirmDialog {...confirmDialog} onCancel={() => setConfirmDialog({})} />

                {formMantenimientos && (
                    <MantenimientosForm
                        show={formMantenimientos}
                        onHide={(e) => {
                            setFormMantenimientos(false)
                        }}
                        onSave={refetch}
                    />
                )}
            </div>
        </Card >

    )
}
export default MantenimientoCard;
