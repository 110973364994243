import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const usuariosApi = createApi({
    reducerPath: 'usuariosApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "usuarios",
        prepareHeaders: (headers, {getState}) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        obtenerUsuario: build.query({
            query: (usuario) => `/${usuario}`,
        }),
        obtenerUsuarios: build.query({
            query: ([pageNumber, elementsPerPage]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        buscarUsuarios: build.query({
            query: ([{usuario, nombreCompleto}, pageNumber, elementsPerPage]) =>
                `buscador?${usuario ? 'usuario=' + usuario : ''}&${nombreCompleto ? 'nombreCompleto=' + nombreCompleto : ''}&page=${pageNumber}&size=${elementsPerPage}`,
        }),
        guardarUsuario: build.mutation({
            query: (usuario) => ({
                url: '',
                method: !usuario.fechaVersion ? 'POST' : 'PATCH',
                body: usuario,
            }),
        }),
        eliminarUsuario: build.mutation({
            query: (usuario) => ({
                url: '',
                method: 'DELETE',
                body: usuario,
            }),
        }),
    }),
});
export const {
    useObtenerUsuarioQuery,
    useLazyObtenerUsuarioQuery,
    useObtenerUsuariosQuery,
    useBuscarUsuariosQuery,
    useLazyBuscarUsuariosQuery,
    useGuardarUsuarioMutation,
    useEliminarUsuarioMutation
} = usuariosApi;
