import React, {useEffect, useState} from 'react';
import {Document, Page, pdfjs} from 'react-pdf';
import {Modal, Button, Image, Container, Stack} from 'react-bootstrap';

// Configuramos la URL del Worker para react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfViewer = ({title, data}) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [scale, setScale] = useState(1.0);
    const [pdfWidth, setPdfWidth] = useState(0);

    const onDocumentLoadSuccess = ({numPages}) => {
        setNumPages(numPages);
    };
    const handleNextPage = () => {
        if (pageNumber < numPages) {
            setPageNumber(pageNumber + 1);
        }
    };

    const handlePrevPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    };

    const handleZoomIn = () => {
        setScale(scale + 0.1);
    };

    const handleZoomOut = () => {
        setScale(scale - 0.1);
    };

    const onPageLoad = (page) => {
        setPdfWidth(page.originalWidth);
    }

    useEffect(() => {
        const setWindowDimensions = () => {
            const parentDiv = document.querySelector('#pdf-container')
            let pageScale = Math.min(parentDiv.clientWidth / pdfWidth, 1);
            if (scale !== pageScale) {
                setScale(pageScale);
            }
        }

        if(scale === 1.0){
            setWindowDimensions();
        }
        window.addEventListener('resize', setWindowDimensions);
        return () => {
            window.removeEventListener('resize', setWindowDimensions)
        }
    }, [scale, pdfWidth])

    const handlePrint = () => {
        fetch(data)
            .then(res => res.blob())
            .then(blob => {
                const url = URL.createObjectURL(blob);

                const iframe = document.createElement('iframe');
                iframe.style.display = 'none';
                iframe.src = url;

                iframe.onload = function () {
                    // After the iframe has loaded, trigger printing
                    iframe.contentWindow.print();
                    URL.revokeObjectURL(url); // Clean up the URL object
                };

                document.body.appendChild(iframe);
            })
    };

    const handleDownload = () => {
        let link = document.createElement("a");
        link.href = data;
        link.download = title;
        link.click();
        link = null;
    };

    return (<Container id="pdf-container" className="d-flex justify-content-center mwx-800 mx-auto flex-column p-0">
        <Stack direction="horizontal" gap={3} className="justify-content-center flex-wrap mb-1">
            <Button onClick={handlePrevPage} disabled={pageNumber <= 1} variant="outline-primary" size="sm">
                <i className="bi bi-arrow-left"></i> Anterior
            </Button>
            <Button onClick={handleNextPage} disabled={pageNumber >= numPages} variant="outline-primary" size="sm">
                <i className="bi bi-arrow-right"></i> Siguiente
            </Button>
            <Button onClick={handleZoomIn} variant="outline-primary" size="sm"> <i className="bi bi-zoom-in" ></i> Zoom </Button>
            <Button onClick={handleZoomOut} variant="outline-primary" size="sm"> <i className="bi bi-zoom-out"></i> Zoom </Button>
            <Button onClick={handlePrint} variant="outline-primary" size="sm"> <i className="bi bi-printer"></i> Imprimir
            </Button>
            <Button onClick={handleDownload} variant="outline-primary" size="sm">
                <i className="bi bi-cloud-download"></i> Descargar</Button>
        </Stack>
        <p className="text-center fw-bold m-0 mb-1">
            Página {pageNumber} de {numPages}
        </p>
        <Document className="mx-auto shadow-lg bg-body rounded" file={data} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} scale={scale} renderTextLayer={false} renderAnnotationLayer={false}
                  onLoadSuccess={onPageLoad}/>
        </Document>
    </Container>)
};

const ImageViewer = ({title, data}) => {
    const handleDownload = () => {
        let link = document.createElement("a");
        link.href = data;
        link.download = title;
        link.click();
        link = null;
    };

    return (<>
        <Stack direction="horizontal" gap={3} className="justify-content-center flex-wrap m-0 mb-1">
            <Button onClick={handleDownload} variant="outline-primary" size="sm">
                <i className="bi bi-cloud-download"></i> Descargar</Button>
        </Stack>
        <Container className="d-flex justify-content-center h-100" style={{paddingBottom: 30}}>
            <Image src={data} alt="Image" fluid thumbnail/>
        </Container></>);
};
const FileResolver = ({file, name}) => {
    const pdfHeader = 'data:application/pdf';
    const imageHeader = 'data:image/';

    if (file.startsWith(pdfHeader)) {
        return <PdfViewer title={name} data={file}/>;
    }
    if (file.startsWith(imageHeader)) {
        return <ImageViewer title={name} data={file}/>;
    }
    return (<iframe
        title={name}
        src={file}
        height="100%"
        width="100%"
    ></iframe>);
}

const FileViewer = ({title, file, fileName, showModal, closeModal}) => {
    return (showModal && <Modal show={showModal} onHide={closeModal} fullscreen centered size="xl">
        <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <FileResolver file={file} name={fileName}/>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
                Cerrar
            </Button>
        </Modal.Footer>
    </Modal>);
};

export {PdfViewer, ImageViewer};
export default FileViewer;
