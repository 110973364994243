import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const localidadesApi= createApi({
    reducerPath: 'localidadesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + 'localidades',
        prepareHeaders: (headers, {getState}) => {
            const token = getState().auth.userInfo.token;
            if (token){
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),

    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        obtenerLocalidad: build.query({
            query: (idLocalidad) => `/${idLocalidad}`,
        }),
        obtenerLocalidades: build.query({
            query:([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        guardarLocalidad: build.mutation({
            query: (localidad) => ({
                url: '',
                method: localidad.idLocalidad ? 'PUT' : 'POST',
                body: localidad,
            }),
        }),
        eliminarLocalidad:  build.mutation({
            query: (localidad) => ({
                url: '',
                method: 'DELETE',
                body: localidad,
            }),
        }),
    })
})

export const {
    useObtenerLocalidadQuery,
    useObtenerLocalidadesQuery,
    useLazyObtenerLocalidadesQuery,
    useGuardarLocalidadMutation,
    useEliminarLocalidadMutation
} = localidadesApi