import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import { REDMINE_API_BASE_URL, REDMINE_KEY} from "../helpers/constants";

export const soporteApi = createApi({
    reducerPath: 'soporteApi',
    baseQuery: fetchBaseQuery({
        baseUrl: REDMINE_API_BASE_URL,
        prepareHeaders: (headers ,{getState}) => {
            const redkey = REDMINE_KEY;
            if(redkey){
                headers.set('X-Redmine-API-Key',`${redkey}`)
            }
            headers.set('Content-Type', 'application/json');
        },
    }),
    endpoints: (build) => ({
        guardarAsunto: build.mutation({
            query: () => ({
                url: 'issues.json',
                method: 'POST'
            })
        }),
    }),
});

export const { useGuardarAsuntoMutation } = soporteApi;

