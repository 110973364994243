import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const mantenimientosPiezasApi = createApi({
    reducerPath: 'mantenimientosPiezasApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "mantenimientos/piezas",
        prepareHeaders: (headers, {getState}) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        obtenerMantenimientosPiezas: build.query({
            query: (idMantenimiento) => `/${idMantenimiento}`,
        }),
        eliminarMantenimientoPieza: build.mutation({
            query: (mantenimientoPieza) => ({
                url: '',
                method: 'DELETE',
                body: mantenimientoPieza,
            }),
        }),
    }),
});
export const {
    useObtenerMantenimientosPiezasQuery,
    useEliminarMantenimientoPiezaMutation
} = mantenimientosPiezasApi;
