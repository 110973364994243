import React from "react";
import { Link } from "react-router-dom";
import { Card, Col, Row } from "react-bootstrap";


const ConfiguracionDocumento = () => {

    return (<>
        <main id="main" className="main">
            <div className="pagetitle">
                <h1>Configuración de Documentos</h1>
            </div>
            <section className="section">
                <Row>
                    <Col lg={3} md={4} sm={2} >
                        <Card>
                            <Card.Body className="text-center">
                                <div>
                                    <i class="bi bi-building-fill" style={{ fontSize: '6rem', color: "#2f71ff" }} />
                                </div>
                                <Link className="btn btn-primary" to={"/configuraciones/configuraciones/documentos/view"} >
                                    Empresas
                                </Link>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={3} md={4} sm={12} >
                        <Card>
                            <Card.Body className="text-center">
                                {/* REEMPLAZAR RUTA */}
                                <div>
                                    <i class="bi bi-person-fill" style={{ fontSize: '6rem', color: "#2f71ff" }} />
                                </div>
                                <Link className="btn btn-primary" to={"/configuraciones/configuraciones/documentos/view"} >
                                    Empleados
                                </Link>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={3} md={4} sm={12} >
                        <Card>
                            <Card.Body className="text-center">
                                <div>
                                    <i class="bi bi-car-front-fill" style={{ fontSize: '6rem', color: "#2f71ff" }} />
                                </div>
                                {/* REEMPLAZAR RUTA */}
                                <Link className="btn btn-primary" to={"/configuraciones/configuraciones/documentos/view"} >
                                    Vehículos
                                </Link>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </section>
            <div className="pagetitle">
                <h1>Otras acciones</h1>
            </div>
            <section className="section">
                <Row>
                    <Col lg={3} md={4} sm={12} >
                        <Card>
                            <Card.Body className="text-center">
                                <div>
                                    <i class="bi bi-file-earmark-text-fill" style={{ fontSize: '6rem', color: "#2f71ff" }} />
                                </div>
                                <Link className="btn btn-primary" to={"/catalogos/tipos/documentos"} >
                                    Tipos de documentos
                                </Link>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </section>
        </main>
    </>);
};

export default ConfiguracionDocumento;
