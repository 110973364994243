import React, {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Alert, Button, Form, Modal} from "react-bootstrap";
import {useGuardarImpuestoMutation, useObtenerImpuestoQuery} from "../../../services/impuesto.service";
import {NumericFormat} from "react-number-format";

const ImpuestosForm = ({show, onHide, onSave}) => {

    const handleClose = (e) => {
        onHide(e);
    };

    const {control, handleSubmit, setValue, register, reset, formState: {errors}} = useForm();

    const {data: impuesto} = useObtenerImpuestoQuery(show.idImpuesto, {skip: !show.idImpuesto, refetchOnMountOrArgChange: true});
    const [guardarImpuesto] = useGuardarImpuestoMutation();

    useEffect(() => {
        if (impuesto) {
            reset({
                idImpuesto: impuesto.idImpuesto,
                impuesto: impuesto.impuesto,
                valor: impuesto.valor,
                idEstatus: impuesto.idEstatus,
                fechaVersion: impuesto.fechaVersion
            });
        } else {
            //default values
            reset({idEstatus: 1})
        }
    }, [impuesto, reset]);

    const onSubmit = async (formData) => {
        let {data} = await guardarImpuesto(formData);
        if(data){
            setValue('idImpuesto', data.id);
            setValue('fechaVersion', data.fechaVersion);
            onSave();
        }
    };

    return (<> {
        <Modal show={show} onHide={handleClose} centered backdrop="static" keyboard={false}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>{show.idImpuesto ? 'Editar' : 'Nuevo'} [ Impuesto ]</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="text" id="idImpuesto" className="d-none" {...register("idImpuesto")} />
                    <Form.Control type="text" id="fechaVersion" className="d-none" {...register("fechaVersion")} />
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="impuesto" className="fw-bold">
                            Impuesto
                        </Form.Label>
                        <Form.Control
                            {...register('impuesto', {
                                required: 'Ingrese un dato válido',
                                maxLength: {value: 30, message: 'Ha excedido el máximo de caracteres'}
                            })}
                        />
                        {errors.impuesto && (<Alert variant="danger" role="alert">
                            {errors.impuesto.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className="mb-3">
                    <Form.Label htmlFor="importe" className="fw-bold">Valor</Form.Label>
                        <Controller
                            render={({field}) => (
                                <NumericFormat
                                    className="form-control"
                                    id="valor"
                                    thousandSeparator={true}
                                    suffix={' %'}
                                    decimalScale={2}
                                    fixedDecimalScale
                                    allowNegative={false}
                                    value={field.value || ''}
                                    onValueChange={(v) => {
                                        field.onChange(Number(v.value) > 0 ? Number(v.value) : '0');
                                    }}
                                    isAllowed={({value}) => (Number(value?.split(".")[0]) <= 100 )}
                                />
                            )}
                            name="valor"
                            control={control}
                            rules={{required: "Ingrese un dato válido"}}
                        />
                        {errors.valor && (
                            <Alert variant="danger">
                                {errors.valor.message}
                            </Alert>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Controller
                            name="idEstatus"
                            control={control}
                            render={({field}) => (<>
                                    <Form.Label htmlFor="idEstatus">Estatus</Form.Label>
                                    <Form.Check
                                        id="idEstatus"
                                        type="switch"
                                        label={Boolean(field.value) ? 'Activo' : 'Inactivo'}
                                        checked={Boolean(field.value)}
                                        onChange={(e) => {
                                            field.onChange(Number(e.target.checked))
                                        }}
                                    />
                                </>

                            )}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant="primary" className="me-2">
                        <i className="bi bi-save"></i> Guardar
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>}
    </>);
};

export default ImpuestosForm;
