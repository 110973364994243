import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const metodosTrasladosAlmacenesApi = createApi({
    reducerPath: 'metodosTrasladosAlmacenesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "metodos/traslados/almacenes",
        prepareHeaders: (headers, {getState}) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        obtenerMetodoTrasladoAlmacen: build.query({
            query: (idMetodoTrasladoAlmacen) => `/${idMetodoTrasladoAlmacen}`,
        }),
        obtenerMetodosTrasladosAlmacenes: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        guardarMetodoTrasladoAlmacen: build.mutation({
            query: (metodoTrasladoAlmacen) => ({
                url: '',
                method: metodoTrasladoAlmacen.idMetodoTrasladoAlmacen ? 'PATCH' : 'POST',
                body: metodoTrasladoAlmacen,
            }),
        }),
        eliminarMetodoTrasladoAlmacen: build.mutation({
            query: (metodoTrasladoAlmacen) => ({
                url: '',
                method: 'DELETE',
                body: metodoTrasladoAlmacen,
            }),
        }),
    }),
});
export const {
    useObtenerMetodoTrasladoAlmacenQuery,
    useObtenerMetodosTrasladosAlmacenesQuery,
    useLazyObtenerMetodosTrasladosAlmacenesQuery,
    useGuardarMetodoTrasladoAlmacenMutation,
    useEliminarMetodoTrasladoAlmacenMutation
} = metodosTrasladosAlmacenesApi;
