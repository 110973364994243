import React, { useState } from "react";
import { Button, Card } from "react-bootstrap";
import EmpresasForm from "../../views/administracion/empresas/EmpresasVista";
import ConfirmDialog from "../base/ConfirmDialog";
import { Link } from "react-router-dom";
const EmpresaCard = ({ empresa, refetch, eliminarEmpresa }) => {
    const [vistaEmpresas, setVistaEmpresas] = useState(null);
    const [confirmDialog, setConfirmDialog] = useState({});

    return (
        <Card className={`mb-0 mt-3`}>
            <Card.Header className={`pb-0 mb-0 fw-bold alert alert-primary`}>
                <h5 className="card-title pt-1 pb-0 text-primary">
                    {empresa.empresa}{" "}
                </h5>
            </Card.Header>
            <div className="row card-body pt-2">
                <p className="card-text mb-1">
                    Estatus: <b>{empresa.estatus.estatus}</b>
                </p>
                <div className="col-md-12 mb-2 pb-0">
                    <p className="card-text mb-1">
                        <strong>Rfc: </strong>
                        {empresa.rfc}
                    </p>
                    <p className="card-text mb-1">
                        <strong>Régimen fiscal: </strong>
                        {empresa.regimenFiscal.regimenFiscal}
                    </p>
                    <p className="card-text mb-1">
                        <strong>Contacto Principal / Representante Legal: </strong>
                        {empresa.representanteLegal}
                    </p>
                </div>

                <div>
                    <Link
                        className="col-12 text-end"
                        to={"/administracion/empresas/view/" + empresa.idEmpresa}
                    >
                        <Button
                            variant="outline-warning"
                            className="me-2"
                            size="sm"
                            onClick={(e) => setVistaEmpresas(empresa)}
                        >
                            <i className="bi bi-pencil-square"> </i>
                            Editar
                        </Button>
                    </Link>

                    <Button
                        variant="outline-danger"
                        className="me-2"
                        size="sm"
                        onClick={(e) =>
                            setConfirmDialog({
                                message: "¿Desea eliminar este registro?",
                                onConfirm: async () => {
                                    await eliminarEmpresa({ idEmpresa: empresa.idEmpresa });
                                    refetch();
                                    setConfirmDialog({});
                                },
                            })
                        }
                    >
                        <i className="bi bi-trash"> </i>
                        Eliminar
                    </Button>
                </div>
            </div>
            <ConfirmDialog {...confirmDialog} onCancel={() => setConfirmDialog({})} />

            {vistaEmpresas && (
                <EmpresasForm
                    show={vistaEmpresas}
                    onHide={(e) => {
                        setVistaEmpresas(false);
                    }}
                    onSave={refetch}
                />
            )}
        </Card>
    );
};

export default EmpresaCard;
