import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const procesoEtapaCampoApi = createApi({
    reducerPath: 'procesosEtapasCamposApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "procesos/etapas/campos",
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (build) => ({
        obtenerCampos: build.query({
            query: ([idProceso, idEtapa, idEstatus, inOut]) => ({
                url: "",
                params: {idProceso, idEtapa, idEstatus, inOut}
            }),
        }),
    }),
});

export const { useObtenerCamposQuery } = procesoEtapaCampoApi;
