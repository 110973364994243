import { createSlice } from '@reduxjs/toolkit';
import { perfilApi } from '../services/perfil.service';

const perfilSlice = createSlice({
    name: 'perfil',
    initialState: {},
    reducers: {},
    extraReducers: (builder) => {
        builder.addMatcher(
            perfilApi.endpoints.obtenerAlumno.matchFulfilled,
            (state, action) => {
                // Actualizar el estado con los datos del alumno obtenidos
                return action.payload;
            }
        );
    },
});

export default perfilSlice.reducer;
