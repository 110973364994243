import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const clasesTransportesApi = createApi({
    reducerPath: 'clasesTransportesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "clases/transportes",
        prepareHeaders: (headers, {getState}) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        obtenerClaseTransporte: build.query({
            query: (idClaseTransporte) => `/${idClaseTransporte}`,
        }),
        obtenerClasesTransportes: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        guardarClaseTransporte: build.mutation({
            query: (claseTransporte) => ({
                url: '',
                method: claseTransporte.idClaseTransporte ? 'PUT' : 'POST',
                body: claseTransporte,
            }),
        }),
        eliminarClaseTransporte: build.mutation({
            query: (claseTransporte) => ({
                url: '',
                method: 'DELETE',
                body: claseTransporte,
            }),
        }),
    }),
});
export const {
    useObtenerClaseTransporteQuery,
    useObtenerClasesTransportesQuery,
    useLazyObtenerClasesTransportesQuery,
    useGuardarClaseTransporteMutation,
    useEliminarClaseTransporteMutation
} = clasesTransportesApi;
