import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const observacionesApi = createApi({
    reducerPath: 'observacionesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "observaciones",
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (build) => ({
        obtenerObservaciones: build.query({
            query: ([idProceso,idSolicitudPago]) => `/procesos/${idProceso}/${idSolicitudPago}`,
        }),
        guardarObservaciones: build.mutation({
            query: (observacion) => ({
                url: '',
                method: 'POST',
                body: observacion,
            }),
        }),

    }),
});

export const { useObtenerObservacionesQuery, useGuardarObservacionesMutation } = observacionesApi;
