import {
    useObtenerLocalidadesQuery,
    useEliminarLocalidadMutation,
    useLazyObtenerLocalidadesQuery
} from '../../../services/localidad.service'
import React, { useEffect, useState } from 'react'
import { Button, Row, Col, Card } from 'react-bootstrap'
import SeverPagination from '../../../components/base/SeverPagination'
import LocalidadesForm from './LocalidadesForm'
import BootstrapTable from '../../../components/base/BootstrapTable'
import ConfirmDialog from '../../../components/base/ConfirmDialog'
import { ExportToExcel } from '../../../components/base/ExportToExcel'

const Localidades = () => {
    const [pageNumber, setPageNumber] = useState(1)
    const [elementsPerPage, setElementsPerPage] = useState(10)
    const [formLocalidades, setFormLocalidades] = useState(null)
    const [confirmDialog, setConfirmDialog] = useState({})
    const [isMobile, setIsMobile] = useState(window.innerWidth < 576);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 576);
        };

        // Añade un listener para el evento resize
        window.addEventListener('resize', handleResize);

        // Limpia el listener cuando el componente se desmonta
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const { data: localidades, refetch } = useObtenerLocalidadesQuery([pageNumber, elementsPerPage], { refetchOnMountOrArgChange: true })
    const [trigger] = useLazyObtenerLocalidadesQuery()

    const [eliminarLocalidad] = useEliminarLocalidadMutation()

    return (<div id='main' className='main'>
        <section className='section'>
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Body>
                            <div className='btn-toolbar justify-content-between' role='toolbar'>
                                <div className='pagetitle'>
                                    <h1>Localidades</h1>
                                </div>
                            </div>
                            {localidades && (
                                <BootstrapTable
                                    striped
                                    toolbar={({ columns }) => (
                                        <div>
                                            <Button href='#' className='me-2' variant='primary' onClick={(e) => {
                                                setFormLocalidades({})
                                            }}>
                                                <i className='bi bi-plus-square'></i>&nbsp;Nuevo
                                            </Button>
                                            <ExportToExcel columns={columns} onClick={trigger} fileName={'localidades'} />
                                        </div>
                                    )}
                                    data={localidades.data}
                                    columns={[{
                                        header: 'Nombre',
                                        accessorKey: 'localidad',
                                        className: isMobile ? 'text-movil' : 'text-short'
                                    }, {
                                        header: 'Estatus',
                                        accessorKey: 'estatus.estatus',
                                        enableGlobalFilter: false
                                    }, {
                                        header: "Acciones",
                                        enableGlobalFilter: false,
                                        cell: ({ row: { original } }) => (
                                            <div className="w-100 d-grid d-sm-block gap-1">
                                                <Button
                                                    variant="warning" className="me-2" size="sm"
                                                    onClick={(e) => setFormLocalidades(original)} >
                                                    <i className="bi bi-pencil-square"> </i>
                                                    Editar
                                                </Button>
                                                <Button
                                                    variant="danger" className="me-2" size="sm" onClick={(e) => setConfirmDialog({
                                                        message: "¿Desea eliminar este registro?",
                                                        onConfirm: async () => {
                                                            await eliminarLocalidad({
                                                                idLocalidad: original.idLocalidad,
                                                            });
                                                            refetch();
                                                            setConfirmDialog({});
                                                        }
                                                    })} >
                                                    <i className="bi bi-trash"> </i>
                                                    Eliminar
                                                </Button>
                                            </div>
                                        ),
                                    }
                                    ]}
                                    onGlobalFilterChange={(e) => {
                                        setPageNumber(1)
                                        setElementsPerPage(e ? -1 : 10)
                                    }}
                                />
                            )}
                            {localidades && (
                                <SeverPagination
                                    data={localidades.data}
                                    currentPage={pageNumber}
                                    totalElements={localidades.totalElements}
                                    elementsPerPage={elementsPerPage}
                                    onPageChange={setPageNumber}
                                    onElementsPerPageChange={setElementsPerPage}
                                />
                            )}
                        </Card.Body>
                        <ConfirmDialog
                            {...confirmDialog} onCancel={() => setConfirmDialog({})}
                        />
                    </Card>
                </Col>
            </Row>
        </section>
        {formLocalidades && (<LocalidadesForm show={formLocalidades} onHide={(e) => {
            setFormLocalidades(false)
        }}
            onSave={refetch}
        />
        )}
    </div>
    )
}

export default Localidades
