import React from "react";

const Spinner = () => {
    return(
        <div className="spinner-border" role="status">
            <span className="visually-hidden">Cargando...</span>
        </div>
    );
};

export default Spinner;