import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const unidadesMedidasApi = createApi({
    reducerPath: 'unidadesMedidasApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "unidades/medidas",
        prepareHeaders: (headers, {getState}) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        obtenerUnidadMedida: build.query({
            query: (idUnidadMedida) => `/${idUnidadMedida}`,
        }),
        obtenerUnidadesMedidas: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        guardarUnidadMedida: build.mutation({
            query: (unidadMedida) => ({
                url: '',
                method: unidadMedida.idUnidadMedida ? 'PUT' : 'POST',
                body: unidadMedida,
            }),
        }),
        eliminarUnidadMedida: build.mutation({
            query: (unidadMedida) => ({
                url: '',
                method: 'DELETE',
                body: unidadMedida,
            }),
        }),
    }),
});
export const {
    useObtenerUnidadMedidaQuery,
    useObtenerUnidadesMedidasQuery,
    useLazyObtenerUnidadesMedidasQuery,
    useGuardarUnidadMedidaMutation,
    useEliminarUnidadMedidaMutation
} = unidadesMedidasApi;
