import {
    useObtenerFabricantesTransportesQuery,
    useEliminarFabricanteTransporteMutation,
    useLazyObtenerFabricantesTransportesQuery,
} from "../../../services/fabricanteTransporte.service";
import React, { useState } from "react";
import { Button, Row, Col, Card } from "react-bootstrap";
import SeverPagination from "../../../components/base/SeverPagination";
import FabricantesTransportesForm from "./FabricantesTransportesForm";
import BootstrapTable from "../../../components/base/BootstrapTable";
import ConfirmDialog from "../../../components/base/ConfirmDialog";
import { ExportToExcel } from "../../../components/base/ExportToExcel";

const FabricantesTransportes = () => {
    const [pageNumber, setPageNumber] = useState(1);
    const [elementsPerPage, setElementsPerPage] = useState(10);

    const [formFabricantesTransportes, setFormFabricantesTransportes] =
        useState(null);
    const [confirmDialog, setConfirmDialog] = useState({});

    const { data: fabricantesTransportes, refetch } =
        useObtenerFabricantesTransportesQuery([pageNumber, elementsPerPage], {
            refetchOnMountOrArgChange: true,
        });
    const [trigger] = useLazyObtenerFabricantesTransportesQuery();

    const [eliminarFabricanteTransporte] =
        useEliminarFabricanteTransporteMutation();

    return (
        <div id="main" className="main">
            <section className="section">
                <Row>
                    <Col lg={12}>
                        <Card>
                            <Card.Body>
                                <div
                                    className="btn-toolbar justify-content-between"
                                    role="toolbar"
                                >
                                    <div className="pagetitle">
                                        <h1>Fabricantes de transportes</h1>
                                    </div>
                                </div>
                                {fabricantesTransportes && (
                                    <BootstrapTable
                                        striped
                                        toolbar={({ columns }) => (
                                            <div>
                                                <Button
                                                    href="#"
                                                    className="me-2"
                                                    variant="primary"
                                                    onClick={(e) => {
                                                        setFormFabricantesTransportes({});
                                                    }}
                                                >
                                                    <i className="bi bi-plus-square"></i>&nbsp;Nuevo
                                                </Button>
                                                <ExportToExcel
                                                    columns={columns}
                                                    onClick={trigger}
                                                    fileName={"fabricantesTransportes"}
                                                />
                                            </div>
                                        )}
                                        data={fabricantesTransportes.data}
                                        columns={[
                                            {
                                                header: "Fabricante de transporte",
                                                accessorKey: "fabricanteTransporte",
                                            },
                                            {
                                                header: "Estatus",
                                                accessorKey: "estatus.estatus",
                                                enableGlobalFilter: false,
                                            },
                                            {
                                                header: "Acciones",
                                                enableGlobalFilter: false,
                                                cell: ({ row: { original } }) => (
                                                    <div className="w-100 d-grid d-sm-block gap-1">
                                                        <Button
                                                            variant="warning"
                                                            className="me-2"
                                                            size="sm"
                                                            onClick={(e) =>
                                                                setFormFabricantesTransportes(original)
                                                            }
                                                        >
                                                            <i className="bi bi-pencil-square"> </i>
                                                            Editar
                                                        </Button>
                                                        <Button
                                                            variant="danger"
                                                            className="me-2"
                                                            size="sm"
                                                            onClick={(e) =>
                                                                setConfirmDialog({
                                                                    message: "¿Desea eliminar este registro?",
                                                                    onConfirm: async () => {
                                                                        await eliminarFabricanteTransporte({
                                                                            idFabricanteTransporte:
                                                                                original.idFabricanteTransporte,
                                                                        });
                                                                        refetch();
                                                                        setConfirmDialog({});
                                                                    },
                                                                })
                                                            }
                                                        >
                                                            <i className="bi bi-trash"> </i>
                                                            Eliminar
                                                        </Button>
                                                    </div>
                                                ),
                                            },
                                        ]}
                                        onGlobalFilterChange={(e) => {
                                            setPageNumber(1);
                                            setElementsPerPage(e ? -1 : 10);
                                        }}
                                    />
                                )}
                                {fabricantesTransportes && (
                                    <SeverPagination
                                        data={fabricantesTransportes.data}
                                        currentPage={pageNumber}
                                        totalElements={fabricantesTransportes.totalElements}
                                        elementsPerPage={elementsPerPage}
                                        onPageChange={setPageNumber}
                                        onElementsPerPageChange={setElementsPerPage}
                                    />
                                )}
                            </Card.Body>

                            <ConfirmDialog
                                {...confirmDialog}
                                onCancel={() => setConfirmDialog({})}
                            />
                        </Card>
                    </Col>
                </Row>
            </section>
            {formFabricantesTransportes && (
                <FabricantesTransportesForm
                    show={formFabricantesTransportes}
                    onHide={(e) => {
                        setFormFabricantesTransportes(false);
                    }}
                    onSave={refetch}
                />
            )}
        </div>
    );
};

export default FabricantesTransportes;
