import {
    useEliminarEmpresaMutation,
    useBuscarEmpresasQuery,
} from "../../../services/empresa.service";
import React, { useEffect, useState } from "react";
import { Button, Row, Col, Card, Form } from "react-bootstrap";
import SeverPagination from "../../../components/base/SeverPagination";
import EmpresasVista from "./EmpresasVista";
import EmpresaCard from "../../../components/empresas/EmpresaCard";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { cleanProperties } from "../../../helpers/cleanProperties";
import { Controller, useForm } from "react-hook-form";
import { useObtenerEstatusQuery } from "../../../services/estatus.service";
import { useObtenerRegimenesFiscalesQuery } from "../../../services/regimenFiscal.service";

const Empresas = () => {
    const { control, register, reset, handleSubmit } = useForm();

    //Funcionalidades propias del Proceso Empresas /EDITAR/ELIMINAR
    const [vistaEmpresas, setVistaEmpresas] = useState(null);
    const [eliminarEmpresa] = useEliminarEmpresaMutation();

    const { state, pathname } = useLocation();
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useState({});

    const [pageNumber, setPageNumber] = useState(1);
    const [elementsPerPage, setElementsPerPage] = useState(10);

    const { data: empresas, refetch } = useBuscarEmpresasQuery(
        { pageNumber, elementsPerPage, searchParams },
        { refetchOnMountOrArgChange: true }
    );

    // const { data: estatus } = useObtenerEstatusQuery();

    const onSubmit = async (formData) => {
        setSearchParams(cleanProperties(formData));
        refetch();
    };

    const clearForm = () => {
        navigate(pathname, {});
        setSearchParams({});
        reset();
    };

    useEffect(() => {
        setSearchParams(state || {});
        reset({
            buscador: state?.buscador || "",
            idEstatus: state?.idEstatus || "1",
        });
    }, [reset, setSearchParams, state]);

    const { data: regimenesFiscales } = useObtenerRegimenesFiscalesQuery();

    return (
        <>
            <main id="main" className="main">
                <Form
                    id="formulario"
                    className="row g-3"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div className="input-group">
                        <Form.Control
                            type="text"
                            placeholder="Buscar por: Empresa, Contacto/Representante"
                            {...register("buscador")}
                        />
                        <Button type="submit" variant="primary" title={"Buscar"}>
                            <i className="bi bi-search"></i>
                        </Button>
                        <Button
                            className="ms-2"
                            variant="primary"
                            title={"Limpiar"}
                            onClick={(e) => clearForm()}
                        >
                            <i className="bi bi-eraser"></i>
                        </Button>
                        <Button
                            variant="primary"
                            className="ms-2"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#filtros"
                            aria-expanded="false"
                            aria-controls="filtros"
                            title={"Filtros"}
                        >
                            <i className="bi bi-sliders"></i>
                        </Button>
                    </div>

                    <div className="collapse" id="filtros">
                        <Card className="card-body mb-0">
                            <h5 className="mb-3 mt-3 fw-bold">Criterios de filtrado.</h5>
                            <Row>
                                <Col className="border-end">
                                    <Form.Label htmlFor="rfc" className="form-label">
                                        RFC
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        className="form-control mb-3"
                                        id="rfc"
                                        {...register("rfc")}
                                    />
                                </Col>

                                <Col className="border-end">
                                    <Form.Label htmlFor="idRegimenFiscal">
                                        Régimen fiscal
                                    </Form.Label>
                                    <Form.Select
                                        className="mwx-400"
                                        id="idRegimenFiscal"
                                        {...register("idRegimenFiscal", {})}
                                    >
                                        <option value="">Seleccionar</option>
                                        {regimenesFiscales &&
                                            regimenesFiscales.data.map((regimenFiscal) => (
                                                <option
                                                    key={regimenFiscal.idRegimenFiscal}
                                                    value={regimenFiscal.idRegimenFiscal}
                                                >
                                                    {regimenFiscal.regimenFiscal}
                                                </option>
                                            ))}
                                    </Form.Select>
                                </Col>

                                <Col className="border-end">
                                    <Controller
                                        name="idEstatus"
                                        control={control}
                                        render={({ field }) => (
                                            <>
                                                <Form.Label htmlFor="idEstatus">
                                                    Estatus de la solicitud
                                                </Form.Label>
                                                <Form.Check
                                                    id="estatus.-0"
                                                    type="radio"
                                                    label="Todas"
                                                    value=""
                                                    {...register("idEstatus")}
                                                    defaultChecked={!state?.idEstatus}
                                                />
                                                <Form.Check
                                                    id="estatus.1"
                                                    type="radio"
                                                    label="Activo"
                                                    value="1" // Valor fijo para 'Activo'
                                                    {...register("idEstatus")}
                                                    defaultChecked={parseInt(state?.idEstatus) === 1}
                                                />
                                                <Form.Check
                                                    id="estatus.0"
                                                    type="radio"
                                                    label="Inactivo"
                                                    value="0" // Valor fijo para 'Inactivo'
                                                    {...register("idEstatus")}
                                                    defaultChecked={parseInt(state?.idEstatus) === 0}
                                                />
                                            </>
                                        )}
                                    />
                                </Col>
                            </Row>

                            <Row className="text-end mt-3">
                                <Button type="submit" variant="primary">
                                    Aplicar
                                </Button>
                            </Row>
                        </Card>
                    </div>
                </Form>

                <Row className="mt-3">
                    <Link
                        className="col-12 text-end"
                        to={"/administracion/empresas/view"}
                    >
                        <Button variant="primary">
                            <i className="bi bi-file-earmark-plus-fill"></i> Nuevo
                        </Button>
                    </Link>
                </Row>

                {empresas?.data &&
                    empresas.data.map((empresa) => (
                        <EmpresaCard
                            key={"empresa." + empresa.idEmpresa}
                            empresa={empresa}
                            refetch={refetch}
                            eliminarEmpresa={eliminarEmpresa}
                        />
                    ))}

                {empresas && (
                    <SeverPagination
                        data={empresas.data}
                        currentPage={pageNumber}
                        totalElements={empresas.totalElements}
                        elementsPerPage={elementsPerPage}
                        onPageChange={setPageNumber}
                        onElementsPerPageChange={setElementsPerPage}
                    />
                )}

                {vistaEmpresas && (
                    <EmpresasVista
                        show={vistaEmpresas}
                        onHide={(e) => {
                            setVistaEmpresas(false);
                        }}
                        onSave={refetch}
                    />
                )}
            </main>
        </>
    );
};

export default Empresas;
