import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const consultasApi = createApi({
    reducerPath: 'consultasApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "consultas",
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (build) => ({
        obtenerBandejaSolicitudes: build.query({
            query: (idEstatus) => `bandejas/${idEstatus}`,
        }),
        obtenerLineaTiempo: build.query({
            query: (idSolicitudPago) => `lineas/tiempos/${idSolicitudPago}`,
        }),

    }),
});

export const { useObtenerBandejaSolicitudesQuery, useObtenerLineaTiempoQuery } = consultasApi;
