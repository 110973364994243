import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const seccionesAlmacenesApi = createApi({
    reducerPath: 'seccionesAlmacenesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "secciones/almacenes",
        prepareHeaders: (headers, {getState}) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        obtenerSeccionAlmacen: build.query({
            query: (idSeccionAlmacen) => `/${idSeccionAlmacen}`,
        }),
        obtenerSeccionesAlmacenes: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        obtenerSeccionesAlmacenesPorAlmacen: build.query({
            query: (idAlmacen) => `/busqueda/${idAlmacen}?page=1&size=-1`,
        }),
        guardarSeccionAlmacen: build.mutation({
            query: (seccionAlmacen) => ({
                url: '',
                method: seccionAlmacen.idSeccionAlmacen ? 'PUT' : 'POST',
                body: seccionAlmacen,
            }),
        }),
        eliminarSeccionAlmacen: build.mutation({
            query: (seccionAlmacen) => ({
                url: '',
                method: 'DELETE',
                body: seccionAlmacen,
            }),
        }),
    }),
});

export const {
    useObtenerSeccionAlmacenQuery,
    useObtenerSeccionesAlmacenesQuery,
    useLazyObtenerSeccionesAlmacenesQuery,
    useObtenerSeccionesAlmacenesPorAlmacenQuery,
    useGuardarSeccionAlmacenMutation,
    useEliminarSeccionAlmacenMutation
} = seccionesAlmacenesApi;
