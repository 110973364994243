import React, { useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Alert, Button, Card, Form, InputGroup, Col, Row, FormGroup } from "react-bootstrap"
import { useGuardarMantenimientoMutation, useObtenerMantenimientoQuery } from "../../../services/mantenimiento.service"
import { useObtenerTiposMantenimientosQuery } from "../../../services/tipoMantenimiento.service";
import { useBuscarTransporteQuery } from "../../../services/transporte.service";
import { useObtenerEmpresasQuery } from "../../../services/empresa.service";
import Select from "react-select";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import TransporteInfo from "../../mantenimientos/mantenimientos/TransporteInfo";
import { Link, useParams } from "react-router-dom";
import { useObtenerEstatusPorTipoQuery } from "../../../services/estatus.service";
import FormLabelCounter from "../../../components/base/FormLabelCounter";
import { NumericFormat } from "react-number-format";
import {
    useEliminarMantenimientoPiezaMutation,
    useObtenerMantenimientosPiezasQuery
} from "../../../services/mantenimientoPieza.service";
import { v4 as uuidv4 } from "uuid";
import BootstrapTable from "../../../components/base/BootstrapTable";
import { toCurrency } from "../../../helpers/toCurrency";
import ConfirmDialog from "../../../components/base/ConfirmDialog";
import ControlMantenimientosPiezas from "./ControlMantenimientosPiezas";

const ControlMantenimientosForm = () => {

    const { id: idMantenimiento } = useParams();

    const { handleSubmit, setValue, register, reset, control, watch, formState: { errors } } = useForm()

    const { data: mantenimiento } = useObtenerMantenimientoQuery(idMantenimiento, { skip: !idMantenimiento, refetchOnMountOrArgChange: true })
    const [guardarMantenimiento] = useGuardarMantenimientoMutation()

    const { data: tiposMantenimientos } = useObtenerTiposMantenimientosQuery();
    const { data: empresas } = useObtenerEmpresasQuery();
    const { data: estatus } = useObtenerEstatusPorTipoQuery(2);

    const transporteRef = useRef(null);
    const [searchTransporte, setSearchTransporte] = useState("");
    const [buscadorTransporte, setBuscadorTransporte] = useState("");
    const { data: transportes } = useBuscarTransporteQuery({ buscador: buscadorTransporte });
    const [showModalTransporte, setShowModalTransporte] = useState(null);

    const { data: piezasData } = useObtenerMantenimientosPiezasQuery(idMantenimiento, { skip: !idMantenimiento, refetchOnMountOrArgChange: true });
    const [eliminarPieza] = useEliminarMantenimientoPiezaMutation();

    const [confirmDialog, setConfirmDialog] = useState({});
    const [formPiezas, setFormPiezas] = useState(null);
    const [piezas, setPiezas] = useState([]);

    const handleShowModalTransporte = () => {
        setShowModalTransporte(watch("idTransporte"));
    };

    const handleCloseModalTransporte = () => {
        setShowModalTransporte(null);
    };

    useEffect(() => {
        if (mantenimiento && tiposMantenimientos && transportes) {
            reset({
                idMantenimiento: mantenimiento.idMantenimiento,
                idTransporte: mantenimiento.idTransporte,
                idTipoMantenimiento: mantenimiento.idTipoMantenimiento,
                idTipoTransporte: mantenimiento.idTipoTransporte,
                idClaseTransporte: mantenimiento.idClaseTransporte,
                idFabricanteTransporte: mantenimiento.idFabricanteTransporte,
                idEmpresa: mantenimiento.idEmpresa,
                idMarcaTransporte: mantenimiento.idMarcaTransporte,
                fechaMantenimiento: mantenimiento.fechaMantenimiento,
                kilometraje: mantenimiento.kilometraje,
                idEstatus: mantenimiento.idEstatus,
                descripcion: mantenimiento.descripcion,
                totalMantenimiento: mantenimiento.totalMantenimiento,
                fechaConclusion: mantenimiento.fechaConclusion,
                fechaSugeridaMantenimiento: mantenimiento.fechaSugeridaMantenimiento,
                observacionesAdicionales: mantenimiento.observacionesAdicionales,
                fechaVersion: mantenimiento.fechaVersion
            })
        }
    }, [mantenimiento, tiposMantenimientos, transportes, reset])

    //carga piezas
    useEffect(() => {
        if (piezasData) {
            setPiezas(piezasData.map(e => {
                return { ...e, uuid: uuidv4() }
            }))
        }
    }, [piezasData, setPiezas]);


    const actualizarPiezas = (uuid, nuevaPieza) => {
        setPiezas(prevPiezas => {
            // Verificar si idAReemplazar no es null
            if (prevPiezas.find(e => (e.uuid === uuid))) {
                // Actualizar el producto existente si el ID coincide
                return prevPiezas.map(pieza =>
                    pieza.uuid === uuid ? nuevaPieza : pieza
                );
            } else {
                // Agregar un nuevo producto al final del array
                return [...prevPiezas, nuevaPieza];
            }
        });

        if (nuevaPieza.nuevo) {
            setFormPiezas({});
        } else {
            setFormPiezas(false);
        }
    };

    const onSubmit = async (formData) => {
        formData.mantenimientoPiezas = piezas.map(pieza => {
            let data = { ...pieza };
            data.idMantenimiento = formData.idMantenimiento;
            data.idTransporte = formData.idTransporte;
            data.idEstatus = formData.idEstatus;
            return data;
        });

        let { data } = await guardarMantenimiento(formData)
        if (data) {
            setValue('idMantenimiento', data.id)
            setValue('fechaVersion', data.fechaVersion)
        }
    }

    const labelByTrasporte = (transporte) => {
        if (!transporte) return null;
        return `Placa: ${transporte.numeroPlaca} - Marca: ${transporte.marcaTransporte.marcaTransporte} - Modelo: ${transporte.modelo}`
    }


    return (
        <div id="main" className="main">
            <section className="section">
                <Row>
                    <Col lg={12}>
                        <Card>
                            <Card.Body>
                                <fieldset>
                                    <legend>
                                        <div className='d-flex justify-content-between mb-3'>
                                            <p className='fs-5 fw-bold'>Control de mantenimientos</p>
                                            <p className='fs-6 text-end'> *Los campos en negritas son requeridos{" "}</p>
                                        </div>
                                    </legend>
                                </fieldset>

                                <Form onSubmit={handleSubmit(onSubmit)}>
                                    <Form.Control type="text" id="idMantenimiento"
                                        className="d-none" {...register("idMantenimiento")} />
                                    <Form.Control type="text" id="fechaVersion"
                                        className="d-none" {...register("fechaVersion")} />

                                        <Card>
                                            <div className='container'>
                                                <div className='row'>
                                                    <div className='col-md-8'>
                                                        <div className='fw-bold'>
                                                            <p className='fs-3'>Información y Detalles del Mantenimiento</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='container'>
                                                <div className='row'>
                                                    <div className='col-md-3'>

                                                        <FormGroup className='mb-3'>
                                                            <Form.Label htmlFor='idTransporte' className='fw-bold'>
                                                                Vehículo
                                                            </Form.Label>
                                                            <InputGroup>
                                                                <Controller
                                                                    render={({ field }) => (
                                                                        transportes?.data &&
                                                                        <Select
                                                                            ref={transporteRef}
                                                                            value={{
                                                                                value: field.value,
                                                                                label: labelByTrasporte(transportes.data.find((t) => t.idTransporte === field.value)) ?? "Seleccionar"
                                                                            }}
                                                                            inputValue={searchTransporte}
                                                                            menuIsOpen={searchTransporte}
                                                                            isSearchable={true}
                                                                            placeholder="Seleccionar"
                                                                            className="form-floating mwx-450"
                                                                            isDisabled
                                                                            options={transportes.data.map((transporte) => ({
                                                                                value: transporte.idTransporte,
                                                                                label: labelByTrasporte(transporte)
                                                                            }))}
                                                                            onInputChange={(v) => {
                                                                                setSearchTransporte(v);
                                                                                if (v) {
                                                                                    setBuscadorTransporte(v);
                                                                                }
                                                                            }}
                                                                            onChange={(v) => {
                                                                                field.onChange(Number(v.value) > 0 ? Number(v.value) : '0');
                                                                            }}
                                                                            onMenuOpen={(v) => {
                                                                                setSearchTransporte(undefined)
                                                                            }}
                                                                        />

                                                                    )}
                                                                    name="idTransporte"
                                                                    control={control}
                                                                    rules={{ required: "Ingrese un dato válido" }}
                                                                />
                                                                {watch("idTransporte") ? (
                                                                    <>
                                                                        {showModalTransporte && (
                                                                            <TransporteInfo show={showModalTransporte}
                                                                                onHide={handleCloseModalTransporte} />
                                                                        )}
                                                                        <Button className="text-secondary" variant="outline-secondary"
                                                                            onClick={handleShowModalTransporte} title="Ver vehículo">
                                                                            <i className="bi bi-eye"></i>
                                                                        </Button>
                                                                    </>
                                                                ) : ''}
                                                        </InputGroup>
                                                        </FormGroup>
                                                    </div>

                                                    <div className='col-md-3 border-end'>                                                       
                                                        <Form.Group className="mb-3">
                                                            <Form.Label htmlFor="tipoMantenimiento" className='fw-bold'>
                                                                Tipo de mantenimiento
                                                            </Form.Label>
                                                            <Form.Select
                                                            className="mwx-450"
                                                            id="idTipoMantenimiento"
                                                            disabled
                                                            {...register("idTipoMantenimiento", {
                                                                required: "Ingrese un dato válido"
                                                            })}
                                                            >
                                                                <option value="">Seleccionar</option>
                                                                {tiposMantenimientos?.data && tiposMantenimientos.data.map((tipoMantenimiento) => (
                                                                    <option key={'tiposMantenimientos.' + tipoMantenimiento.idTipoMantenimiento}
                                                                        value={tipoMantenimiento.idTipoMantenimiento}>
                                                                        {tipoMantenimiento.tipoMantenimiento}
                                                                    </option>
                                                                ))}
                                                            </Form.Select>
                                                            {errors.idTipoMantenimiento && (<Alert variant='danger' role='alert'>
                                                                {errors.idTipoMantenimiento.message}
                                                            </Alert>)}
                                                        </Form.Group>
                                                    </div>

                                                    <div className='col-md-3'>
                                                        <Form.Group className="mb-3 col-sm-12">
                                                            <Form.Label htmlFor="fechaMantenimiento" className="fw-bold">
                                                                Fecha de mantenimiento y hora
                                                            </Form.Label>
                                                            <div>
                                                                <Controller
                                                                    render={({ field }) => (
                                                                        <DatePicker
                                                                            className="form-control"
                                                                            id="fechaMantenimiento"
                                                                            dateFormat="dd/MM/yyyy HH:mm"
                                                                            showTimeSelect
                                                                            timeIntervals={10}
                                                                            selected={field.value ? dayjs(field.value, 'DD/MM/YYYY HH:mm').toDate() : null}
                                                                            onChange={(date) => {
                                                                                field.onChange(dayjs(date).format("DD/MM/YYYY HH:mm"));
                                                                            }}
                                                                            disabled
                                                                        />
                                                                    )}
                                                                    name="fechaMantenimiento"
                                                                    control={control}
                                                                />
                                                            </div>
                                                            {errors.fechaMantenimiento && (<Alert variant="danger" role="alert">
                                                                {errors.fechaMantenimiento.message}
                                                            </Alert>)}
                                                        </Form.Group>
                                                    </div>

                                                    <div className='col-md-3'>
                                                        <Form.Group className="mb-3 col-sm-5">
                                                            <Form.Label htmlFor="kilometraje" className="fw-bold">
                                                                Kilometraje
                                                            </Form.Label>
                                                            <Controller
                                                                render={({ field }) => (
                                                                    <NumericFormat
                                                                        className="form-control mwx-150"
                                                                        id="kilometraje"
                                                                        thousandSeparator={true}
                                                                        decimalScale={2}
                                                                        fixedDecimalScale
                                                                        disabled
                                                                        allowNegative={false}
                                                                        value={field.value || ''}
                                                                        onValueChange={(v) => {
                                                                            field.onChange(Number(v.value) > 0 ? Number(v.value) : '0');
                                                                        }}
                                                                        isAllowed={({ value }) => (value?.split(".")[0].length <= 10)}
                                                                        readOnly
                                                                    />
                                                                )}
                                                                name="kilometraje"
                                                                control={control}
                                                            />
                                                            {errors.kilometraje && (<Alert variant="danger" role="alert">
                                                                {errors.kilometraje.message}
                                                            </Alert>)}
                                                        </Form.Group>
                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className='col-md-3'>
                                                        <Form.Group className='mb-3 col-sm-8'>
                                                            <Form.Label htmlFor='idEmpresa' className='fw-bold'>
                                                                Proveedor
                                                            </Form.Label>
                                                            <Form.Select
                                                                id="idEmpresa"
                                                                className="mwx-350"
                                                                disabled
                                                                {...register("idEmpresa", {
                                                                    required: "Ingrese un dato válido"
                                                                })}
                                                            >
                                                                <option value="">Seleccionar</option>
                                                                {empresas?.data && empresas.data.map((empresa) => (
                                                                    <option key={'empresas.' + empresa.idEmpresa}
                                                                        value={empresa.idEmpresa}>
                                                                        {empresa.empresa}
                                                                    </option>
                                                                ))}
                                                            </Form.Select>
                                                            {errors.idEmpresa && (<Alert variant='danger' role='alert'>
                                                                {errors.idEmpresa.message}
                                                            </Alert>)}
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                        
                                        <Card>
                                            <div className='container'>
                                                <div className='row'>
                                                    <div className='col-md-8'>
                                                        <div className='fw-bold'>
                                                            <p className='fs-3'>Estado del Mantenimiento</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='container'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <Form.Group className="mb-3">
                                                            <FormLabelCounter
                                                                htmlFor="descripcion"
                                                                className="fw-bold mwx-350"
                                                                input={watch('descripcion')}
                                                                maxLength={4000}
                                                            >
                                                                Descripción del trabajo realizado
                                                            </FormLabelCounter>
                                                            <Form.Control
                                                                as="textarea"
                                                                rows="4"
                                                                className="mwx-350"
                                                                {...register('descripcion', {
                                                                    required: 'Ingrese un dato válido',
                                                                    maxLength: { value: 200, message: 'Ha excedido el máximo de caracteres' }
                                                                })}
                                                            />
                                                            {errors.descripcion && (<Alert variant="danger" role="alert" className="mwx-350">
                                                                {errors.descripcion.message}
                                                            </Alert>)}
                                                        </Form.Group>
                                                    </div>

                                                    <div className='col-md-4'>
                                                        <Form.Group className="mb-3">
                                                            <FormLabelCounter
                                                                htmlFor="observacionesAdicionales"
                                                                className="fw-bold mwx-350"
                                                                input={watch('observacionesAdicionales')}
                                                                maxLength={4000}
                                                            >
                                                                Observaciones adicionales
                                                            </FormLabelCounter>
                                                            <Form.Control
                                                                as="textarea"
                                                                rows="4"
                                                                className="mwx-350"
                                                                {...register('observacionesAdicionales', {
                                                                    required: 'Ingrese un dato válido',
                                                                    maxLength: { value: 200, message: 'Ha excedido el máximo de caracteres' }
                                                                })}
                                                            />
                                                            {errors.descripobservacionesAdicionalescion && (<Alert variant="danger" role="alert" className="mwx-350">
                                                                {errors.observacionesAdicionales.message}
                                                            </Alert>)}
                                                        </Form.Group>
                                                    </div>

                                                    <div className='col-md-3'>
                                                        <Form.Group className='mb-3 col-sm-8'>
                                                            <Form.Label htmlFor='idEstatus' className='fw-bold'>
                                                                Estatus
                                                            </Form.Label>
                                                            <Form.Select
                                                                id="idEstatus"
                                                                className="mwx-350"
                                                                {...register("idEstatus", {
                                                                    required: "Ingrese un dato válido"
                                                                })}
                                                            >
                                                                <option value="">Seleccionar</option>
                                                                {estatus && estatus.map((estatus) => (
                                                                    <option key={'estatus.' + estatus.idEstatus}
                                                                        value={estatus.idEstatus}>
                                                                        {estatus.estatus}
                                                                    </option>
                                                                ))}
                                                            </Form.Select>
                                                            {errors.idEstatus && (<Alert variant='danger' role='alert'>
                                                                {errors.idEstatus.message}
                                                            </Alert>)}
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>

                                        <Card>
                                            <div className='container'>
                                                <div className='row'>
                                                    <div className='col-md-8'>
                                                        <div className='fw-bold'>
                                                            <p className='fs-3'>Costos y Fechas</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='container'>
                                                <div className='row'>
                                                    <div className='col-md-3'>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label htmlFor="totalMantenimiento" className="fw-bold">
                                                                Total del mantenimiento
                                                            </Form.Label>
                                                            <Controller
                                                                render={({ field }) => (
                                                                    <NumericFormat
                                                                        className="form-control mwx-350"
                                                                        id="totalMantenimiento"
                                                                        prefix={'$ '}
                                                                        thousandSeparator={true}
                                                                        decimalScale={2}
                                                                        fixedDecimalScale
                                                                        allowNegative={false}
                                                                        value={field.value || ''}
                                                                        onValueChange={(v) => {
                                                                            field.onChange(Number(v.value) > 0 ? Number(v.value) : '0');
                                                                        }}
                                                                        isAllowed={({ value }) => (value?.split(".")[0].length <= 10)}
                                                                    />
                                                                )}
                                                                name="totalMantenimiento"
                                                                control={control}
                                                                rules={{ required: "Ingrese un dato válido" }}
                                                            />
                                                            {errors.totalMantenimiento && (<Alert variant="danger" role="alert">
                                                                {errors.totalMantenimiento.message}
                                                            </Alert>)}
                                                        </Form.Group>
                                                    </div>

                                                    <div className='col-md-3'>
                                                        <Form.Group className="mb-3 col-sm-10">
                                                            <Form.Label htmlFor="fechaConclusion" className="fw-bold">
                                                                Fecha de conclusión
                                                            </Form.Label>
                                                            <div>
                                                                <Controller
                                                                    render={({ field }) => (
                                                                        <DatePicker
                                                                            className="form-control"
                                                                            id="fechaConclusion"
                                                                            dateFormat="dd/MM/yyyy"
                                                                            value={field.value}
                                                                            onChange={(date) => {
                                                                                field.onChange(dayjs(date).format("DD/MM/YYYY"));
                                                                            }}
                                                                            excludeDateIntervals={[
                                                                                { start: 1, end: dayjs(mantenimiento?.fechaMantenimiento, 'DD/MM/YYYY HH:mm').subtract(1, 'day').toDate() },
                                                                            ]}
                                                                        />
                                                                    )}
                                                                    name="fechaConclusion"
                                                                    control={control}
                                                                />
                                                            </div>
                                                            {errors.fechaConclusion && (<Alert variant="danger" role="alert">
                                                                {errors.fechaConclusion.message}
                                                            </Alert>)}
                                                        </Form.Group>
                                                    </div>

                                                    <div className='col-md-4'>
                                                        <Form.Group className="mb-3 col-sm-12">
                                                            <Form.Label htmlFor="fechaSugeridaMantenimiento" className="fw-bold">
                                                                Próxima fecha de mantenimiento sugerida
                                                            </Form.Label>
                                                            <div>
                                                                <Controller
                                                                    render={({ field }) => (
                                                                        <DatePicker
                                                                            className="form-control"
                                                                            id="fechaSugeridaMantenimiento"
                                                                            dateFormat="dd/MM/yyyy"
                                                                            value={field.value}
                                                                            onChange={(date) => {
                                                                                field.onChange(dayjs(date).format("DD/MM/YYYY"));
                                                                            }}
                                                                            excludeDateIntervals={[
                                                                                { start: 1, end: dayjs(mantenimiento?.fechaMantenimiento, 'DD/MM/YYYY HH:mm').subtract(1, 'day').toDate() },
                                                                            ]}
                                                                        />
                                                                    )}
                                                                    name="fechaSugeridaMantenimiento"
                                                                    control={control}
                                                                />
                                                            </div>
                                                            {errors.fechaSugeridaMantenimiento && (<Alert variant="danger" role="alert">
                                                                {errors.fechaSugeridaMantenimiento.message}
                                                            </Alert>)}
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    
                                        <Card>
                                            <div className='container'>
                                                <div className='row'>
                                                    <div className='col-md-5'>
                                                        <div className='fw-bold'>
                                                            <p className='fs-3'> Partes Cambiadas o Reparadas </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='container'>
                                                <div className='row'>
                                                    <fieldset className="mb-2 me-sm-5">
                                                        <BootstrapTable
                                                            striped
                                                            search={false}
                                                            toolbar={({ columns }) => (
                                                                <div className="me-auto">
                                                                    <Button className="me-2" variant="primary" onClick={(e) => {
                                                                        setFormPiezas({})
                                                                    }}>
                                                                        <i className="bi bi-plus-square"></i>&nbsp;Añadir
                                                                    </Button>

                                                                </div>
                                                            )}
                                                            data={piezas || []}
                                                            columns={[{
                                                                header: 'Nombre de la pieza', accessorKey: 'nombrePieza', wrapText: true
                                                            }, {
                                                                header: 'Código de la pieza', accessorKey: 'codigoPieza'
                                                            }, {
                                                                header: 'Cantidad', accessorKey: 'cantidad', className: "text-end"
                                                            }, {
                                                                header: 'Costo', accessorKey: 'costo', className: "text-end",
                                                                cell: ({ row: { original } }) => (toCurrency(original.costo)),
                                                            }, {
                                                                header: 'Acciones',
                                                                enableGlobalFilter: false,
                                                                cell: ({ row: { original } }) => (
                                                                    <>
                                                                        <Button variant="warning" className="me-2" size="sm" title="Editar"
                                                                            onClick={e => setFormPiezas(original)}>
                                                                            <i className="bi bi-pencil-square"> </i>
                                                                        </Button>
                                                                        <Button variant="danger" className="me-2" size="sm" title="Eliminar"
                                                                            onClick={e => setConfirmDialog({
                                                                                message: '¿Desea eliminar este registro?',
                                                                                onConfirm: async () => {
                                                                                    if (original.idMantenimientoPieza) {
                                                                                        await eliminarPieza({ idMantenimientoPieza: original.idMantenimientoPieza });
                                                                                    }

                                                                                    setPiezas(prevPiezas => {
                                                                                        return prevPiezas.filter(pieza => (pieza.uuid !== original.uuid));
                                                                                    });

                                                                                    setConfirmDialog({});
                                                                                }
                                                                            })}>
                                                                            <i className="bi bi-trash"> </i>
                                                                        </Button>
                                                                    </>)
                                                            }]}
                                                        />
                                                        <ConfirmDialog {...confirmDialog} onCancel={() => setConfirmDialog({})} />
                                                    </fieldset>
                                                </div>
                                            </div>
                                        </Card>

                                    <Button type="submit" variant="primary" className="me-2">
                                        <i className="bi bi-save"></i> Guardar
                                    </Button>
                                    <Link className="btn btn-secondary me-md-3 me-2"
                                        to={"/mantenimientos/programaciones/mantenimientos/"}>
                                        <i className="bi bi-box-arrow-in-left"></i> Regresar
                                    </Link>

                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </section>
            {formPiezas && <ControlMantenimientosPiezas show={formPiezas} onHide={(e) => {
                setFormPiezas(false);
            }} onSave={actualizarPiezas} />}
        </div>);
}
export default ControlMantenimientosForm;