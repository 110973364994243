import React from 'react';
import { Modal, Button, Card } from 'react-bootstrap';
import { useObtenerLineaTiempoQuery } from '../../services/consulta.service';
import { useObtenerSolicitudPagoQuery } from '../../services/solicitudPago.service';

const LineaTiempoInfo = ({ show, onHide }) => {
    const handleClose = () => {
        onHide();
    };

    const { data: lineaTiempo } = useObtenerLineaTiempoQuery(show, {
        refetchOnMountOrArgChange: true,
    });

    const { data: solicitudPago } = useObtenerSolicitudPagoQuery(
        { idSolicitudPago: show },
        { refetchOnMountOrArgChange: true }
    );

    return (
        <>
            {lineaTiempo && (
                <Modal show={show} centered onHide={handleClose} size="xl">
                    <Modal.Header closeButton>
                        <Modal.Title>Línea de tiempo</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="vh-75 overflow-auto">
                        <div className="row">
                            <div className="col-md-6">
                                <Card>
                                    <Card.Header className="pb-0 fw-bold">
                                        <h5 className="card-title pt-1 pb-0 text-primary">
                                            {solicitudPago?.folio.toString().padStart(7, '0')} |{' '}
                                            {solicitudPago?.empresaProveedor.empresa}
                                        </h5>
                                    </Card.Header>
                                    <Card.Body>
                                        <p className="card-text mb-1">
                                            <strong>Estatus: </strong>
                                            {solicitudPago?.estatus.estatus}
                                        </p>
                                        <p className="card-text mb-1">
                                            <strong>Solicita: </strong>
                                            {solicitudPago?.empleado.nombreCustom}
                                        </p>
                                        <p className="card-text mb-1">
                                            <strong>Empresa: </strong>
                                            {solicitudPago?.empresa.empresa}
                                        </p>
                                        <p className="card-text mb-1">
                                            <strong>Proyecto: </strong>
                                            {solicitudPago?.proyecto.proyecto}
                                        </p>
                                    </Card.Body>
                                </Card>
                            </div>
                            <div className="col-md-6">
                                <div className="todo-timeline mb-5">
                                    <div className="row">
                                        <ul className="v-timeline">
                                            {lineaTiempo.map((estatus, index) => (
                                                <li key={'lineaTiempo.' + index} className="col">
                          <span
                              className={`v-timeline-icon ${
                                  index === lineaTiempo.length - 1
                                      ? (!estatus.fecha ? 'v-last' : 'v-end')
                                      : !estatus.fecha
                                          ? 'v-not'
                                          : ''
                              }`}
                          ></span>
                                                    <span className="pl-30 top-bold-label">
                            {estatus.estatus}
                          </span>
                                                    {index !== lineaTiempo.length && (
                                                        <>
                                                            <p className="pl-30 mid-paragraph mb-0">
                                                                {estatus.nombreCompleto}
                                                            </p>
                                                            <p className="pl-30">{estatus.fecha}</p>
                                                        </>
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
};

export default LineaTiempoInfo;
