import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const reportesApi = createApi({
    reducerPath: 'reportesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "reportes",
        prepareHeaders: (headers, {getState}) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (build) => ({
        obtenerReporteSolicitudPago: build.query({
            query: (idSolicitudPago) => `/solicitudes/pagos/${idSolicitudPago}?`,
        }),    
        obtenerReporteAlmacenesKardex: build.query({
            query: (searchParams) => ({
                url: `/almacenes/kardex.pdf?${new URLSearchParams(searchParams).toString()}`,
                responseHandler: (response) => response.text(),
            }),
        }),
    }),
});

export const {
    useObtenerReporteSolicitudPagoQuery,
    useObtenerReporteAlmacenesKardexQuery,
} = reportesApi;
