import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { Controller, useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import FormLabelCounter from "../../../components/base/FormLabelCounter";
import ConfirmDialog from "../../../components/base/ConfirmDialog";
import _, { inRange } from "lodash";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import { useObtenerEmpresasEmpleadosQuery } from '../../../services/empresa.service';
import { useObtenerEstatusEtapaQuery } from "../../../services/estatus.service";
import { useObtenerVacacionQuery } from "../../../services/vacacion.service";
import { useGuardarVacacionMutation } from "../../../services/vacacion.service";
/* import { useObtenerEmpleadoQuery } from "../../../services/empleado.service"; */

const VacacionesForm = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { userInfo } = useSelector((state) => state.auth);
    const {
        control,
        register,
        unregister,
        reset,
        handleSubmit,
        setValue,
        getValues,
        watch,
        formState: { errors }
    } = useForm();

    const { id: idVacacion } = useParams();

    const {
        data: vacacion,
        refetch: refetchVacacion
    } = useObtenerVacacionQuery({ idVacacion }, { skip: !idVacacion, refetchOnMountOrArgChange: true });

    const currentButton = useRef(null);

    const {
        data: botones,
        refetch: refetchBotones
    } = useObtenerEstatusEtapaQuery([idVacacion || 0, idVacacion ? userInfo.etapa : 1], { refetchOnMountOrArgChange: true });

    const [guardarVacacion] = useGuardarVacacionMutation();
    const refetchAll = async () => {
        await refetchVacacion();
        refetchBotones();
    }

    const [idEmpresa, setIdEmpresa] = useState(0);
    const [idEmpleado, setIdEmpleado] = useState(userInfo.idEmpleado);
    const [estatus, setEstatus] = useState('');
    const [fechaSolicitud, setFechaSolicitud] = useState('');
    const [confirmDialog, setConfirmDialog] = useState({});
    const [fechaIngreso, setFechaIngreso] = useState('');
    const [fechaInicial, setFechaInicial] = useState('');
    const [fechaFinal, setFechaFinal] = useState('');
    const [diasSolicitados, setDiasSolicitados] = useState(0);
    const [formObservaciones, setFormObservaciones] = useState(null);

    const calculateDaysDifference = (startDate, endDate) => {
        console.log("Start Date:", startDate);
        console.log("End Date:", endDate);
        if (startDate && endDate) {
            const start = dayjs(startDate, "DD/MM/YYYY");
            const end = dayjs(endDate, "DD/MM/YYYY");
            const difference = end.diff(start, 'day') + 1; // Add 1 to include both start and end dates
            console.log("Days Difference:", difference);
            setDiasSolicitados(difference > 0 ? difference : 0);
        } else {
            setDiasSolicitados(0);
        }
    };


    const [nombreEmpleado, setNombreEmpleado] = useState(userInfo.userName);

    const { data: empresas } = useObtenerEmpresasEmpleadosQuery();
    /* const { data: empleado2 } = useObtenerEmpleadoQuery(); */


    /* const deshabilitarCampos = getValues('idEstatus') ? ![101, 104].includes(getValues('idEstatus')) : false; */

    useEffect(() => {
        if (vacacion) {
            const {
                idEmpleado,
                idEmpresa,
                idEstatus,
                idVacacionLey,
                fechaSolicitud,
                fechaIncial,
                fechaFinal,
                diasSolicitados,
                diasAutorizados,
                observaciones,
                usuario,
                fechaVersion,
                estatus,
                empleado
            } = vacacion;

            if (empresas)
                setIdEmpresa(idEmpresa);

            /* if (empleado2)
                setIdEmpleado(idEmpleado)
 */
            setEstatus(estatus.estatus);
            setFechaSolicitud(fechaSolicitud);
            setNombreEmpleado(empleado.nombreCustom);
        }
    }, [reset, setValue, vacacion, empresas/* ,empleado2 */]);

    const onSubmit = async (formData, urlBase) => {
        let data;
        formData.idVacacion = parseInt(idVacacion)
        formData.diasSolicitados = diasSolicitados

        let result = await guardarVacacion(formData);
        data = result.data;

        if (data) {
            toast.success(currentButton.current.mensajeOk);
            console.log('Data 1', data)
        } else {
            toast.error(currentButton.current.mensajeError);
            console.log('Data 2', data)
        }

        if (data) {
            if (urlBase) {
                navigate(urlBase);
            } else {
                if (!idVacacion) {
                    navigate(`${location.pathname}/${data?.objeto.idVacacion}`);
                    console.log('Data 3', data)
                } else if (data.objeto) {

                    let { idVacacion, fechaVersion, idEstatus, fechaSolicitud } = data.objeto;
                    setValue('idVacacion', idVacacion);
                    setValue('fechaVersion', fechaVersion)
                    setValue('idEstatus', idEstatus)

                    setFechaSolicitud(fechaSolicitud)
                    console.log('Data 4', data)
                    refetchAll();
                    console.log('Data 5', data)
                }
            }
        }
    };

    console.log('Hola antes del return');
    console.log('Vacacion ', vacacion)
    console.log('Nombre empleado ', nombreEmpleado)
    console.log('Empresa ', empresas)
    console.log('User info', userInfo)
    /* console.log('Empleado ',empleado2) */
    /* console.log('Id empleado',userInfo.idEmpleado) */

    return (
        <>
            <main id="main" className="main">
                <section className="section">
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <Card.Body>
                                    <fieldset>
                                        <legend>
                                            <h2>NUEVA [SOLICITUD DE VACACIONES]</h2>
                                            <h6> *Los campos en negritas son requeridos</h6>
                                        </legend>
                                    </fieldset>
                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        {/* Cambiar esto a los ids que voy a ocupar al parecer se quedan asi*/}
                                        <Form.Control type="text" id="idVacacion" className="d-none" defaultValue={idVacacion} {...register("idVacacion")} />
                                        <Form.Control type="text" id="idEstatus" className="d-none" {...register("idEstatus")} />
                                        <Form.Control type="text" id="fechaVersion" className="d-none" {...register("fechaVersion")} />

                                        {/* Si recupera el nombre del empleado */}
                                        <Row className="mb-3">
                                            <Col md={12}>
                                                <Form.Label htmlFor="nombreEmpleado">Nombre Empleado</Form.Label>
                                                <Form.Control type="text" className="mwx-400" id="nombreEmpleado" disabled
                                                    value={nombreEmpleado} />
                                            </Col>
                                        </Row>
                                        {/* Se busca empresa */}
                                        <Row className="mb-3">
                                            <Col md={12}>
                                                <Form.Label htmlFor="idEmpresa" className="fw-bold">Empresa</Form.Label>
                                                <Form.Select
                                                    className="mwx-400"
                                                    value={idEmpresa}
                                                    id="idEmpresa"
                                                    disabled=""
                                                    {...register("idEmpresa", {
                                                        required: "Ingrese un dato válido",
                                                        onChange: (event) => {
                                                            setIdEmpresa(event.target.value);
                                                        },
                                                    })}
                                                >
                                                    <option value="">Seleccionar</option>
                                                    {empresas &&
                                                        empresas.map((empresa) => (
                                                            <option key={'empresa.' + empresa.idEmpresa}
                                                                value={empresa.idEmpresa}>
                                                                {empresa.empresa}
                                                            </option>
                                                        ))}
                                                </Form.Select>
                                                {errors.idEmpresa && (
                                                    <Alert variant="danger" className="mwx-400">
                                                        {errors.idEmpresa.message}
                                                    </Alert>
                                                )}
                                            </Col>
                                        </Row>

                                        {/* Fecha de ingreso */}
                                        <Row className="mb-3">
                                            <Col md={12}>
                                                <Form.Label htmlFor="fechaIngreso">Fecha de ingreso</Form.Label>
                                                <Form.Control type="text" className="mwx-200" id="fechaIngreso" disabled
                                                    value={fechaIngreso} />
                                            </Col>
                                        </Row >

                                        {/* Fecha incial */}
                                        <Row className="mb-3">
                                            <Col md={12}>
                                                <Form.Label htmlFor="fechaInicial" className="fw-bold">Fecha inicio </Form.Label>
                                                <div>
                                                    <Controller
                                                        render={({ field }) => (
                                                            <DatePicker
                                                                className="form-control"
                                                                id="fechaInicial"
                                                                dateFormat="dd/MM/yyyy"
                                                                selected={field.value ? dayjs(field.value, "DD/MM/YYYY").toDate() : null}
                                                                onChange={(date) => {
                                                                    const formattedDate = dayjs(date).format("DD/MM/YYYY");
                                                                    field.onChange(formattedDate);
                                                                    setFechaInicial(formattedDate);
                                                                    calculateDaysDifference(formattedDate, fechaFinal);
                                                                }}
                                                            />
                                                        )}
                                                        name="fechaInicial"
                                                        control={control}
                                                        rules={{ required: "Ingrese un dato válido" }}
                                                    />
                                                    {errors.fechaInicial && (<Alert variant="danger" role="alert">
                                                        {errors.fechaInicial.message}
                                                    </Alert>)}
                                                </div>
                                            </Col>
                                        </Row>

                                        {/* Fecha final */}
                                        <Row className="mb-3">
                                            <Col md={12}>
                                                <Form.Label htmlFor="fechaFinal" className="fw-bold">Fecha fin</Form.Label>
                                                <div>
                                                    <Controller
                                                        render={({ field }) => (
                                                            <DatePicker
                                                                className="form-control"
                                                                id="fechaFinal"
                                                                dateFormat="dd/MM/yyyy"
                                                                selected={field.value ? dayjs(field.value, "DD/MM/YYYY").toDate() : null}
                                                                onChange={(date) => {
                                                                    const formattedDate = dayjs(date).format("DD/MM/YYYY");
                                                                    field.onChange(formattedDate);
                                                                    setFechaFinal(formattedDate);
                                                                    calculateDaysDifference(fechaInicial, formattedDate);
                                                                }}
                                                            />
                                                        )}
                                                        name="fechaFinal"
                                                        control={control}
                                                        rules={{ required: "Ingrese un dato válido" }}
                                                    />
                                                </div>
                                                {errors.fechaFinal && (<Alert variant="danger" role="alert">
                                                    {errors.fechaFinal.message}
                                                </Alert>)}
                                            </Col>
                                        </Row>

                                        {/* Diferencia de días */}
                                        <Row className="mb-3">
                                            <Col md={12}>
                                                <Form.Label htmlFor="diasSolicitados" className="fw-bold">Días</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    className="mwx-200"
                                                    id="diasSolicitados"
                                                    value={diasSolicitados}
                                                    readOnly
                                                    disabled
                                                />
                                            </Col>
                                        </Row>

                                        {/* Botones */}
                                        <Row className="mt-3">
                                            <Col md={12}>
                                                <div className="d-grid gap-2 d-md-block">
                                                    <Link className="btn btn-secondary me-md-3" to={"/solicitudes/vacaciones"}>
                                                        <i className="bi bi-box-arrow-in-left"></i> Regresar
                                                    </Link>
                                                    {botones?.map((boton) => (
                                                        <Button
                                                            key={'btn.' + boton.idEstatus}
                                                            className={'btn ' + boton.css + ' me-md-3'}
                                                            onClick={() => {
                                                                if (boton.idEstatus) {
                                                                    setValue("idEstatus", boton.idEstatus);
                                                                }

                                                                currentButton.current = boton;

                                                                if (boton.confirmar) {
                                                                    setConfirmDialog({
                                                                        message: boton.confirmarMensaje || '.',
                                                                        onConfirm: () => {
                                                                            handleSubmit(onSubmit)();
                                                                            setConfirmDialog({});
                                                                        }
                                                                    })

                                                                } else if (boton.modal) {
                                                                    setFormObservaciones({
                                                                        accion: boton.accion,
                                                                        idVacacion: idVacacion,
                                                                        idEstatus: boton.idEstatus,
                                                                        idEtapa: userInfo.etapa,
                                                                        idProceso: 1,
                                                                        urlBase: boton.urlBase
                                                                    });

                                                                } else {
                                                                    handleSubmit(onSubmit)();
                                                                }
                                                            }}
                                                        >
                                                            <i className={`bi bi-${boton.icono}`}></i> {boton.accion}
                                                        </Button>
                                                    ))}
                                                </div>
                                            </Col>
                                        </Row>

                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </section>
            </main>
        </>
    )
}

export default VacacionesForm;