import React, {useState} from "react";
import {Button, Row, Col, Card} from "react-bootstrap";
import SeverPagination from "../../../components/base/SeverPagination";
import UsuariosForm from "./UsuariosForm";
import BootstrapTable from "../../../components/base/BootstrapTable";
import ConfirmDialog from "../../../components/base/ConfirmDialog";
import {
    useBuscarUsuariosQuery,
    useEliminarUsuarioMutation, useLazyBuscarUsuariosQuery,
} from "../../../services/usuario.service";
import {ExportToExcel} from "../../../components/base/ExportToExcel";
import UsuariosBuscardorForm from "./UsuariosBuscadorForm";
import UsuariosPermisosForm from "./UsuariosPermisosForm";

const Usuarios = () => {
    const [pageNumber, setPageNumber] = useState(1);
    const [elementsPerPage, setElementsPerPage] = useState(10);

    const [formUsuarios, setFormUsuarios] = useState(null);

    const [buscadorData, setBuscadorData] = useState({});
    const [formUsuariosBuscador, setFormUsuariosBuscador] = useState(true);
    const [formUsuariosPermisos, setFormUsuariosPermisos] = useState(null);
    const [confirmDialog, setConfirmDialog] = useState({});

    const {
        data: usuarios = [],
        refetch
    } = useBuscarUsuariosQuery([buscadorData, pageNumber, elementsPerPage], {
        skip: !Object.keys(buscadorData).length,
        refetchOnMountOrArgChange: true
    });
    const [ trigger ] = useLazyBuscarUsuariosQuery();

    const [eliminarUsuario] = useEliminarUsuarioMutation();

    return (<div id="main" className="main">
        <section className="section">
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Body>
                            <div className="btn-toolbar align-items-baseline" role="toolbar">
                                <div className="pagetitle">
                                    <h1>Usuarios</h1>
                                </div>
                            </div>
                            {usuarios &&
                                <BootstrapTable striped
                                                toolbar={({columns}) => (
                                                    <div>
                                                        <Button type="submit" variant="primary" className="me-2"
                                                                onClick={e => (setFormUsuariosBuscador(true))}>
                                                            <i className="bi bi-search"></i> Buscar
                                                        </Button>
                                                        <Button className="me-2" variant="primary"
                                                                onClick={(e) => {
                                                                    setFormUsuarios({nuevo: true})
                                                                }}>
                                                            <i className="bi bi-plus-square"></i>&nbsp;Nuevo
                                                        </Button>
                                                        <ExportToExcel columns={columns}
                                                                       onClick={_ => trigger([buscadorData, 1, -1])}
                                                                       fileName={'usuarios'}/>
                                                    </div>
                                                )}
                                                data={usuarios}
                                                search={false}
                                                columns={
                                                    [{
                                                        header: 'Usuario',
                                                        accessorKey: 'usuario',
                                                    },{
                                                        header: 'Nombre completo',
                                                        accessorKey: 'nombreCompleto',
                                                    },{
                                                        header: 'Rol',
                                                        accessorKey: 'rol.rol',
                                                    }, {
                                                        header: 'Estatus',
                                                        accessorKey: 'estatus.estatus',
                                                        enableGlobalFilter: false
                                                    }, {
                                                        header: 'Acciones',
                                                        enableGlobalFilter: false,
                                                        cell: ({row: {original}}) => (
                                                            <div className="w-100 d-grid d-sm-block gap-1">
                                                                <Button variant="primary" className="me-2" size="sm"
                                                                        onClick={e => setFormUsuariosPermisos(original)}>
                                                                    <i className="bi bi-key"> </i>
                                                                    Permisos
                                                                </Button>
                                                                <Button variant="warning" className="me-2" size="sm"
                                                                        onClick={e => setFormUsuarios(original)}>
                                                                    <i className="bi bi-pencil-square"> </i>
                                                                    Editar
                                                                </Button>
                                                                <Button variant="danger" className="me-2" size="sm"
                                                                        onClick={e => setConfirmDialog({
                                                                            message: '¿Desea eliminar este registro?',
                                                                            onConfirm: async () => {
                                                                                await eliminarUsuario({usuario: original.usuario});
                                                                                refetch();
                                                                                setConfirmDialog({});

                                                                            }
                                                                        })}>
                                                                    <i className="bi bi-trash"> </i>
                                                                    Eliminar
                                                                </Button>
                                                            </div>
                                                        )
                                                    }]
                                                }
                                                onGlobalFilterChange={(e => {
                                                    setPageNumber(1);
                                                    setElementsPerPage(e ? -1 : 10);
                                                })}
                                />
                            }

                            {usuarios  &&
                                <SeverPagination
                                    data={usuarios.data}
                                    currentPage={pageNumber}
                                    totalElements={usuarios.totalElements}
                                    elementsPerPage={elementsPerPage}
                                    onPageChange={setPageNumber}
                                    onElementsPerPageChange={setElementsPerPage}
                                />}
                        </Card.Body>

                        <ConfirmDialog {...confirmDialog} onCancel={() => setConfirmDialog({})}/>
                    </Card>
                </Col>
            </Row>
        </section>
        {formUsuarios && <UsuariosForm show={formUsuarios}
                                       onHide={() => {
                                           setFormUsuarios(false);
                                           //si no se ha consultado nada,abrir buscar
                                           if(!Object.keys(buscadorData).length){
                                               setFormUsuariosBuscador(true);
                                           }
                                       }}
                                       onSave={Object.keys(buscadorData).length ? refetch : null}/>}
        {formUsuariosBuscador &&
            <UsuariosBuscardorForm show={formUsuariosBuscador}
                                   data={buscadorData}
                                   onHide={() => (setFormUsuariosBuscador(false))}
                                   onNew={e => {
                                       setFormUsuariosBuscador(false);
                                       setFormUsuarios({nuevo: true})
                                   }}
                                   onSearch={setBuscadorData}/>}
        {formUsuariosPermisos &&
            <UsuariosPermisosForm show={formUsuariosPermisos} onHide={() => (setFormUsuariosPermisos(null))}/>}
    </div>);
};

export default Usuarios;
