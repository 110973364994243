import React, {useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import {Controller, useForm} from 'react-hook-form';
import {useBuscarSolicitudesPagosQuery} from "../services/solicitudPago.service";
import {NumericFormat} from "react-number-format";
import {useObtenerEstatusQuery} from "../services/estatus.service";
import SolicitudPagoCard from "../components/solicitudes_pagos/SolicitudPagoCard";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {cleanProperties} from "../helpers/cleanProperties";
import dayjs from "dayjs";
import SeverPagination from "../components/base/SeverPagination";
import {Button, Card, Col, Form, Row} from "react-bootstrap";

const SolicitudesBandeja = () => {
    const {control, register, reset, handleSubmit} = useForm();

    const {state, pathname} = useLocation();
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useState({});

    const [pageNumber, setPageNumber] = useState(1);
    const [elementsPerPage, setElementsPerPage] = useState(10);

    const {data: solicitudes, refetch} = useBuscarSolicitudesPagosQuery({pageNumber, elementsPerPage, searchParams}, {refetchOnMountOrArgChange: true});
    const {data: estatus} = useObtenerEstatusQuery();

    const onSubmit = async (formData) => {
        setSearchParams(cleanProperties(formData));
        refetch();
    };

    const clearForm = () => {
        navigate(pathname, {});
        setSearchParams({});
        reset();
    }

    useEffect(() => {
        setSearchParams(state || {});
        reset({buscador: state?.buscador || '', idEstatus: state?.idEstatus || '0'});
    }, [reset, setSearchParams, state]);

    return (<>
        <main id="main" className="main">
            <Form id="formulario" className="row g-3" onSubmit={handleSubmit(onSubmit)}>
                <div className="input-group">
                    <Form.Control
                        type="text"
                        placeholder="Buscar solicitud por: Folio, Proyecto, Proveedor, Empresa"
                        {...register("buscador")}
                    />
                    <Button type="submit" variant="primary" title={"Buscar"}>
                        <i className="bi bi-search"></i>
                    </Button>
                    <Button className="ms-2" variant="primary" title={"Limpiar"} onClick={(e) => (clearForm())}>
                        <i className="bi bi-eraser"></i>
                    </Button>
                    <Button
                        variant="primary"
                        className="ms-2"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#filtros"
                        aria-expanded="false"
                        aria-controls="filtros"
                        title={"Filtros"}
                    >
                        <i className="bi bi-sliders"></i>
                    </Button>
                </div>
                <div className="collapse" id="filtros">
                    <Card className="card-body mb-0">
                        <h5 className="mb-3 mt-3 fw-bold">Criterios de filtrado.</h5>
                        <Row>
                            <Col className="border-end">
                                <h6>Rango de Fechas</h6>
                                <Form.Label htmlFor="fechaInicial" className="form-label">
                                    Fecha Inicial
                                </Form.Label>
                                <Col xs={6} className="mb-2">
                                    <Controller
                                        render={({ field }) => (
                                            <DatePicker
                                                className="form-control"
                                                id="fechaInicial"
                                                dateFormat="dd/MM/yyyy"
                                                value={field.value}
                                                onChange={(date) => {
                                                    field.onChange(dayjs(date).format("DD/MM/YYYY"));
                                                }}
                                            />
                                        )}
                                        name="fechaInicial"
                                        control={control}
                                    />
                                </Col>
                                <Form.Label htmlFor="fechaFinal" className="form-label">
                                    Fecha Final
                                </Form.Label>
                                <Col xs={6}>
                                    <Controller
                                        render={({ field }) => (
                                            <DatePicker
                                                className="form-control"
                                                id="fechaFinal"
                                                dateFormat="dd/MM/yyyy"
                                                value={field.value}
                                                onChange={(date) => {
                                                    field.onChange(dayjs(date).format("DD/MM/YYYY"));
                                                }}
                                            />
                                        )}
                                        name="fechaFinal"
                                        control={control}
                                    />
                                </Col>
                            </Col>
                            <Col className="border-end">
                                <Form.Label htmlFor="solicitante" className="form-label">
                                    Nombre del solicitante
                                </Form.Label>
                                <Form.Control type="text" className="form-control mb-3" id="solicitante" {...register("nombreCompleto")} />
                            </Col>
                            <Col className="border-end">
                                <h6>Estatus de la solicitud</h6>
                                <Form.Check
                                    id={'estatus.0'}
                                    type="radio"
                                    label={"Todas"}
                                    value=""
                                    {...register("idEstatus")}
                                    defaultChecked={!state?.idEstatus}
                                />
                                {estatus && estatus.map((est) => (
                                    <Form.Check
                                        id={'estatus.' + est.idEstatus}
                                        key={'estatus.' + est.idEstatus}
                                        type="radio"
                                        label={est.estatus}
                                        value={est.idEstatus}
                                        {...register("idEstatus")}
                                        defaultChecked={parseInt(state?.idEstatus) === parseInt(est.idEstatus)}
                                    />
                                ))}
                            </Col>
                            <Col>
                                <div>
                                    <Form.Label htmlFor="importeMinimo">Importe de pago</Form.Label>
                                    <Controller
                                        render={({ field }) => (
                                            <NumericFormat
                                                className="form-control mwx-400"
                                                id="importeMinimo"
                                                thousandSeparator={true}
                                                prefix={'$ '}
                                                decimalScale={2}
                                                fixedDecimalScale
                                                allowNegative={false}
                                                value={field.value || ''}
                                                onValueChange={(v) => {
                                                    field.onChange(Number(v.value) > 0 ? Number(v.value) : null);
                                                }}
                                                isAllowed={({value}) => (value?.split(".")[0].length <= 10)}
                                            />
                                        )}
                                        name="importeMinimo"
                                        control={control}
                                    />
                                </div>
                                <div>
                                    <Form.Label htmlFor="importeMaximo">Importe máximo</Form.Label>
                                    <Controller
                                        render={({ field }) => (
                                            <NumericFormat
                                                className="form-control mwx-400"
                                                id="importeMaximo"
                                                thousandSeparator={true}
                                                prefix={'$ '}
                                                decimalScale={2}
                                                fixedDecimalScale
                                                allowNegative={false}
                                                value={field.value || ''}
                                                onValueChange={(v) => {
                                                    field.onChange(Number(v.value) > 0 ? Number(v.value) : null);
                                                }}
                                                isAllowed={({value}) => (value?.split(".")[0].length <= 10)}
                                            />
                                        )}
                                        name="importeMaximo"
                                        control={control}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row className="text-end mt-3">
                            <Button  type="submit" variant="primary">Aplicar</Button>
                        </Row>
                    </Card>
                </div>
            </Form>
            <Row className="mt-3">
                <Link className="col-12 text-end" to={"/solicitudes/pagos/view"}>
                    <Button variant="primary">
                        <i className="bi bi-file-earmark-plus-fill"></i> Nueva solicitud
                    </Button>
                </Link>
            </Row>

            {solicitudes?.data && solicitudes.data.map((solicitud) => (
                <SolicitudPagoCard key={'solicitud.' + solicitud.idSolicitudPago} solicitud={solicitud}/>
            ))}
            {solicitudes &&
                <SeverPagination
                    data={solicitudes.data}
                    currentPage={pageNumber}
                    totalElements={solicitudes.totalElements}
                    elementsPerPage={elementsPerPage}
                    onPageChange={setPageNumber}
                    onElementsPerPageChange={setElementsPerPage}
                />
            }

        </main>
    </>);
};

export default SolicitudesBandeja;
