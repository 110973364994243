import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const impuestosApi = createApi({
    reducerPath: 'impuestosApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "impuestos",
        prepareHeaders: (headers, {getState}) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        obtenerImpuesto: build.query({
            query: (idImpuesto) => `/${idImpuesto}`,
        }),
        obtenerImpuestos: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        guardarImpuesto: build.mutation({
            query: (impuesto) => ({
                url: '',
                method: impuesto.idImpuesto ? 'PUT' : 'POST',
                body: impuesto,
            }),
        }),
        eliminarImpuesto: build.mutation({
            query: (impuesto) => ({
                url: '',
                method: 'DELETE',
                body: impuesto,
            }),
        }),
    }),
});
export const {
    useObtenerImpuestoQuery,
    useObtenerImpuestosQuery,
    useLazyObtenerImpuestosQuery,
    useGuardarImpuestoMutation,
    useEliminarImpuestoMutation
} = impuestosApi;
