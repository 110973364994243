import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Alert, Button, Form, Modal} from "react-bootstrap";
import {useGuardarTipoPersonaMutation, useObtenerTipoPersonaQuery} from "../../../services/tipoPersona.service";
import {useObtenerTiposPersonasBaseQuery} from '../../../services/tipoPersonaBase.service';

const TiposPersonasForm = ({show, onHide, onSave}) => {

    const handleClose = (e) => {
        onHide(e);
    };

    const {control, handleSubmit, setValue, register, reset, formState: {errors}} = useForm();

    const {data: tipoPersona} = useObtenerTipoPersonaQuery(show.idTipoPersona, {skip: !show.idTipoPersona, refetchOnMountOrArgChange: true});    

    const {data: tiposPersonasBase} = useObtenerTiposPersonasBaseQuery();

    const [guardarTipoPersona] = useGuardarTipoPersonaMutation();

    useEffect(() => {
        if (tipoPersona && tiposPersonasBase) {
            reset({
                idTipoPersona: tipoPersona.idTipoPersona,
                idTipoPersonaBase : tipoPersona.idTipoPersonaBase,
                tipoPersona: tipoPersona.tipoPersona,                
                idEstatus: tipoPersona.idEstatus
            });
        } else {
            //default values
            reset({idEstatus: 1})
        }
    }, [tipoPersona, tiposPersonasBase, reset]);

    const onSubmit = async (formData) => {
        let {data} = await guardarTipoPersona(formData);
        if(data){
            setValue('idTipoPersona', data.id);
            onSave();
        }
    };

    return (<> {
        <Modal show={show} onHide={handleClose} centered backdrop="static" keyboard={false}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>{show.idTipoPersona ? 'Editar' : 'Nuevo'} [ Tipo Persona ]</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="text" id="idTipoPersona" className="d-none" {...register("idTipoPersona")} />
                    <Form.Group className="mb-3">


                        <Form.Label htmlFor='tipoPersona' className='fw-bold'>
                            Tipo de Persona
                        </Form.Label>
                        <Form.Control
                            {...register('tipoPersona', {
                                required: 'Ingrese un dato valido',
                                maxLength: { value: 20, message: 'Ha excedido el máximo de caracteres' }
                            })} />
                            
                                <Form.Label htmlFor="idTipoPersonaBase" className="fw-bold">Base</Form.Label>
                                <Form.Select
                                    className="mwx-400"
                                    id="idTipoPersonaBase"
                                    {...register("idTipoPersonaBase", {
                                        required: "Ingrese un dato válido"
                                    })}
                                >
                                    <option value="">Seleccionar</option>
                                    {tiposPersonasBase?.data &&
                                        tiposPersonasBase?.data.map((tipoPersonaBase) => (
                                            <option key={tipoPersonaBase.idTipoPersonaBase}
                                                value={tipoPersonaBase.idTipoPersonaBase}>
                                                {tipoPersonaBase.tipoPersonaBase}
                                            </option>
                                        ))}
                                </Form.Select>
                                {errors.idTipoPersonaBase && (
                                    <Alert variant="danger" className="mwx-400">
                                        {errors.idTipoPersonaBase.message}
                                    </Alert>
                                )}
                        
                        {errors.tipoPersonaBase && (<Alert variant="danger" role="alert">
                            {errors.tipoPersonaBase.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Controller
                            name="idEstatus"
                            control={control}
                            render={({field}) => (<>
                                    <Form.Label htmlFor="idEstatus">Estatus</Form.Label>
                                    <Form.Check
                                        id="idEstatus"
                                        type="switch"
                                        label={Boolean(field.value) ? 'Activo' : 'Inactivo'}
                                        checked={Boolean(field.value)}
                                        onChange={(e) => {
                                            field.onChange(Number(e.target.checked))
                                        }}
                                    />
                                </>

                            )}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant="primary" className="me-2">
                        <i className="bi bi-save"></i> Guardar
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>}
    </>);
};

export default TiposPersonasForm;
