import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const tiposPagosApi = createApi({
    reducerPath: 'tiposPagosApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (build) => ({
        obtenerTiposPagos: build.query({
            query: () => 'tipos/pagos',
        }),

    }),
});

export const { useObtenerTiposPagosQuery } = tiposPagosApi;
