import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const metodosSalidasAlmacenesApi = createApi({
    reducerPath: 'metodosSalidasAlmacenesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "metodos/salidas/almacenes",
        prepareHeaders: (headers, {getState}) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        obtenerMetodoSalidaAlmacen: build.query({
            query: (idMetodoSalidaAlmacen) => `/${idMetodoSalidaAlmacen}`,
        }),
        obtenerMetodosSalidasAlmacenes: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        guardarMetodoSalidaAlmacen: build.mutation({
            query: (metodoSalidaAlmacen) => ({
                url: '',
                method: metodoSalidaAlmacen.idMetodoSalidaAlmacen ? 'PUT' : 'POST',
                body: metodoSalidaAlmacen,
            }),
        }),
        eliminarMetodoSalidaAlmacen: build.mutation({
            query: (metodoSalidaAlmacen) => ({
                url: '',
                method: 'DELETE',
                body: metodoSalidaAlmacen,
            }),
        }),
    }),
});
export const {
    useObtenerMetodoSalidaAlmacenQuery,
    useObtenerMetodosSalidasAlmacenesQuery,
    useLazyObtenerMetodosSalidasAlmacenesQuery,
    useGuardarMetodoSalidaAlmacenMutation,
    useEliminarMetodoSalidaAlmacenMutation
} = metodosSalidasAlmacenesApi;
