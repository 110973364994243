import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const turnosApi = createApi({
    reducerPath: 'turnosApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "turnos",
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        obtenerTurno: build.query({
            query: (idTurno) => `/${idTurno}`,
        }),
        obtenerTurnos: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        guardarTurno: build.mutation({
            query: (turno) => ({
                url: '',
                method: turno.idTurno ? 'PUT' : 'POST',
                body: turno,
            }),
        }),
        eliminarTurno: build.mutation({
            query: (turno) => ({
                url: '',
                method: 'DELETE',
                body: turno,
            }),
        }),
    }),
});
export const {
    useObtenerTurnoQuery,
    useObtenerTurnosQuery,
    useLazyObtenerTurnosQuery,
    useGuardarTurnoMutation,
    useEliminarTurnoMutation
} = turnosApi;