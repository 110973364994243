import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const tiposCombustiblesApi = createApi({
    reducerPath: 'tiposCombustiblesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "tipos/combustibles",
        prepareHeaders: (headers, {getState}) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        obtenerTipoCombustible: build.query({
            query: (idTipoCombustible) => `/${idTipoCombustible}`,
        }),
        obtenerTiposCombustibles: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        guardarTipoCombustible: build.mutation({
            query: (tipoCombustible) => ({
                url: '',
                method: tipoCombustible.idTipoCombustible ? 'PUT' : 'POST',
                body: tipoCombustible,
            }),
        }),
        eliminarTipoCombustible: build.mutation({
            query: (tipoCombustible) => ({
                url: '',
                method: 'DELETE',
                body: tipoCombustible,
            }),
        }),
    }),
});
export const {
    useObtenerTipoCombustibleQuery,
    useObtenerTiposCombustiblesQuery,
    useLazyObtenerTiposCombustiblesQuery,
    useGuardarTipoCombustibleMutation,
    useEliminarTipoCombustibleMutation
} = tiposCombustiblesApi;
