import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const archivosApi = createApi({
    reducerPath: 'archivosApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "archivos",
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (build) => ({
        guardarArchivo: build.mutation({
            query: ({ ruta, archivo, base64 }) => ({
                url: `/${ruta}`,
                method: 'POST',
                body: {archivo, base64},
            }),
        }),
        obtenerArchivo: build.query({
            query: (idArchivo) => `/base/${idArchivo}`,
        }),
    }),
});

export const {
    useGuardarArchivoMutation,
    useLazyObtenerArchivoQuery
} = archivosApi;
