import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const tiposSeccionesAlmacenesApi = createApi({
    reducerPath: 'tiposSeccionesAlmacenesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "tipos/secciones/almacenes",
        prepareHeaders: (headers, {getState}) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        obtenerTipoSeccionAlmacen: build.query({
            query: (idTipoSeccionAlmacen) => `/${idTipoSeccionAlmacen}`,
        }),
        obtenerTiposSeccionesAlmacenes: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        guardarTipoSeccionAlmacen: build.mutation({
            query: (tipoSeccionAlmacen) => ({
                url: '',
                method: tipoSeccionAlmacen.idTipoSeccionAlmacen ? 'PUT' : 'POST',
                body: tipoSeccionAlmacen,
            }),
        }),
        eliminarTipoSeccionAlmacen: build.mutation({
            query: (tipoSeccionAlmacen) => ({
                url: '',
                method: 'DELETE',
                body: tipoSeccionAlmacen,
            }),
        }),
    }),
});
export const {
    useObtenerTipoSeccionAlmacenQuery,
    useObtenerTiposSeccionesAlmacenesQuery,
    useLazyObtenerTiposSeccionesAlmacenesQuery,
    useGuardarTipoSeccionAlmacenMutation,
    useEliminarTipoSeccionAlmacenMutation
} = tiposSeccionesAlmacenesApi;
