import React, {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Alert, Button, Form, Modal} from "react-bootstrap";
import {useGuardarInventarioMutation, useObtenerInventarioQuery} from "../../../services/inventario.service";
import {useObtenerMarcasQuery} from "../../../services/marca.service";
import {useObtenerColoresQuery} from "../../../services/color.service";
import FormLabelCounter from "../../../components/base/FormLabelCounter";
import {toCurrency} from "../../../helpers/toCurrency";

const InventariosForm = ({show, onHide, onSave}) => {

    const handleClose = (e) => {
        onHide(e);
    };

    const {control, handleSubmit, setValue, register, reset, watch, setFocus, formState: {errors}} = useForm();

    const {data: inventario} = useObtenerInventarioQuery(show.idInventario, {
        skip: !show.idInventario,
        refetchOnMountOrArgChange: true
    });

    const [guardarInventario] = useGuardarInventarioMutation();

    const {data: marcas} = useObtenerMarcasQuery();
    const {data: colores} = useObtenerColoresQuery();

    useEffect(() => {
        if (inventario && marcas && colores) {
            reset({
                idInventario: inventario.idInventario,
                idEntrada: inventario.idEntrada,
                idProducto: inventario.idProducto,
                idUnidadMedida: inventario.idUnidadMedida,
                idMarca: inventario.idMarca,
                idColor: inventario.idColor,
                numeroInventario: inventario.numeroInventario,
                //fechaEntrada: inventario.fechaEntrada,
                consecutivo: inventario.consecutivo,
                precioUnitario: inventario.precioUnitario,
                modelo: inventario.modelo,
                numeroSerie: inventario.numeroSerie,
                descripcionComplementaria: inventario.descripcionComplementaria,
                idEstatus: inventario.idEstatus,
                fechaVersion: inventario.fechaVersion
            });
        }
    }, [inventario, marcas, colores, reset]);

    useEffect(() => {
        if(!show.numeroInventario){
            setFocus("numeroInventario");
        }
    }, [show, setFocus]);

    
    const onSubmit = async (formData) => {
        let {data} = await guardarInventario(formData);
        if (data) {
            setValue('fechaVersion', data.fechaVersion);
            onSave();
        }
    };

    return (<> {
        <Modal show={show} onHide={handleClose} centered backdrop="static" keyboard={false} >
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title className="h6">Código: {show.producto?.codigoProducto} | {show.producto?.producto} | {toCurrency(show.precioUnitario)} | No. de entrada: {show.consecutivo}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="text" id="idInventario" className="d-none" {...register("idInventario")} />
                    <Form.Control type="text" id="fechaVersion" className="d-none" {...register("fechaVersion")} />
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="numeroInventario" className="fw-bold">
                            Número de inventario
                        </Form.Label>
                        <Form.Control
                            className="mwx-350"
                            {...register('numeroInventario', {
                                required: 'Ingrese un dato válido',
                                maxLength: {value: 30, message: 'Ha excedido el máximo de caracteres'}
                            })}
                        />
                        {errors.numeroInventario && (<Alert variant="danger" role="alert">
                            {errors.numeroInventario.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className='mb-3'>
                        <Form.Label htmlFor='idMarca' className='fw-bold'>
                            Marca
                        </Form.Label>
                        <Form.Select
                            className="mwx-350"
                            id="idMarca"
                            {...register("idMarca", {
                                required: "Ingrese un dato válido"
                            })}
                        >
                            <option value="">Seleccionar</option>
                            {marcas?.data && marcas.data.map((marca) => (
                                <option key={'marcas.' + marca.idMarca}
                                        value={marca.idMarca}>
                                    {marca.marca}
                                </option>
                            ))}
                        </Form.Select>
                        {errors.idMarca && (<Alert variant='danger' role='alert' className="mwx-350">
                            {errors.idMarca.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="modelo" className="fw-bold">
                            Modelo
                        </Form.Label>
                        <Form.Control
                            className="mwx-350"
                            {...register('modelo', {
                                required: 'Ingrese un dato válido'
                            })}
                        />
                        {errors.modelo && (<Alert variant="danger" role="alert">
                            {errors.modelo.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="numeroSerie">
                            Número de serie
                        </Form.Label>
                        <Form.Control
                            className="mwx-350"
                            {...register('numeroSerie', {
                                maxLength: {value: 50, message: 'Ha excedido el máximo de caracteres'}
                            })}
                        />
                        {errors.numeroSerie && (<Alert variant="danger" role="alert">
                            {errors.numeroSerie.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className='mb-3'>
                        <Form.Label htmlFor='idColor' className='fw-bold'>
                            Color
                        </Form.Label>
                        <Form.Select
                            className="mwx-350"
                            id="idColor"
                            {...register("idColor", {
                                required: "Ingrese un dato válido"
                            })}
                        >
                            <option value="">Seleccionar</option>
                            {colores?.data && colores.data.map((color) => (
                                <option key={'colores.' + color.idColor}
                                        value={color.idColor}>
                                    {color.color}
                                </option>
                            ))}
                        </Form.Select>
                        {errors.idColor && (<Alert variant='danger' role='alert' className="mwx-350">
                            {errors.idColor.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <FormLabelCounter
                            htmlFor="descripcionComplementaria"
                            className="mwx-350"
                            input={watch('descripcionComplementaria')}
                            maxLength={2000}
                        >
                            Descripción complementaria
                        </FormLabelCounter>
                        <Form.Control
                            as="textarea"
                            rows="4"
                            className="mwx-350"
                            {...register('descripcionComplementaria', {
                                maxLength: {value: 2000, message: 'Ha excedido el máximo de caracteres'}
                            })}
                        />
                        {errors.descripcionComplementaria && (<Alert variant="danger" role="alert" className="mwx-350">
                            {errors.descripcionComplementaria.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Controller
                            name="idEstatus"
                            control={control}
                            render={({field}) => (<>
                                    <Form.Label htmlFor="idEstatus">Estatus</Form.Label>
                                    <Form.Check
                                        id="idEstatus"
                                        type="switch"
                                        label={Boolean(field.value) ? 'Activo' : 'Inactivo'}
                                        checked={Boolean(field.value)}
                                        onChange={(e) => {
                                            field.onChange(Number(e.target.checked))
                                        }}
                                    />
                                </>

                            )}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant="primary" className="ms-auto">
                        <i className="bi bi-save"></i> Guardar
                    </Button>
                    <Button variant="secondary" onClick={handleClose} className="me-auto">
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>}
    </>);
};

export default InventariosForm;
