import {
    useObtenerProductosQuery,
    useEliminarProductoMutation,
    useLazyObtenerProductosQuery,
} from "../../../services/producto.service";
import React, { useState } from "react";
import { Button, Row, Col, Card } from "react-bootstrap";
import SeverPagination from "../../../components/base/SeverPagination";
import ProductosForm from "./ProductosForm";
import BootstrapTable from "../../../components/base/BootstrapTable";
import ConfirmDialog from "../../../components/base/ConfirmDialog";
import { ExportToExcel } from "../../../components/base/ExportToExcel";
import {toCurrency} from "../../../helpers/toCurrency";

const Productos = () => {
    const [pageNumber, setPageNumber] = useState(1);
    const [elementsPerPage, setElementsPerPage] = useState(10);

    const [formProductos, setFormProductos] = useState(null);
    const [confirmDialog, setConfirmDialog] = useState({});

    const { data: productos, refetch } = useObtenerProductosQuery( [pageNumber, elementsPerPage], { refetchOnMountOrArgChange: true });
    const [trigger] = useLazyObtenerProductosQuery();

    const [eliminarProducto] = useEliminarProductoMutation();

    return (
        <div id="main" className="main">
            <section className="section">
                <Row>
                    <Col lg={12}>
                        <Card>
                            <Card.Body>
                                <div className="btn-toolbar justify-content-between" role="toolbar"  >
                                    <div className="pagetitle">
                                        <h1>Productos</h1>
                                    </div>
                                </div>
                                {productos && (
                                    <BootstrapTable
                                        striped
                                        toolbar={({ columns }) => (
                                            <div>
                                                <Button href="#" className="me-2" variant="primary" onClick={(e) => {
                                                        setFormProductos({});
                                                    }} >
                                                    <i className="bi bi-plus-square"></i>&nbsp;Nuevo
                                                </Button>
                                                <ExportToExcel columns={columns} onClick={trigger} fileName={"productos"}/>
                                            </div>
                                        )}
                                        data={productos.data}
                                        columns={[{
                                                header: "Descripción",
                                                accessorKey: "producto",
                                                wrapText: true
                                            }, {
                                                header: "Tipo de producto",
                                                accessorKey: "tipoProducto.tipoProducto",
                                            }, {
                                                header: "Categoría",
                                                accessorKey: "categoriaProducto.categoriaProducto",
                                            }, {
                                                header: "Código de producto",
                                                accessorKey: "codigoProducto",
                                            }, {
                                                header: "Precio de venta",
                                                accessorKey: "precioVenta",
                                                cell: ({ row: { original } }) => (toCurrency(original.precioVenta)),
                                            },{
                                                header: "Estatus",
                                                accessorKey: "estatus.estatus",
                                                enableGlobalFilter: false,
                                            },   {
                                                header: "Acciones",
                                                enableGlobalFilter: false,
                                                cell: ({ row: { original } }) => (
                                                    <div className="w-100 d-grid d-sm-block gap-1">
                                                        <Button
                                                            variant="warning"  className="me-2" size="sm" 
                                                            onClick={(e) => setFormProductos(original)} >
                                                            <i className="bi bi-pencil-square"> </i>
                                                            Editar
                                                        </Button>
                                                        <Button
                                                            variant="danger" className="me-2"  size="sm" onClick={(e) => setConfirmDialog({
                                                                    message: "¿Desea eliminar este registro?",
                                                                    onConfirm: async () => {
                                                                        await eliminarProducto({
                                                                            idProducto: original.idProducto,
                                                                        });
                                                                        refetch();
                                                                        setConfirmDialog({});
                                                                    }
                                                                })} >
                                                            <i className="bi bi-trash"> </i>
                                                            Eliminar
                                                        </Button>
                                                    </div>
                                                ),
                                            },
                                        ]}
                                        onGlobalFilterChange={(e) => {
                                            setPageNumber(1);
                                            setElementsPerPage(e ? -1 : 10);
                                        }}
                                    />
                                )}
                                {productos && (
                                    <SeverPagination
                                        data={productos.data}
                                        currentPage={pageNumber}
                                        totalElements={productos.totalElements}
                                        elementsPerPage={elementsPerPage}
                                        onPageChange={setPageNumber}
                                        onElementsPerPageChange={setElementsPerPage}
                                    />
                                )}
                            </Card.Body>
                            <ConfirmDialog
                                {...confirmDialog} onCancel={() => setConfirmDialog({})} />
                        </Card>
                    </Col>
                </Row>
            </section>
            {formProductos && ( <ProductosForm show={formProductos} onHide={(e) => {
                        setFormProductos(false);
                    }}
                                            onSave={refetch}
                />
            )}
        </div>
    );
};

export default Productos;
