import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const paisesApi = createApi({
    reducerPath: 'paisesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "paises",
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),




    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        
        obtenerPais: build.query({
            query: (idPais) => `/${idPais}`,
        }),
        obtenerPaises: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        guardarPais: build.mutation({
            query: (pais) => ({
                url: '',
                method: pais.idPais ? 'PUT' : 'POST',
                body: pais,
            }),
        }),
        eliminarPais: build.mutation({
            query: (pais) => ({
                url: '',
                method: 'DELETE',
                body: pais,
            }),
        }),
    }),
});

export const {   
               useObtenerPaisQuery,
               useObtenerPaisesQuery,
               useLazyObtenerPaisesQuery, 
               useGuardarPaisMutation,
               useEliminarPaisMutation
} = paisesApi;
