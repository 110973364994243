
import React, { useState } from 'react';
import { NumericFormat } from "react-number-format";
import { Button, Card } from "react-bootstrap";
import EntradasProductosForm from "../../views/procesos/entradas_productos/EntradasProductosForm";
import ConfirmDialog from "../base/ConfirmDialog";
import { Link } from 'react-router-dom';

const EntradaProductoCard = ({ entrada, refetch, eliminarEntradaProducto }) => {

    const [formEntradasProductos, setFormEntradasProductos] = useState(null);
    const [confirmDialog, setConfirmDialog] = useState({});

    return (
        <Card className={`mb-0 mt-3`}>
            <Card.Header className={`pb-0 mb-0 fw-bold alert alert-primary`}>
                <h5 className="card-title pt-1 pb-0 text-primary">Número de entrada: {entrada.numero} </h5>
            </Card.Header>
            <div className="row card-body pt-2">
                <p className="card-text mb-1">
                    <span className="text-muted">Fecha de registro: <b>{entrada.fechaRegistro?.split(" ")[0]}</b></span> |
                    Estatus: <b>{entrada.estatus.estatus}</b></p>
                <div className="col-md-12 mb-2 pb-0">
                    <p className="card-text mb-1">
                        <strong>Almacén: </strong>{entrada.almacen.almacen}</p>
                    <p className="card-text mb-1">
                        <strong>Origen: </strong>{entrada.origenEntrada.origenEntrada}</p>
                    <p className="card-text mb-1">
                        <strong>Total: $ </strong>{entrada.total}</p>

                </div>


                <div>




                    <Link className="col-12 text-end" to={"/entradas/productos/view/" + entrada.idEntrada}>
                        <Button variant="outline-warning" className="me-2" size="sm"
                            onClick={e => setFormEntradasProductos(entrada)}>
                            <i className="bi bi-pencil-square"> </i>
                            Editar
                        </Button>
                    </Link>

                    <Button variant="outline-danger" className="me-2" size="sm" onClick={e => setConfirmDialog({
                        message: '¿Desea eliminar este registro?', onConfirm: async () => {
                            await eliminarEntradaProducto({ idEntrada: entrada.idEntrada });
                            refetch();
                            setConfirmDialog({});

                        }
                    })}>
                        <i className="bi bi-trash"> </i>
                        Eliminar
                    </Button>
                </div>

            </div>
            <ConfirmDialog {...confirmDialog} onCancel={() => setConfirmDialog({})} />

            {formEntradasProductos && <EntradasProductosForm show={formEntradasProductos} onHide={(e) => {
                setFormEntradasProductos(false);
            }} onSave={refetch} />}
        </Card>

    );
};

export default EntradaProductoCard;
