import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const empleadosApi = createApi({
    reducerPath: 'empleadosApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "empleados",
        prepareHeaders: (headers, {getState}) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        obtenerEmpleadosBancos: build.query({
            query: () => '/bancos/empleado',
        }),       
        obtenerEmpleado: build.query({
            query: (idEmpleado) => `/${idEmpleado}`,
        }),
        obtenerEmpleados: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        buscarEmpleados: build.query({
            query: ({pageNumber, elementsPerPage, searchParams}) => `/buscador?page=${pageNumber}&size=${elementsPerPage}&` + new URLSearchParams(searchParams).toString(),
        }),
        guardarEmpleado: build.mutation({
            query: (empleado) => ({
                url: '',
                method: empleado.idEmpleado ? 'PUT' : 'POST',
                body: empleado,
            }),
        }),
        eliminarEmpleado: build.mutation({
            query: (empleado) => ({
                url: '',
                method: 'DELETE',
                body: empleado,
            }),
        }),
    }),
});

export const {
    useObtenerEmpleadoQuery,
    useObtenerEmpleadosQuery,
    useBuscarEmpleadosQuery,
    useObtenerEmpleadosBancosQuery,
    useLazyObtenerEmpleadosQuery,
    useGuardarEmpleadoMutation,
    useEliminarEmpleadoMutation
} = empleadosApi


