import {
    useObtenerColoresQuery,
    useEliminarColorMutation,
    useLazyObtenerColoresQuery,
} from "../../../services/color.service";
import React, { useState } from "react";
import { Button, Row, Col, Card } from "react-bootstrap";
import SeverPagination from "../../../components/base/SeverPagination";
import ColoresForm from "./ColoresForm";
import BootstrapTable from "../../../components/base/BootstrapTable";
import ConfirmDialog from "../../../components/base/ConfirmDialog";
import { ExportToExcel } from "../../../components/base/ExportToExcel";

const Colores = () => {
    const [pageNumber, setPageNumber] = useState(1);
    const [elementsPerPage, setElementsPerPage] = useState(10);

    const [formColores, setFormColores] = useState(null);
    const [confirmDialog, setConfirmDialog] = useState({});

    const { data: colores, refetch } = useObtenerColoresQuery(
        [pageNumber, elementsPerPage],
        { refetchOnMountOrArgChange: true }
    );
    const [trigger] = useLazyObtenerColoresQuery();

    const [eliminarColor] = useEliminarColorMutation();

    return (
        <div id="main" className="main">
            <section className="section">
                <Row>
                    <Col lg={12}>
                        <Card>
                            <Card.Body>
                                <div
                                    className="btn-toolbar justify-content-between"
                                    role="toolbar"
                                >
                                    <div className="pagetitle">
                                        <h1>Colores</h1>
                                    </div>
                                </div>
                                {colores && (
                                    <BootstrapTable
                                        striped
                                        toolbar={({ columns }) => (
                                            <div>
                                                <Button
                                                    href="#"
                                                    className="me-2"
                                                    variant="primary"
                                                    onClick={(e) => {
                                                        setFormColores({});
                                                    }}
                                                >
                                                    <i className="bi bi-plus-square"></i>&nbsp;Nuevo
                                                </Button>
                                                <ExportToExcel
                                                    columns={columns}
                                                    onClick={trigger}
                                                    fileName={"colores"}
                                                />
                                            </div>
                                        )}
                                        data={colores.data}
                                        columns={[
                                            {
                                                header: "Color",
                                                accessorKey: "color",
                                            },
                                            {
                                                header: "Código",
                                                accessorKey: "codigo",
                                                cell: (codigo) => (
                                                    <b
                                                        style={{
                                                            backgroundColor: codigo?.getValue(),
                                                            padding: "5px",
                                                        }}
                                                    >
                                                        {codigo?.getValue()}
                                                    </b>
                                                ),
                                            },
                                            {
                                                header: "Estatus",
                                                accessorKey: "estatus.estatus",
                                                enableGlobalFilter: false,
                                            },
                                            {
                                                header: "Acciones",
                                                enableGlobalFilter: false,
                                                cell: ({ row: { original } }) => (
                                                    <div className="w-100 d-grid d-sm-block gap-1">
                                                        <Button
                                                            variant="warning"
                                                            className="me-2"
                                                            size="sm"
                                                            onClick={(e) => setFormColores(original)}
                                                        >
                                                            <i className="bi bi-pencil-square"> </i>
                                                            Editar
                                                        </Button>
                                                        <Button
                                                            variant="danger"
                                                            className="me-2"
                                                            size="sm"
                                                            onClick={(e) =>
                                                                setConfirmDialog({
                                                                    message: "¿Desea eliminar este registro?",
                                                                    onConfirm: async () => {
                                                                        await eliminarColor({
                                                                            idColor: original.idColor,
                                                                        });
                                                                        refetch();
                                                                        setConfirmDialog({});
                                                                    },
                                                                })
                                                            }
                                                        >
                                                            <i className="bi bi-trash"> </i>
                                                            Eliminar
                                                        </Button>
                                                    </div>
                                                ),
                                            },
                                        ]}
                                        onGlobalFilterChange={(e) => {
                                            setPageNumber(1);
                                            setElementsPerPage(e ? -1 : 10);
                                        }}
                                    />
                                )}
                                {colores && (
                                    <SeverPagination
                                        data={colores.data}
                                        currentPage={pageNumber}
                                        totalElements={colores.totalElements}
                                        elementsPerPage={elementsPerPage}
                                        onPageChange={setPageNumber}
                                        onElementsPerPageChange={setElementsPerPage}
                                    />
                                )}
                            </Card.Body>

                            <ConfirmDialog
                                {...confirmDialog}
                                onCancel={() => setConfirmDialog({})}
                            />
                        </Card>
                    </Col>
                </Row>
            </section>
            {formColores && (
                <ColoresForm
                    show={formColores}
                    onHide={(e) => {
                        setFormColores(false);
                    }}
                    onSave={refetch}
                />
            )}
        </div>
    );
};

export default Colores;
