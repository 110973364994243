import {
    useObtenerInventariosQuery,
    useLazyObtenerInventariosQuery
} from "../../../services/inventario.service";
import React, {useState} from "react";
import {Button, Row, Col, Card} from "react-bootstrap";
import SeverPagination from "../../../components/base/SeverPagination";
import InventariosForm from "./InventariosForm";
import BootstrapTable from "../../../components/base/BootstrapTable";
import ConfirmDialog from "../../../components/base/ConfirmDialog";
import {ExportToExcel} from "../../../components/base/ExportToExcel";
import {toCurrency} from "../../../helpers/toCurrency";

const Inventarios = () => {
    const [pageNumber, setPageNumber] = useState(1);
    const [elementsPerPage, setElementsPerPage] = useState(10);

    const [formInventarios, setFormInventarios] = useState(null);
    const [confirmDialog, setConfirmDialog] = useState({});

    const {
        data: inventarios, refetch
    } = useObtenerInventariosQuery([pageNumber, elementsPerPage], {refetchOnMountOrArgChange: true});
    const [ trigger ] = useLazyObtenerInventariosQuery();

    return (<div id="main" className="main">
        <section className="section">
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Body>
                            <div className="btn-toolbar justify-content-between" role="toolbar">
                                <div className="pagetitle">
                                    <h1>Productos inventariables</h1>
                                </div>
                            </div>
                            {inventarios &&
                                <BootstrapTable striped
                                    toolbar={({columns}) => (
                                        <div>
                                            <ExportToExcel columns={columns} onClick={trigger} fileName={'inventarios'}/>
                                        </div>
                                    )}
                                    data={inventarios.data}
                                    columns={[{
                                        header: 'No. de inventario', accessorKey: 'numeroInventario', className: "text-end"
                                    }, {
                                        header: 'Descripción', accessorKey: 'producto.producto'
                                    }, {
                                        header: 'Código', accessorKey: 'producto.codigoProducto'
                                    }, {
                                        header: 'Unidad de medida', accessorKey: 'unidadMedida.unidadMedida'
                                    }, {
                                        header: 'Fecha de entrada', accessorKey: 'fechaEntrada', className: "text-center",
                                        cell: info => info?.getValue().split(' ')[0],
                                    }, {
                                        header: 'Número de entrada', accessorKey: 'entrada.numero', className: "text-end"
                                    }, {
                                        header: 'Precio unitario', accessorKey: 'precioUnitario', className: "text-end",
                                        cell: ({ row: { original } }) => (toCurrency(original.precioUnitario)),
                                    }, {
                                        header: 'Estatus', accessorKey: 'estatus.estatus', enableGlobalFilter: false
                                    }, {
                                        header: 'Acciones',
                                        enableGlobalFilter: false,
                                        cell: ({row: {original}}) => (
                                        <div className="w-100 d-grid d-sm-block gap-1">
                                            <Button variant="warning" className="me-2" size="sm"
                                                    onClick={e => setFormInventarios(original)}>
                                                <i className="bi bi-pencil-square"> </i>
                                                Editar
                                            </Button>
                                        </div>)
                                    }]}
                                    onGlobalFilterChange={(e => {
                                        setPageNumber(1);
                                        setElementsPerPage(e ? -1 : 10);
                                    })}
                                />}
                            {inventarios && <SeverPagination
                                data={inventarios.data}
                                currentPage={pageNumber}
                                totalElements={inventarios.totalElements}
                                elementsPerPage={elementsPerPage}
                                onPageChange={setPageNumber}
                                onElementsPerPageChange={setElementsPerPage}
                            />}
                        </Card.Body>

                        <ConfirmDialog {...confirmDialog} onCancel={() => setConfirmDialog({})}/>
                    </Card>
                </Col>
            </Row>
        </section>
        {formInventarios && <InventariosForm show={formInventarios} onHide={(e) => {
            setFormInventarios(false);
        }} onSave={refetch}/>}
    </div>);
};

export default Inventarios;
