import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { useObtenerTiposPersonasQuery } from "../../../services/tipoPersona.service";
import { useObtenerTiposDocumentosQuery } from "../../../services/tipoDocumento.service";
import { useObtenerDocumentosArchivosQuery } from "../../../services/procesoEtapaDocumento.service";
import {
  useGuardarConfiguracionDocumentoMutation,
  useObtenerConfiguracionDocumentoQuery,
} from "../../../services/configuracionDocumento.service";
import Spinner from "../../../components/base/Spinner";

const ConfiguracionDocumentoModal = ({ show, onHide, onSave }) => {

  const handleClose = (e) => {
    onHide(e);
  };

  const {
    control,
    handleSubmit,
    setValue,
    register,
    reset,
    watch,
    setFocus,
    formState: { errors },
  } = useForm();

  const { data: tipoPersonas } = useObtenerTiposPersonasQuery();
  const { data: procesoEtapaTipoDocumentos } =
    useObtenerDocumentosArchivosQuery([3, 5, 0]);

  const { data: configuracionDocumento } =
    useObtenerConfiguracionDocumentoQuery(show.idConfiguracionDocumento, {
      skip: !show.idConfiguracionDocumento,
      refetchOnMountOrArgChange: true,
    });
  const [guardarConfiguracionDocumento] =
    useGuardarConfiguracionDocumentoMutation();

  useEffect(() => {
    if (configuracionDocumento) {
      reset({
        idConfiguracionDocumento:
          configuracionDocumento.idConfiguracionDocumento,
        idProcesoEtapaTipoDocumento:
          configuracionDocumento.idProcesoEtapaTipoDocumento,
        idTipoPersona: configuracionDocumento.idTipoPersona,
        idEstatus: configuracionDocumento.idEstatus,
        obligatorio: configuracionDocumento.obligatorio,
        fechaVersion: configuracionDocumento.fechaVersion,
      });
    }
  }, [configuracionDocumento, reset]);

  useEffect(() => {
    if (!show.idConfiguracionDocumento) {
      setFocus("configuracionDocumento");
    }
  }, [show, setFocus]);

  const onSubmit = async (formData) => {
    let { data } = await guardarConfiguracionDocumento(formData);
    if (data) {
      setValue("idConfiguracionDocumento", data.id);
      setValue("fechaVersion", data.fechaVersion);
      onSave();
    }
  };

  if (!procesoEtapaTipoDocumentos && !tipoPersonas && !configuracionDocumento) {
    return null;
  }

  return (
    <>
      {" "}
      {
        <Modal
          show={show}
          onHide={handleClose}
          centered
          backdrop="static"
          keyboard={false}
        >
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header closeButton>
              <Modal.Title>
                {watch("idConfiguracionDocumento") ? "Editar" : "Nuevo"} [
                Configuración ]
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Control
                type="text"
                id="idConfiguracionDocumento"
                className="d-none"
                {...register("idConfiguracionDocumento")}
              />
              <Form.Control
                type="text"
                id="fechaVersion"
                className="d-none"
                {...register("fechaVersion")}
              />

              <Form.Group className="mb-3">
                <Form.Label
                  htmlFor="idProcesoEtapaTipoDocumento"
                  className="fw-bold"
                >
                  Documento
                </Form.Label>
                <Form.Select
                  className="mwx-350"
                  id="idProcesoEtapaTipoDocumento"
                  {...register("idProcesoEtapaTipoDocumento", {
                    required: "Ingrese un dato válido",
                  })}
                >
                  <option value="">Seleccionar</option>
                  {procesoEtapaTipoDocumentos &&
                    procesoEtapaTipoDocumentos?.map((documento) => (
                      <option
                        key={
                          "documentos." +
                          documento.procesoEtapaTipoDocumento
                            .idProcesoEtapaTipoDocumento
                        }
                        value={
                          documento.procesoEtapaTipoDocumento
                            .idProcesoEtapaTipoDocumento
                        }
                      >
                        {
                          documento.procesoEtapaTipoDocumento.tipoDocumento
                            .tipoDocumento
                        }
                      </option>
                    ))}
                </Form.Select>
                {errors.idProcesoEtapaTipoDocumento && (
                  <Alert variant="danger" role="alert">
                    {errors.idProcesoEtapaTipoDocumento.message}
                  </Alert>
                )}
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label htmlFor="idTipoPersona" className="fw-bold">
                  Tipo de persona
                </Form.Label>
                <Form.Select
                  className="mwx-350"
                  id="idTipoPersona"
                  {...register("idTipoPersona", {
                    required: "Ingrese un dato válido",
                  })}
                >
                  <option value="">Seleccionar</option>
                  {tipoPersonas?.data &&
                    tipoPersonas.data.map((persona) => (
                      <option
                        key={"personas." + persona.idTipoPersona}
                        value={persona.idTipoPersona}
                      >
                        {persona.tipoPersona}
                      </option>
                    ))}
                </Form.Select>
                {errors.idPais && (
                  <Alert variant="danger" role="alert">
                    {errors.idTipoPersona.message}
                  </Alert>
                )}
              </Form.Group>

              <div className="row">
                <Form.Group className="col-4">
                  <Controller
                    name="idEstatus"
                    control={control}
                    defaultValue="1"
                    render={({ field }) => (
                      <>
                        <Form.Label htmlFor="idEstatus">Estatus</Form.Label>
                        <Form.Check
                          id="idEstatus"
                          type="switch"
                          label={Boolean(field.value) ? "Activo" : "Inactivo"}
                          checked={Boolean(field.value)}
                          onChange={(e) => {
                            field.onChange(Number(e.target.checked));
                          }}
                        />
                      </>
                    )}
                  />
                </Form.Group>

                <Form.Group className="col-4">
                  <Controller
                    name="obligatorio"
                    control={control}
                    defaultValue="1"
                    render={({ field }) => (
                      <>
                        <Form.Label htmlFor="obligatorio">
                          Obligatorio
                        </Form.Label>
                        <Form.Check
                          id="obligatorio"
                          type="switch"
                          label={Boolean(field.value) ? "Si" : "No"}
                          checked={Boolean(field.value)}
                          onChange={(e) => {
                            field.onChange(Number(e.target.checked));
                          }}
                        />
                      </>
                    )}
                  />
                </Form.Group>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button type="submit" variant="primary" className="me-2">
                <i className="bi bi-save"></i> Guardar
              </Button>
              <Button variant="secondary" onClick={handleClose}>
                Cerrar
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      }
    </>
  );
};

export default ConfiguracionDocumentoModal;
