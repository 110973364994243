import React, {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Alert, Button, Form, Modal} from "react-bootstrap";
import {useGuardarProtocoloCorreoMutation, useObtenerProtocoloCorreoQuery} from "../../../services/protocoloCorreo.service";

const ProtocolosCorreosForm = ({show, onHide, onSave}) => {

    const handleClose = (e) => {
        onHide(e);
    };

    const {control, handleSubmit, setValue, register, reset, formState: {errors}} = useForm();

    const {data: protocoloCorreo} = useObtenerProtocoloCorreoQuery(show.idProtocoloCorreo, {skip: !show.idProtocoloCorreo, refetchOnMountOrArgChange: true});
    const [guardarProtocoloCorreo] = useGuardarProtocoloCorreoMutation();

    useEffect(() => {
        if (protocoloCorreo) {
            reset({
                idProtocoloCorreo: protocoloCorreo.idProtocoloCorreo,
                protocoloCorreo: protocoloCorreo.protocoloCorreo,
                idEstatus: protocoloCorreo.idEstatus
            });
        } else {
            //default values
            reset({idEstatus: 1})
        }
    }, [protocoloCorreo, reset]);

    const onSubmit = async (formData) => {
        let {data} = await guardarProtocoloCorreo(formData);
        if(data){
            setValue('idProtocoloCorreo', data.id);
           
            onSave();
        }
    };

    return (<> {
        <Modal show={show} onHide={handleClose} centered backdrop="static" keyboard={false}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>{show.idProtocoloCorreo ? 'Editar' : 'Nuevo'} [ ProtocoloCorreo ]</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="text" id="idProtocoloCorreo" className="d-none" {...register("idProtocoloCorreo")} />
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="protocoloCorreo" className="fw-bold">
                            Protocolo Correo
                        </Form.Label>
                        <Form.Control
                            {...register('protocoloCorreo', {
                                required: 'Ingrese un dato válido',
                                maxLength: {value: 20, message: 'Ha excedido el máximo de caracteres'}
                            })}
                        />
                        {errors.protocoloCorreo && (<Alert variant="danger" role="alert">
                            {errors.protocoloCorreo.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Controller
                            name="idEstatus"
                            control={control}
                            render={({field}) => (<>
                                    <Form.Label htmlFor="idEstatus">Estatus</Form.Label>
                                    <Form.Check
                                        id="idEstatus"
                                        type="switch"
                                        label={Boolean(field.value) ? 'Activo' : 'Inactivo'}
                                        checked={Boolean(field.value)}
                                        onChange={(e) => {
                                            field.onChange(Number(e.target.checked))
                                        }}
                                    />
                                </>

                            )}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant="primary" className="me-2">
                        <i className="bi bi-save"></i> Guardar
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>}
    </>);
};

export default ProtocolosCorreosForm;
