import { createSlice } from "@reduxjs/toolkit";
import { empresaLogin, userLogin } from "../actions/authActions";

//inicializar los datos del usuario desde local storage
const userInfo = localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null

const userToken = localStorage.getItem('userInfo')
    ? userInfo.token
    : null

const initialState = {
    loading: false,
    userInfo,
    userToken,
    error: null,
    success: false,
}

const authSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        logout: (state) => {

            localStorage.removeItem('userInfo');//borra los datos del almacenamiento local
            state.loading = false;
            state.userInfo = null;
            state.userToken = null;
            state.error = null;

        },
        setCredentials: (state, {payload}) => {
            state.userInfo = payload
        },
    },
    extraReducers: (builder) => {
        builder.addCase(userLogin.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(userLogin.fulfilled, (state, {payload})=> {
            state.loading = false;
            state.userInfo = payload;
            state.userToken = payload.token;
            state.success = true;
        })
        builder.addCase(userLogin.rejected, (state, {payload}) => {
            state.loading = false;
            state.error = payload;
        })
        builder.addCase(empresaLogin.pending,(state) => {
            state.loading = true;
            state.error = null;
        })
        builder.addCase(empresaLogin.fulfilled, (state, {payload}) => {
            state.loading = false;
            state.userInfo = payload;
            state.userToken = payload.token;
            state.success = true;
        })
        builder.addCase(empresaLogin.rejected, (state, {payload}) => {
            state.loading = false;
            state.error = payload;
        })
    },
});

export const { logout, setCredentials } = authSlice.actions;
export default authSlice.reducer;