import React, {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Alert, Button, Form, Modal} from "react-bootstrap";
import {NumericFormat} from "react-number-format";
import {v4 as uuidv4} from "uuid";
const ControlMantenimientosPiezas = ({show: mantenimientoPieza, onHide, onSave}) => {

    const handleClose = (e) => {
        onHide(e);
    };

    const {control, handleSubmit, register, reset, watch, formState: {errors}} = useForm();

    useEffect(() => {
        if (mantenimientoPieza?.uuid) {
            reset({
                uuid: mantenimientoPieza.uuid,
                idMantenimientoPieza: mantenimientoPieza.idMantenimientoPieza,
                cantidad: mantenimientoPieza.cantidad  || 1,
                costo: mantenimientoPieza.costo || '0',
                codigoPieza: mantenimientoPieza.codigoPieza,
                nombrePieza: mantenimientoPieza.nombrePieza,
                fechaVersion: mantenimientoPieza.fechaVersion
            });
        } else {
            //default values
            reset({
                cantidad: 1,
                costo: '0',
            });
        }

    }, [mantenimientoPieza, reset]);

    const onSubmit = async (formData) => {

        //generar id unico
        if(!formData.uuid){
            formData.uuid = uuidv4();
        }
        onSave(formData.uuid, formData);

        if(formData.nuevo){
            reset({
                uuid: null,
                idMantenimientoPieza: null,
                cantidad: 1,
                costo: '0',
                codigoPieza: null,
                nombrePieza: null,
                fechaVersion: null
            });
        }
    };

    return (<> {
        <Modal show={mantenimientoPieza} onHide={handleClose} centered backdrop="static" keyboard={false}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>{watch("idMantenimientoPieza") ? 'Editar' : 'Nuevo'} [ Pieza Cambiada o Reparada ]</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="text" id="uuid" className="d-none" {...register("uuid")} />
                    <Form.Control type="text" id="idMantenimientoPieza" className="d-none" {...register("idMantenimientoPieza")} />
                    <Form.Control type="text" id="fechaVersion" className="d-none" {...register("fechaVersion")} />

                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="cantidad" className="fw-bold">
                            Cantidad
                        </Form.Label>
                        <Controller
                            render={({field}) => (
                                <NumericFormat
                                    className="form-control"
                                    id="cantidad"
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    fixedDecimalScale
                                    allowNegative={false}
                                    value={field.value || ''}
                                    onValueChange={(v) => {
                                        field.onChange(Number(v.value) > 0 ? Number(v.value) : '0');
                                    }}
                                    isAllowed={({value}) => (value?.split(".")[0].length <= 10)}
                                />
                            )}
                            name="cantidad"
                            control={control}
                            rules={{required: "Ingrese un dato válido"}}
                        />
                        {errors.cantidad && (<Alert variant="danger" role="alert">
                            {errors.cantidad.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="nombrePieza" className="fw-bold">
                            Nombre de la pieza
                        </Form.Label>
                        <Form.Control
                            {...register('nombrePieza', {
                                required: 'Ingrese un dato válido',
                                maxLength: {value: 50, message: 'Ha excedido el máximo de caracteres'}
                            })}
                        />
                        {errors.nombrePieza && (<Alert variant="danger" role="alert">
                            {errors.nombrePieza.message}
                        </Alert>)}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="codigoPieza" className="fw-bold">
                            Código de la pieza
                        </Form.Label>
                        <Form.Control
                            {...register('codigoPieza', {
                                required: 'Ingrese un dato válido',
                                maxLength: {value: 50, message: 'Ha excedido el máximo de caracteres'}
                            })}
                        />
                        {errors.codigoPieza && (<Alert variant="danger" role="alert">
                            {errors.codigoPieza.message}
                        </Alert>)}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="costo" className="fw-bold">
                            Costo
                        </Form.Label>
                        <Controller
                            render={({field}) => (
                                <NumericFormat
                                    className="form-control"
                                    id="costo"
                                    thousandSeparator={true}
                                    prefix={'$ '}
                                    decimalScale={2}
                                    fixedDecimalScale
                                    allowNegative={false}
                                    value={field.value || ''}
                                    onValueChange={(v) => {
                                        field.onChange(Number(v.value) > 0 ? Number(v.value) : '0');
                                    }}
                                    isAllowed={({value}) => (value?.split(".")[0].length <= 10)}
                                />
                            )}
                            name="costo"
                            control={control}
                            rules={{required: "Ingrese un dato válido"}}
                        />
                        {errors.costo && (<Alert variant="danger" role="alert">
                            {errors.costo.message}
                        </Alert>)}
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant="primary" className="me-2" >
                        <i className="bi bi-save"></i> Añadir y cerrar
                    </Button>
                    <Button type="submit" variant="success" className="me-2" onClick={handleSubmit((d) => {d.nuevo = true; onSubmit(d)})}>
                        <i className="bi bi-save"></i> Añadir y nuevo
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>}
    </>);
};

export default ControlMantenimientosPiezas;
