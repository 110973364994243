import React, { useState } from "react";
import { Button, Card } from "react-bootstrap";
import ConfirmDialog from "../base/ConfirmDialog";
import EmpleadosVista from "../../views/administracion/empleados/EmpleadosVista";
import { Link } from "react-router-dom";
const EmpleadoCard = ({ empleado, refetch, eliminarEmpleado }) => {
    const [confirmDialog, setConfirmDialog] = useState({});
    const [vistaEmpleados, setVistaEmpleados] = useState(null);

    return (
        <Card className={`mb-0 mt-3`}>
            <Card.Header className={`pb-0 mb-0 fw-bold alert alert-primary`}>
                <h5 className="card-title pt-1 pb-0 text-primary">
                    {empleado.nombre}{" "}
                </h5>
            </Card.Header>
            <div className="row card-body pt-2">
                <p className="card-text mb-1">
                    <span className="text-muted">
                        Fecha de ingreso: <b>{empleado.fechaIngreso}</b>
                    </span>{" "}
                    | Estatus: <b>{empleado.estatus.estatus}</b>
                </p>
                <div className="col-md-12 mb-2 pb-0">
                    <p className="card-text mb-1">
                        <strong>RFC: </strong>
                        {empleado.rfc}
                    </p>
                    <p className="card-text mb-1">
                        <strong>Régimen fiscal: </strong>
                        {empleado.regimenFiscal.regimenFiscal}
                    </p>
                    <p className="card-text mb-1">
                        <strong>Teléfono de contacto: </strong>
                        {empleado.numeroTelefono}
                    </p>
                </div>

                <div>
                    <Link
                        className="col-12 text-end"
                        to={"/administracion/empleados/view/" + empleado.idEmpleado}
                    >
                        <Button
                            variant="outline-warning"
                            className="me-2"
                            size="sm"
                            onClick={(e) => setVistaEmpleados(empleado)}
                        >
                            <i className="bi bi-pencil-square"> </i>
                            Editar
                        </Button>
                    </Link>

                    <Button
                        variant="outline-danger"
                        className="me-2"
                        size="sm"
                        onClick={(e) =>
                            setConfirmDialog({
                                message: "¿Desea eliminar este registro?",
                                onConfirm: async () => {
                                    await eliminarEmpleado({ idEmpleado: empleado.idEmpleado });
                                    refetch();
                                    setConfirmDialog({});
                                },
                            })
                        }
                    >
                        <i className="bi bi-trash"> </i>
                        Eliminar
                    </Button>
                </div>
            </div>
            <ConfirmDialog {...confirmDialog} onCancel={() => setConfirmDialog({})} />

            {vistaEmpleados && (
                <EmpleadosVista
                    show={vistaEmpleados}
                    onHide={(e) => {
                        setVistaEmpleados(false);
                    }}
                    onSave={refetch}
                />
            )}
        </Card>
    );
};

export default EmpleadoCard;
