import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const rolesModulosApi = createApi({
    reducerPath: 'rolesModulosApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "roles/modulos",
        prepareHeaders: (headers, {getState}) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (build) => ({
        obtenerRolesModulos: build.query({
            query: (idRol) => `/${idRol}`,
        }),
        guardarRolesModulos: build.mutation({
            query: (rolesModulos) => ({
                url: '',
                method: 'POST',
                body: rolesModulos,
            }),
        }),
    }),
});
export const {
    useObtenerRolesModulosQuery,
    useLazyObtenerRolesModulosQuery,
    useGuardarRolesModulosMutation,
} = rolesModulosApi;
