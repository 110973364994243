import React, { useRef, useState } from "react";
import {
    useGuardarArchivoMutation,
    useLazyObtenerArchivoQuery,
} from "../../services/archivo.service";
import { Button, Form } from "react-bootstrap";
import FileViewer from "./FileViewer";
import ConfirmDialog from "./ConfirmDialog";
import { toast } from "react-toastify";

const InputFile = ({
    name,
    archivo,
    register,
    setValue,
    ruta,
    disabled = false,
    required = false,
    accept = ".pdf, .jpg, .jpeg, .png",
    maxLength,
    notMargin,
}) => {
    const [guardarArchivo] = useGuardarArchivoMutation();
    const [obtenerArchivo] = useLazyObtenerArchivoQuery();

    const fileInputRef = useRef(null);
    const [file, setFile] = useState(archivo);
    const [base64, setBase64] = useState(null);
    const [confirmDialog, setConfirmDialog] = useState({});
    const openPromptBrowser = () => {
        fileInputRef.current.click();
    };
    const handleArchivoChange = (event) => {
        let archivo = event.target.files?.item(0);
        let reader = new FileReader();

        if (archivo) {
            const extension = archivo.name.split(".").pop().toLowerCase();
            // Verificar si la extensión es válida
            if (!accept.includes(extension)) {
                // Mostrar un toast indicando que la extensión del archivo no es compatible
                toast.warn(
                    <>
                        <strong>Advertencia.</strong> <br />{" "}
                        {"El archivo debe tener una extensión: " + accept}{" "}
                    </>
                );
                return; // Cancelar la operación
            }

            // Verificar el tamaño del archivo
            if (maxLength && archivo.size > maxLength * 1024 * 1024) {
                // Mostrar un toast indicando que el tamaño del archivo excede el límite
                toast.warn("El archivo no puede exceder los " + maxLength + " MB");
                return; // Cancelar la operación
            }

            reader.onload = async (e) => {
                let base64 = e.target.result?.split(",")[1];
                if (archivo && base64) {
                    let result = await guardarArchivo({
                        ruta: ruta,
                        archivo: archivo.name,
                        base64: base64,
                    });
                    setFile({
                        ...file,
                        archivo: archivo.name,
                        idArchivo: result.data?.id,
                    });
                    setValue(name, result.data?.id);
                }
            };
            reader.readAsDataURL(archivo);
        }
    };

    const handleArchivoClick = (e) => {
        if (file.idArchivo) {
            e.preventDefault();
            setConfirmDialog({
                message: "¿Estás seguro que deseas eliminar o reemplazar este archivo?",
                onConfirm: async () => {
                    e.target.value = "";
                    await setFile({});
                    await setConfirmDialog({});
                    fileInputRef.current.click();
                },
            });
        }
    };

    const downloadFile = async () => {
        let { data } = await obtenerArchivo(file.idArchivo);
        setBase64(data);
    };

    return (
        <>
            <div
                className={`input-group ${notMargin ? "mb-0" : "mb-3"} cursor-pointer`}
            >
                <label htmlFor={name} className="input-group-text" role="button">
                    Seleccionar archivo
                </label>
                <Form.Control
                    type="text"
                    className="form-control text-truncate"
                    role="button"
                    value={file.archivo || ""}
                    title={file.archivo || ""}
                    onClick={!disabled ? openPromptBrowser : undefined}
                    disabled={disabled}
                    readOnly
                />
                <Form.Control
                    type="file"
                    className="d-none"
                    id={name}
                    onChange={handleArchivoChange}
                    onClick={handleArchivoClick}
                    accept={accept}
                    ref={fileInputRef}
                    disabled={disabled}
                />
                {file.idArchivo > 0 && (
                    <Button
                        type="button"
                        variant={"primary"}
                        title="Ver archivo"
                        onClick={downloadFile}
                    >
                        <i className="bi bi-files" title="Ver archivo">
                            {" "}
                        </i>
                    </Button>
                )}
                <Form.Control
                    type="text"
                    className="d-none"
                    value={file.idArchivo || ""}
                    {...register(name, { required: required })}
                />
            </div>
            <FileViewer
                title={file.archivo}
                file={base64}
                fileName={file.archivo}
                showModal={base64}
                closeModal={() => setBase64(null)}
            />
            <ConfirmDialog {...confirmDialog} onCancel={() => setConfirmDialog({})} />
        </>
    );
};

export default InputFile;
