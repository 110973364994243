import React, { useEffect, useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { Alert, Button, Form, Card, Col, Row } from "react-bootstrap";
import {
    useGuardarEmpleadoMutation,
    useObtenerEmpleadoQuery,
} from "../../../services/empleado.service";
import { useObtenerRegimenesFiscalesQuery } from "../../../services/regimenFiscal.service";
import { useObtenerEstatusEtapaQuery } from "../../../services/estatus.service";
import { useSelector } from "react-redux";
import ConfirmDialog from "../../../components/base/ConfirmDialog";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";

import _ from "lodash";

const EmpleadosVista = (onHide) => {
    const { userInfo } = useSelector((state) => state.auth);
    const {
        handleSubmit,
        setValue,
        register,
        reset,
        control,
        formState: { errors },
    } = useForm();

    const [idRegimenFiscal, setIdRegimenFiscal] = useState(0);

    const { data: regimenesFiscales } = useObtenerRegimenesFiscalesQuery();

    const { id: idEmpleado } = useParams();

    const { data: empleado, refetch: refetchEmpleado } = useObtenerEmpleadoQuery(
        idEmpleado,
        { skip: !idEmpleado, refetchOnMountOrArgChange: true }
    );

    const [guardarEmpleado] = useGuardarEmpleadoMutation();
    const refetchAll = () => {
        refetchEmpleado();
    };

    useEffect(() => {
        if (empleado && regimenesFiscales) {
            reset({
                idEmpleado: empleado.idEmpleado,
                nombre: empleado.nombre,
                primerApellido: empleado.primerApellido,
                segundoApellido: empleado.segundoApellido,
                fechaNacimiento: empleado.fechaNacimiento,
                fechaIngreso: empleado.fechaIngreso,
                idRegimenFiscal: empleado.idRegimenFiscal,
                rfc: empleado.rfc,
                correoElectronico: empleado.correoElectronico,
                calle: empleado.calle,
                numeroExterior: empleado.numeroExterior,
                numeroInterior: empleado.numeroInterior,
                codigoPostal: empleado.codigoPostal,
                codigoPostalFiscal: empleado.codigoPostalFiscal,
                colonia: empleado.colonia,
                numeroSeguroSocial: empleado.numeroSeguroSocial,
                numeroTelefono: empleado.numeroTelefono,
                nombreContactoEmergencia: empleado.nombreContactoEmergencia,
                numeroContacto: empleado.numeroContacto,
                idEstatus: empleado.idEstatus,
            });
        } else {
            //default values
            reset({ idEstatus: 1 });
        }
    }, [empleado, regimenesFiscales, reset]);

    const onSubmit = async (formData) => {
        let data;
        formData.idEmpleado = parseInt(idEmpleado);
        let result = await guardarEmpleado(formData);
        data = result.data;
    };

    return (
        <>
            <main id="main" className="main">
                <section className="section">
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <Card.Body>
                                    <fieldset>
                                        <legend className="d-flex flex-column flex-md-row justify-content-between align-items-right">
                                            <div className="fw-bold">
                                                <p className="fs-5 mb-0"> Empleados </p>
                                            </div>

                                            <div>
                                                <p className="fs-6 mb-0">
                                                    *Los campos en negritas son requeridos{" "}
                                                </p>
                                            </div>
                                        </legend>
                                    </fieldset>

                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        <Form.Control
                                            type="text"
                                            id="idEmpresa"
                                            className="d-none"
                                            {...register("idEmpresa")}
                                        />

                                        <Card>
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="fw-bold">
                                                            <p className="fs-3"> SECCION </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <Form.Group className="mb-3">
                                                            <Form.Label htmlFor="nombre" className="fw-bold">
                                                                Nombre
                                                            </Form.Label>
                                                            <Form.Control
                                                                {...register("nombre", {
                                                                    required: "Ingrese un dato válido",
                                                                    maxLength: {
                                                                        value: 30,
                                                                        message:
                                                                            "Ha excedido el máximo de caracteres",
                                                                    },
                                                                })}
                                                            />
                                                            {errors.nombre && (
                                                                <Alert variant="danger" role="alert">
                                                                    {errors.nombre.message}
                                                                </Alert>
                                                            )}
                                                        </Form.Group>
                                                    </div>

                                                    <div className="col-md-3">
                                                        <Form.Group className="mb-3">
                                                            <Form.Label
                                                                htmlFor="primerApellido"
                                                                className="fw-bold"
                                                            >
                                                                Primer Apellido
                                                            </Form.Label>
                                                            <Form.Control
                                                                {...register("primerApellido", {
                                                                    required: "Ingrese un dato válido",
                                                                    maxLength: {
                                                                        value: 30,
                                                                        message:
                                                                            "Ha excedido el máximo de caracteres",
                                                                    },
                                                                })}
                                                            />
                                                            {errors.primerApellido && (
                                                                <Alert variant="danger" role="alert">
                                                                    {errors.primerApellido.message}
                                                                </Alert>
                                                            )}
                                                        </Form.Group>
                                                    </div>

                                                    <div className="col-md-3">
                                                        <Form.Group className="mb-3">
                                                            <Form.Label htmlFor="segundoApellido">
                                                                Segundo Apellido
                                                            </Form.Label>
                                                            <Form.Control
                                                                className="mwx-350"
                                                                {...register("segundoApellido", {
                                                                    maxLength: {
                                                                        value: 30,
                                                                        message:
                                                                            "Ha excedido el máximo de caracteres",
                                                                    },
                                                                })}
                                                            />
                                                            {errors.segundoApellido && (
                                                                <Alert variant="danger" role="alert">
                                                                    {errors.segundoApellido.message}
                                                                </Alert>
                                                            )}
                                                        </Form.Group>
                                                    </div>

                                                    <div className="col-md-3">
                                                        <Form.Group className="mb-3 col-sm-8">
                                                            <Form.Label
                                                                htmlFor="fechaNacimiento"
                                                                className="fw-bold"
                                                            >
                                                                Fecha de nacimiento
                                                            </Form.Label>
                                                            <div>
                                                                <Controller
                                                                    render={({ field }) => (
                                                                        <DatePicker
                                                                            className="form-control"
                                                                            id="fechaNacimiento"
                                                                            dateFormat="dd/MM/yyyy"
                                                                            selected={
                                                                                field.value
                                                                                    ? dayjs(
                                                                                        field.value,
                                                                                        "DD/MM/YYYY"
                                                                                    ).toDate()
                                                                                    : null
                                                                            }
                                                                            onChange={(date) => {
                                                                                field.onChange(
                                                                                    dayjs(date).format("DD/MM/YYYY")
                                                                                );
                                                                            }}
                                                                        />
                                                                    )}
                                                                    name="fechaNacimiento"
                                                                    control={control}
                                                                    rules={{ required: "Ingrese un dato válido" }}
                                                                />
                                                            </div>
                                                            {errors.fechaNacimiento && (
                                                                <Alert variant="danger" role="alert">
                                                                    {errors.fechaNacimiento.message}
                                                                </Alert>
                                                            )}
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>

                                        <Card>
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="fw-bold">
                                                            <p className="fs-3">SECCION</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <Row className="mb-3">
                                                            <Col md={12}>
                                                                <Form.Label
                                                                    htmlFor="idRegimenFiscal"
                                                                    className="fw-bold"
                                                                >
                                                                    Régimen fiscal
                                                                </Form.Label>
                                                                <Form.Select
                                                                    className="mwx-400"
                                                                    value={idRegimenFiscal}
                                                                    id="idRegimenFiscal"
                                                                    {...register("idRegimenFiscal", {
                                                                        required: "Ingrese un dato válido",
                                                                        onChange: (event) => {
                                                                            setIdRegimenFiscal(event.target.value);
                                                                        },
                                                                    })}
                                                                >
                                                                    <option value="">Seleccionar</option>
                                                                    {regimenesFiscales &&
                                                                        regimenesFiscales.data.map(
                                                                            (regimenFiscal) => (
                                                                                <option
                                                                                    key={regimenFiscal.idRegimenFiscal}
                                                                                    value={regimenFiscal.idRegimenFiscal}
                                                                                >
                                                                                    {regimenFiscal.regimenFiscal}
                                                                                </option>
                                                                            )
                                                                        )}
                                                                </Form.Select>
                                                                {errors.idRegimenFiscal && (
                                                                    <Alert variant="danger" className="mwx-400">
                                                                        {errors.idRegimenFiscal.message}
                                                                    </Alert>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                    </div>

                                                    <div className="col-md-3">
                                                        <Form.Group className="mb-3">
                                                            <Form.Label htmlFor="rfc" className="fw-bold">
                                                                RFC
                                                            </Form.Label>
                                                            <Form.Control
                                                                {...register("rfc", {
                                                                    required: "Ingrese un dato valido",
                                                                    maxLength: {
                                                                        value: 13,
                                                                        message:
                                                                            "Ha excedido el máximo de caracteres",
                                                                    },
                                                                    pattern: {
                                                                        value: /^[A-Z&Ñ]{4}\d{6}[A-Z0-9]{3}$/, // Valida el formato del RFC de persona física
                                                                        message: "Formato de RFC inválido.",
                                                                    },
                                                                })}
                                                            />
                                                            {errors.rfc && (
                                                                <Alert variant="danger" role="alert">
                                                                    {errors.rfc.message}
                                                                </Alert>
                                                            )}
                                                        </Form.Group>
                                                    </div>

                                                    <div className="col-md-3">
                                                        <Form.Group className="mb-3">
                                                            <Form.Label
                                                                htmlFor="correoElectronico"
                                                                className="fw-bold"
                                                            >
                                                                Correo Electrónico
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="email"
                                                                placeholder="name@example.com"
                                                                {...register("correoElectronico", {
                                                                    required: "Ingrese un dato valido",
                                                                    maxLength: {
                                                                        value: 50,
                                                                        message:
                                                                            "Ha excedido el máximo de caracteres",
                                                                    },
                                                                })}
                                                            />
                                                            {errors.correoElectronico && (
                                                                <Alert variant="danger" role="alert">
                                                                    {errors.correoElectronico.message}
                                                                </Alert>
                                                            )}
                                                        </Form.Group>
                                                    </div>

                                                    <div className="col-md-3">
                                                        <Form.Group className="mb-3">
                                                            <Form.Label htmlFor="calle" className="fw-bold">
                                                                Calle
                                                            </Form.Label>
                                                            <Form.Control
                                                                {...register("calle", {
                                                                    required: "Ingrese un dato valido",
                                                                    maxLength: {
                                                                        value: 100,
                                                                        message:
                                                                            "Ha excedido el máximo de caracteres",
                                                                    },
                                                                })}
                                                            />
                                                            {errors.calle && (
                                                                <Alert variant="danger" role="alert">
                                                                    {errors.calle.message}
                                                                </Alert>
                                                            )}
                                                        </Form.Group>
                                                    </div>

                                                    <div className="col-md-3">
                                                        <Form.Group className="mb-3">
                                                            <Form.Label
                                                                htmlFor="numeroExterior"
                                                                className="fw-bold"
                                                            >
                                                                Número Exterior
                                                            </Form.Label>
                                                            <Form.Control
                                                                {...register("numeroExterior", {
                                                                    required: "Ingrese un dato valido",
                                                                    maxLength: {
                                                                        value: 10,
                                                                        message:
                                                                            "Ha excedido el máximo de caracteres",
                                                                    },
                                                                })}
                                                            />
                                                            {errors.numeroExterior && (
                                                                <Alert variant="danger" role="alert">
                                                                    {errors.numeroExterior.message}
                                                                </Alert>
                                                            )}
                                                        </Form.Group>
                                                    </div>

                                                    <div className="col-md-3">
                                                        <Form.Group className="mb-3">
                                                            <Form.Label htmlFor="numeroInterior">
                                                                Número Interior
                                                            </Form.Label>
                                                            <Form.Control
                                                                className="mwx-350"
                                                                {...register("numeroInterior", {
                                                                    maxLength: {
                                                                        value: 10,
                                                                        message:
                                                                            "Ha excedido el máximo de caracteres",
                                                                    },
                                                                })}
                                                            />
                                                            {errors.numeroInterior && (
                                                                <Alert variant="danger" role="alert">
                                                                    {errors.numeroInterior.message}
                                                                </Alert>
                                                            )}
                                                        </Form.Group>
                                                    </div>

                                                    <div className="col-md-3">
                                                        <Form.Group className="mb-3">
                                                            <Form.Label htmlFor="codigoPostal">
                                                                Código Postal
                                                            </Form.Label>
                                                            <Form.Control
                                                                {...register("codigoPostal", {
                                                                    maxLength: {
                                                                        value: 6,
                                                                        message:
                                                                            "Ha excedido el máximo de caracteres",
                                                                    },
                                                                    pattern: {
                                                                        value: /^\d+$/, // Asegura que solo se introduzcan números.
                                                                        message: "Solo se permiten números.",
                                                                    },
                                                                })}
                                                            />
                                                            {errors.codigoPostal && (
                                                                <Alert variant="danger" role="alert">
                                                                    {errors.codigoPostal.message}
                                                                </Alert>
                                                            )}
                                                        </Form.Group>
                                                    </div>

                                                    <div className="col-md-3">
                                                        <Form.Group className="mb-3">
                                                            <Form.Label
                                                                htmlFor="codigoPostalFiscal"
                                                                className="fw-bold"
                                                            >
                                                                Código Postal Fiscal
                                                            </Form.Label>
                                                            <Form.Control
                                                                {...register("codigoPostalFiscal", {
                                                                    required: "Ingrese un dato valido",
                                                                    maxLength: {
                                                                        value: 6,
                                                                        message:
                                                                            "Ha excedido el máximo de caracteres",
                                                                    },
                                                                    pattern: {
                                                                        value: /^\d+$/, // Asegura que solo se introduzcan números.
                                                                        message: "Solo se permiten números.",
                                                                    },
                                                                })}
                                                            />
                                                            {errors.codigoPostalFiscal && (
                                                                <Alert variant="danger" role="alert">
                                                                    {errors.codigoPostalFiscal.message}
                                                                </Alert>
                                                            )}
                                                        </Form.Group>
                                                    </div>

                                                    <div className="col-md-3">
                                                        <Form.Group className="mb-3">
                                                            <Form.Label htmlFor="colonia" className="fw-bold">
                                                                Colonia
                                                            </Form.Label>
                                                            <Form.Control
                                                                {...register("colonia", {
                                                                    required: "Ingrese un dato valido",
                                                                    maxLength: {
                                                                        value: 100,
                                                                        message:
                                                                            "Ha excedido el máximo de caracteres",
                                                                    },
                                                                })}
                                                            />
                                                            {errors.colonia && (
                                                                <Alert variant="danger" role="alert">
                                                                    {errors.colonia.message}
                                                                </Alert>
                                                            )}
                                                        </Form.Group>
                                                    </div>

                                                    <div className="col-md-3">
                                                        <Form.Group className="mb-3">
                                                            <Form.Label htmlFor="numeroSeguroSocial">
                                                                Numero de Seguro Social
                                                            </Form.Label>
                                                            <Form.Control
                                                                {...register("numeroSeguroSocial", {
                                                                    maxLength: {
                                                                        value: 10,
                                                                        message:
                                                                            "Ha excedido el máximo de caracteres",
                                                                    },
                                                                })}
                                                            />
                                                            {errors.numeroSeguroSocial && (
                                                                <Alert variant="danger" role="alert">
                                                                    {errors.numeroSeguroSocial.message}
                                                                </Alert>
                                                            )}
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>

                                        <Card>
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="fw-bold">
                                                            <p className="fs-3">SECCION</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <Form.Group className="mb-3">
                                                            <Form.Label
                                                                htmlFor="numeroTelefono"
                                                                className="fw-bold"
                                                            >
                                                                Numero de contacto
                                                            </Form.Label>
                                                            <Form.Control
                                                                {...register("numeroTelefono", {
                                                                    required: "Ingrese un dato valido",
                                                                    maxLength: {
                                                                        value: 10,
                                                                        message:
                                                                            "Ha excedido el máximo de caracteres",
                                                                    },
                                                                    pattern: {
                                                                        value: /^\d+$/, // Asegura que solo se introduzcan números.
                                                                        message: "Solo se permiten números.",
                                                                    },
                                                                })}
                                                            />
                                                            {errors.numeroTelefono && (
                                                                <Alert variant="danger" role="alert">
                                                                    {errors.numeroTelefono.message}
                                                                </Alert>
                                                            )}
                                                        </Form.Group>
                                                    </div>

                                                    <div className="col-md-3">
                                                        <Form.Group className="mb-3">
                                                            <Form.Label
                                                                htmlFor="nombreContactoEmergencia"
                                                                className="fw-bold"
                                                            >
                                                                Nombre de contacto en caso de emergencia
                                                            </Form.Label>
                                                            <Form.Control
                                                                {...register("nombreContactoEmergencia", {
                                                                    required: "Ingrese un dato valido",
                                                                    maxLength: {
                                                                        value: 50,
                                                                        message:
                                                                            "Ha excedido el máximo de caracteres",
                                                                    },
                                                                })}
                                                            />
                                                            {errors.nombreContactoEmergencia && (
                                                                <Alert variant="danger" role="alert">
                                                                    {errors.nombreContactoEmergencia.message}
                                                                </Alert>
                                                            )}
                                                        </Form.Group>
                                                    </div>

                                                    <div className="col-md-3">
                                                        <Form.Group className="mb-3">
                                                            <Form.Label
                                                                htmlFor="numeroContacto"
                                                                className="fw-bold"
                                                            >
                                                                Numero de contacto de emergencia
                                                            </Form.Label>
                                                            <Form.Control
                                                                {...register("numeroContacto", {
                                                                    required: "Ingrese un dato valido",
                                                                    maxLength: {
                                                                        value: 10,
                                                                        message:
                                                                            "Ha excedido el máximo de caracteres",
                                                                    },
                                                                    pattern: {
                                                                        value: /^\d+$/, // Asegura que solo se introduzcan números.
                                                                        message: "Solo se permiten números.",
                                                                    },
                                                                })}
                                                            />
                                                            {errors.numeroContacto && (
                                                                <Alert variant="danger" role="alert">
                                                                    {errors.numeroContacto.message}
                                                                </Alert>
                                                            )}
                                                        </Form.Group>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>

                                        <Card>
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div className="fw-bold">
                                                            <p className="fs-3">SECCION</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <Form.Group className="mb-3 col-sm-8">
                                                            <Form.Label
                                                                htmlFor="fechaIngreso"
                                                                className="fw-bold"
                                                            >
                                                                Fecha de ingreso
                                                            </Form.Label>
                                                            <div>
                                                                <Controller
                                                                    render={({ field }) => (
                                                                        <DatePicker
                                                                            className="form-control"
                                                                            id="fechaIngreso"
                                                                            dateFormat="dd/MM/yyyy"
                                                                            selected={
                                                                                field.value
                                                                                    ? dayjs(
                                                                                        field.value,
                                                                                        "DD/MM/YYYY"
                                                                                    ).toDate()
                                                                                    : null
                                                                            }
                                                                            onChange={(date) => {
                                                                                field.onChange(
                                                                                    dayjs(date).format("DD/MM/YYYY")
                                                                                );
                                                                            }}
                                                                        />
                                                                    )}
                                                                    name="fechaIngreso"
                                                                    control={control}
                                                                    rules={{ required: "Ingrese un dato válido" }}
                                                                />
                                                            </div>
                                                            {errors.fechaIngreso && (
                                                                <Alert variant="danger" role="alert">
                                                                    {errors.fechaIngreso.message}
                                                                </Alert>
                                                            )}
                                                        </Form.Group>
                                                    </div>

                                                    <div className="col-md-3">
                                                        <Form.Group className="mb-3">
                                                            <Controller
                                                                name="idEstatus"
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <>
                                                                        <Form.Label htmlFor="idEstatus">
                                                                            Estatus
                                                                        </Form.Label>
                                                                        <Form.Check
                                                                            id="idEstatus"
                                                                            type="switch"
                                                                            label={
                                                                                Boolean(field.value)
                                                                                    ? "Activo"
                                                                                    : "Inactivo"
                                                                            }
                                                                            checked={Boolean(field.value)}
                                                                            onChange={(e) => {
                                                                                field.onChange(
                                                                                    Number(e.target.checked)
                                                                                );
                                                                            }}
                                                                        />
                                                                    </>
                                                                )}
                                                            />
                                                        </Form.Group>
                                                        {errors.idEstatus && (
                                                            <Alert variant="danger" role="alert">
                                                                {errors.idEstatus.message}
                                                            </Alert>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>

                                        <Button
                                            type="submit"
                                            variant="primary"
                                            className="me-2"
                                            size="md"
                                        >
                                            <i className="bi bi-save"></i> Guardar
                                        </Button>
                                        <Link
                                            to={{
                                                pathname: "/administracion/empleados",
                                                state: { empleado: empleado },
                                            }}
                                            className="col-12 text-end"
                                        >
                                            <Button variant="secondary" className="me-2" size="md">
                                                <i className="bi bi-box-arrow-in-left"></i> Regresar
                                            </Button>
                                        </Link>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </section>
            </main>
        </>
    );
};

export default EmpleadosVista;
