import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const entradasApi = createApi({
    reducerPath: 'entradasApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "entradas",
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        obtenerEntrada: build.query({
            query: (idEntrada) => `/${idEntrada}`,
        }),
        obtenerEntradas: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        buscarEntradas: build.query({
            query: ({ pageNumber, elementsPerPage, searchParams }) => `/buscador?page=${pageNumber}&size=${elementsPerPage}&` + new URLSearchParams(searchParams).toString(),
        }),
        guardarEntrada: build.mutation({
            query: (entrada) => ({
                url: '',
                method: entrada.idEntrada ? 'PUT' : 'POST',
                body: entrada,
            }),
        }),
        eliminarEntrada: build.mutation({
            query: (entrada) => ({
                url: '',
                method: 'DELETE',
                body: entrada,
            }),
        }),
    }),
});
export const {
    useObtenerEntradaQuery,
    useObtenerEntradasQuery,
    useBuscarEntradasQuery,
    useLazyObtenerEntradasQuery,
    useGuardarEntradaMutation,
    useEliminarEntradaMutation
} = entradasApi;
