import {
    useObtenerPuestosQuery,
    useEliminarPuestoMutation,
    useLazyObtenerPuestosQuery
} from '../../../services/puesto.service'
import React, { useState } from 'react'
import { Button, Row, Col, Card } from 'react-bootstrap'
import SeverPagination from '../../../components/base/SeverPagination'
import PuestosForm from './PuestosForm'
import BootstrapTable from '../../../components/base/BootstrapTable'
import ConfirmDialog from '../../../components/base/ConfirmDialog'
import { ExportToExcel } from '../../../components/base/ExportToExcel'

const Puestos = () => {
    const [pageNumber, setPageNumber] = useState(1)
    const [elementsPerPage, setElementsPerPage] = useState(10)

    const [formPuestos, setFormPuestos] = useState(null)
    const [confirmDialog, setConfirmDialog] = useState({})

    const { data: puestos, refetch } = useObtenerPuestosQuery([pageNumber, elementsPerPage], { refetchOnMountOrArgChange: true })
    const [trigger] = useLazyObtenerPuestosQuery()
    const [eliminarPuesto] = useEliminarPuestoMutation()
    
    return (
        <div id='main' className='main' >
            <section className='section'>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <Card.Body>
                                <div className='btn-toolbar justify-content-between' role='toolbar'>
                                    <div className='pagetitle'>
                                        <h1>Puestos</h1>
                                    </div>
                                </div>
                                {puestos && (
                                    <BootstrapTable
                                        striped
                                        toolbar={({ columns }) => (
                                            <div>
                                                <Button href='#' className='me-2' variant='primary' onClick={(e) => {
                                                    setFormPuestos({})
                                                }}>
                                                    <i className='bi bi-plus-square'></i>&nbsp;Nuevo
                                                </Button>
                                                <ExportToExcel columns={columns} onClick={trigger} fileName={'puestos'} />
                                            </div>
                                        )}
                                        data={puestos.data}
                                        columns={[{
                                            header: 'Nombre del puesto',
                                            accessorKey: 'puesto',
                                            wrapText: true
                                        }, {
                                            header: 'Clave',
                                            accessorKey: 'clave'
                                        }, {
                                            header: 'Estatus',
                                            accessorKey: 'estatus.estatus',
                                            enableGlobalFilter: false
                                        }, {
                                            header: 'Acciones',
                                            enableGlobalFilter: false,
                                            cell: ({ row: { original } }) => (
                                                <div className="w-100 d-grid d-sm-block gap-1">
                                                    <Button
                                                        variant="warning" className="me-2" size="sm"
                                                        onClick={(e) => setFormPuestos(original)} >
                                                        <i className="bi bi-pencil-square"> </i>
                                                        Editar
                                                    </Button>
                                                    <Button
                                                        variant="danger" className="me-2" size="sm" onClick={(e) => setConfirmDialog({
                                                            message: "¿Desea eliminar este registro?",
                                                            onConfirm: async () => {
                                                                await eliminarPuesto({
                                                                    idPuesto: original.idPuesto,
                                                                });
                                                                refetch();
                                                                setConfirmDialog({});
                                                            }
                                                        })} >
                                                        <i className="bi bi-trash"> </i>
                                                        Eliminar
                                                    </Button>
                                                </div>
                                            ),
                                        }
                                        ]}
                                        onGlobalFilterChange={(e) => {
                                            setPageNumber(1)
                                            setElementsPerPage(e ? -1 : 10)
                                        }}
                                    />
                                )}
                                {puestos && (
                                    <SeverPagination
                                        data={puestos.data}
                                        currentPage={pageNumber}
                                        totalElements={puestos.totalElements}
                                        elementsPerPage={elementsPerPage}
                                        onPageChange={setPageNumber}
                                        onElementsPerPageChange={setElementsPerPage}
                                    />
                                )}
                            </Card.Body>
                            <ConfirmDialog
                                {...confirmDialog} onCancel={() => setConfirmDialog({})}
                            />
                        </Card>
                    </Col>
                </Row>
            </section>
            {formPuestos && (<PuestosForm show={formPuestos} onHide={(e) => {
                setFormPuestos(false)
            }}
                onSave={refetch}
            />
            )}
        </div>
    )
}

export default Puestos