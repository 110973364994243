import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {Alert, Button, Form, Modal} from "react-bootstrap";

const UsuariosBuscardorForm = ({show, data, onHide, onSearch, onNew}) => {

    const handleClose = () => {
        onHide();
    };

    const {handleSubmit, register, watch, reset, formState: {errors}} = useForm();

    useEffect(() => {
        if (data) {
            reset({
                usuario: data.usuario,
                nombreCompleto: data.nombreCompleto
            });
        }
    }, [data, reset]);

    const onSubmit = async (formData) => {
        onSearch(formData);
        handleClose();
    };

    return (<> {<Modal show={show} onHide={handleClose} centered backdrop="static" className="modal-dialog"
                       keyboard={false}
                       style={{position: "fixed", left: 0, right: 0, width: 350, margin: 'auto'}}>
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header closeButton>
                <Modal.Title>Buscar [ Usuarios ]</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <i className="bi bi-person-fill text-primary d-block text-center"
                   style={{fontSize: '6em', lineHeight: 0}}></i>
                <Form.Group className="mb-3 mwx-200">
                    <Form.Label htmlFor="usuario" className="fw-bold">
                        Usuario
                    </Form.Label>
                    <Form.Control
                        {...register('usuario', {
                            validate: (value) => {
                                return !!value.trim() || !!watch("nombreCompleto").trim();
                            },
                        })}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label htmlFor="nombreCompleto" className="fw-bold">
                        Nombre completo
                    </Form.Label>
                    <Form.Control
                        {...register('nombreCompleto', {
                            validate: (value) => {
                                return !!value.trim() || !!watch("usuario").trim();
                            },
                        })}
                    />
                </Form.Group>
                {(errors.nombreCompleto || errors.usuario) && (<Alert variant="danger" role="alert">
                    Ingrese al menos un dato válido
                </Alert>)}
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <Button type="submit" variant="primary" className="me-4">
                    <i className="bi bi-search"></i> Buscar
                </Button>
                <Button variant="secondary" onClick={onNew}>
                    <i className="bi bi-plus-square"></i> Nuevo Usuario
                </Button>
            </Modal.Footer>
        </Form>
    </Modal>}
    </>);
};

export default UsuariosBuscardorForm;
