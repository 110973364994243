import React, {useState} from 'react';
import {NumericFormat} from "react-number-format";
import {Link} from "react-router-dom";
import ObservacionInfo from "./ObservacionInfo";
import LineaTiempoInfo from "./LineaTiempoInfo";
import SolicitudPagoReporte from "./SolicitudPagoReporte";
import {Badge, Button, Card} from "react-bootstrap";

const SolicitudPagoCard = ({solicitud}) => {

    const [modalObservaciones, setModalObservaciones] = useState(null);
    const [modalLineaTiempo, setModalLineaTiempo] = useState(null);
    const [modalReporteSolicitudPago, setModalReporteSolicitudPago] = useState(null);

    return (<Card.Header className="row mt-3">
        <Card.Header className="col-sm-12 col-md-12">
            <Card className={`mb-0`}>
                <Card.Header className={`pb-0 fw-bold alert ${solicitud.estatus.color}`}>
                    <h5 className="card-title pt-1 pb-0 text-primary">{solicitud.folio.toString().padStart(7, '0')} | {solicitud.empresaProveedor.empresa}</h5>
                </Card.Header>
                <div className="row card-body">
                    <p className="card-text mb-0">
                        <span className="text-muted">Fecha de modificación: <b>{solicitud.fechaVersion?.split(" ")[0]}</b></span> |
                        Estatus: <b>{solicitud.estatus.estatus}</b></p>
                    <div className="col-md-12 mb-2 pb-0">
                        <p className="card-text mb-1">
                            <strong>Solicita: </strong>{solicitud.empleado.nombreCustom}</p>
                        <p className="card-text mb-1">
                            <strong>Empresa: </strong>{solicitud.empresa.empresa}</p>
                        <p className="card-text mb-1">
                            <strong>Proyecto: </strong>{solicitud.proyecto.proyecto}</p>
                        <p className="card-text mb-1">
                            <strong>Monto: </strong>
                            <NumericFormat
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$ '}
                                decimalScale={2}
                                fixedDecimalScale
                                value={solicitud.importe}/>
                            &nbsp;{solicitud.tipoMoneda.clave}
                        </p>
                    </div>

                    <div className="col-md-12">
                        <div className="d-inline mb-2">
                            <Link className="btn btn-sm btn-outline-primary me-2 mb-2 py-0"
                                  data-bs-toggle="tooltip" data-bs-title="Visualizar Expediente"
                                  to={"/solicitudes/pagos/view/" + solicitud.idSolicitudPago}>
                                <i className="bi bi-file-text fs-5"></i> Visualizar solicitud
                            </Link>
                        </div>
                        <div className="d-inline mb-2">
                            <Button
                                onClick={() => setModalObservaciones({idProceso: 1, id: solicitud.idSolicitudPago})}
                                variant="outline-primary"
                                size="sm"
                                className="me-2 mb-2 py-0"
                                disabled={solicitud.numeroObservacion === 0}
                            >
                                <i className="bi bi-book fs-5"></i> Observaciones{' '}
                                <Badge
                                    bg={solicitud.numeroObservacion > 0 ? 'danger' : 'secondary'}
                                >
                                    {solicitud.numeroObservacion}
                                </Badge>
                            </Button>
                            {modalObservaciones && (
                                <ObservacionInfo
                                    show={modalObservaciones}
                                    onHide={() => setModalObservaciones(null)}
                                />
                            )}
                        </div>
                        <div className="d-inline mb-2">
                            <Button
                                onClick={() => setModalLineaTiempo(solicitud.idSolicitudPago)}
                                variant="outline-primary"
                                size="sm"
                                className="me-2 mb-2 py-0"
                            >
                                <i className="bi bi-diagram-3 fs-5"></i> Línea de tiempo
                            </Button>
                            {modalLineaTiempo && <LineaTiempoInfo show={modalLineaTiempo} onHide={() => (setModalLineaTiempo(null))} />}
                        </div>
                        <div className="d-inline mb-2">
                            <Button
                                onClick={() =>
                                    setModalReporteSolicitudPago(solicitud.idSolicitudPago)
                                }
                                variant="outline-primary"
                                size="sm"
                                className="me-2 mb-2 py-0"
                            >
                                <i className="bi bi-file-pdf fs-5"></i> Imprimir
                            </Button>
                            {modalReporteSolicitudPago && <SolicitudPagoReporte show={modalReporteSolicitudPago} name={`solicitud-${solicitud.folio}`} onHide={() => (setModalReporteSolicitudPago(null))} />}
                        </div>
                    </div>

                </div>

            </Card>
        </Card.Header>
    </Card.Header>);
};

export default SolicitudPagoCard;
