import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const usuariosModulosApi = createApi({
    reducerPath: 'usuariosModulosApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "usuarios/modulos",
        prepareHeaders: (headers, {getState}) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (build) => ({
        obtenerUsuariosModulos: build.query({
            query: (usuario) => `/${usuario}`,
        }),
        guardarUsuariosModulos: build.mutation({
            query: (rolesModulos) => ({
                url: '',
                method: 'POST',
                body: rolesModulos,
            }),
        }),
    }),
});
export const {
    useObtenerUsuariosModulosQuery,
    useLazyObtenerUsuariosModulosQuery,
    useGuardarUsuariosModulosMutation,
} = usuariosModulosApi;
