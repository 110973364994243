import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const categoriasApi = createApi({
    reducerPath: 'categoriasApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "categorias",
        prepareHeaders: (headers, {getState}) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        obtenerCategoria: build.query({
            query: (idCategoria) => `/${idCategoria}`,
        }),
        obtenerCategorias: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        guardarCategoria: build.mutation({
            query: (categoria) => ({
                url: '',
                method: categoria.idCategoria ? 'PUT' : 'POST',
                body: categoria,
            }),
        }),
        eliminarCategoria: build.mutation({
            query: (categoria) => ({
                url: '',
                method: 'DELETE',
                body: categoria,
            }),
        }),
    }),
});

export const {
    useObtenerCategoriaQuery,
    useObtenerCategoriasQuery,
    useLazyObtenerCategoriasQuery,
    useGuardarCategoriaMutation,
    useEliminarCategoriaMutation
} = categoriasApi;
