import React, {useEffect} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {Alert, Button, Form, Modal} from "react-bootstrap"
import {useGuardarUnidadMedidaMutation, useObtenerUnidadMedidaQuery} from "../../../services/unidadesMedidas.service"

const UnidadesMedidasForm = ({show, onHide, onSave}) => {

    const handleClose = (e) => {
        onHide(e)
    }

    const {control, handleSubmit, setValue, register, reset, formState: {errors}} = useForm()

    const {data: unidadMedida} = useObtenerUnidadMedidaQuery(show.idUnidadMedida, {skip: !show.idUnidadMedida, refetchOnMountOrArgChange: true})
    const [guardarUnidadMedida] = useGuardarUnidadMedidaMutation()

    useEffect(() => {
        if (unidadMedida) {
            reset({
                idUnidadMedida: unidadMedida.idUnidadMedida,
                unidadMedida: unidadMedida.unidadMedida,
                idEstatus: unidadMedida.idEstatus,
                fechaVersion: unidadMedida.fechaVersion
            })
        } else {
            //default values
            reset({idEstatus: 1})
        }
    }, [unidadMedida, reset])

    const onSubmit = async (formData) => {
        let {data} = await guardarUnidadMedida(formData)
        if(data){
            setValue('idUnidadMedida', data.id)
            setValue('fechaVersion', data.fechaVersion)
            onSave()
        }
    }

    return (<> {
        <Modal show={show} onHide={handleClose} centered backdrop="static" keyboard={false}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>{show.idUnidadMedida ? 'Editar' : 'Nuevo'} [ Unidad Medida ]</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="text" id="idUnidadMedida" className="d-none" {...register("idUnidadMedida")} />
                    <Form.Control type="text" id="fechaVersion" className="d-none" {...register("fechaVersion")} />
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="unidadMedida" className="fw-bold">
                            Unidad Medida
                        </Form.Label>
                        <Form.Control
                            {...register('unidadMedida', {
                                required: 'Ingrese un dato válido',
                                maxLength: {value: 50, message: 'Ha excedido el máximo de caracteres'}
                            })}
                        />
                        {errors.unidadMedida && (<Alert variant="danger" role="alert">
                            {errors.unidadMedida.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Controller
                            name="idEstatus"
                            control={control}
                            render={({field}) => (<>
                                    <Form.Label htmlFor="idEstatus">Estatus</Form.Label>
                                    <Form.Check
                                        id="idEstatus"
                                        type="switch"
                                        label={Boolean(field.value) ? 'Activo' : 'Inactivo'}
                                        checked={Boolean(field.value)}
                                        onChange={(e) => {
                                            field.onChange(Number(e.target.checked))
                                        }}
                                    />
                                </>

                            )}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant="primary" className="me-2">
                        <i className="bi bi-save"></i> Guardar
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>}
    </>)
}
export default UnidadesMedidasForm