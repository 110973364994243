import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../actions/authActions";
import Spinner from "../components/base/Spinner";
import Logo from "../assets/img/logo.png";
import Error from "../components/Error";
import { Button, Card, Container, Form, InputGroup } from "react-bootstrap";

const Login = () => {
  const { loading, userInfo, error } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const navigate = useNavigate();

  //redirige usuarios autenticados a la página de inicio
  useEffect(() => {
    if (userInfo && userInfo.userType === "B") {
      navigate("/dashboard");
    } else if (userInfo && userInfo.userType === "X"){
      navigate("/dashboard");
    } else if (userInfo && userInfo.userType === "C"){
      navigate("/dashboard")
    }
  }, [navigate, userInfo]);

  const submitForm = (data) => {
      dispatch(userLogin(data));
  };

  return (
    <>
      <main>
        <Container>

          <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

                  <div className="d-flex justify-content-center py-4">
                    <div className="logo d-flex align-items-center w-auto">
                      <img src={Logo} alt=""/>
                      <span className="d-none d-lg-block text-decoration-none">MORSA</span>
                    </div>
                  </div>

                  { error ? (
                    <Error message={error} />
                  ) : null }

                  <Card className="mb-3">

                    <Card.Body>

                      <div className="pt-4 pb-2">
                        <Card.Title className="text-center pb-0 fs-4">Acceso al sistema</Card.Title>
                        <p className="text-center small">favor de teclear su usuario y contraseña</p>
                      </div>

                      <Form className="row g-3 needs-validation" noValidate onSubmit={handleSubmit(submitForm)}  autoComplete="off">

                        <Form.Group className="mb-2" controlId="user">
                          <Form.Label className="form-label">Usuario</Form.Label>
                          <InputGroup className="mb-2">
                          <InputGroup.Text id="user-addon">@</InputGroup.Text>
                            <Form.Control
                              placeholder="Usuario"
                              aria-label="Usuario"
                              aria-describedby="user-addon"
                              {...register("user")}
                              name="user"
                              className="form-control"
                              required
                            />
                          </InputGroup>
                        </Form.Group>

                        <Form.Group className="mb-2" controlId="password">
                          <Form.Label className="form-label">Contraseña</Form.Label>
                          <Form.Control
                            type="password"
                            name="password"
                            aria-label="Contraseña"
                            aria-describedby="password-addon"
                            {...register("password")}
                            required
                          />
                        </Form.Group>

                        <Form.Group className="mb-2">
                          <Form.Check 
                            type="checkbox"
                            name="remember"
                            value={true}
                            label="Recordar mis datos"
                          />
                        </Form.Group>

                        <Form.Group className="mb-2">
                          <Button className="w-100" variant="primary" type="submit">{loading ? <Spinner /> : "INICIAR SESIÓN"}</Button>
                        </Form.Group>
                      </Form>

                    </Card.Body>
                  </Card>

                  

                </div>
              </div>
            </div>

          </section>

        </Container>
      </main>
    </>
  );
};

export default Login;
