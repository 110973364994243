import React, { Fragment, useCallback, useState } from "react"; //, useState
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./views/Login";
import Copyright from "./components/Copyright";
import Dashboard from "./views/Dashboard";
import ProtectedRoute from "./routing/ProtectedRoute";
import Barras from "./components/Barras";
import { logout } from "./slices/authSlice";
import { useDispatch } from "react-redux";
import AuthVerify from "./helpers/AuthVerify";
import "./App.css";
import SolicitudesBandeja from "./views/SolicitudesBandeja";
import "react-datepicker/dist/react-datepicker.css";
import SolicitudesPagos from "./views/SolicitudesPagos";
import Bandejas from "./views/Bandejas";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import LoaderModal from "./components/base/SpinnerModal";
import Bancos from "./views/catalogos/bancos/Bancos";
import Roles from "./views/catalogos/roles/Roles";
import Usuarios from "./views/catalogos/usuarios/Usuarios";
import FAB from "./components/base/FAB";
import MesaDeAyuda from "./helpers/MesaDeAyuda";
import exportAsImage from "./helpers/exportAsImage";
import Turnos from "./views/catalogos/turnos/Turnos";
import Marcas from "./views/catalogos/marcas/Marcas";
import Prioridades from "./views/catalogos/prioridades/Prioridades";
import UnidadesMedidas from "./views/catalogos/unidadesMedidas/UnidadesMedidas";
import Categorias from "./views/catalogos/categorias/Categorias";
import Departamentos from "./views/catalogos/departamentos/Departamentos";
import ConfiguracionesCorreos from "./views/catalogos/configuracionesCorreos/ConfiguracionesCorreos";
import RegimenesFiscales from "./views/catalogos/regimenes/RegimenesFiscales";
import ProtocolosCorreos from "./views/catalogos/protocolos/ProtocolosCorreos";
import Empresas from "./views/administracion/empresas/Empresas";
import EmpresasVista from "./views/administracion/empresas/EmpresasVista";
import TiposPersonas from "./views/catalogos/tiposPersonas/TiposPersonas";
import Productos from "./views/catalogos/productos/Productos";
import Municipios from "./views/catalogos/municipios/Municipios";
import SolicitudesReembolsos from "./views/procesos/solicitudes_reembolsos/SolicitudesReembolsos";
import BuscadorReembolsos from "./views/procesos/solicitudes_reembolsos/BuscadorReembolsos";
import Almacenes from "./views/catalogos/almacenes/Almacenes";
import SeccionesAlmacenes from "./views/catalogos/seccionesAlmacenes/SeccionesAlmacenes";
import TiposSeccionesAlmacenes from "./views/catalogos/tiposSeccionesAlmacenes/TiposSeccionesAlmacenes";
import MetodosSalidasAlmacenes from "./views/catalogos/MetodosSalidasAlmacenes/MetodosSalidasAlmacenes";
import MetodosTrasladosAlmacenes from "./views/catalogos/MetodosTrasladosAlmacenes/MetodosTrasladosAlmacenes";
import PasillosAlmacenes from "./views/catalogos/pasillosAlmacenes/PasillosAlmacenes";
import EstanteriasAlmacenes from "./views/catalogos/estanteriasAlmacenes/EstanteriasAlmacenes";
import Colores from "./views/catalogos/colores/Colores";
import TiposProductos from "./views/catalogos/tiposProductos/TiposProductos";
import Impuestos from "./views/catalogos/impuestos/Impuestos";
import OrigenesEntradas from "./views/catalogos/origenesEntradas/OrigenesEntradas";
import OrigenesSalidas from "./views/catalogos/origenesSalidas/OrigenesSalidas";
import EntradasProductos from "./views/procesos/entradas_productos/EntradasProductos";
import EntradasProductosForm from "./views/procesos/entradas_productos/EntradasProductosForm";
import SalidasProductos from "./views/procesos/salidas_productos/SalidasProductos";
import SalidasProductosForm from "./views/procesos/salidas_productos/SalidasProductosForm";
import Inventarios from "./views/catalogos/inventarios/Inventarios";
import ConfiguracionesAlmacenes from "./views/configuraciones/configuracionesAlmacenes/ConfiguracionesAlmacenes";
import CategoriasProductos from "./views/catalogos/categoriasProductos/CategoriasProductos";
import TiposCombustibles from "./views/catalogos/tiposCombustibles/TiposCombustibles";
import ClasesTransportes from "./views/catalogos/clasesTransportes/clasesTransportes";
import MarcasTransportes from "./views/catalogos/marcasTransportes/MarcasTransportes";
import FabricantesTransportes from "./views/catalogos/fabricantesTransportes/FabricantesTransportes";
import Transportes from "./views/catalogos/transportes/Transportes";
import Mantenimientos from "./views/mantenimientos/mantenimientos/Mantenimientos";
import TiposMantenimientos from "./views/catalogos/tiposMantenimientos/TiposMantenimientos";
import Kardex from "./views/reportes/almacen/kardex";
import ControlMantenimientos from "./views/mantenimientos/control_mantenimientos/ControlMantenimientos";
import ControlMantenimientosForm from "./views/mantenimientos/control_mantenimientos/ControlMantenimientosForm";
import Localidades from "./views/catalogos/localidades/Localidades";
import TiposInstalaciones from "./views/catalogos/tiposInstalaciones/TiposInstalaciones";
import Empleados from "./views/administracion/empleados/Empleados";
import EmpleadosVista from "./views/administracion/empleados/EmpleadosVista";
import Puestos from "./views/catalogos/puestos/Puestos";
import TiposDocumentos from "./views/catalogos/tiposDocumentos/TiposDocumentos";
import DocumentacionLegal from "./views/catalogos/documentacionLegal/DocumentacionLegal";
import ConfiguracionDocumento from "./views/configuraciones/configuracionesDocumentos/ConfiguracionDocumento";
import ConfiguracionDocumentoForm from "./views/configuraciones/configuracionesDocumentos/ConfiguracionDocumentoForm";
import Vacaciones from "./views/procesos/vacaciones/Vacaciones"

import VacacionesForm from "./views/procesos/vacaciones/VacacionesForm"

function App() {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const props = {
    handleClose,
    handleShow,
    show,
    exportAsImage,
  };
  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  return (
    <>
      <Router>
        <Routes>
          <Fragment>
            <Route path="/" element={<Login />} />
            <Route element={<Barras />}>
              <Route element={<ProtectedRoute />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/bandejas/:id" element={<Bandejas />} />
                <Route
                  path="/solicitudes/pagos"
                  element={<SolicitudesBandeja />}
                />
                <Route
                  path="/solicitudes/pagos/view/:id?"
                  element={<SolicitudesPagos />}
                />
                <Route path="/catalogos/bancos" element={<Bancos />} />
                <Route path="/catalogos/turnos" element={<Turnos />} />
                <Route path="/catalogos/marcas" element={<Marcas />} />
                <Route
                  path="/catalogos/prioridades"
                  element={<Prioridades />}
                />
                <Route
                  path="/catalogos/unidades/medidas"
                  element={<UnidadesMedidas />}
                />
                <Route path="/catalogos/categorias" element={<Categorias />} />
                <Route
                  path="/catalogos/departamentos"
                  element={<Departamentos />}
                />
                <Route
                  path="/catalogos/configuraciones/correos"
                  element={<ConfiguracionesCorreos />}
                />
                <Route
                  path="/catalogos/regimenes/fiscales"
                  element={<RegimenesFiscales />}
                />
                <Route
                  path="/catalogos/protocolos/correos"
                  element={<ProtocolosCorreos />}
                />
                <Route
                  path="/catalogos/tipos/personas"
                  element={<TiposPersonas />}
                />
                <Route path="/catalogos/productos" element={<Productos />} />
                <Route path="/catalogos/municipios" element={<Municipios />} />
                <Route path="/catalogos/almacenes" element={<Almacenes />} />
                <Route
                  path="/catalogos/secciones/almacenes"
                  element={<SeccionesAlmacenes />}
                />
                <Route
                  path="/catalogos/tipos/secciones"
                  element={<TiposSeccionesAlmacenes />}
                />
                <Route
                  path="/catalogos/metodos/salidas"
                  element={<MetodosSalidasAlmacenes />}
                />
                <Route
                  path="/catalogos/metodos/traslados"
                  element={<MetodosTrasladosAlmacenes />}
                />
                <Route
                  path="/catalogos/almacenes/pasillos"
                  element={<PasillosAlmacenes />}
                />
                <Route
                  path="/catalogos/almacenes/estanterias"
                  element={<EstanteriasAlmacenes />}
                />
                <Route
                  path="/catalogos/tipos/productos"
                  element={<TiposProductos />}
                />
                <Route path="/catalogos/colores" element={<Colores />} />
                <Route path="/catalogos/impuestos" element={<Impuestos />} />
                <Route
                  path="/catalogos/origenes/entradas"
                  element={<OrigenesEntradas />}
                />
                <Route
                  path="/catalogos/origenes/salidas"
                  element={<OrigenesSalidas />}
                />
                <Route
                  path="/catalogos/categorias/productos"
                  element={<CategoriasProductos />}
                />
                <Route
                  path="/catalogos/tipos/combustibles"
                  element={<TiposCombustibles />}
                />
                <Route
                  path="/catalogos/clases/transportes"
                  element={<ClasesTransportes />}
                />
                <Route
                  path="/catalogos/marcas/transportes"
                  element={<MarcasTransportes />}
                />
                <Route
                  path="/catalogos/fabricantes/transportes"
                  element={<FabricantesTransportes />}
                />
                <Route
                  path="/catalogos/transportes"
                  element={<Transportes />}
                />
                <Route
                  path="/catalogos/tipos/mantenimientos"
                  element={<TiposMantenimientos />}
                />
                <Route
                  path="/catalogos/tipos/instalaciones"
                  element={<TiposInstalaciones />}
                />
                <Route
                  path="/catalogos/localidades"
                  element={<Localidades />}
                />
                <Route path="/catalogos/puestos" element={<Puestos />} />
                <Route
                  path="/catalogos/tipos/documentos"
                  element={<TiposDocumentos />}
                />
                <Route
                  path="/administracion/documentacion/legal"
                  element={<DocumentacionLegal />}
                />
                <Route path="/seguridad/roles" element={<Roles />} />
                <Route path="/seguridad/usuarios" element={<Usuarios />} />
                <Route
                  path="/buscador/reembolsos"
                  element={<BuscadorReembolsos />}
                />
                <Route
                  path="/solicitudes/reembolsos/view/:id?"
                  element={<SolicitudesReembolsos />}
                />
                <Route
                  path="/solicitudes/vacaciones"
                  element={<Vacaciones />}
                /> 
                <Route
                  path="/solicitudes/vacaciones/view/:id?"
                  element={<VacacionesForm />}
                />
                <Route
                  path="/entradas/productos"
                  element={<EntradasProductos />}
                />
                <Route
                  path="/entradas/productos/view/:id?"
                  element={<EntradasProductosForm />}
                />
                <Route
                  path="/salidas/productos"
                  element={<SalidasProductos />}
                />
                <Route
                  path="/salidas/productos/view/:id?"
                  element={<SalidasProductosForm />}
                />
                '' ADMINISTRACION
                <Route path="/administracion/empresas" element={<Empresas />} />
                <Route
                  path="/administracion/empresas/view/:id?"
                  element={<EmpresasVista />}
                />
                <Route
                  path="/administracion/empleados"
                  element={<Empleados />}
                />
                <Route
                  path="/administracion/empleados/view/:id?"
                  element={<EmpleadosVista />}
                />
                '' CONFIGURACIONES
                <Route
                  path="/configuraciones/configuraciones/documentos"
                  element={<ConfiguracionDocumento />}
                />
                <Route
                  path="/configuraciones/configuraciones/almacenes"
                  element={<ConfiguracionesAlmacenes />}
                />
                <Route
                  path="/configuraciones/configuraciones/documentos/view"
                  element={<ConfiguracionDocumentoForm />}
                />
                '' MANTENIMIENTOS
                <Route
                  path="/mantenimientos/mantenimientos"
                  element={<Mantenimientos />}
                />
                <Route
                  path="/mantenimientos/programaciones/mantenimientos"
                  element={<ControlMantenimientos />}
                />
                <Route
                  path="/mantenimientos/programaciones/mantenimientos/view/:id?"
                  element={<ControlMantenimientosForm />}
                />
                <Route path="/inventarios" element={<Inventarios />} />
                <Route path="/reportes/almacen/kardex" element={<Kardex />} />
              </Route>
            </Route>
            <Route path="*" element={<Navigate to="/" replace />} />
          </Fragment>
        </Routes>
        <AuthVerify logOut={logOut} />
        <FAB {...props} />
        <MesaDeAyuda {...props} />
      </Router>
      <LoaderModal />
      <ToastContainer className="col-sm-3" />
      <Copyright />
    </>
  );
}

export default App;
