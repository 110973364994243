import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const prioridadesApi = createApi({
    reducerPath: 'prioridadesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "prioridades",
        prepareHeaders: (headers, {getState}) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }), 
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        obtenerPrioridad: build.query({
            query: (idPrioridad) => `/${idPrioridad}`,
        }),
        obtenerPrioridades: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        guardarPrioridad: build.mutation({
            query: (prioridad) => ({
                url: '',
                method: prioridad.idPrioridad ? 'PUT' : 'POST',
                body: prioridad,
            }),
        }),
        eliminarPrioridad: build.mutation({
            query: (prioridad) => ({
                url: '',
                method: 'DELETE',
                body: prioridad,
            }),
        }),
    }),
});
export const {
    useObtenerPrioridadQuery,
    useObtenerPrioridadesQuery,
    useLazyObtenerPrioridadesQuery,
    useGuardarPrioridadMutation,
    useEliminarPrioridadMutation
} = prioridadesApi;
