import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const pasillosAlmacenesApi = createApi({
    reducerPath: 'pasillosAlmacenesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "pasillos/almacenes",
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),




    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        obtenerPasilloAlmacen: build.query({
            query: (idPasilloAlmacen) => `/${idPasilloAlmacen}`,
        }),
        obtenerPasillosAlmacenes: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        guardarPasilloAlmacen: build.mutation({
            query: (pasilloAlmacen) => ({
                url: '',
                method: pasilloAlmacen.idPasilloAlmacen ? 'PUT' : 'POST',
                body: pasilloAlmacen,
            }),
        }),
        eliminarPasilloAlmacen: build.mutation({
            query: (pasilloAlmacen) => ({
                url: '',
                method: 'DELETE',
                body: pasilloAlmacen,
            }),
        }),
    }),
});

export const {   
               useObtenerPasilloAlmacenQuery,
               useObtenerPasillosAlmacenesQuery,
               useObtenerPasillosAlmacenesByBaseQuery,
               useLazyObtenerPasillosAlmacenesQuery,               
               useGuardarPasilloAlmacenMutation,
               useEliminarPasilloAlmacenMutation
} = pasillosAlmacenesApi;
