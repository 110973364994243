import React, { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../slices/authSlice";
import logo from "../assets/img/logo.png"
import profileImg from "../assets/img/profile-img.jpg"
import messages1 from "../assets/img/messages-1.jpg"
import messages2 from "../assets/img/messages-2.jpg"
import messages3 from "../assets/img/messages-3.jpg"
import SideBarItem from "./SideBarItem";

const Barras = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState);
    const bodyClass = document.body.classList;
    if(bodyClass.contains('toggle-sidebar')){
      bodyClass.remove('toggle-sidebar');
      
    } else {
     bodyClass.add('toggle-sidebar');
    }
  };

  const handleClick = () => {
    if(isSidebarOpen){
      toggleSidebar()
    }
  }

  const { userInfo } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const Salir = () => {
    dispatch(logout());
    const baseUrl = window.location.origin;
    window.location.href = `${baseUrl}/`;
  };

  if(!userInfo){
    Salir();
  }
  let home = "";

  switch (userInfo.userType) {
    case "X":
      home = "dashboard";
      break;
    case "A":
      home = "dashboard";
      break;
    case "C":
      home = "dashboard";
      break;
    default:
      home = "dashboard";
      break;
  }

  

  

  return (
    <>
      <header id="header" className="header fixed-top d-flex align-items-center">

        <div className="d-flex align-items-center justify-content-between">
          <Link to={home} className="logo d-flex align-items-center text-decoration-none">
            <img src={logo} alt=""/>
            <span className="d-none d-lg-block">MORSA</span>
          </Link>
          <i className="bi bi-list toggle-sidebar-btn" onClick={toggleSidebar}></i>
        </div>


        <nav className="header-nav ms-auto">
          <ul className="d-flex align-items-center">

            <li className="nav-item dropdown">

              <Link className="nav-link nav-icon" data-bs-toggle="dropdown">
                <i className="bi bi-bell"></i>
                <span className="badge bg-primary badge-number">4</span>
              </Link>

              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">
                <li className="dropdown-header">
                  You have 4 new notifications
                  <Link to={''}><span className="badge rounded-pill bg-primary p-2 ms-2">View all</span></Link>
                </li>
                <li>
                  <hr className="dropdown-divider"/>
                </li>

                <li className="notification-item">
                  <i className="bi bi-exclamation-circle text-warning"></i>
                  <div>
                    <h4>Lorem Ipsum</h4>
                    <p>Quae dolorem earum veritatis oditseno</p>
                    <p>30 min. ago</p>
                  </div>
                </li>

                <li>
                  <hr className="dropdown-divider"/>
                </li>

                <li className="notification-item">
                  <i className="bi bi-x-circle text-danger"></i>
                  <div>
                    <h4>Atque rerum nesciunt</h4>
                    <p>Quae dolorem earum veritatis oditseno</p>
                    <p>1 hr. ago</p>
                  </div>
                </li>

                <li>
                  <hr className="dropdown-divider"/>
                </li>

                <li className="notification-item">
                  <i className="bi bi-check-circle text-success"></i>
                  <div>
                    <h4>Sit rerum fuga</h4>
                    <p>Quae dolorem earum veritatis oditseno</p>
                    <p>2 hrs. ago</p>
                  </div>
                </li>

                <li>
                  <hr className="dropdown-divider"/>
                </li>

                <li className="notification-item">
                  <i className="bi bi-info-circle text-primary"></i>
                  <div>
                    <h4>Dicta reprehenderit</h4>
                    <p>Quae dolorem earum veritatis oditseno</p>
                    <p>4 hrs. ago</p>
                  </div>
                </li>

                <li>
                  <hr className="dropdown-divider"/>
                </li>
                <li className="dropdown-footer">
                  <Link to={''}>Show all notifications</Link>
                </li>

              </ul>

            </li>

            <li className="nav-item dropdown">

              <Link className="nav-link nav-icon" data-bs-toggle="dropdown">
                <i className="bi bi-chat-left-text"></i>
                <span className="badge bg-success badge-number">3</span>
              </Link>

              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow messages">
                <li className="dropdown-header">
                  You have 3 new messages
                  <Link><span className="badge rounded-pill bg-primary p-2 ms-2">View all</span></Link>
                </li>
                <li>
                  <hr className="dropdown-divider"/>
                </li>

                <li className="message-item">
                  <Link>
                    <img src={messages1} alt="" className="rounded-circle"/>
                    <div>
                      <h4>Maria Hudson</h4>
                      <p>Velit asperiores et ducimus soluta repudiandae labore officia est ut...</p>
                      <p>4 hrs. ago</p>
                    </div>
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider"/>
                </li>

                <li className="message-item">
                  <Link>
                    <img src={messages2} alt="" className="rounded-circle"/>
                    <div>
                      <h4>Anna Nelson</h4>
                      <p>Velit asperiores et ducimus soluta repudiandae labore officia est ut...</p>
                      <p>6 hrs. ago</p>
                    </div>
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider"/>
                </li>

                <li className="message-item">
                  <Link>
                    <img src={messages3} alt="" className="rounded-circle"/>
                    <div>
                      <h4>David Muldon</h4>
                      <p>Velit asperiores et ducimus soluta repudiandae labore officia est ut...</p>
                      <p>8 hrs. ago</p>
                    </div>
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider"/>
                </li>

                <li className="dropdown-footer">
                  <Link>Show all messages</Link>
                </li>

              </ul>

            </li>

            <li className="nav-item dropdown pe-3">

              <Link className="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                <img src={profileImg} alt="Profile" className="rounded-circle"/>
                <span className="d-none d-md-block dropdown-toggle ps-2">{userInfo.userName}</span>
              </Link>

              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                <li className="dropdown-header">
                  <h6>{userInfo.userName}</h6>
                  <span>{userInfo.rol}</span>
                </li>
                <li>
                  <hr className="dropdown-divider"/>
                </li>

                <li>
                  {/*agregar link al perfil */}
                  <Link className="dropdown-item d-flex align-items-center">
                    <i className="bi bi-person"></i>
                    <span>My Profile</span>
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider"/>
                </li>

                <li>
                  <Link className="dropdown-item d-flex align-items-center">
                    <i className="bi bi-gear"></i>
                    <span>Account Settings</span>
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider"/>
                </li>

                <li>
                  {/* link a pagina de ayuda ? */}
                  <Link className="dropdown-item d-flex align-items-center">
                    <i className="bi bi-question-circle"></i>
                    <span>Need Help?</span>
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider"/>
                </li>

                <li>
                  <Link className="dropdown-item d-flex align-items-center" onClick={Salir}>
                    <i className="bi bi-box-arrow-right"></i>
                    <span>Salir</span>
                  </Link>
                </li>

              </ul>
            </li>

          </ul>
        </nav>

      </header>

      <aside id="sidebar" className="sidebar">

        <ul className="sidebar-nav" id="sidebar-nav">
          {userInfo.userMenu && userInfo.userMenu.map((modulo) => (
              <SideBarItem
                  key={`modulo.${modulo.idModulo}`}
                  modulo={modulo}
                  onClick={(e) => {
                    handleClick()
                  }}/>
          ))}

        </ul>

      </aside>

      <Outlet />
    </>
  );
};

export default Barras;
