import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const proyectosApi = createApi({
    reducerPath: 'proyectosApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (build) => ({
        obtenerProyectos: build.query({
            query: () => '/proyectos',
        }),
        obtenerProyectosPorIdEmpresa: build.query({
            query: (idEmpresa) => `/proyectos/empresas/${idEmpresa}`,
        }),
    }),
});

export const { useObtenerProyectosQuery, useObtenerProyectosPorIdEmpresaQuery } = proyectosApi;
