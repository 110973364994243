import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const empresasApi = createApi({
    reducerPath: 'empresasApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "empresas",
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),


    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        obtenerEmpresasEmpleados: build.query({
            query: () => '/empleados',
        }),
        obtenerEmpresa: build.query({
            query: (idEmpresa) => `/${idEmpresa}`,
        }),
        buscarEmpresas: build.query({
            query: ({ pageNumber, elementsPerPage, searchParams }) => `/buscador?page=${pageNumber}&size=${elementsPerPage}&` + new URLSearchParams(searchParams).toString(),
        }),
        obtenerProveedoresIdEmpresa: build.query({
            query: (idEmpresa) => `/proveedores/${idEmpresa}`,
        }),
        obtenerBancosIdEmpresa: build.query({
            query: (idEmpresa) => `/bancos/${idEmpresa}`,
        }),
        obtenerEmpresas: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        guardarEmpresa: build.mutation({
            query: (empresa) => ({
                url: '',
                method: empresa.idEmpresa ? 'PUT' : 'POST',
                body: empresa,
            }),
        }),
        eliminarEmpresa: build.mutation({
            query: (empresa) => ({
                url: '',
                method: 'DELETE',
                body: empresa,
            }),
        }),
    }),
});

export const {
    useObtenerEmpresaQuery,
    useObtenerEmpresasQuery,
    useBuscarEmpresasQuery,
    useObtenerEmpresasEmpleadosQuery,
    useObtenerProveedoresIdEmpresaQuery,
    useObtenerBancosIdEmpresaQuery,
    useLazyObtenerEmpresasQuery,
    useGuardarEmpresaMutation,
    useEliminarEmpresaMutation
} = empresasApi;
