import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const solicitudesPagosApi = createApi({
    reducerPath: 'solicitudesPagosApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "solicitudes/pagos",
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (build) => ({
        obtenerSolicitudPago: build.query({
            query: ({idSolicitudPago}) => `/${idSolicitudPago}?`,
        }),
        buscarSolicitudesPagos: build.query({
            query: ({pageNumber, elementsPerPage, searchParams}) => `/buscador?page=${pageNumber}&size=${elementsPerPage}&` + new URLSearchParams(searchParams).toString(),
        }),
        guardarSolicitudPago: build.mutation({
            query: (solicitud) => ({
                url: '',
                method: solicitud.idSolicitudPago ? 'PATCH' : 'POST',
                body: solicitud,
            }),
        }),
    }),
});

export const {
    useObtenerSolicitudPagoQuery,
    useBuscarSolicitudesPagosQuery,
    useGuardarSolicitudPagoMutation,
} = solicitudesPagosApi;
