import React from 'react'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {Button} from "react-bootstrap";
import {toast} from "react-toastify";
import _ from "lodash";

export const ExportToExcel = ({columns, onClick, fileName}) => {
    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const exportToSheet = (headers, rows, fileName) => {
        const ws = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(ws, [headers]);
        XLSX.utils.sheet_add_json(ws, rows, {skipHeader: true, origin: "A2"});
        const wb = {Sheets: {data: ws}, SheetNames: ["data"]};
        const excelBuffer = XLSX.write(wb, {bookType: "xlsx", type: "array"});
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    };
    return (
        <Button href="#" className="me-2" variant="secondary" onClick={async (e) => {
            const {data: apiData} = await onClick();

            if (apiData?.length || apiData?.data?.length) {
                const selected = columns.filter(e => e.accessorKey);
                const keys = selected.map(e => e.accessorKey)
                const headers = selected.map(e => e.header)
                //filtrar propiedades basado en las columnas
                const rows = _.map(apiData.data?? apiData, (obj) => {
                    const newObj = {};
                    keys.forEach(prop => {
                        let value = _.get(obj, prop);
                        if(typeof value === 'boolean'){
                            value = value? 'Si' : 'No';
                        }
                        _.set(newObj, prop.replaceAll(".", '_'), value);
                    });
                    return newObj;
                });

                exportToSheet(headers, rows, fileName)
            } else {
                toast.info("No se encontraron registros");
            }

        }}>
            <i className="bi bi-file-earmark-excel"></i>&nbsp;Excel
        </Button>
    );
};