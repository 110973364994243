import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const municipiosApi = createApi({
    reducerPath: 'municipiosApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "municipios",
        prepareHeaders: (headers, {getState}) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        obtenerMunicipio: build.query({
            query: (idMunicipio) => `/${idMunicipio}`,
        }),
        obtenerMunicipios: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        obtenerMunicipiosPorEstado: build.query({
            query: (idEntidadFederativa) => `/entidad/federativa/${idEntidadFederativa}`,
        }),
        guardarMunicipio: build.mutation({
            query: (municipio) => ({
                url: '',
                method: municipio.idMunicipio ? 'PUT' : 'POST',
                body: municipio,
            }),
        }),
        eliminarMunicipio: build.mutation({
            query: (municipio) => ({
                url: '',
                method: 'DELETE',
                body: municipio,
            }),
        }),
    }),
});

export const {
    useObtenerMunicipioQuery,
    useObtenerMunicipiosQuery,
    useObtenerMunicipiosPorEstadoQuery,
    useLazyObtenerMunicipiosQuery,
    useGuardarMunicipioMutation,
    useEliminarMunicipioMutation
} = municipiosApi;
