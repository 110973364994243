import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const solicitudesReembolsosApi = createApi({
    reducerPath: 'solicitudesReembolsosApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "solicitudes/reembolsos",
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        }
    }),
    endpoints: (build) => ({
        obtenerSolicitudReembolso: build.query({
            query: ({idSolicitudReembolso}) => `/${idSolicitudReembolso}?`,
        }),
        buscarSolicitudesReembolsos: build.query({
            query: ({pageNumber, elementsPerPage, searchParams}) => `/buscador?page=${pageNumber}&size=${elementsPerPage}&` + new URLSearchParams(searchParams).toString(),
        }),
        guardarSolicitudReembolso: build.mutation({
            query: (solicitud) => ({
                url: '',
                method: solicitud.idSolicitudReembolso ? 'PATCH' : 'POST',
                body: solicitud
            })
        })
    })
});

export const {
    useObtenerSolicitudReembolsoQuery,
    useBuscarSolicitudesReembolsosQuery,
    useGuardarSolicitudReembolsoMutation,
} = solicitudesReembolsosApi;