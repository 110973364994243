import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const estanteriasAlmacenesApi = createApi({
    reducerPath: 'estanteriasAlmacenesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "estanterias/almacenes",
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        obtenerEstanteriaAlmacen: build.query({
            query: (idEstanteriaAlmacen) => `/${idEstanteriaAlmacen}`,
        }),
        obtenerEstanteriasAlmacenes: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        guardarEstanteriaAlmacen: build.mutation({
            query: (estanteriaAlmacen) => ({
                url: '',
                method: estanteriaAlmacen.idEstanteriaAlmacen ? 'PUT' : 'POST',
                body: estanteriaAlmacen,
            }),
        }),
        eliminarEstanteriaAlmacen: build.mutation({
            query: (estanteriaAlmacen) => ({
                url: '',
                method: 'DELETE',
                body: estanteriaAlmacen,
            }),
        }),
    }),
});

export const {   
               useObtenerEstanteriaAlmacenQuery,
               useObtenerEstanteriasAlmacenesQuery,
               useObtenerEstanteriasAlmacenesByBaseQuery,
               useLazyObtenerEstanteriasAlmacenesQuery,               
               useGuardarEstanteriaAlmacenMutation,
               useEliminarEstanteriaAlmacenMutation
} = estanteriasAlmacenesApi;
