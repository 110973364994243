import React, {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Alert,Button, Form, Modal} from "react-bootstrap";
import {useGuardarTipoInstalacionMutation, useObtenerTipoInstalacionQuery} from "../../../services/tipoInstalacion.service";

const TiposInstalacionesForm = ({show, onHide, onSave}) => {

    const handleClose = (e) => {
        onHide(e);
    };

    const {control, handleSubmit, setValue, register, reset, formState: { errors } } = useForm();

    const {data: tipoInstalacion} = useObtenerTipoInstalacionQuery(show.idTipoInstalacion, {skip: !show.idTipoInstalacion, refetchOnMountOrArgChange: true});    

    const [guardarTipoInstalacion] = useGuardarTipoInstalacionMutation();

    useEffect(() => {
        if (tipoInstalacion) {
            reset({
                idTipoInstalacion: tipoInstalacion.idTipoInstalacion,
                tipoInstalacion: tipoInstalacion.tipoInstalacion,                
                idEstatus: tipoInstalacion.idEstatus
            });
        } else {
            //default values
            reset({idEstatus: 1})
        }
    }, [tipoInstalacion, reset]);

    const onSubmit = async (formData) => {
        let {data} = await guardarTipoInstalacion(formData);
        if(data){
            setValue('idTipoInstalacion', data.id);
            onSave();
        }
    };

    return (<> {
        <Modal show={show} onHide={handleClose} centered backdrop="static" keyboard={false}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>{show.idTipoInstalacion ? 'Editar' : 'Nuevo'} [Tipo de instalacion] </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="text" id="idTipoInstalacion" className="d-none" {...register("idTipoInstalacion")} />
                    <Form.Group className="mb-3">


                        <Form.Label htmlFor='tipoInstalacion' className='fw-bold'>
                            Nombre
                        </Form.Label>
                        <Form.Control
                            {...register('tipoInstalacion', {
                                required: 'Ingrese un dato valido',
                                maxLength: { value: 255, message: 'Ha excedido el máximo de caracteres' }
                            })} />
                            {errors.tipoInstalacion && (<Alert variant='danger' role='alert'>
                            {errors.tipoInstalacion.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Controller
                            name="idEstatus"
                            control={control}
                            render={({field}) => (<>
                                    <Form.Label htmlFor="idEstatus">Estatus</Form.Label>
                                    <Form.Check
                                        id="idEstatus"
                                        type="switch"
                                        label={Boolean(field.value) ? 'Activo' : 'Inactivo'}
                                        checked={Boolean(field.value)}
                                        onChange={(e) => {
                                            field.onChange(Number(e.target.checked))
                                        }}
                                    />
                                </>

                            )}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant="primary" className="me-2">
                        <i className="bi bi-save"></i> Guardar
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>}
    </>);
};

export default TiposInstalacionesForm;
