import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const fabricantesTransportesApi = createApi({
    reducerPath: 'fabricantesTransportesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "fabricantes/transportes",
        prepareHeaders: (headers, {getState}) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        obtenerFabricanteTransporte: build.query({
            query: (idFabricanteTransporte) => `/${idFabricanteTransporte}`,
        }),
        obtenerFabricantesTransportes: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        guardarFabricanteTransporte: build.mutation({
            query: (fabricanteTransporte) => ({
                url: '',
                method: fabricanteTransporte.idFabricanteTransporte ? 'PUT' : 'POST',
                body: fabricanteTransporte,
            }),
        }),
        eliminarFabricanteTransporte: build.mutation({
            query: (fabricanteTransporte) => ({
                url: '',
                method: 'DELETE',
                body: fabricanteTransporte,
            }),
        }),
    }),
});
export const {
    useObtenerFabricanteTransporteQuery,
    useObtenerFabricantesTransportesQuery,
    useLazyObtenerFabricantesTransportesQuery,
    useGuardarFabricanteTransporteMutation,
    useEliminarFabricanteTransporteMutation
} = fabricantesTransportesApi;
