import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const salidasApi = createApi({
    reducerPath: 'salidasApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "salidas",
        prepareHeaders: (headers, {getState}) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        obtenerSalida: build.query({
            query: (idSalida) => `/${idSalida}`,
        }),
        obtenerSalidas: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        guardarSalida: build.mutation({
            query: (salida) => ({
                url: '',
                method: salida.idSalida ? 'PUT' : 'POST',
                body: salida,
            }),
        }),
        eliminarSalida: build.mutation({
            query: (salida) => ({
                url: '',
                method: 'DELETE',
                body: salida,
            }),
        }),
        buscarSalidas: build.query({
            query: ({pageNumber, elementsPerPage, searchParams }) => 
            `/buscador?page=${pageNumber}&size=${elementsPerPage}&` + new URLSearchParams(searchParams).toString(),
        }),
    }),
});
export const {
    useObtenerSalidaQuery,
    useObtenerSalidasQuery,
    useLazyObtenerSalidasQuery,
    useGuardarSalidaMutation,
    useEliminarSalidaMutation,
    useBuscarSalidasQuery
} = salidasApi;
