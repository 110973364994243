import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const configuracionesDocumentosApi = createApi({
    reducerPath: 'configuracionesDocumentosApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "configuraciones/documentos",
        prepareHeaders: (headers, {getState}) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        obtenerConfiguracionDocumento: build.query({
            query: (idConfiguracionDocumento) => `/${idConfiguracionDocumento}`,
        }),
        obtenerConfiguracionesDocumentos: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        guardarConfiguracionDocumento: build.mutation({
            query: (configuracionDocumento) => ({
                url: '',
                method: configuracionDocumento.idConfiguracionDocumento ? 'PUT' : 'POST',
                body: configuracionDocumento,
            }),
        }),
        eliminarConfiguracionDocumento: build.mutation({
            query: (configuracionDocumento) => ({
                url: '',
                method: 'DELETE',
                body: configuracionDocumento,
            }),
        }),
    }),
});
export const {
    useObtenerConfiguracionDocumentoQuery,
    useObtenerConfiguracionesDocumentosQuery,
    useLazyObtenerConfiguracionesDocumentosQuery,
    useGuardarConfiguracionDocumentoMutation,
    useEliminarConfiguracionDocumentoMutation
} = configuracionesDocumentosApi;
