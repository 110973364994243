import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';




export const protocolosCorreosApi = createApi({
    reducerPath: 'protocolosCorreosApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "protocolos/correos",
        prepareHeaders: (headers, {getState}) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),


    

    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        obtenerProtocoloCorreo: build.query({
            query: (idProtocolosCorreos) => `/${idProtocolosCorreos}`,
        }),
        obtenerProtocolosCorreos: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        guardarProtocoloCorreo: build.mutation({
            query: (protocoloCorreo) => ({
                url: '',
                method: protocoloCorreo.idProtocolosCorreos ? 'PUT' : 'POST',
                body: protocoloCorreo,
            }),
        }),
        eliminarProtocoloCorreo: build.mutation({
            query: (protocoloCorreo) => ({
                url: '',
                method: 'DELETE',
                body: protocoloCorreo,
            }),
        }),
    }),
});
export const {
    useObtenerProtocoloCorreoQuery,
    useObtenerProtocolosCorreosQuery,
    useLazyObtenerProtocolosCorreosQuery,
    useGuardarProtocoloCorreoMutation,
    useEliminarProtocoloCorreoMutation
} = protocolosCorreosApi;
