import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useObtenerEmpresaQuery } from '../../services/empresa.service';

const ProveedorInfo = ({ show, onHide }) => {
    const handleClose = () => {
        // Lógica para cerrar el modal
        onHide();
    };
    const { data: proveedor } = useObtenerEmpresaQuery(show);

    return (
        <>
            {proveedor && (
                <Modal show={show} centered onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Información del proveedor</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="rfc">RFC</Form.Label>
                                <Form.Control type="text" id="rfc" disabled value={proveedor.rfc} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="nombre">Nombre</Form.Label>
                                <Form.Control type="text" id="nombre" disabled value={proveedor.empresa} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="domicilio">Domicilio</Form.Label>
                                <Form.Control as="textarea" id="domicilio" disabled value={proveedor.domicilio} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="telefono">Teléfono</Form.Label>
                                <Form.Control type="text" id="telefono" disabled value={proveedor.telefono} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="correo">Correo electrónico</Form.Label>
                                <Form.Control type="text" id="correo" disabled value={proveedor.correoElectronico} />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
};

export default ProveedorInfo;
