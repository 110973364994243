import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import { Button, Form, Modal} from "react-bootstrap";
import {
    useGuardarRolesModulosMutation,
    useLazyObtenerRolesModulosQuery,
    useObtenerRolesModulosQuery
} from "../../../services/rolModulo.service";
import BootstrapTable, {getTreeKey} from "../../../components/base/BootstrapTable";
import {ExportToExcel} from "../../../components/base/ExportToExcel";
import {toast} from "react-toastify";
const RolesPermisosForm = ({show, onHide}) => {
    const [table, setTable] = useState(null);
    const handleClose = () => {
        onHide();
    };

    const {control, handleSubmit, register, watch} = useForm();

    const {data: rolesPermisos, refetch} = useObtenerRolesModulosQuery(show.idRol, {refetchOnMountOrArgChange: true});
    const [ trigger ] = useLazyObtenerRolesModulosQuery();

    const [guardarRolesModulos] = useGuardarRolesModulosMutation();

    useEffect(() => {
        const getExpandedModulos = (modulos, acc, key) => {
            modulos.map((e, index) => {
                if (e.idEstatus) {
                    let k = key ? key + '.' + index : index + '';
                    acc[k] = true;
                    if (e.modulos?.length) {
                        let expandableChild = e.modulos.filter(child => child.modulos?.length)
                        if(expandableChild.length > 0){
                            getExpandedModulos(expandableChild, acc, k);
                        }
                    }
                }
                return acc;
            })
            return acc;
        }

        if (table && rolesPermisos) {
            table.setExpanded(getExpandedModulos(rolesPermisos, {}, ''))
        }
    }, [table, rolesPermisos]);

    const getEstatus = (modulo) => {
        if (modulo.modulos && modulo.modulos.length > 0) {
            return modulo.modulos.some(childModulo => getEstatus(childModulo) === 1) ? 1 : 0;
        } else {
            return modulo.idEstatus ? 1 : 0;
        }
    }
    const flattenModulos = (modulos, acc) => {
        modulos.map((e) => {
            let obj = {
                idRol: show.idRol,
                idModulo: e.idModulo,
                idEstatus: e.idEstatus,
                crear: e.crear,
                editar: e.editar,
                ver: e.ver,
                eliminar: e.eliminar,
                fechaLineaTiempo:  e.fechaLineaTiempo
            }
            if (e.modulos && e.modulos.length > 0) {
                obj.idEstatus = e.modulos.some(childModulo => getEstatus(childModulo) === 1) ? 1 : 0;
                flattenModulos(e.modulos, acc);
            }
            acc.push(obj);
            return acc;
        })
        return acc;
    }
    const onSubmit = async (formData) => {
        let modulos = flattenModulos(formData.modulos, []);
        modulos = modulos.filter(e => e.idEstatus > 0);

        if(modulos.length > 0) {
            await guardarRolesModulos(modulos);
            refetch();
        } else {
            toast.warn("Debe seleccionar al menos un permiso")
        }

    };

    return (<> {<Modal show={show} onHide={handleClose} centered backdrop="static" keyboard={false} fullscreen>
        <Modal.Header closeButton>
            <Modal.Title>Permisos rol [ {show.rol} ]</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                {rolesPermisos && <BootstrapTable
                    toolbar={({columns}) => (<div>
                        <ExportToExcel columns={columns} onClick={_ => trigger(show.idRol)} fileName={'roles-permisos'}/>
                    </div>)}
                    data={rolesPermisos}
                    getSubRows={row => row.modulos}
                    setTable={setTable}
                    search={false}
                    sort={false}
                    columns={[{
                        header: 'Modulo',
                        accessorKey: 'modulo',
                        cell: ({row, getValue}) => {
                            return (<div style={{paddingLeft: `${row.depth * 2}rem`}}>
                                <>
                                    <Form.Control type="text" className="d-none"
                                                  value={row.original.idModulo} {...register(`${getTreeKey(row.id)}.idModulo`, {required: true})} />
                                    {row.getCanExpand() ? (<>
                                        <Button onClick={row.getToggleExpandedHandler()} style={{cursor: 'pointer'}}
                                                variant={"outline-secondary"} size={"sm"}>
                                            {row.getIsExpanded() ? <i className="bi bi-caret-down-fill"></i> :
                                                <i className="bi bi-caret-right-fill"></i>}
                                        </Button></>) : (
                                        <Controller
                                            name={`${getTreeKey(row.id)}.idEstatus`}
                                            control={control}
                                            defaultValue={row.original.idEstatus}
                                            render={({field}) => (<>
                                                    <Form.Check
                                                        inline
                                                        size={"sm"}
                                                        type="checkbox"
                                                        checked={Boolean(field.value)}
                                                        onChange={(e) => {
                                                            field.onChange(Number(e.target.checked))
                                                        }}
                                                    />
                                                </>

                                            )}
                                        />)}{' '}
                                    {getValue()}
                                </>
                            </div>)
                        },
                    }, {
                        header: 'Crear',
                        accessorKey: 'crear',
                        enableGlobalFilter: false,
                        cell: ({row, getValue}) => (row.original.modulos.length > 0 ?
                                <Form.Control type="text" className="d-none"
                                              value={getValue()} {...register(`${getTreeKey(row.id)}.crear`)} /> :
                                <Form.Select
                                    size={"sm"}
                                    className="mwx-250"
                                    {...register(`${getTreeKey(row.id)}.crear`)}
                                    defaultValue={getValue()}
                                    disabled={!Boolean(watch(`${getTreeKey(row.id)}.idEstatus`))}
                                >
                                    <option value="N">No</option>
                                    <option value="P">Propias</option>
                                    <option value="T">Todas</option>
                                </Form.Select>
                        )
                    }, {
                        header: 'Editar',
                        accessorKey: 'editar',
                        enableGlobalFilter: false,
                        cell: ({row, getValue}) => (row.original.modulos.length > 0 ?
                                <Form.Control type="text" className="d-none"
                                              value={getValue()} {...register(`${getTreeKey(row.id)}.editar`)} /> :
                                <Form.Select
                                    size={"sm"}
                                    className="mwx-250"
                                    {...register(`${getTreeKey(row.id)}.editar`)}
                                    defaultValue={getValue()}
                                    disabled={!Boolean(watch(`${getTreeKey(row.id)}.idEstatus`))}
                                >
                                    <option value="N">No</option>
                                    <option value="P">Propias</option>
                                    <option value="T">Todas</option>
                                </Form.Select>
                        )
                    }, {
                        header: 'Ver',
                        accessorKey: 'ver',
                        enableGlobalFilter: false,
                        cell: ({row, getValue}) => (row.original.modulos.length > 0 ?
                                <Form.Control type="text" className="d-none"
                                              value={getValue()} {...register(`${getTreeKey(row.id)}.ver`)} /> :
                                <Form.Select
                                    size={"sm"}
                                    className="mwx-250"
                                    {...register(`${getTreeKey(row.id)}.ver`)}
                                    defaultValue={getValue()}
                                    disabled={!Boolean(watch(`${getTreeKey(row.id)}.idEstatus`))}
                                >
                                    <option value="N">No</option>
                                    <option value="P">Propias</option>
                                    <option value="T">Todas</option>
                                </Form.Select>
                        )
                    }, {
                        header: 'Eliminar',
                        accessorKey: 'eliminar',
                        enableGlobalFilter: false,
                        cell: ({row, getValue}) => (row.original.modulos.length > 0 ?
                                <Form.Control type="text" className="d-none"
                                              value={getValue()} {...register(`${getTreeKey(row.id)}.eliminar`)} /> :
                                <Form.Select
                                    size={"sm"}
                                    className="mwx-250"
                                    {...register(`${getTreeKey(row.id)}.eliminar`)}
                                    defaultValue={getValue()}
                                    disabled={!Boolean(watch(`${getTreeKey(row.id)}.idEstatus`))}
                                >
                                    <option value="N">No</option>
                                    <option value="P">Propias</option>
                                    <option value="T">Todas</option>
                                </Form.Select>
                        )
                    },{
                        header: 'Fecha linea de tiempo',
                        accessorKey: 'fechaLineaTiempo',
                        enableGlobalFilter: false,
                        cell: ({row, getValue}) => (row.original.modulos.length > 0 ?
                                <Form.Control type="text" className="d-none"
                                              value={getValue()} {...register(`${getTreeKey(row.id)}.fechaLineaTiempo`)} /> :
                                <Controller
                                    name={`${getTreeKey(row.id)}.fechaLineaTiempo`}
                                    control={control}
                                    defaultValue={row.original.fechaLineaTiempo}
                                    render={({field}) => (<>
                                            <Form.Check
                                                inline
                                                size={"sm"}
                                                type="checkbox"
                                                checked={Boolean(field.value)}
                                                disabled={!Boolean(watch(`${getTreeKey(row.id)}.idEstatus`))}
                                                onChange={(e) => {
                                                    field.onChange(Number(e.target.checked))
                                                }}
                                            />
                                        </>

                                    )}
                                />
                        )
                    }]}
                />}
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button type="submit" variant="primary" className="me-4" onClick={handleSubmit(onSubmit)}>
                <i className="bi bi-save"></i> Guardar
            </Button>
            <Button variant="secondary" onClick={handleClose}>
                Cerrar
            </Button>
        </Modal.Footer>
    </Modal>}
    </>);
};

export default RolesPermisosForm;
