import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const tiposPersonasBaseApi = createApi({
    reducerPath: 'tiposPersonasBaseApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "tipos/personas/base",
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),




    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        
        obtenerTipoPersonaBase: build.query({
            query: (idTipoPersonaBase) => `/${idTipoPersonaBase}`,
        }),
        obtenerTiposPersonasBase: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        guardarTipoPersonaBase: build.mutation({
            query: (tipoPersonaBase) => ({
                url: '',
                method: tipoPersonaBase.idTipoPersonaBase ? 'PUT' : 'POST',
                body: tipoPersonaBase,
            }),
        }),
        eliminarTipoPersonaBase: build.mutation({
            query: (tipoEmpresa) => ({
                url: '',
                method: 'DELETE',
                body: tipoEmpresa,
            }),
        }),
    }),
});

export const {   
               useObtenerTipoPersonaBaseQuery,
               useObtenerTiposPersonasBaseQuery,
               useLazyObtenerTiposPersonasBaseQuery,               
               useGuardarTipoPersonaBaseMutation,
               useEliminarTipoPersonaBaseMutation
} = tiposPersonasBaseApi;
