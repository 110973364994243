import React, {useEffect, useRef, useState} from "react";
import {Alert, Button, Card, Col, Form, InputGroup, Row} from "react-bootstrap";
import {Controller, useForm} from 'react-hook-form';
import ProveedorInfo from "../components/solicitudes_pagos/ProveedorInfo";
import {
    useObtenerBancosIdEmpresaQuery,
    useObtenerEmpresasEmpleadosQuery,
    useObtenerProveedoresIdEmpresaQuery
} from '../services/empresa.service';
import {useObtenerProyectosPorIdEmpresaQuery} from "../services/proyecto.service";
import {useObtenerTiposMonedasQuery} from "../services/tipoMoneda.service";
import {useObtenerTiposPagosQuery} from "../services/tipoPago.service";
import {useGuardarSolicitudPagoMutation, useObtenerSolicitudPagoQuery} from "../services/solicitudPago.service";
import {NumericFormat} from 'react-number-format';
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import ObservacionForm from "../components/solicitudes_pagos/ObservacionForm";
import {useObtenerEstatusEtapaQuery} from "../services/estatus.service";
import {useSelector} from "react-redux";
import {
    useEliminarDocumentoMutation
} from "../services/documento.service";
import InputFile from "../components/base/InputFile";
import {toast} from "react-toastify";
import FormLabelCounter from "../components/base/FormLabelCounter";
import ConfirmDialog from "../components/base/ConfirmDialog";
import {
    useObtenerDocumentosArchivosQuery, 
    useGuardarProcesoEtapaDocumentoMutation
} from "../services/procesoEtapaDocumento.service";
import _ from "lodash";
import {useObtenerCamposQuery} from "../services/procesoEtapaCampo.service";


const SolicitudesPagos = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {userInfo} = useSelector((state) => state.auth);
    const {
        control,
        register,
        unregister,
        reset,
        handleSubmit,
        setValue,
        getValues,
        watch,
        formState: {errors}
    } = useForm();

    const [idEmpresa, setIdEmpresa] = useState(0);
    const [idProveedor, setIdProveedor] = useState(0);
    const [estatus, setEstatus] = useState('');
    const [folio, setFolio] = useState('');
    const [fechaHora, setFechaHora] = useState('');
    const [documentos, setDocumentos] = useState(null);
    const [confirmDialog, setConfirmDialog] = useState({});

    const [showModalProveedor, setShowModalProveedor] = useState(null);
    const [formObservaciones, setFormObservaciones] = useState(null);

    const [solicita, setSolicita] = useState(userInfo.userName)

    const currentButton = useRef(null);

    const {data: empresas} = useObtenerEmpresasEmpleadosQuery();
    const {data: proyectos} = useObtenerProyectosPorIdEmpresaQuery(idEmpresa, {skip: !idEmpresa});
    const {data: tiposPagos} = useObtenerTiposPagosQuery();
    const {data: tiposMonedas} = useObtenerTiposMonedasQuery();
    const {data: proveedores} = useObtenerProveedoresIdEmpresaQuery(idEmpresa, {skip: !idEmpresa})
    const {data: empresasBancos} = useObtenerBancosIdEmpresaQuery(idProveedor, {skip: !idProveedor})

    const {id: idSolicitudPago} = useParams();

    const {
        data: solicitudPago,
        refetch: refetchSolicitud
    } = useObtenerSolicitudPagoQuery({idSolicitudPago}, {skip: !idSolicitudPago, refetchOnMountOrArgChange: true});

    const {
        data: botones,
        refetch: refetchBotones
    } = useObtenerEstatusEtapaQuery([idSolicitudPago || 0, idSolicitudPago ? userInfo.etapa : 1], {refetchOnMountOrArgChange: true});

    const {
        data: documentosData,
        refetch: refetchDocumentos
    } = useObtenerDocumentosArchivosQuery([userInfo.proceso, idSolicitudPago ? userInfo.etapa : 1, idSolicitudPago || 0], {refetchOnMountOrArgChange: true});

    const {
        data: camposData,
    } = useObtenerCamposQuery([userInfo.proceso, idSolicitudPago ? userInfo.etapa : 1, watch('idEstatus') || 101, 'E'], {refetchOnMountOrArgChange: true})

    const [guardarSolicitudPago] = useGuardarSolicitudPagoMutation();
    const refetchAll = async() => {
        await refetchSolicitud();
        refetchBotones();
        refetchDocumentos();
    }

    const handleShowModalProveedor = () => {
        setShowModalProveedor(idProveedor);
    };

    const handleCloseModalProveedor = () => {
        setShowModalProveedor(null);
    };

    const [eliminarDocumento] = useEliminarDocumentoMutation();

    const [guardarProcesoEtapaDocumento] = useGuardarProcesoEtapaDocumentoMutation();

    const deshabilitarCampos = getValues('idEstatus') ? ![101, 104].includes(getValues('idEstatus')) : false;

    useEffect(() => {
        if (documentosData) {
            setDocumentos(documentosData);
        }
    }, [setDocumentos, documentosData]);

    useEffect(() => {
        if (solicitudPago) {
            const {
                idEmpresa,
                idProyecto,
                idEmpresaProveedor,
                idEmpresaBanco,
                numeroCotizacion,
                idTipoMoneda,
                idTipoPago,
                importe,
                descripcion,
                referenciaBancaria,
                idEstatus,
                fechaVersion,
                empleado,
                estatus,
                folio,
                fechaHora
            } = solicitudPago;

            if (empresas) {
                setIdEmpresa(idEmpresa);
            }

            if (proveedores) {
                setIdProveedor(idEmpresaProveedor);
            }

            setEstatus(estatus.estatus);
            setFolio(folio);
            setFechaHora(fechaHora);
            setSolicita(empleado.nombreCustom);

            if (empresas && proyectos && tiposPagos && proveedores && empresasBancos) {
                setValue('idEmpresa', idEmpresa);
                setValue('idProyecto', idProyecto);
                setValue('idEmpresaProveedor', idEmpresaProveedor);
                setValue('idEmpresaBanco', idEmpresaBanco);
                setValue('numeroCotizacion', numeroCotizacion);
                setValue('idTipoMoneda', idTipoMoneda);
                setValue('idTipoPago', idTipoPago);
                setValue('importe', importe);
                setValue('descripcion', descripcion);
                setValue('referenciaBancaria', referenciaBancaria);
                setValue('idEstatus', idEstatus);
                setValue('fechaVersion', fechaVersion);

            }
        }
    }, [reset, setValue, solicitudPago, tiposPagos, empresas, proyectos, proveedores, empresasBancos]);

    const onSubmit = async (formData, urlBase) => {
        let data;        
        formData.idSolicitudPago = parseInt(idSolicitudPago)
        formData.idEmpresaBanco = parseInt(formData.idEmpresaBanco ? formData.idEmpresaBanco :"0")
        let result = await guardarSolicitudPago(formData);
        data = result.data;

        if (data) {
            toast.success(currentButton.current.mensajeOk);
        } else {
            toast.error(currentButton.current.mensajeError);
        }

        if (data) {
            if (urlBase) {
                navigate(urlBase);
            } else {
                if (!idSolicitudPago) {
                    navigate(`${location.pathname}/${data?.objeto.idSolicitudPago}`);
                } else if (data.objeto) {

                    let {idSolicitudPago, fechaVersion, idEstatus, fechaHora} = data.objeto;
                    setValue('idSolicitudPago', idSolicitudPago);
                    setValue('fechaVersion', fechaVersion)
                    setValue('idEstatus', idEstatus)

                   
                    setFechaHora(fechaHora)
                    refetchAll();
                }

            }
        }

    };

    return (
        <>
            <main id="main" className="main">
                <section className="section">
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <Card.Body>
                                    <fieldset>
                                        <legend>
                                            <h2>SOLICITUD DE PAGO</h2>
                                            <h6> *Los campos en negritas son requeridos</h6>
                                        </legend>
                                    </fieldset>
                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        <Form.Control type="text" id="idSolicitudPago" className="d-none"
                                                      defaultValue={idSolicitudPago} {...register("idSolicitudPago")} />
                                        <Form.Control type="text" id="idEstatus"
                                                      className="d-none" {...register("idEstatus")} />
                                        <Form.Control type="text" id="fechaVersion"
                                                      className="d-none" {...register("fechaVersion")} />

                                        <Row className="mb-3">
                                            <Col md={12}>
                                                <Form.Label htmlFor="folio">Folio</Form.Label>
                                                <Form.Control type="text" className="mwx-250" id="folio" disabled
                                                              value={folio}/>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={12}>
                                                <Form.Label htmlFor="estatus">Estatus</Form.Label>
                                                <Form.Control type="text" className="mwx-250" id="estatus" disabled
                                                              value={estatus}/>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={12}>
                                                <Form.Label htmlFor="fechaHora">Fecha</Form.Label>
                                                <Form.Control type="text" className="mwx-200" id="fechaHora" disabled
                                                              value={fechaHora}/>
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={12}>
                                                <Form.Label htmlFor="solicita">Solicita</Form.Label>
                                                <Form.Control type="text" className="mwx-400" id="solicita" disabled
                                                              value={solicita}/>
                                            </Col>
                                        </Row>

                                        <Row className="mb-3">
                                            <Col md={12}>
                                                <Form.Label htmlFor="idEmpresa" className="fw-bold">Empresa</Form.Label>
                                                <Form.Select
                                                    className="mwx-400"
                                                    value={idEmpresa}
                                                    id="idEmpresa"
                                                    disabled={deshabilitarCampos}
                                                    {...register("idEmpresa", {
                                                        required: "Ingrese un dato válido",
                                                        onChange: (event) => {
                                                            setIdEmpresa(event.target.value);
                                                        },
                                                    })}
                                                >
                                                    <option value="">Seleccionar</option>
                                                    {empresas &&
                                                        empresas.map((empresa) => (
                                                            <option key={'empresa.' + empresa.idEmpresa}
                                                                    value={empresa.idEmpresa}>
                                                                {empresa.empresa}
                                                            </option>
                                                        ))}
                                                </Form.Select>
                                                {errors.idEmpresa && (
                                                    <Alert variant="danger" className="mwx-400">
                                                        {errors.idEmpresa.message}
                                                    </Alert>
                                                )}
                                            </Col>
                                        </Row>
                                        
                                        <Row className="mb-3">
                                            <Col md={12}>
                                                <Form.Label htmlFor="idProyecto"
                                                            className="fw-bold">Proyecto</Form.Label>
                                                <Form.Select
                                                    id="idProyecto"
                                                    className="mwx-400"
                                                    disabled={deshabilitarCampos}
                                                    {...register("idProyecto", {
                                                        required: "Ingrese un dato válido"
                                                    })}
                                                >
                                                    <option value="">Seleccionar</option>
                                                    {proyectos &&
                                                        proyectos.map((proyecto) => (
                                                            <option key={'proyecto.' + proyecto.idProyecto}
                                                                    value={proyecto.idProyecto}>
                                                                {proyecto.proyecto.proyecto}
                                                            </option>
                                                        ))}
                                                </Form.Select>
                                                {errors.idProyecto && (
                                                    <Alert variant="danger" className="mwx-400">
                                                        {errors.idProyecto.message}
                                                    </Alert>
                                                )}

                                            </Col>
                                        </Row>

                                        <div className="row mb-3">
                                            <div className="col-md-12">
                                                <Form.Group controlId="idTipoPago">
                                                    <Form.Label className="fw-bold">Tipo de pago</Form.Label>
                                                    <Col>
                                                        <Controller
                                                            name="idTipoPago"
                                                            control={control}
                                                            defaultValue={getValues("idTipoPago")} // Asigna el valor seleccionado a defaultValue
                                                            rules={{required: "Ingrese un dato válido",}}
                                                            render={({field}) => (
                                                                tiposPagos?.map((tipoPago) => (
                                                                    <div className="form-check form-check-inline ps-0"
                                                                         key={'tipoPago.' + tipoPago.idTipoPago}>
                                                                        <Form.Check
                                                                            type="radio"
                                                                            label={tipoPago.tipoPago}
                                                                            id={`tipoPago.${tipoPago.idTipoPago}`}
                                                                            value={tipoPago.idTipoPago}
                                                                            checked={tipoPago.idTipoPago === field.value}
                                                                            onChange={(e) => {
                                                                                field.onChange(parseInt(e.target.value))
                                                                            }} // send value to hook form
                                                                            disabled={deshabilitarCampos}
                                                                        />
                                                                    </div>
                                                                ))
                                                            )}
                                                        />
                                                        {errors.idTipoPago && (
                                                            <Alert variant="danger" className="mwx-400">
                                                                {errors.idTipoPago.message}
                                                            </Alert>
                                                        )}
                                                    </Col>
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <Row className="mb-3">
                                            <Col md={12}>
                                                <FormLabelCounter
                                                    htmlFor="descripcion"
                                                    className="fw-bold mwx-400"
                                                    input={watch('descripcion')}
                                                    maxLength={4000}
                                                >
                                                    Descripción de lo solicitado
                                                </FormLabelCounter>

                                                <Form.Control
                                                    as="textarea"
                                                    className="mwx-400"
                                                    rows="8"
                                                    id="descripcion"
                                                    disabled={deshabilitarCampos}
                                                    {...register("descripcion", {
                                                        required: "Ingrese un dato válido",
                                                        maxLength: {
                                                            value: 4000,
                                                            message: 'Ha excedido el máximo de caracteres'
                                                        }
                                                    })}
                                                />
                                                {errors.descripcion && (
                                                    <Alert variant="danger" className="mwx-400">
                                                        {errors.descripcion.message}
                                                    </Alert>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={12}>
                                                <Form.Label htmlFor="idTipoMoneda" className="fw-bold">Tipo de
                                                    moneda</Form.Label>
                                                <Form.Select
                                                    className="mwx-250"
                                                    id="idTipoMoneda"
                                                    disabled={deshabilitarCampos}
                                                    {...register("idTipoMoneda", {
                                                        required: "Ingrese un dato válido"
                                                    })}
                                                >
                                                    <option value="">Seleccionar</option>
                                                    {tiposMonedas && tiposMonedas.map((tipoMoneda) => (
                                                        <option key={'tipoMoneda.' + tipoMoneda.idTipoMoneda}
                                                                value={tipoMoneda.idTipoMoneda}>
                                                            {tipoMoneda.tipoMoneda}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                                {errors.idTipoMoneda && (
                                                    <Alert variant="danger" className="mwx-400">
                                                        {errors.idTipoMoneda.message}
                                                    </Alert>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={12}>
                                                <Form.Label htmlFor="importe" className="fw-bold">Monto con
                                                    IVA</Form.Label>
                                                <Controller
                                                    render={({field}) => (
                                                        <NumericFormat
                                                            className="form-control mwx-400"
                                                            id="importe"
                                                            thousandSeparator={true}
                                                            prefix={'$ '}
                                                            decimalScale={2}
                                                            fixedDecimalScale
                                                            allowNegative={false}
                                                            value={field.value || ''}
                                                            onValueChange={(v) => {
                                                                field.onChange(Number(v.value) > 0 ? Number(v.value) : null);
                                                            }}
                                                            isAllowed={({value}) => (value?.split(".")[0].length <= 10)}
                                                            disabled={deshabilitarCampos}
                                                        />
                                                    )}
                                                    name="importe"
                                                    control={control}
                                                    rules={{required: "Ingrese un dato válido"}}
                                                />
                                                {errors.importe && (
                                                    <Alert variant="danger" className="mwx-400">
                                                        {errors.importe.message}
                                                    </Alert>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={12}>
                                                <Form.Label htmlFor="idEmpresaProveedor"
                                                            className="fw-bold">Proveedor</Form.Label>
                                                <InputGroup>
                                                    <Form.Select
                                                        className="mwx-400"
                                                        id="idEmpresaProveedor"
                                                        value={idProveedor}
                                                        disabled={deshabilitarCampos}
                                                        {...register("idEmpresaProveedor", {
                                                            required: "Ingrese un dato válido",
                                                            onChange: (event) => {
                                                                setIdProveedor(event.target.value)
                                                            }
                                                        })}
                                                    >
                                                        <option value="">Seleccionar</option>
                                                        {proveedores && proveedores.map((empresa) => (
                                                            <option key={'proveedor.' + empresa.idEmpresa}
                                                                    value={empresa.idEmpresa}>
                                                                {empresa.empresa}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                    {idProveedor ? (
                                                        <>
                                                            {showModalProveedor && (
                                                                <ProveedorInfo show={showModalProveedor}
                                                                               onHide={handleCloseModalProveedor}/>
                                                            )}
                                                            <Button onClick={handleShowModalProveedor}
                                                                    variant="outline-secondary">
                                                                <i className="bi bi-eye"></i>
                                                            </Button>
                                                        </>
                                                    ) : ''}
                                                </InputGroup>
                                                {errors.idEmpresaProveedor && (
                                                    <Alert variant="danger" className="mwx-400">
                                                        {errors.idEmpresaProveedor.message}
                                                    </Alert>
                                                )}
                                            </Col>
                                        </Row>
                                        { camposData?.some(e => (e.campo === "idEmpresaBanco"  && e.mostrar)) &&
                                        <Row className="mb-3">
                                            <Col md={12}>
                                                <Form.Label htmlFor="idEmpresaBanco" className="fw-bold">Cuenta bancaria</Form.Label>
                                                <Form.Select
                                                    className="mwx-400"
                                                    id="idEmpresaBanco"
                                                    {...register("idEmpresaBanco", {
                                                        required: "Ingrese un dato válido"
                                                    })}
                                                >
                                                    <option value="">Seleccionar</option>
                                                    {empresasBancos && empresasBancos.map((empresaBanco) => (
                                                        <option key={'empresaBanco.' + empresaBanco.idEmpresaBanco}
                                                                value={empresaBanco.idEmpresaBanco}>
                                                            {empresaBanco.banco.banco} - {empresaBanco.clabe}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                                {errors.idEmpresaBanco && (
                                                    <Alert variant="danger" className="mwx-400">
                                                        {errors.idEmpresaBanco.message}
                                                    </Alert>
                                                )}
                                            </Col>
                                        </Row>
                                        }
                                        <Row className="mb-3">
                                            <Col md={12}>
                                                <Form.Label htmlFor="numeroCotizacion">Número de
                                                    cotización/Factura</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    className="mwx-400"
                                                    id="numeroCotizacion"
                                                    disabled={deshabilitarCampos}
                                                    {...register("numeroCotizacion")}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={12}>
                                                <Form.Label htmlFor="referenciaBancaria">Referencia
                                                    bancaria</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    className="mwx-400"
                                                    id="referenciaBancaria"
                                                    disabled={deshabilitarCampos}
                                                    {...register("referenciaBancaria")}
                                                />
                                            </Col>
                                        </Row>
                                        <fieldset className="mb-3">
                                            <legend>Documentos digitales (PDF o Imagen)</legend>
                                            {documentos?.map((obj, indexProceso) => (
                                                <div
                                                    key={`procesoEtapaDocumento.${obj.idProcesoEtapaTipoDocumento}`}
                                                    className="mwx-450">
                                                    <Form.Control type="text" className="d-none" value={obj.idProcesoEtapaTipoDocumento}
                                                                  {...register(`procesoEtapaDocumento.${indexProceso}.idProcesoEtapaTipoDocumento`)} />
                                                    <Form.Control type="text" className="d-none" value={obj.idOrigen}
                                                                  {...register(`procesoEtapaDocumento.${indexProceso}.idOrigen`)} />
                                                    <Form.Control type="text" className="d-none" value={obj.fechaVersion || ''}
                                                                  {...register(`procesoEtapaDocumento.${indexProceso}.fechaVersion`)} />
                                                    <fieldset className="border rounded-3 p-3 py-1 mb-2">
                                                        <legend className="float-none ps-3 mt-0 mb-2 fs-5">
                                                            <Form.Label>
                                                                {obj.procesoEtapaTipoDocumento.tipoDocumento.tipoDocumento}
                                                                {
                                                                    <Controller
                                                                        name={`procesoEtapaDocumento.${indexProceso}.requerido`}
                                                                        control={control}
                                                                        render={({field}) => (<>
                                                                                <Form.Check
                                                                                    reverse
                                                                                    id={`procesoEtapaDocumento.${indexProceso}.requerido`}
                                                                                    type="checkbox"
                                                                                    className={!obj.procesoEtapaTipoDocumento.mostrar ? "d-none" : "d-sm-inline pe-sm-0 ps-sm-4"}
                                                                                    label={`¿Genera ${obj.procesoEtapaTipoDocumento.tipoDocumento.tipoDocumento}?`}
                                                                                    checked={Boolean(field.value)}
                                                                                    disabled= {!obj.editarRequeridoTipoDocumento}
                                                                                    onChange={async(e) => { 
                                                                                            field.onChange(Boolean(e.target.checked))                                                                                                                                                                                   
                                                                                            await guardarProcesoEtapaDocumento({
                                                                                                idProcesoEtapaTipoDocumento:obj.idProcesoEtapaTipoDocumento, 
                                                                                                requerido: Boolean(e.target.checked)
                                                                                            }) 
                                                                                            refetchDocumentos()                                                                                       
                                                                                        }
                                                                                    }
                                                                                />
                                                                            </>

                                                                        )}
                                                                        defaultValue={obj.requerido}
                                                                    />
                                                                }
                                                            </Form.Label>
                                                            <Button className="float-end" variant="primary"
                                                                    disabled={deshabilitarCampos}
                                                                    onClick={(e) => {
                                                                        const nuevoDocumento = {
                                                                            ...obj.documento[0],
                                                                            archivo: {},
                                                                            idArchivo: 0,
                                                                            idDocumento: 0
                                                                        };

                                                                        const nuevosDocumentos = _.cloneDeep(documentos);
                                                                        nuevosDocumentos[indexProceso].documento.push(nuevoDocumento);
                                                                        setDocumentos(nuevosDocumentos);
                                                                    }}>
                                                                <i className="bi bi-plus-square"></i>
                                                            </Button>
                                                        </legend>
                                                        {obj.documento.map((documento, indexDocumento) => (
                                                            <div
                                                                key={`documento.${indexDocumento}`}>
                                                                <InputGroup className="flex-sm-nowrap">
                                                                    <InputFile
                                                                        name={`procesoEtapaDocumento.${indexProceso}.documento.${indexDocumento}.idArchivo`}
                                                                        archivo={documento.archivo}
                                                                        ruta={'documentos'}
                                                                        required={documento.validarDocumentoEtapa ? 'Ingrese un documento válido' : false}
                                                                        disabled={!documento.editar}
                                                                        register={register}
                                                                        setValue={setValue} // TODO: parche por posible incompatiblidad react 18
                                                                    />
                                                                    {<Button type="button"
                                                                             className="mb-3 rounded ms-sm-2"
                                                                             variant={"danger"} title="Eliminar archivo"
                                                                             disabled={!documento.editar || !(obj.documento.length > 1)}
                                                                             onClick={(e) => {
                                                                                 setConfirmDialog({
                                                                                     message: '¿Desea eliminar este registro?',
                                                                                     onConfirm: async () => {
                                                                                         if (documento.idDocumento !== 0)
                                                                                             await eliminarDocumento({idDocumento: documento.idDocumento});
                                                                                         let nuevosDocumentos = _.cloneDeep(documentos);
                                                                                         nuevosDocumentos[indexProceso].documento = nuevosDocumentos[indexProceso].documento.filter((doc, index) => index !== indexDocumento)
                                                                                         setDocumentos(nuevosDocumentos);
                                                                                         unregister(`procesoEtapaDocumento.${indexProceso}.documento.${indexDocumento}`);
                                                                                         setConfirmDialog({});
                                                                                     }
                                                                                 })
                                                                             }}>
                                                                        <i className="bi bi-x-lg" title="Eliminar archivo"> </i>
                                                                    </Button>}
                                                                </InputGroup>
                                                                <Form.Control
                                                                    type="text"
                                                                    className='d-none'
                                                                    value={documento.idProcesoEtapaTipoDocumento}
                                                                    {...register(`procesoEtapaDocumento.${indexProceso}.documento.${indexDocumento}.idProcesoEtapaTipoDocumento`)}
                                                                />
                                                                {errors?.procesoEtapaDocumento?.[indexProceso]?.documento?.[indexDocumento] && (
                                                                    <Alert variant="danger" className="mwx-450"
                                                                           role="alert">
                                                                        {errors.procesoEtapaDocumento[indexProceso].documento[indexDocumento].idArchivo.message}
                                                                    </Alert>
                                                                )}
                                                            </div>

                                                        ))
                                                        }

                                                    </fieldset>
                                                </div>
                                            ))}
                                            <ConfirmDialog {...confirmDialog} onCancel={() => setConfirmDialog({})}/>
                                        </fieldset>
                                        <Row className="mt-3">
                                            <Col md={12}>
                                                <div className="d-grid gap-2 d-md-block">
                                                    <Link className="btn btn-secondary me-md-3"
                                                          to={"/solicitudes/pagos"}>
                                                        <i className="bi bi-box-arrow-in-left"></i> Regresar
                                                    </Link>
                                                    {botones?.map((boton) => (
                                                        <Button
                                                            key={'btn.' + boton.idEstatus}
                                                            className={'btn ' + boton.css + ' me-md-3'}
                                                            onClick={() => {
                                                                if (boton.idEstatus) {
                                                                    setValue("idEstatus", boton.idEstatus);
                                                                }
                                                                currentButton.current = boton;

                                                                if(boton.confirmar){
                                                                    setConfirmDialog({
                                                                        message: boton.confirmarMensaje || '.',
                                                                        onConfirm: () => {
                                                                            handleSubmit(onSubmit)();
                                                                            setConfirmDialog({});
                                                                        }
                                                                    })

                                                                } else if (boton.modal) {
                                                                    setFormObservaciones({
                                                                        accion: boton.accion,
                                                                        idSolicitudPago: idSolicitudPago,
                                                                        idEstatus: boton.idEstatus,
                                                                        idEtapa: userInfo.etapa,
                                                                        idProceso: 1,
                                                                        urlBase: boton.urlBase
                                                                    });

                                                                } else {
                                                                    handleSubmit(onSubmit)();
                                                                }
                                                            }}
                                                        >
                                                            <i className={`bi bi-${boton.icono}`}></i> {boton.accion}
                                                        </Button>
                                                    ))}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <ConfirmDialog {...confirmDialog} onCancel={() => setConfirmDialog({})}/>

                                    {formObservaciones && <ObservacionForm show={formObservaciones}
                                                                           onHide={() => (setFormObservaciones(null))}
                                                                           onSubmit={(urlBase) => {
                                                                               //urlBase: el back devuelve donde debe redirigir
                                                                               handleSubmit((formData) => {
                                                                                   onSubmit(formData, urlBase)
                                                                               })();
                                                                           }}/>}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </section>
            </main>
        </>
    );
};

export default SolicitudesPagos;
