import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const regimenesFiscalesApi = createApi({
    reducerPath: 'regimenesFiscalesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "regimenes/fiscales",
        prepareHeaders: (headers, {getState}) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),



    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        obtenerRegimenFiscal: build.query({
            query: (idRegimenFiscal) => `/${idRegimenFiscal}`,
        }),
        obtenerRegimenesFiscales: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        guardarRegimenFiscal: build.mutation({
            query: (regimenFiscal) => ({
                url: '',
                method: regimenFiscal.idRegimenFiscal ? 'PUT' : 'POST',
                body: regimenFiscal,
            }),
        }),
        eliminarRegimenFiscal: build.mutation({
            query: (regimenFiscal) => ({
                url: '',
                method: 'DELETE',
                body: regimenFiscal,
            }),
        }),
    }),
});
export const {
    useObtenerRegimenFiscalQuery,
    useObtenerRegimenesFiscalesQuery,
    useLazyObtenerRegimenesFiscalesQuery,
    useGuardarRegimenFiscalMutation,
    useEliminarRegimenFiscalMutation
} = regimenesFiscalesApi;
