import {
    useBuscarEmpresasQuery
} from '../../../services/empresa.service'
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import SeverPagination from "../../../components/base/SeverPagination";
import DocumentacionLegalVista from './DocumentacionLegalVista'
import { useLocation, useNavigate } from "react-router-dom";
import { cleanProperties } from "../../../helpers/cleanProperties";
import { useForm } from 'react-hook-form';
import DocumentacionCard from './../../../components/documentacion_legal/documentacionLegalCard';

const DocumentacionLegal = () => {

    const { control, register, reset, handleSubmit } = useForm();

    //Funcionalidades propias del Proceso Empresas /EDITAR
    const [vistaDocumentacionLegal, setVistaDocumentacionLegal] = useState(null);

    const { state, pathname } = useLocation();
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useState({});

    const [pageNumber, setPageNumber] = useState(1);
    const [elementsPerPage, setElementsPerPage] = useState(10);

    const {
        data: empresas, refetch
    } = useBuscarEmpresasQuery({ pageNumber, elementsPerPage, searchParams }, { refetchOnMountOrArgChange: true });

    const onSubmit = async (formData) => {
        setSearchParams(cleanProperties(formData));
        refetch();
    };

    const clearForm = () => {
        navigate(pathname, {});
        setSearchParams({});
        reset();
    }

    useEffect(() => {
        setSearchParams(state || {});
        reset({ buscador: state?.buscador || '', idEstatus: state?.idEstatus || '1' });
    }, [reset, setSearchParams, state]);

    return (<>
        <main id="main" className="main">
            <Form id="formulario" className="row g-3" onSubmit={handleSubmit(onSubmit)}>
                <div className="input-group">
                    <Form.Control
                        type="text"
                        placeholder="Buscar por: Empresa, RFC, Representante"
                        {...register("buscador")}
                    />
                    <Button type="submit" variant="primary" title={"Buscar"}>
                        <i className="bi bi-search"></i>
                    </Button>
                    <Button className="ms-2" variant="primary" title={"Limpiar"} onClick={(e) => (clearForm())}>
                        <i className="bi bi-eraser"></i>
                    </Button>
                </div>
            </Form>


            {empresas?.data && empresas.data.map((empresa) => (
                <DocumentacionCard key={'empresa.' + empresa.idEmpresa} empresa={empresa} refetch={refetch} />
            ))}

            {empresas && <SeverPagination
                data={empresas.data}
                currentPage={pageNumber}
                totalElements={empresas.totalElements}
                elementsPerPage={elementsPerPage}
                onPageChange={setPageNumber}
                onElementsPerPageChange={setElementsPerPage}
            />}

            {vistaDocumentacionLegal && <DocumentacionLegalVista show={vistaDocumentacionLegal} onHide={(e) => {
                setVistaDocumentacionLegal(false);
            }} onSave={refetch} />}
        </main>
    </>)

}

export default DocumentacionLegal;


