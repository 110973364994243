import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const tiposTransportesApi = createApi({
    reducerPath: 'tiposTransportesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "tipos/transportes",
        prepareHeaders: (headers, {getState}) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        obtenerTipoTransporte: build.query({
            query: (idTipoTransporte) => `/${idTipoTransporte}`,
        }),
        obtenerTiposTransportes: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        guardarTipoTransporte: build.mutation({
            query: (tipoTransporte) => ({
                url: '',
                method: tipoTransporte.idTipoTransporte ? 'PUT' : 'POST',
                body: tipoTransporte,
            }),
        }),
        eliminarTipoTransporte: build.mutation({
            query: (tipoTransporte) => ({
                url: '',
                method: 'DELETE',
                body: tipoTransporte,
            }),
        }),
    }),
});
export const {
    useObtenerTipoTransporteQuery,
    useObtenerTiposTransportesQuery,
    useLazyObtenerTiposTransportesQuery,
    useGuardarTipoTransporteMutation,
    useEliminarTipoTransporteMutation
} = tiposTransportesApi;
