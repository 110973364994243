import React, {useState} from 'react';
import {PdfViewer} from "../../../components/base/FileViewer";
import {useObtenerReporteAlmacenesKardexQuery} from "../../../services/reporte.service";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {cleanProperties} from "../../../helpers/cleanProperties";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import {useObtenerAlmacenesQuery} from "../../../services/almacen.service";

const Kardex = () => {
    const {handleSubmit, register, control, reset} = useForm();
    const [searchParams, setSearchParams] = useState({b64: true});

    const {data: almacenes} = useObtenerAlmacenesQuery();

    const {
        data: reporte,
        refetch
    } = useObtenerReporteAlmacenesKardexQuery(searchParams, {refetchOnMountOrArgChange: true});

    const onSubmit = async (formData) => {
        setSearchParams(cleanProperties(formData));
        refetch();
    };

    const clearForm = () => {
        setSearchParams({b64: true});
        reset();
    }

    return (
        <main id="main" className="main">
            <Card className="card-body mb-0">
                <Form id="formulario" className="row g-3" onSubmit={handleSubmit(onSubmit)} autoComplete="no">
                    <Form.Control type="text" id="b64" className="d-none" {...register("b64")} value="true"/>

                    <h5 className="mb-3 mt-3 fw-bold">Criterios de filtrado.</h5>
                    <Row>
                        <Col sm={3}>
                            <Form.Label htmlFor="producto" className="form-label">
                                Producto
                            </Form.Label>
                            <Form.Control type="text" className="form-control  form-control-sm mb-2"
                                          {...register("producto")} />
                        </Col>
                    </Row>

                    <Row className="mb-2">
                        <h6>Rango de Fechas</h6>
                        <Col sm={3}>
                            <Form.Label htmlFor="fechaInicial" className="form-label">
                                Fecha Inicial
                            </Form.Label>
                            <div>
                                <Controller
                                    render={({field}) => (
                                        <DatePicker
                                            className="form-control form-control-sm"
                                            id="fechaInicial"
                                            dateFormat="dd/MM/yyyy"
                                            value={field.value}
                                            onChange={(date) => {
                                                field.onChange(dayjs(date).format("DD/MM/YYYY"));
                                            }}
                                        />
                                    )}
                                    name="fechaInicial"

                                    control={control}
                                />
                            </div>
                        </Col>

                        <Col sm={3}>
                            <Form.Label htmlFor="fechaFinal" className="form-label">
                                Fecha Final
                            </Form.Label>
                            <div>
                                <Controller
                                    render={({field}) => (
                                        <DatePicker
                                            className="form-control form-control-sm"
                                            id="fechaFinal"
                                            dateFormat="dd/MM/yyyy"
                                            value={field.value}
                                            onChange={(date) => {
                                                field.onChange(dayjs(date).format("DD/MM/YYYY"));
                                            }}
                                        />
                                    )}
                                    name="fechaFinal"
                                    control={control}
                                />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={3}>
                            <Form.Label htmlFor='idAlmacen'>
                                Almacen
                            </Form.Label>
                            <Form.Select
                                className="form-select-sm"
                                id="idAlmacen"
                                {...register("idAlmacen")}
                            >
                                <option value="">Seleccionar</option>
                                {almacenes?.data && almacenes.data.map((almacen) => (
                                    <option key={'almacenes.' + almacen.idAlmacen} value={almacen.idAlmacen}>
                                        {almacen.almacen}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>
                    </Row>

                    <Col sm={3} className="text-center mt-2 w-100">
                        <Button type="submit" variant="primary"> <i className="bi bi-search"></i>  Aplicar</Button>
                        <Button className="ms-2" variant="secondary" title={"Limpiar"} onClick={(e) => (clearForm())}>
                            <i className="bi bi-eraser"></i> Limpiar
                        </Button>
                    </Col>
                </Form>
            </Card>
            <Row className="mt-3">
                {reporte && <PdfViewer title={"Kárdex de almacén"} data={reporte}/>}
            </Row>
        </main>

    );
};

export default Kardex;
