import {
    useObtenerMunicipiosQuery,
    useEliminarMunicipioMutation,
    useLazyObtenerMunicipiosQuery
} from "../../../services/municipio.service";
import React, { useState } from "react";
import { Button, Row, Col, Card } from "react-bootstrap";
import SeverPagination from "../../../components/base/SeverPagination";
import MunicipiosForm from "./MunicipiosForm";
import BootstrapTable from "../../../components/base/BootstrapTable";
import ConfirmDialog from "../../../components/base/ConfirmDialog";
import { ExportToExcel } from "../../../components/base/ExportToExcel";

const Municipios = () => {
    const [pageNumber, setPageNumber] = useState(1);
    const [elementsPerPage, setElementsPerPage] = useState(10);

    const [formMunicipios, setFormMunicipios] = useState(null);
    const [confirmDialog, setConfirmDialog] = useState({});

    const {
        data: municipios, refetch
    } = useObtenerMunicipiosQuery([pageNumber, elementsPerPage], { refetchOnMountOrArgChange: true });
    const [trigger] = useLazyObtenerMunicipiosQuery();

    const [eliminarMunicipio] = useEliminarMunicipioMutation();

    return (<div id="main" className="main">
        <section className="section">
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Body>
                            <div className="btn-toolbar justify-content-between" role="toolbar">
                                <div className="pagetitle">
                                    <h1>Municipios</h1>
                                </div>
                            </div>
                            {municipios &&
                                <BootstrapTable striped
                                    toolbar={({ columns }) => (
                                        <div>
                                            <Button href="#" className="me-2" variant="primary" onClick={(e) => {
                                                setFormMunicipios({})
                                            }}>
                                                <i className="bi bi-plus-square"></i>&nbsp;Nuevo
                                            </Button>
                                            <ExportToExcel columns={columns} onClick={trigger} fileName={'municipios'} />
                                        </div>
                                    )}
                                    data={municipios.data}
                                    columns={[{
                                        header: 'Municipio', accessorKey: 'municipio'
                                    }, {
                                        header: 'Entidad federativa', accessorKey: 'entidadFederativa.entidadFederativa'
                                    },  {
                                        header: 'País',
                                        accessorKey: 'entidadFederativa.pais.pais'
                                    }, {


                                        header: 'Acciones',
                                        enableGlobalFilter: false,
                                        cell: ({ row: { original } }) => (
                                            <div className="w-100 d-grid d-sm-block gap-1">
                                                <Button variant="warning" className="me-2" size="sm"
                                                    onClick={e => setFormMunicipios(original)}>
                                                    <i className="bi bi-pencil-square"> </i>
                                                    Editar
                                                </Button>
                                                <Button variant="danger" className="me-2" size="sm"
                                                    onClick={e => setConfirmDialog({
                                                        message: '¿Desea eliminar este registro?', onConfirm: async () => {
                                                            await eliminarMunicipio({ idMunicipio: original.idMunicipio });
                                                            refetch();
                                                            setConfirmDialog({});

                                                        }
                                                    })}>
                                                    <i className="bi bi-trash"> </i>
                                                    Eliminar
                                                </Button>
                                            </div>)
                                    }]}
                                    onGlobalFilterChange={(e => {
                                        setPageNumber(1);
                                        setElementsPerPage(e ? -1 : 10);
                                    })}
                                />}
                            {municipios && <SeverPagination
                                data={municipios.data}
                                currentPage={pageNumber}
                                totalElements={municipios.totalElements}
                                elementsPerPage={elementsPerPage}
                                onPageChange={setPageNumber}
                                onElementsPerPageChange={setElementsPerPage}
                            />}
                        </Card.Body>

                        <ConfirmDialog {...confirmDialog} onCancel={() => setConfirmDialog({})} />
                    </Card>
                </Col>
            </Row>
        </section>
        {formMunicipios && <MunicipiosForm show={formMunicipios} onHide={(e) => {
            setFormMunicipios(false);
        }} onSave={refetch} />}
    </div>);
};

export default Municipios;
