import {
    useObtenerConfiguracionesCorreosQuery,
    useEliminarConfiguracionCorreoMutation,
    useLazyObtenerConfiguracionesCorreosQuery
} from "../../../services/configuracionCorreo.service";
import React, {useState} from "react";
import {Button, Row, Col, Card} from "react-bootstrap";
import SeverPagination from "../../../components/base/SeverPagination";
import ConfiguracionesCorreosForm from "./ConfiguracionesCorreosForm";
import BootstrapTable from "../../../components/base/BootstrapTable";
import ConfirmDialog from "../../../components/base/ConfirmDialog";
import {ExportToExcel} from "../../../components/base/ExportToExcel";

const ConfiguracionesCorreos = () => {
    const [pageNumber, setPageNumber] = useState(1);
    const [elementsPerPage, setElementsPerPage] = useState(10);

    const [formConfiguracionesCorreos, setFormConfiguracionesCorreos] = useState(null);
    const [confirmDialog, setConfirmDialog] = useState({});

    const {
        data: configuracionesCorreos, refetch
    } = useObtenerConfiguracionesCorreosQuery([pageNumber, elementsPerPage], {refetchOnMountOrArgChange: true});
    const [ trigger ] = useLazyObtenerConfiguracionesCorreosQuery();

    const [eliminarConfiguracionCorreo] = useEliminarConfiguracionCorreoMutation();

    return (<div id="main" className="main">
        <section className="section">
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Body>
                            <div className="btn-toolbar justify-content-between" role="toolbar">
                                <div className="pagetitle">
                                    <h1>Configuraciones Correos</h1>
                                </div>
                            </div>
                            {configuracionesCorreos &&
                                <BootstrapTable striped
                                    toolbar={({columns}) => (
                                        <div>
                                            <Button href="#" className="me-2" variant="primary" onClick={(e) => {
                                                setFormConfiguracionesCorreos({})
                                            }}>
                                                <i className="bi bi-plus-square"></i>&nbsp;Nuevo
                                            </Button>
                                            <ExportToExcel columns={columns} onClick={trigger} fileName={'configuracionesCorreos'}/>
                                        </div>
                                    )}
                                    data={configuracionesCorreos.data}
                                    columns={[{
                                        header: 'Protocolo Correo', accessorKey: 'idProtocoloCorreo'
                                    },{
                                        header: 'Servidor', accessorKey: 'servidor'
                                    },{
                                        header: 'Puerto', accessorKey: 'puerto'
                                    },{
                                        header: 'Usuario Correo', accessorKey: 'usuarioCorreo'
                                    },{
                                        header: 'Password', accessorKey: 'password'
                                    },{
                                        header: 'Predeterminado', accessorKey: 'predeterminado'
                                    },{
                                        header: 'Acciones',
                                        enableGlobalFilter: false,
                                        cell: ({row: {original}}) => (
                                        <div className="w-100 d-grid d-sm-block gap-1">
                                            <Button variant="warning" className="me-2" size="sm"
                                                    onClick={e => setFormConfiguracionesCorreos(original)}>
                                                <i className="bi bi-pencil-square"> </i>
                                                Editar
                                            </Button>
                                            <Button variant="danger" className="me-2" size="sm" onClick={e => setConfirmDialog({
                                                message: '¿Desea eliminar este registro?', onConfirm: async () => {
                                                    await eliminarConfiguracionCorreo({idConfiguracionCorreo: original.idConfiguracionCorreo});
                                                    refetch();
                                                    setConfirmDialog({});

                                                }
                                            })}>
                                                <i className="bi bi-trash"> </i>
                                                Eliminar
                                            </Button>
                                        </div>)
                                    }]}
                                    onGlobalFilterChange={(e => {
                                        setPageNumber(1);
                                        setElementsPerPage(e ? -1 : 10);
                                    })}
                                />}
                            {configuracionesCorreos &&  <SeverPagination
                                data={configuracionesCorreos.data}
                                currentPage={pageNumber}
                                totalElements={configuracionesCorreos.totalElements}
                                elementsPerPage={elementsPerPage}
                                onPageChange={setPageNumber}
                                onElementsPerPageChange={setElementsPerPage}
                            />}
                        </Card.Body>

                        <ConfirmDialog {...confirmDialog} onCancel={() => setConfirmDialog({})}/>
                    </Card>
                </Col>
            </Row>
        </section>
        {formConfiguracionesCorreos && <ConfiguracionesCorreosForm show={formConfiguracionesCorreos} onHide={(e) => {
            setFormConfiguracionesCorreos(false);
        }} onSave={refetch}/>}
    </div>);
};

export default ConfiguracionesCorreos;
