import {
    useEliminarEntradaMutation,
    useBuscarEntradasQuery,
} from "../../../services/entrada.service";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Form } from "react-bootstrap";
import SeverPagination from "../../../components/base/SeverPagination";
import EntradaProductoCard from "../../../components/entradas_productos/EntradaProductoCard";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { cleanProperties } from "../../../helpers/cleanProperties";
import { Controller, useForm } from "react-hook-form";
import { useObtenerEstatusQuery } from "../../../services/estatus.service";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import { NumericFormat } from "react-number-format";

const EntradasProductos = () => {
    const { control, register, reset, handleSubmit } = useForm();

    //Funcionalidades propias del Proceso EntradasProductos /EDITAR/ELIMINAR
    const [eliminarEntradaProducto] = useEliminarEntradaMutation();

    const { state, pathname } = useLocation();
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useState({});

    const [pageNumber, setPageNumber] = useState(1);
    const [elementsPerPage, setElementsPerPage] = useState(10);

    const { data: entradas, refetch } = useBuscarEntradasQuery(
        { pageNumber, elementsPerPage, searchParams },
        { refetchOnMountOrArgChange: true }
    );

    // const { data: estatus } = useObtenerEstatusQuery();

    const onSubmit = async (formData) => {
        setSearchParams(cleanProperties(formData));
        refetch();
    };

    const clearForm = () => {
        navigate(pathname, {});
        setSearchParams({});
        reset();
    };

    useEffect(() => {
        setSearchParams(state || {});
        reset({
            buscador: state?.buscador || "",
            idEstatus: state?.idEstatus || "1",
        });
    }, [reset, setSearchParams, state]);

    return (
        <>
            <main id="main" className="main">
                <Form
                    id="formulario"
                    className="row g-3"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div className="input-group">
                        <Form.Control
                            type="text"
                            placeholder="Buscar por: Número de entrada, Almacén, Origen"
                            {...register("buscador")}
                        />
                        <Button type="submit" variant="primary" title={"Buscar"}>
                            <i className="bi bi-search"></i>
                        </Button>
                        <Button
                            className="ms-2"
                            variant="primary"
                            title={"Limpiar"}
                            onClick={(e) => clearForm()}
                        >
                            <i className="bi bi-eraser"></i>
                        </Button>
                        <Button
                            variant="primary"
                            className="ms-2"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#filtros"
                            aria-expanded="false"
                            aria-controls="filtros"
                            title={"Filtros"}
                        >
                            <i className="bi bi-sliders"></i>
                        </Button>
                    </div>
                    <div className="collapse" id="filtros">
                        <Card className="card-body mb-0">
                            <h5 className="mb-3 mt-3 fw-bold">Criterios de filtrado.</h5>
                            <Row>
                                <Col className="border-end col">
                                    <Form.Label htmlFor="fechaRegistro" className="form-label">
                                        Fecha Registro
                                    </Form.Label>
                                    <Col className="mb-2">
                                        <Controller
                                            render={({ field }) => (
                                                <DatePicker
                                                    className="form-control"
                                                    id="fechaRegistro"
                                                    dateFormat="dd/MM/yyyy"
                                                    value={field.value}
                                                    onChange={(date) => {
                                                        field.onChange(dayjs(date).format("DD/MM/YYYY"));
                                                    }}
                                                />
                                            )}
                                            name="fechaRegistro"
                                            control={control}
                                        />
                                    </Col>
                                </Col>

                                <Col className="border-end col">
                                    <Col>
                                        <div>
                                            <Form.Label htmlFor="importeMinimo">
                                                Importe mínimo
                                            </Form.Label>
                                            <Controller
                                                render={({ field }) => (
                                                    <NumericFormat
                                                        className="form-control mwx-400"
                                                        id="importeMinimo"
                                                        thousandSeparator={true}
                                                        prefix={"$ "}
                                                        decimalScale={2}
                                                        fixedDecimalScale
                                                        allowNegative={false}
                                                        value={field.value || ""}
                                                        onValueChange={(v) => {
                                                            field.onChange(
                                                                Number(v.value) > 0 ? Number(v.value) : null
                                                            );
                                                        }}
                                                        isAllowed={({ value }) =>
                                                            value?.split(".")[0].length <= 10
                                                        }
                                                    />
                                                )}
                                                name="importeMinimo"
                                                control={control}
                                            />
                                        </div>
                                    </Col>
                                </Col>

                                <Col className="border-end col">
                                    <Col>
                                        <div>
                                            <Form.Label htmlFor="importeMaximo">
                                                Importe máximo
                                            </Form.Label>
                                            <Controller
                                                render={({ field }) => (
                                                    <NumericFormat
                                                        className="form-control mwx-400"
                                                        id="importeMaximo"
                                                        thousandSeparator={true}
                                                        prefix={"$ "}
                                                        decimalScale={2}
                                                        fixedDecimalScale
                                                        allowNegative={false}
                                                        value={field.value || ""}
                                                        onValueChange={(v) => {
                                                            field.onChange(
                                                                Number(v.value) > 0 ? Number(v.value) : null
                                                            );
                                                        }}
                                                        isAllowed={({ value }) =>
                                                            value?.split(".")[0].length <= 10
                                                        }
                                                    />
                                                )}
                                                name="importeMaximo"
                                                control={control}
                                            />
                                        </div>
                                    </Col>
                                </Col>

                                <Col className="border-end col">
                                    <Controller
                                        name="idEstatus"
                                        control={control}
                                        render={({ field }) => (
                                            <>
                                                <Form.Label htmlFor="idEstatus">
                                                    Estatus de la solicitud
                                                </Form.Label>
                                                <Form.Check
                                                    id="estatus.-0"
                                                    type="radio"
                                                    label="Todas"
                                                    value=""
                                                    {...register("idEstatus")}
                                                    defaultChecked={!state?.idEstatus}
                                                />
                                                <Form.Check
                                                    id="estatus.1"
                                                    type="radio"
                                                    label="Activo"
                                                    value="1" // Valor fijo para 'Activo'
                                                    {...register("idEstatus")}
                                                    defaultChecked={parseInt(state?.idEstatus) === 1}
                                                />
                                                <Form.Check
                                                    id="estatus.0"
                                                    type="radio"
                                                    label="Inactivo"
                                                    value="0" // Valor fijo para 'Inactivo'
                                                    {...register("idEstatus")}
                                                    defaultChecked={parseInt(state?.idEstatus) === 0}
                                                />
                                            </>
                                        )}
                                    />
                                </Col>
                            </Row>

                            <Row className="text-end mt-3">
                                <Button type="submit" variant="primary">
                                    Aplicar
                                </Button>
                            </Row>
                        </Card>
                    </div>
                </Form>
                <Row className="mt-3">
                    <Link className="col-12 text-end" to={"/entradas/productos/view/"}>
                        <Button type="submit" variant="primary">
                            <i className="bi bi-file-earmark-plus-fill"></i> Nuevo
                        </Button>
                    </Link>
                </Row>

                {entradas?.data &&
                    entradas.data.map((entrada) => (
                        <EntradaProductoCard
                            key={"entrada." + entrada.idEntrada}
                            entrada={entrada}
                            refetch={refetch}
                            eliminarEntradaProducto={eliminarEntradaProducto}
                        />
                    ))}

                {entradas && (
                    <SeverPagination
                        data={entradas.data}
                        currentPage={pageNumber}
                        totalElements={entradas.totalElements}
                        elementsPerPage={elementsPerPage}
                        onPageChange={setPageNumber}
                        onElementsPerPageChange={setElementsPerPage}
                    />
                )}
            </main>
        </>
    );
};

export default EntradasProductos;
