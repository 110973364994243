import React, { useState } from 'react';
import { Button, Card } from "react-bootstrap";
import SalidasProductosForm from '../../views/procesos/salidas_productos/SalidasProductosForm'
import ConfirmDialog from "../base/ConfirmDialog";
import { Link } from 'react-router-dom';

const SalidaProductoCard = ({salida, refetch, eliminarSalida}) => {

    const [vistaSalidasProductos, setVistaSalidasProductos] = useState(null)
    const [confirmDialog, setConfirmDialog] = useState({})

    return (
        <Card className={`mb-0 mt-3`}>
            <Card.Header className={`pb-0 mb-0 fw-bold alert alert-primary`}>
                <h5 className="card-title pt-1 pb-0 text-primary">Número de salida: {salida.numero} </h5>
            </Card.Header>
            <div className="row card-body pt-2">
                <p className="card-text mb-1">
                    <span className="text-muted">Fecha de registro: <b>{salida.fechaRegistro?.split(" ")[0]}</b></span> |
                    Estatus: <b>{salida.estatus.estatus}</b>
                </p>
                <div className="col-md-12 mb-2 pb-0">
                    <p className="card-text mb-1">
                        <strong>Almacen: </strong>{salida.almacen.almacen}</p>
                    <p className="card-text mb-1">
                        <strong>Origen: </strong>{salida.origenSalida.origenSalida}</p>
                    <p className="card-text mb-1">
                        <strong>Total: </strong>$ {salida.total}</p>
                </div>
                <div>
                    <Link className="col-12 text-end" to={"/salidas/productos/view/" + salida.idSalida}>
                        <Button variant="outline-warning" className="me-2" size="sm"
                            onClick={e => setVistaSalidasProductos(salida)}>
                            <i className="bi bi-pencil-square"> </i>
                            Editar
                        </Button>
                    </Link>

                    <Button variant="outline-danger" className="me-2" size="sm" onClick={e => setConfirmDialog({
                        message: '¿Desea eliminar este registro?', onConfirm: async () => {
                            await eliminarSalida({ idSalida: salida.idSalida });
                            refetch();
                            setConfirmDialog({});

                        }
                    })}>
                        <i className="bi bi-trash"> </i>
                        Eliminar
                    </Button>
                </div>

            </div>
            <ConfirmDialog {...confirmDialog} onCancel={() => setConfirmDialog({})} />

            {vistaSalidasProductos && <SalidasProductosForm show={vistaSalidasProductos} onHide={(e) => {
                setVistaSalidasProductos(false);
            }} onSave={refetch} />}
        </Card>
    )
}

export default SalidaProductoCard