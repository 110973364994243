import React, {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Alert, Button, Form, Modal, Row} from "react-bootstrap";
import {useGuardarTransporteMutation, useObtenerTransporteQuery} from "../../../services/transporte.service";
import {useObtenerTiposTransportesQuery} from "../../../services/tipoTransporte.service";
import {useObtenerClasesTransportesQuery} from "../../../services/claseTransporte.service";
import {useObtenerMarcasTransportesQuery} from "../../../services/marcaTransporte.service";
import {useObtenerColoresQuery} from "../../../services/color.service";
import {useObtenerTiposCombustiblesQuery} from "../../../services/tipoCombustible.service";
import {useObtenerFabricantesTransportesQuery} from "../../../services/fabricanteTransporte.service";

const TransportesForm = ({show, onHide, onSave}) => {

    const handleClose = (e) => {
        onHide(e);
    };

    const {control, handleSubmit, setValue, register, reset, watch, setFocus, formState: {errors}} = useForm();

    const {data: transporte} = useObtenerTransporteQuery(show.idTransporte, {
        skip: !show.idTransporte,
        refetchOnMountOrArgChange: true
    });
    const [guardarTransporte] = useGuardarTransporteMutation();

    const {data: tiposTransportes} = useObtenerTiposTransportesQuery();
    const {data: clasesTransportes} = useObtenerClasesTransportesQuery();
    const {data: marcasTransportes} = useObtenerMarcasTransportesQuery();
    const {data: colores} = useObtenerColoresQuery();
    const {data: tiposCombustibles} = useObtenerTiposCombustiblesQuery();
    const {data: fabricantes} = useObtenerFabricantesTransportesQuery();

    useEffect(() => {
        if (transporte && tiposTransportes && clasesTransportes && marcasTransportes && colores && tiposCombustibles && fabricantes) {
            reset({
                idTransporte: transporte.idTransporte,
                idTipoTransporte: transporte.idTipoTransporte,
                idClaseTransporte: transporte.idClaseTransporte,
                idMarcaTransporte: transporte.idMarcaTransporte,
                idColor: transporte.idColor,
                idTipoCombustible: transporte.idTipoCombustible,
                idFabricanteTransporte: transporte.idFabricanteTransporte,
                numeroIdentificacion: transporte.numeroIdentificacion,
                modelo: transporte.modelo,
                anioFabricacion: transporte.anioFabricacion,
                numeroMotor: transporte.numeroMotor,
                pesoCarga: transporte.pesoCarga,
                capacidadCarga: transporte.capacidadCarga,
                tipoMotor: transporte.tipoMotor,
                potenciaMotor: transporte.potenciaMotor,
                envergadura: transporte.envergadura,
                longitud: transporte.longitud,
                pesoMaximoDespegue: transporte.pesoMaximoDespegue,
                numeroPlaca: transporte.numeroPlaca,
                numeroCilindros: transporte.numeroCilindros,
                numeroSerie: transporte.numeroSerie,
                idEstatus: transporte.idEstatus,
                fechaVersion: transporte.fechaVersion
            });
        }

    }, [transporte, tiposTransportes, clasesTransportes, marcasTransportes, colores, tiposCombustibles, fabricantes, reset]);

    useEffect(() => {
        if (!show.idTransporte) {
            setFocus("idTipoTransporte");
        }
    }, [show, setFocus]);

    const onSubmit = async (formData) => {
        let dataForm = {}
        let elementos = document.getElementById("transporte-form").elements;
        Array.from(elementos).forEach(function(elemento) {
            // Verifica si el input existe (por el cambio de tipo)
            if (elemento.name && formData.hasOwnProperty(elemento.name)) {
                dataForm[elemento.name] = formData[elemento.name];
            }
        });

        let {data} = await guardarTransporte(dataForm);
        if (data) {
            setValue('idTransporte', data.id);
            setValue('fechaVersion', data.fechaVersion);
            onSave();
        }
    };

    return (<> {
        <Modal className="modal-fixed" show={show} onHide={handleClose} centered backdrop="static" keyboard={false}>
            <Form id="transporte-form" onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>{watch("idTransporte") ? 'Editar' : 'Nuevo'} [ Transporte ]</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="text" id="idTransporte" className="d-none" {...register("idTransporte")} />
                    <Form.Control type="text" id="fechaVersion" className="d-none" {...register("fechaVersion")} />
                    <Form.Group className='mb-3'>
                        <Form.Label htmlFor='idTipoTransporte' className='fw-bold'>
                            Tipo
                        </Form.Label>
                        <Form.Select
                            className="mwx-350"
                            id="idTipoTransporte"
                            {...register("idTipoTransporte", {
                                required: "Ingrese un dato válido"
                            })}
                        >
                            <option value="">Seleccionar</option>
                            {tiposTransportes?.data && tiposTransportes.data.map((tipoTransporte) => (
                                <option key={'tipoTransporte.' + tipoTransporte.idTipoTransporte}
                                        value={tipoTransporte.idTipoTransporte}>
                                    {tipoTransporte.tipoTransporte}
                                </option>
                            ))}
                        </Form.Select>
                        {errors.idTipoTransporte && (<Alert variant='danger' role='alert'>
                            {errors.idTipoTransporte.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className='mb-3'>
                        <Form.Label htmlFor='idClaseTransporte' className='fw-bold'>
                            Clase
                        </Form.Label>
                        <Form.Select
                            className="mwx-350"
                            id="idClaseTransporte"
                            {...register("idClaseTransporte", {
                                required: "Ingrese un dato válido"
                            })}
                        >
                            <option value="">Seleccionar</option>
                            {clasesTransportes?.data && clasesTransportes.data.map((claseTransporte) => (
                                <option key={'claseTransporte.' + claseTransporte.idClaseTransporte}
                                        value={claseTransporte.idClaseTransporte}>
                                    {claseTransporte.claseTransporte}
                                </option>
                            ))}
                        </Form.Select>
                        {errors.idClaseTransporte && (<Alert variant='danger' role='alert'>
                            {errors.idClaseTransporte.message}
                        </Alert>)}
                    </Form.Group>

                    {[1, 3].includes(parseInt(watch("idTipoTransporte"))) &&
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="numeroIdentificacion" className="fw-bold">
                                Número de identificación (NIV)
                            </Form.Label>
                            <Form.Control
                                className="mwx-350"
                                {...register('numeroIdentificacion', {
                                    required: 'Ingrese un dato válido',
                                    maxLength: {value: 50, message: 'Ha excedido el máximo de caracteres'}
                                })}
                            />
                            {errors.numeroIdentificacion && (<Alert variant="danger" role="alert">
                                {errors.numeroIdentificacion.message}
                            </Alert>)}
                        </Form.Group>
                    }

                    {[1, 3].includes(parseInt(watch("idTipoTransporte"))) &&
                        <Form.Group className='mb-3'>
                            <Form.Label htmlFor='idMarcaTransporte' className='fw-bold'>
                                Marca
                            </Form.Label>
                            <Form.Select
                                className="mwx-350"
                                id="idMarcaTransporte"
                                {...register("idMarcaTransporte", {
                                    required: "Ingrese un dato válido"
                                })}
                            >
                                <option value="">Seleccionar</option>
                                {marcasTransportes?.data && marcasTransportes.data.map((marcaTransporte) => (
                                    <option key={'marcaTransporte.' + marcaTransporte.idMarcaTransporte}
                                            value={marcaTransporte.idMarcaTransporte}>
                                        {marcaTransporte.marcaTransporte}
                                    </option>
                                ))}
                            </Form.Select>
                            {errors.idMarcaTransporte && (<Alert variant='danger' role='alert'>
                                {errors.idMarcaTransporte.message}
                            </Alert>)}
                        </Form.Group>
                    }

                    {[1, 2, 3].includes(parseInt(watch("idTipoTransporte"))) &&
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="modelo" className="fw-bold">
                                Modelo
                            </Form.Label>
                            <Form.Control
                                className="mwx-350"
                                {...register('modelo', {
                                    required: 'Ingrese un dato válido',
                                    maxLength: {value: 100, message: 'Ha excedido el máximo de caracteres'}
                                })}
                            />
                            {errors.modelo && (<Alert variant="danger" role="alert">
                                {errors.modelo.message}
                            </Alert>)}
                        </Form.Group>
                    }

                    {[2, 3].includes(parseInt(watch("idTipoTransporte"))) &&
                        <Form.Group className='mb-3'>
                            <Form.Label htmlFor='idFabricanteTransporte' className='fw-bold'>
                                Fabricante
                            </Form.Label>
                            <Form.Select
                                className="mwx-350"
                                id="idFabricanteTransporte"
                                {...register("idFabricanteTransporte", {
                                    required: "Ingrese un dato válido"
                                })}
                            >
                                <option value="">Seleccionar</option>
                                {fabricantes?.data && fabricantes.data.map((fabricante) => (
                                    <option key={'fabricante.' + fabricante.idFabricanteTransporte}
                                            value={fabricante.idFabricanteTransporte}>
                                        {fabricante.fabricanteTransporte}
                                    </option>
                                ))}
                            </Form.Select>
                            {errors.idFabricanteTransporte && (<Alert variant='danger' role='alert'>
                                {errors.idFabricanteTransporte.message}
                            </Alert>)}
                        </Form.Group>
                    }
                    {[2, 3].includes(parseInt(watch("idTipoTransporte"))) &&
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="numeroSerie" className="fw-bold">
                                Número de serie
                            </Form.Label>
                            <Form.Control
                                className="mwx-350"
                                {...register('numeroSerie', {
                                    required: 'Ingrese un dato válido',
                                    maxLength: {value: 50, message: 'Ha excedido el máximo de caracteres'}
                                })}
                            />
                            {errors.numeroSerie && (<Alert variant="danger" role="alert">
                                {errors.numeroSerie.message}
                            </Alert>)}
                        </Form.Group>
                    }
                    {[1, 2, 3].includes(parseInt(watch("idTipoTransporte"))) &&
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="anioFabricacion" className="fw-bold">
                                Año de fabricación
                            </Form.Label>
                            <Form.Control
                                type="number"
                                className="mwx-100"
                                min="1800"
                                max={(new Date()).getFullYear() + 1}
                                {...register('anioFabricacion', {
                                    required: 'Ingrese un dato válido',
                                    maxLength: {value: 4, message: 'Ha excedido el máximo de caracteres'}
                                })}
                            />
                            {errors.anioFabricacion && (<Alert variant="danger" role="alert">
                                {errors.anioFabricacion.message}
                            </Alert>)}
                        </Form.Group>
                    }
                    {[1, 3].includes(parseInt(watch("idTipoTransporte"))) &&
                        <Form.Group className='mb-3'>
                            <Form.Label htmlFor='idColor' className='fw-bold'>
                                Color
                            </Form.Label>
                            <Form.Select
                                className="mwx-100"
                                id="idColor"
                                {...register("idColor", {
                                    required: "Ingrese un dato válido"
                                })}
                            >
                                <option value="">Selec.</option>
                                {colores?.data && colores.data.map((color) => (
                                    <option key={'color.' + color.idColor}
                                            value={color.idColor}>
                                        {color.color}
                                    </option>
                                ))}
                            </Form.Select>
                            {errors.idColor && (<Alert variant='danger' role='alert'>
                                {errors.idColor.message}
                            </Alert>)}
                        </Form.Group>
                    }
                    {[1, 3].includes(parseInt(watch("idTipoTransporte"))) &&
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="numeroMotor" className="fw-bold">
                                Número de motor
                            </Form.Label>
                            <Form.Control
                                className="mwx-350"
                                {...register('numeroMotor', {
                                    required: 'Ingrese un dato válido',
                                    maxLength: {value: 50, message: 'Ha excedido el máximo de caracteres'}
                                })}
                            />
                            {errors.numeroMotor && (<Alert variant="danger" role="alert">
                                {errors.numeroMotor.message}
                            </Alert>)}
                        </Form.Group>
                    }
                    {[1, 3].includes(parseInt(watch("idTipoTransporte"))) &&
                        <Form.Group className='mb-3'>
                            <Form.Label htmlFor='idTipoCombustible' className='fw-bold'>
                                Tipo de combustible
                            </Form.Label>
                            <Form.Select
                                className="mwx-350"
                                id="idTipoCombustible"
                                {...register("idTipoCombustible", {
                                    required: "Ingrese un dato válido"
                                })}
                            >
                                <option value="">Seleccionar</option>
                                {tiposCombustibles?.data && tiposCombustibles.data.map((tipoCombustible) => (
                                    <option key={'tipoCombustible.' + tipoCombustible.idTipoCombustible}
                                            value={tipoCombustible.idTipoCombustible}>
                                        {tipoCombustible.tipoCombustible}
                                    </option>
                                ))}
                            </Form.Select>
                            {errors.idTipoCombustible && (<Alert variant='danger' role='alert'>
                                {errors.idTipoCombustible.message}
                            </Alert>)}
                        </Form.Group>
                    }
                    {[1, 3].includes(parseInt(watch("idTipoTransporte"))) &&
                        <Row className="mwx-350">
                            <Form.Group className="mb-3 col-sm-6">
                                <Form.Label htmlFor="pesoCarga" className="fw-bold">
                                    Peso de carga
                                </Form.Label>
                                <Form.Control
                                    {...register('pesoCarga', {
                                        required: 'Ingrese un dato válido',
                                        maxLength: {value: 10, message: 'Ha excedido el máximo de caracteres'}
                                    })}
                                />
                                {errors.pesoCarga && (<Alert variant="danger" role="alert">
                                    {errors.pesoCarga.message}
                                </Alert>)}
                            </Form.Group>
                            <Form.Group className="mb-3 col-sm-6">
                                <Form.Label htmlFor="capacidadCarga" className="fw-bold">
                                    Capacidad de carga
                                </Form.Label>
                                <Form.Control
                                    {...register('capacidadCarga', {
                                        required: 'Ingrese un dato válido',
                                        maxLength: {value: 20, message: 'Ha excedido el máximo de caracteres'}
                                    })}
                                />
                                {errors.capacidadCarga && (<Alert variant="danger" role="alert">
                                    {errors.capacidadCarga.message}
                                </Alert>)}
                            </Form.Group>
                        </Row>
                    }
                    {[2, 3].includes(parseInt(watch("idTipoTransporte"))) &&
                        <Row className="mwx-350">
                            <Form.Group className="mb-3 col-sm-6">
                                <Form.Label htmlFor="tipoMotor" className="fw-bold">
                                    Tipo de motor
                                </Form.Label>
                                <Form.Control
                                    {...register('tipoMotor', {
                                        required: 'Ingrese un dato válido',
                                        maxLength: {value: 50, message: 'Ha excedido el máximo de caracteres'}
                                    })}
                                />
                                {errors.tipoMotor && (<Alert variant="danger" role="alert">
                                    {errors.tipoMotor.message}
                                </Alert>)}
                            </Form.Group>
                            <Form.Group className="mb-3 col-sm-6">
                                <Form.Label htmlFor="potenciaMotor" className="fw-bold">
                                    Potencia del motor
                                </Form.Label>
                                <Form.Control
                                    {...register('potenciaMotor', {
                                        required: 'Ingrese un dato válido',
                                        maxLength: {value: 50, message: 'Ha excedido el máximo de caracteres'}
                                    })}
                                />
                                {errors.potenciaMotor && (<Alert variant="danger" role="alert">
                                    {errors.potenciaMotor.message}
                                </Alert>)}
                            </Form.Group>
                        </Row>
                    }
                    {[2, 3].includes(parseInt(watch("idTipoTransporte"))) &&
                        <Row className="mwx-350">
                            <Form.Group className="mb-3 col-sm-6">
                                <Form.Label htmlFor="envergadura" className="fw-bold">
                                    Envergadura
                                </Form.Label>
                                <Form.Control
                                    {...register('envergadura', {
                                        required: 'Ingrese un dato válido',
                                        maxLength: {value: 50, message: 'Ha excedido el máximo de caracteres'}
                                    })}
                                />
                                {errors.envergadura && (<Alert variant="danger" role="alert">
                                    {errors.envergadura.message}
                                </Alert>)}
                            </Form.Group>
                            <Form.Group className="mb-3 col-sm-6">
                                <Form.Label htmlFor="longitud" className="fw-bold">
                                    Longitud
                                </Form.Label>
                                <Form.Control
                                    {...register('longitud', {
                                        required: 'Ingrese un dato válido',
                                        maxLength: {value: 50, message: 'Ha excedido el máximo de caracteres'}
                                    })}
                                />
                                {errors.longitud && (<Alert variant="danger" role="alert">
                                    {errors.longitud.message}
                                </Alert>)}
                            </Form.Group>
                        </Row>
                    }
                    {[2, 3].includes(parseInt(watch("idTipoTransporte"))) &&
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="pesoMaximoDespegue" className="fw-bold">
                                Peso máximo de despegue
                            </Form.Label>
                            <Form.Control
                                className="mwx-350"
                                {...register('pesoMaximoDespegue', {
                                    required: 'Ingrese un dato válido',
                                    maxLength: {value: 20, message: 'Ha excedido el máximo de caracteres'}
                                })}
                            />
                            {errors.pesoMaximoDespegue && (<Alert variant="danger" role="alert">
                                {errors.pesoMaximoDespegue.message}
                            </Alert>)}
                        </Form.Group>
                    }
                    {[1, 2, 3].includes(parseInt(watch("idTipoTransporte"))) &&
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="numeroPlaca" className="fw-bold">
                                {parseInt(watch("idTipoTransporte")) === 1 ? "Número de placa" : "Matrícula"}
                            </Form.Label>
                            <Form.Control
                                className="mwx-350"
                                {...register('numeroPlaca', {
                                    required: 'Ingrese un dato válido',
                                    maxLength: {value: 10, message: 'Ha excedido el máximo de caracteres'}
                                })}
                            />
                            {errors.numeroPlaca && (<Alert variant="danger" role="alert">
                                {errors.numeroPlaca.message}
                            </Alert>)}
                        </Form.Group>}
                    {[1, 3].includes(parseInt(watch("idTipoTransporte"))) &&
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="numeroCilindros" className="fw-bold">
                                Número de cilindros
                            </Form.Label>
                            <Form.Control
                                type="number"
                                className="mwx-100"
                                min="1"
                                {...register('numeroCilindros', {
                                    required: 'Ingrese un dato válido',
                                    maxLength: {value: 4, message: 'Ha excedido el máximo de caracteres'}
                                })}
                            />
                            {errors.numeroCilindros && (<Alert variant="danger" role="alert">
                                {errors.numeroCilindros.message}
                            </Alert>)}
                        </Form.Group>
                    }
                    <Form.Group className="mb-3">
                        <Controller
                            name="idEstatus"
                            control={control}
                            defaultValue="1"
                            render={({field}) => (<>
                                    <Form.Label htmlFor="idEstatus">Estatus</Form.Label>
                                    <Form.Check
                                        id="idEstatus"
                                        name="idEstatus"
                                        type="switch"
                                        label={Boolean(field.value) ? 'Activo' : 'Inactivo'}
                                        checked={Boolean(field.value)}
                                        onChange={(e) => {
                                            field.onChange(Number(e.target.checked))
                                        }}
                                    />
                                </>

                            )}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant="primary" className="me-2">
                        <i className="bi bi-save"></i> Guardar
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>}
    </>);
};

export default TransportesForm;
