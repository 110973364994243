import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const rolesApi = createApi({
    reducerPath: 'rolesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "roles",
        prepareHeaders: (headers, {getState}) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (build) => ({
        obtenerRol: build.query({
            query: (idRol) => `/${idRol}`,
        }),
        obtenerRoles: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        guardarRol: build.mutation({
            query: (rol) => ({
                url: '',
                method: rol.idRol ? 'PUT' : 'POST',
                body: rol,
            }),
        }),
        eliminarRol: build.mutation({
            query: (rol) => ({
                url: '',
                method: 'DELETE',
                body: rol,
            }),
        }),
    }),
});
export const {
    useObtenerRolQuery,
    useObtenerRolesQuery,
    useLazyObtenerRolesQuery,
    useGuardarRolMutation,
    useEliminarRolMutation
} = rolesApi;
