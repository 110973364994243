import React, { useState } from 'react';
import { NumericFormat } from "react-number-format";
import { Button, Card } from "react-bootstrap";
import ControlMantenimientosForm from '../../views/mantenimientos/control_mantenimientos/ControlMantenimientosForm';
import ConfirmDialog from "../base/ConfirmDialog";
import { Link } from 'react-router-dom';


const ControlMantenimientoCard = ({ mantenimiento, refetch, eliminarControlMantenimiento }) => {

    const [formControlMantenimientos, setFormControlMantenimientos] = useState(null);
    const [confirmDialog, setConfirmDialog] = useState({});

    console.log(mantenimiento)
    return (
        <Card className={`mb-0 mt-3`}>
            <Card.Header className={`pb-0 mb-0 fw-bold alert alert-primary`}>
                <h5 className="card-title pt-1 pb-0 text-primary">Tipo de transporte: {mantenimiento.transporte.tipoTransporte.tipoTransporte} </h5>
            </Card.Header>

            <div className="row card-body pt-2">

                <p className="card-text mb-1">
                    <span className="text-muted">
                        Fecha de conclusión: <b> {mantenimiento.fechaConclusion?.split(" ")[0]}</b></span> |
                        Estatus: <b>{mantenimiento.estatus.estatus}</b></p>
                <div className="col-md-12 mb-2 pb-0">
                    <p className="card-text mb-1">
                        <strong>Clase:</strong> {mantenimiento.transporte.claseTransporte.claseTransporte}
                        <span className="mx-4"></span>
                        <strong>Modelo:</strong> {mantenimiento.transporte.modelo}</p>
                    <p className="card-text mb-1">
                        <strong>Año de fabricación:</strong> {mantenimiento.transporte.anioFabricacion}
                        <span className="mx-4"></span>
                        <strong>Número de placa/Matricula:</strong> {mantenimiento.transporte.numeroPlaca}</p>
                    <p className="card-text mb-1">
                        <strong>Total del mantenimiento: </strong>
                        <NumericFormat
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$ '}
                            decimalScale={2}
                            fixedDecimalScale
                            value={mantenimiento.totalMantenimiento}/>
                            &nbsp;
                    </p> 
                </div>


                <div>

                    <Link className="col-12 text-end" to={"/mantenimientos/programaciones/mantenimientos/view/" + mantenimiento.idMantenimiento}>
                        <Button variant="outline-warning" className="me-2" size="sm"
                            onClick={e => setFormControlMantenimientos(mantenimiento)}>
                            <i className="bi bi-pencil-square"> </i>
                            Editar
                        </Button>
                    </Link>

                </div>

            </div>

            <ConfirmDialog {...confirmDialog} onCancel={() => setConfirmDialog({})} />

            {formControlMantenimientos && <ControlMantenimientosForm show={formControlMantenimientos} onHide={(e) => {
                setFormControlMantenimientos(false);
            }} onSave={refetch} />}
        </Card>

    );
};

export default ControlMantenimientoCard;