import React, {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Alert, Button, Form, Modal} from "react-bootstrap";
import {useGuardarMetodoTrasladoAlmacenMutation, useObtenerMetodoTrasladoAlmacenQuery} from "../../../services/metodoTrasladoAlmacen.service";

const MetodosTrasladosAlmacenesForm = ({show, onHide, onSave}) => {

    const handleClose = (e) => {
        onHide(e);
    };

    const {control, handleSubmit, setValue, register, reset, formState: {errors}} = useForm();

    const {data: metodoTrasladoAlmacen} = useObtenerMetodoTrasladoAlmacenQuery(show.idMetodoTrasladoAlmacen, {skip: !show.idMetodoTrasladoAlmacen, refetchOnMountOrArgChange: true});
    const [guardarMetodoTrasladoAlmacen] = useGuardarMetodoTrasladoAlmacenMutation();

    useEffect(() => {
        if (metodoTrasladoAlmacen) {
            reset({
                idMetodoTrasladoAlmacen: metodoTrasladoAlmacen.idMetodoTrasladoAlmacen,
                metodoTrasladoAlmacen: metodoTrasladoAlmacen.metodoTrasladoAlmacen,
                idEstatus: metodoTrasladoAlmacen.idEstatus,
                fechaVersion: metodoTrasladoAlmacen.fechaVersion
            });
        } else {
            //default values
            reset({idEstatus: 1})
        }
    }, [metodoTrasladoAlmacen, reset]);

    const onSubmit = async (formData) => {
        let {data} = await guardarMetodoTrasladoAlmacen(formData);
        if(data){
            setValue('idMetodoTrasladoAlmacen', data.id);
            setValue('fechaVersion', data.fechaVersion);
            onSave();
        }
    };

    return (<> {
        <Modal show={show} onHide={handleClose} centered backdrop="static" keyboard={false}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>{show.idMetodoTrasladoAlmacen ? 'Editar' : 'Nuevo'} [ Método de traslado ]</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="text" id="idMetodoTrasladoAlmacen" className="d-none" {...register("idMetodoTrasladoAlmacen")} />
                    <Form.Control type="text" id="fechaVersion" className="d-none" {...register("fechaVersion")} />
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="metodoTrasladoAlmacen" className="fw-bold">
                            Nombre
                        </Form.Label>
                        <Form.Control
                            {...register('metodoTrasladoAlmacen', {
                                required: 'Ingrese un dato válido',
                                maxLength: {value: 100, message: 'Ha excedido el máximo de caracteres'}
                            })}
                        />
                        {errors.metodoTrasladoAlmacen && (<Alert variant="danger" role="alert">
                            {errors.metodoTrasladoAlmacen.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Controller
                            name="idEstatus"
                            control={control}
                            render={({field}) => (<>
                                    <Form.Label htmlFor="idEstatus">Estatus</Form.Label>
                                    <Form.Check
                                        id="idEstatus"
                                        type="switch"
                                        label={Boolean(field.value) ? 'Activo' : 'Inactivo'}
                                        checked={Boolean(field.value)}
                                        onChange={(e) => {
                                            field.onChange(Number(e.target.checked))
                                        }}
                                    />
                                </>

                            )}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant="primary" className="me-2">
                        <i className="bi bi-save"></i> Guardar
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>}
    </>);
};

export default MetodosTrasladosAlmacenesForm;
