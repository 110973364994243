
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { useGuardarMunicipioMutation } from "../../../services/municipio.service";
//import {useObtenerMunicipiosQuery} from "../../../services/municipio.service"
import { useObtenerMunicipioQuery } from '../../../services/municipio.service';
import { useObtenerEntidadesFederativasQuery } from "../../../services/entidadFederativa.service";
import { useObtenerPaisesQuery } from "../../../services/pais.service";


const MunicipiosForm = ({ show, onHide, onSave }) => {

    const handleClose = (e) => {
        onHide(e);
    };

    const { handleSubmit, setValue, register, reset, formState: { errors } } = useForm();

    const { data: municipio } = useObtenerMunicipioQuery(show.idMunicipio, { skip: !show.idMunicipio, refetchOnMountOrArgChange: true });
    const { data: entidadesFederativas } = useObtenerEntidadesFederativasQuery();
    const { data: paises } = useObtenerPaisesQuery();

    //const { data: municipios } = useObtenerMunicipiosQuery();
    const [guardarMunicipio] = useGuardarMunicipioMutation();
    //const [idMunicipio, setIdMunicipio] = useState(0);
    const [idEntidadFederativa, setIdEntidadFederativa] = useState(0);
    const [idPais, setIdPais] = useState(0);


    useEffect(() => {
        if (municipio) {
            reset({
                idMunicipio: municipio.idMunicipio,
                municipio: municipio.municipio,
                idEntidadFederativa: municipio.idEntidadFederativa,
                idPais: municipio.idPais
            });
        } else {
            //default values
            // reset({idEstatus: 1})
        }
    }, [municipio, reset]);

    const onSubmit = async (formData) => {
        let { data } = await guardarMunicipio(formData);
        if (data) {
            setValue('idMunicipio', data.id);

            onSave();
        }
    };

    return (<> {
        <Modal show={show} onHide={handleClose} centered backdrop="static" keyboard={false}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>{show.idMunicipio ? 'Editar' : 'Nuevo'} [ Municipio ]</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="text" id="idMunicipio" className="d-none" {...register("idMunicipio")} />
                    <Form.Group className="mb-3">

                        <Form.Label htmlFor="clave" className="fw-bold">
                            Municipio
                        </Form.Label>
                        <Form.Control
                            {...register('municipio', {
                                required: 'Ingrese un dato válido',
                                maxLength: { value: 100, message: 'Ha excedido el máximo de caracteres' }
                            })}
                        />


                        <Form.Label htmlFor="idEntidadFederativa" className="fw-bold">Entidad Federativa</Form.Label>
                        <Form.Select
                            className="mwx-400"
                            value={idEntidadFederativa}
                            id="idEntidadFederativa"
                            {...register("idEntidadFederativa", {
                                required: "Ingrese un dato válido",
                                onChange: (event) => {
                                    setIdEntidadFederativa(event.target.value);
                                },
                            })}
                        >
                            <option value="">Seleccionar</option>
                            {entidadesFederativas?.data &&
                                entidadesFederativas?.data.map((entidadFederativa) => (
                                    <option key={entidadFederativa.idEntidadFederativa}
                                        value={entidadFederativa.idEntidadFederativa}>
                                        {entidadFederativa.entidadFederativa}
                                    </option>
                                ))}
                        </Form.Select>
                        {errors.idEntidadFederativa && (
                            <Alert variant="danger" className="mwx-400">
                                {errors.idEntidadFederativa.message}
                            </Alert>
                        )}

                        <Form.Label htmlFor="idPais" className="fw-bold">País</Form.Label>
                        <Form.Select
                            className="mwx-400"
                            value={idPais}
                            id="idPais"
                            {...register("idPais", {
                                required: "Ingrese un dato válido",
                                onChange: (event) => {
                                    setIdPais(event.target.value);
                                },
                            })}
                        >
                            <option value="">Seleccionar</option>
                            {paises?.data &&
                                paises?.data.map((pais) => (
                                    <option key={pais.idPais}
                                        value={pais.idPais}>
                                        {pais.pais}
                                    </option>
                                ))}
                        </Form.Select>
                        {errors.idPais && (
                            <Alert variant="danger" className="mwx-400">
                                {errors.idPais.message}
                            </Alert>
                        )}


                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant="primary" className="me-2">
                        <i className="bi bi-save"></i> Guardar
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>}
    </>);
};

export default MunicipiosForm;