import React, {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Alert, Button, Form, Modal} from "react-bootstrap";
import {useGuardarPasilloAlmacenMutation, useObtenerPasilloAlmacenQuery} from "../../../services/pasilloAlmacen.service";

const PasillosAlmacenesForm = ({show, onHide, onSave}) => {

    const handleClose = (e) => {
        onHide(e);
    };

    const {control, handleSubmit, setValue, register, reset, watch, formState: {errors}} = useForm();

    const {data: pasilloAlmacen} = useObtenerPasilloAlmacenQuery(show.idPasilloAlmacen, {skip: !show.idPasilloAlmacen, refetchOnMountOrArgChange: true});
    const [guardarPasilloAlmacen] = useGuardarPasilloAlmacenMutation();

    useEffect(() => {
        if (pasilloAlmacen) {
            reset({
                idPasilloAlmacen: pasilloAlmacen.idPasilloAlmacen,
                pasilloAlmacen: pasilloAlmacen.pasilloAlmacen,
                idEstatus: pasilloAlmacen.idEstatus,
                fechaVersion: pasilloAlmacen.fechaVersion
            });
        } else {
            //default values
            reset({idEstatus: 1})
        }
    }, [pasilloAlmacen, reset]);

    const onSubmit = async (formData) => {
        let {data} = await guardarPasilloAlmacen(formData);
        if(data){
            setValue('idPasilloAlmacen', data.id);
            setValue('fechaVersion', data.fechaVersion);
            onSave();
        }
    };

    return (<> {
        <Modal show={show} onHide={handleClose} centered backdrop="static" keyboard={false}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>{watch("idPasilloAlmacen") ? 'Editar' : 'Nuevo'} [ Pasillo de almacén ]</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="text" id="idPasilloAlmacen" className="d-none" {...register("idPasilloAlmacen")} />
                    <Form.Control type="text" id="fechaVersion" className="d-none" {...register("fechaVersion")} />
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="pasilloAlmacen" className="fw-bold">
                            Nombre
                        </Form.Label>
                        <Form.Control
                            {...register('pasilloAlmacen', {
                                required: 'Ingrese un dato válido',
                                maxLength: {value: 30, message: 'Ha excedido el máximo de caracteres'}
                            })}
                        />
                        {errors.pasilloAlmacen && (<Alert variant="danger" role="alert">
                            {errors.pasilloAlmacen.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Controller
                            name="idEstatus"
                            control={control}
                            render={({field}) => (<>
                                    <Form.Label htmlFor="idEstatus">Estatus</Form.Label>
                                    <Form.Check
                                        id="idEstatus"
                                        type="switch"
                                        label={Boolean(field.value) ? 'Activo' : 'Inactivo'}
                                        checked={Boolean(field.value)}
                                        onChange={(e) => {
                                            field.onChange(Number(e.target.checked))
                                        }}
                                    />
                                </>

                            )}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant="primary" className="me-2">
                        <i className="bi bi-save"></i> Guardar
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>}
    </>);
};

export default PasillosAlmacenesForm;
