import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const salidasProductosApi = createApi({
    reducerPath: 'salidasProductosApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "salidas/productos",
        prepareHeaders: (headers, {getState}) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        obtenerSalidaProducto: build.query({
            query: (idSalidaProducto) => `/${idSalidaProducto}`,
        }),
        obtenerSalidasProductos: build.query({
            query: (idSalida) => `/${idSalida}`,
        }),
        guardarSalidaProducto: build.mutation({
            query: (salidaProducto) => ({
                url: '',
                method: salidaProducto.idSalidaProducto ? 'PATCH' : 'POST',
                body: salidaProducto,
            }),
        }),
        eliminarSalidaProducto: build.mutation({
            query: (salidaProducto) => ({
                url: '',
                method: 'DELETE',
                body: salidaProducto,
            }),
        }),
    }),
});
export const {
    useObtenerSalidaProductoQuery,
    useObtenerSalidasProductosQuery,
    useLazyObtenerSalidasProductosQuery,
    useGuardarSalidaProductoMutation,
    useEliminarSalidaProductoMutation
} = salidasProductosApi;
