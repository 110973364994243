import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { useGuardarTurnoMutation, useObtenerTurnoQuery } from '../../../services/turno.service';

const TurnosForm = ({ show, onHide, onSave }) => {

    const handleClose = (e) => {
        onHide(e);
    };

    const { control, handleSubmit, setValue, register, reset, formState: { errors } } = useForm();

    const { data: turno } = useObtenerTurnoQuery(show.idTurno, { skip: !show.idTurno, refetchOnMountOrArgChange: true });
    const [guardarTurno] = useGuardarTurnoMutation();

    useEffect(() => {
        if (turno) {
            reset({
                idTurno: turno.idTurno,
                turno: turno.turno,
                idEstatus: turno.idEstatus,
                clave: turno.clave,
                fechaVersion: turno.fechaVersion
            });
        } else {
            //default values
            reset({ idEstatus: 1 })
        }
    }, [turno, reset]);

    const onSubmit = async (formData) => {
        let { data } = await guardarTurno(formData);
        if (data) {
            setValue('idTurno', data.id)
            setValue('fechaVersion', data.fechaVersion)
            onSave();
        }
    };

    return ( <> {
        < Modal show={show} onHide={handleClose} centered backdrop="static" keyboard={false} >
            <Form onSubmit={handleSubmit(onSubmit)} >
                <Modal.Header closeButton>
                    <Modal.Title>{show.idTurno ? 'Editar' : 'Nuevo'} [Turno]</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="text" id="idTurno" className='d-none' {...register("idTurno")} />
                    <Form.Control type='text' id='clave' className='d-none' {...register('clave')} />
                    <Form.Control type="text" id='fechaVersion' className='d-none' {...register("fechaVersion")} />
                    <Form.Group className='mb-3'>
                    <Form.Label htmlFor='turno' className='fw-bold'>
                            Clave
                        </Form.Label>
                        <Form.Control
                            {...register('clave', {
                                required: 'Ingrese un dato valido',
                                maxLength: { value: 1, message: 'Ha excedido el máximo de caracteres' }
                            })} />
                        <Form.Label htmlFor='turno' className='fw-bold'>
                            Turno
                        </Form.Label>
                        <Form.Control
                            {...register('turno', {
                                required: 'Ingrese un dato valido',
                                maxLength: { value: 30, message: 'Ha excedido el máximo de caracteres' }
                            })} />
                        {errors.turno && (<Alert variant='danger' role='alert'>
                            {errors.turno.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className='mb-3'>
                        <Controller
                            name='idEstatus'
                            control={control}
                            render={({ field }) => (<>
                                <Form.Label htmlFor='idEstatus' > Estatus </Form.Label>
                                <Form.Check
                                    id='idEstatus'
                                    type='switch'
                                    label={Boolean(field.value) ? 'Activo' : 'Inactivo'}
                                    checked={Boolean(field.value)}
                                    onChange={(e) => {
                                        field.onChange(Number(e.target.checked))
                                    }}
                                />
                            </>
                            )}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button type='submit' variant='primary' className='me-2'>
                        <i className='bi bi-save'></i> Guardar
                    </Button>
                    <Button variant='secondary' onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>}
    </>);
};

export default TurnosForm;