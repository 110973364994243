import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Alert, Button, Form, Card, Col, Row } from "react-bootstrap";
import {
  useGuardarEmpresaMutation,
  useObtenerEmpresaQuery,
} from "../../../services/empresa.service";
import { useObtenerRegimenesFiscalesQuery } from "../../../services/regimenFiscal.service";
import { useObtenerTiposPersonasByBaseQuery } from "../../../services/tipoPersona.service";
import { useObtenerPrioridadesQuery } from "../../../services/prioridades.service";
import { useObtenerMunicipiosQuery } from "../../../services/municipio.service";
import { useObtenerPaisesQuery } from "../../../services/pais.service";
import { useObtenerEntidadesFederativasQuery } from "../../../services/entidadFederativa.service";
import { useSelector } from "react-redux";
import InputFile from "../../../components/base/InputFile";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

//import { useObtenerEntidadFederativaQuery } from "../../../services/entidadFederativa.service";

const EmpresasVista = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const {
    handleSubmit,
    setValue,
    register,
    reset,
    control,
    watch,
    formState: { errors },
  } = useForm();

  const { data: regimenesFiscales } = useObtenerRegimenesFiscalesQuery();
  const { data: tiposPersonas } = useObtenerTiposPersonasByBaseQuery(1);
  const { data: prioridades } = useObtenerPrioridadesQuery();
  const { data: municipios } = useObtenerMunicipiosQuery();
  const { data: entidadesFederativas } = useObtenerEntidadesFederativasQuery();
  const { data: paises } = useObtenerPaisesQuery();

  const { id: idEmpresa } = useParams();

  const { data: empresa, refetch: refetchEmpresa } = useObtenerEmpresaQuery(
    idEmpresa,
    { skip: !idEmpresa, refetchOnMountOrArgChange: true }
  );

  const [guardarEmpresa] = useGuardarEmpresaMutation();
  const refetchAll = () => {
    refetchEmpresa();
  };

  useEffect(() => {
    if (empresa && regimenesFiscales && municipios) {
      reset({
        idEmpresa: empresa.idEmpresa,
        empresa: empresa.empresa,
        idRegimenFiscal: empresa.idRegimenFiscal,
        idTipoPersona: empresa.idTipoPersona,
        idMunicipio: empresa.idMunicipio,
        idPrioridad: empresa.idPrioridad,
        idEstatus: empresa.idEstatus,
        rfc: empresa.rfc,
        calle: empresa.calle,
        numeroExterior: empresa.numeroExterior,
        numeroInterior: empresa.numeroInterior,
        colonia: empresa.colonia,
        codigoPostal: empresa.codigoPostal,
        codigoPostalFiscal: empresa.codigoPostalFiscal,
        referenciaUbicacion: empresa.referenciaUbicacion,
        telefonoCorporativo: empresa.telefonoCorporativo,
        correoElectronico: empresa.correoElectronico,
        representanteLegal: empresa.representanteLegal,
        paginaWeb: empresa.paginaWeb,
        fechaVersion: empresa.fechaVersion,
        idPais: empresa.municipio.entidadFederativa.idPais,
        idEntidadFederativa:
          empresa.municipio.entidadFederativa.idEntidadFederativa,
      });
    } else {
      //default values
      reset({
        idEstatus: 1,
      });
    }
  }, [empresa, regimenesFiscales, tiposPersonas, municipios, reset]);

  /*}
          //cascada
          useEffect(() => {
              if (municipios?.data) {
                  if (empresa) {
                      setValue('idEntidadFederativa', empresa.idEntidadFederativa);
                  } else {
                      console.log("Listo")
                  }
          
              }
          }, [entidadFederativa, municipios, setValue]); */

  const onSubmit = async (formData) => {
    let data;
    formData.idEmpresa = parseInt(idEmpresa);
    let result = await guardarEmpresa(formData);
    data = result.data;
  };

  return (
    <>
      <main id="main" className="main">
        <section className="section">
          <Row>
            <Col lg={12}>
              <Card>
                <Card.Body>
                  <fieldset>
                    <legend className="d-flex flex-column flex-md-row justify-content-between align-items-right">
                      <div className="fw-bold">
                        <p className="fs-5 mb-0"> Empresas </p>
                      </div>

                      <div>
                        <p className="fs-6 mb-0">
                          *Los campos en negritas son requeridos{" "}
                        </p>
                      </div>
                    </legend>
                  </fieldset>

                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Control
                      type="text"
                      id="idEmpresa"
                      className="d-none"
                      {...register("idEmpresa")}
                    />

                    <Card>
                      <div className="container">
                        <div className="row">
                          <div className="col-md-3">
                            <div className="fw-bold">
                              <p className="fs-3"> Información General </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="container">
                        <div className="row">
                          <div className="col-md-3">
                            <Form.Group className="mb-3">
                              <Form.Label htmlFor="empresa" className="fw-bold">
                                Empresa
                              </Form.Label>
                              <Form.Control
                                {...register("empresa", {
                                  required: "Ingrese un dato válido",
                                  maxLength: {
                                    value: 200,
                                    message:
                                      "Ha excedido el máximo de caracteres",
                                  },
                                })}
                              />
                              {errors.empresa && (
                                <Alert variant="danger" role="alert">
                                  {errors.empresa.message}
                                </Alert>
                              )}
                            </Form.Group>
                          </div>

                          <div className="col-md-3">
                            <Form.Group className="mb-3">
                              <Col md={12}>
                                <Form.Label
                                  htmlFor="idRegimenFiscal"
                                  className="fw-bold"
                                >
                                  Régimen fiscal
                                </Form.Label>
                                <Form.Select
                                  className="mwx-400"
                                  id="idRegimenFiscal"
                                  {...register("idRegimenFiscal", {
                                    required: "Ingrese un dato válido",
                                  })}
                                >
                                  <option value="">Seleccionar</option>
                                  {regimenesFiscales &&
                                    regimenesFiscales.data.map(
                                      (regimenFiscal) => (
                                        <option
                                          key={regimenFiscal.idRegimenFiscal}
                                          value={regimenFiscal.idRegimenFiscal}
                                        >
                                          {regimenFiscal.regimenFiscal}
                                        </option>
                                      )
                                    )}
                                </Form.Select>
                                {errors.idRegimenFiscal && (
                                  <Alert variant="danger" className="mwx-400">
                                    {errors.idRegimenFiscal.message}
                                  </Alert>
                                )}
                              </Col>
                            </Form.Group>
                          </div>

                          <div className="col-md-3">
                            <Form.Group className="mb-3">
                              <Col md={12}>
                                <Form.Label
                                  htmlFor="idTipoPersona"
                                  className="fw-bold"
                                >
                                  Tipo de persona
                                </Form.Label>
                                <Form.Select
                                  className="mwx-400"
                                  id="idTipoPersona"
                                  {...register("idTipoPersona", {
                                    required: "Ingrese un dato válido",
                                  })}
                                >
                                  <option value="">Seleccionar</option>
                                  {tiposPersonas &&
                                    tiposPersonas.map((tipoPersona) => (
                                      <option
                                        key={tipoPersona.idTipoPersona}
                                        value={tipoPersona.idTipoPersona}
                                      >
                                        {tipoPersona.tipoPersona}
                                      </option>
                                    ))}
                                </Form.Select>
                                {errors.idTipoPersona && (
                                  <Alert variant="danger" className="mwx-400">
                                    {errors.idTipoPersona.message}
                                  </Alert>
                                )}
                              </Col>
                            </Form.Group>
                          </div>

                          <div className="col-md-3">
                            <Form.Group className="mb-3">
                              <Form.Label htmlFor="rfc" className="fw-bold">
                                RFC
                              </Form.Label>
                              <Form.Control
                                {...register("rfc", {
                                  required: "Ingrese un dato valido",
                                  maxLength: {
                                    value: 13,
                                    message:
                                      "Ha excedido el máximo de caracteres",
                                  },
                                  pattern: {
                                    value:
                                      /^([A-Z&Ñ]{3}|[A-Z&Ñ]{4})\d{6}[A-Z0-9]{3}$/, //Valida el formato de un persona física o moral
                                    message: "Formato de RFC inválido.",
                                  },
                                })}
                              />
                              {errors.rfc && (
                                <Alert variant="danger" role="alert">
                                  {errors.rfc.message}
                                </Alert>
                              )}
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    </Card>

                    <Card>
                      <div className="container">
                        <div className="row">
                          <div className="col-md-3">
                            <div className="fw-bold">
                              <p className="fs-3">Ubicación</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="container">
                        <div className="row">
                          <div className="col-md-3">
                            <Row className="mb-3">
                              <Col md={12}>
                                <Form.Label
                                  htmlFor="idPais"
                                  className="fw-bold"
                                >
                                  País
                                </Form.Label>
                                <Form.Select
                                  className="mwx-400"
                                  id="idPais"
                                  {...register("idPais", {
                                    required: "Ingrese un dato válido",
                                  })}
                                >
                                  <option value="">Seleccionar</option>
                                  {paises &&
                                    paises.data.map((pais) => (
                                      <option
                                        key={pais.idPais}
                                        value={pais.idPais}
                                      >
                                        {pais.pais}
                                      </option>
                                    ))}
                                </Form.Select>
                                {errors.idPais && (
                                  <Alert variant="danger" className="mwx-400">
                                    {errors.idPais.message}
                                  </Alert>
                                )}
                              </Col>
                            </Row>
                          </div>

                          <div className="col-md-3">
                            <Row className="mb-3">
                              <Col md={12}>
                                <Form.Label
                                  htmlFor="idEntidadFederativa"
                                  className="fw-bold"
                                >
                                  Estado
                                </Form.Label>
                                <Form.Select
                                  className="mwx-400"
                                  id="idEntidadFederativa"
                                  {...register("idEntidadFederativa", {
                                    required: "Ingrese un dato válido",
                                  })}
                                >
                                  <option value="">Seleccionar</option>
                                  {entidadesFederativas &&
                                    entidadesFederativas.data.map(
                                      (entidadFederativa) => (
                                        <option
                                          key={
                                            entidadFederativa.idEntidadFederativa
                                          }
                                          value={
                                            entidadFederativa.idEntidadFederativa
                                          }
                                        >
                                          {entidadFederativa.entidadFederativa}
                                        </option>
                                      )
                                    )}
                                </Form.Select>
                                {errors.idEntidadFederativa && (
                                  <Alert variant="danger" className="mwx-400">
                                    {errors.idEntidadFederativa.message}
                                  </Alert>
                                )}
                              </Col>
                            </Row>
                          </div>

                          <div className="col-md-3">
                            <Row className="mb-3">
                              <Col md={12}>
                                <Form.Label
                                  htmlFor="idMunicipio"
                                  className="fw-bold"
                                >
                                  Municipio
                                </Form.Label>
                                <Form.Select
                                  className="mwx-400"
                                  id="idMunicipio"
                                  {...register("idMunicipio", {
                                    required: "Ingrese un dato válido",
                                  })}
                                >
                                  <option value="">Seleccionar</option>
                                  {municipios &&
                                    municipios.data.map((municipio) => (
                                      <option
                                        key={municipio.idMunicipio}
                                        value={municipio.idMunicipio}
                                      >
                                        {municipio.municipio}
                                      </option>
                                    ))}
                                </Form.Select>
                                {errors.idMunicipio && (
                                  <Alert variant="danger" className="mwx-400">
                                    {errors.idMunicipio.message}
                                  </Alert>
                                )}
                              </Col>
                            </Row>
                          </div>

                          <div className="col-md-3">
                            <Form.Group className="mb-3">
                              <Form.Label htmlFor="calle" className="fw-bold">
                                Calle
                              </Form.Label>
                              <Form.Control
                                {...register("calle", {
                                  required: "Ingrese un dato valido",
                                  maxLength: {
                                    value: 100,
                                    message:
                                      "Ha excedido el máximo de caracteres",
                                  },
                                })}
                              />

                              {errors.calle && (
                                <Alert variant="danger" role="alert">
                                  {errors.calle.message}
                                </Alert>
                              )}
                            </Form.Group>
                          </div>

                          <div className="col-md-3">
                            <Form.Group className="mb-3">
                              <Form.Label
                                htmlFor="numeroExterior"
                                className="fw-bold"
                              >
                                Número Exterior
                              </Form.Label>
                              <Form.Control
                                {...register("numeroExterior", {
                                  required: "Ingrese un dato valido",
                                  maxLength: {
                                    value: 10,
                                    message:
                                      "Ha excedido el máximo de caracteres",
                                  },
                                })}
                              />
                              {errors.numeroExterior && (
                                <Alert variant="danger" role="alert">
                                  {errors.numeroExterior.message}
                                </Alert>
                              )}
                            </Form.Group>
                          </div>

                          <div className="col-md-3">
                            <Form.Group className="mb-3">
                              <Form.Label htmlFor="numeroInterior">
                                Número Interior
                              </Form.Label>
                              <Form.Control
                                className="mwx-350"
                                {...register("numeroInterior", {
                                  maxLength: {
                                    value: 10,
                                    message:
                                      "Ha excedido el máximo de caracteres",
                                  },
                                })}
                              />
                              {errors.numeroInterior && (
                                <Alert variant="danger" role="alert">
                                  {errors.numeroInterior.message}
                                </Alert>
                              )}
                            </Form.Group>
                          </div>

                          <div className="col-md-3">
                            <Form.Group className="mb-3">
                              <Form.Label htmlFor="colonia" className="fw-bold">
                                Colonia
                              </Form.Label>
                              <Form.Control
                                {...register("colonia", {
                                  required: "Ingrese un dato valido",
                                  maxLength: {
                                    value: 100,
                                    message:
                                      "Ha excedido el máximo de caracteres",
                                  },
                                })}
                              />
                              {errors.colonia && (
                                <Alert variant="danger" role="alert">
                                  {errors.colonia.message}
                                </Alert>
                              )}
                            </Form.Group>
                          </div>

                          <div className="col-md-3">
                            <Form.Group className="mb-3">
                              <Form.Label
                                htmlFor="referenciaUbicacion"
                                className="fw-bold"
                              >
                                Referencia de ubicación
                              </Form.Label>
                              <Form.Control
                                {...register("referenciaUbicacion", {
                                  required: "Ingrese un dato valido",
                                  maxLength: {
                                    value: 255,
                                    message:
                                      "Ha excedido el máximo de caracteres",
                                  },
                                })}
                              />
                              {errors.referenciaUbicacion && (
                                <Alert variant="danger" role="alert">
                                  {errors.referenciaUbicacion.message}
                                </Alert>
                              )}
                            </Form.Group>
                          </div>

                          <div className="col-md-3">
                            <Form.Group className="mb-3">
                              <Form.Label htmlFor="codigoPostal">
                                Código postal
                              </Form.Label>
                              <Form.Control
                                {...register("codigoPostal", {
                                  maxLength: {
                                    value: 6,
                                    message:
                                      "Ha excedido el máximo de caracteres",
                                  },
                                  pattern: {
                                    value: /^\d+$/, // Asegura que solo se introduzcan números.
                                    message: "Solo se permiten números.",
                                  },
                                })}
                              />
                              {errors.codigoPostal && (
                                <Alert variant="danger" role="alert">
                                  {errors.codigoPostal.message}
                                </Alert>
                              )}
                            </Form.Group>
                          </div>

                          <div className="col-md-3">
                            <Form.Group className="mb-3">
                              <Form.Label
                                htmlFor="codigoPostalFiscal"
                                className="fw-bold"
                              >
                                Código postal fiscal
                              </Form.Label>
                              <Form.Control
                                {...register("codigoPostalFiscal", {
                                  required: "Ingrese un dato valido",
                                  maxLength: {
                                    value: 6,
                                    message:
                                      "Ha excedido el máximo de caracteres",
                                  },
                                  pattern: {
                                    value: /^\d+$/, // Asegura que solo se introduzcan números.
                                    message: "Solo se permiten números.",
                                  },
                                })}
                              />
                              {errors.codigoPostalFiscal && (
                                <Alert variant="danger" role="alert">
                                  {errors.codigoPostalFiscal.message}
                                </Alert>
                              )}
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    </Card>

                    <Card>
                      <div className="container">
                        <div className="row">
                          <div className="col-md-3">
                            <div className="fw-bold">
                              <p className="fs-3">Información adicional</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="container">
                        <div className="row">
                          <div className="col-md-3">
                            <Form.Group className="mb-3">
                              <Form.Label
                                htmlFor="telefonoCorporativo"
                                className="fw-bold"
                              >
                                Teléfono del corporativo
                              </Form.Label>
                              <Form.Control
                                {...register("telefonoCorporativo", {
                                  required: "Ingrese un dato valido",
                                  maxLength: {
                                    value: 10,
                                    message:
                                      "Ha excedido el máximo de caracteres",
                                  },
                                  pattern: {
                                    value: /^\d+$/, // Asegura que solo se introduzcan números.
                                    message: "Solo se permiten números.",
                                  },
                                })}
                              />

                              {errors.telefonoCorporativo && (
                                <Alert variant="danger" role="alert">
                                  {errors.telefonoCorporativo.message}
                                </Alert>
                              )}
                            </Form.Group>
                          </div>

                          <div className="col-md-3">
                            <Form.Group className="mb-3">
                              <Form.Label
                                htmlFor="correoElectronico"
                                className="fw-bold"
                              >
                                Correo electrónico
                              </Form.Label>
                              <Form.Control
                                type="email"
                                placeholder="name@example.com"
                                {...register("correoElectronico", {
                                  required: "Ingrese un dato valido",
                                  maxLength: {
                                    value: 50,
                                    message:
                                      "Ha excedido el máximo de caracteres",
                                  },
                                })}
                              />
                              {errors.correoElectronico && (
                                <Alert variant="danger" role="alert">
                                  {errors.correoElectronico.message}
                                </Alert>
                              )}
                            </Form.Group>
                          </div>

                          <div className="col-md-3">
                            <Form.Group className="mb-3">
                              <Form.Label
                                htmlFor="representanteLegal"
                                className="fw-bold"
                              >
                                Representante Legal / Contacto
                              </Form.Label>
                              <Form.Control
                                {...register("representanteLegal", {
                                  required: "Ingrese un dato valido",
                                  maxLength: {
                                    value: 50,
                                    message:
                                      "Ha excedido el máximo de caracteres",
                                  },
                                })}
                              />
                              {errors.representanteLegal && (
                                <Alert variant="danger" role="alert">
                                  {errors.representanteLegal.message}
                                </Alert>
                              )}
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    </Card>

                    <Card>
                      <div className="container">
                        <div className="row">
                          <div className="col-md-3">
                            <div className="fw-bold">
                              <p className="fs-3">Contacto corporativo</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="container">
                        <div className="row">
                          <div className="col-md-3">
                            <Form.Group className="mb-3">
                              {" "}
                              <Form.Label htmlFor="paginaWeb">
                                Página Web
                              </Form.Label>
                              <Form.Control
                                placeholder="https://"
                                {...register("paginaWeb", {
                                  maxLength: {
                                    value: 100,
                                    message:
                                      "Ha excedido el máximo de caracteres",
                                  },
                                  pattern: {
                                    value: /^(ftp|http|https):\/\/[^ "]+$/, // Valida el formato de página web
                                    message:
                                      "Formato de dirección de página web inválido.",
                                  },
                                })}
                              />
                              {errors.paginaWeb && (
                                <Alert variant="danger" role="alert">
                                  {errors.paginaWeb.message}
                                </Alert>
                              )}
                            </Form.Group>
                          </div>

                          <div className="col-md-3">
                            <Row className="mb-3">
                              <Col md={12}>
                                <Form.Label htmlFor="idPrioridad">
                                  Prioridad
                                </Form.Label>
                                <Form.Select
                                  className="mwx-400"
                                  id="idPrioridad"
                                  {...register("idPrioridad", {})}
                                >
                                  <option value="">Seleccionar</option>
                                  {prioridades &&
                                    prioridades.data.map((prioridad) => (
                                      <option
                                        key={prioridad.idPrioridad}
                                        value={prioridad.idPrioridad}
                                      >
                                        {prioridad.prioridad}
                                      </option>
                                    ))}
                                </Form.Select>
                                {errors.idPrioridad && (
                                  <Alert variant="danger" className="mwx-400">
                                    {errors.idPrioridad.message}
                                  </Alert>
                                )}
                              </Col>
                            </Row>
                          </div>

                          <div className="col-md-3">
                            <Form.Label htmlFor="idArchivo" className="fw-bold">
                              Subir Logo
                            </Form.Label>

                            <InputFile
                              name={`idArchivo`}
                              // TODO: poner variable empresa.archivo.archivo para ver el documento al editar
                              archivo={empresa?.archivo?.archivo ?? ""}
                              ruta={"logos"}
                              accept=".jpg, .jpeg, .png"
                              maxLength={4}
                              required={"Ingrese un logo"}
                              register={register}
                              setValue={setValue}
                              notMargin={true}
                            />
                            {errors.idArchivo && (
                              <Alert variant="danger" className="mwx-400">
                                {errors.idArchivo.message}
                              </Alert>
                            )}
                          </div>

                          <div className="col-md-3">
                            <Form.Group className="mb-3">
                              <Controller
                                name="idEstatus"
                                control={control}
                                render={({ field }) => (
                                  <>
                                    <Form.Label htmlFor="idEstatus">
                                      Estatus
                                    </Form.Label>
                                    <Form.Check
                                      id="idEstatus"
                                      type="switch"
                                      label={
                                        Boolean(field.value)
                                          ? "Activo"
                                          : "Inactivo"
                                      }
                                      checked={Boolean(field.value)}
                                      onChange={(e) => {
                                        field.onChange(
                                          Number(e.target.checked)
                                        );
                                      }}
                                    />
                                  </>
                                )}
                              />
                            </Form.Group>
                            {errors.idEstatus && (
                              <Alert variant="danger" role="alert">
                                {errors.idEstatus.message}
                              </Alert>
                            )}
                          </div>
                        </div>
                      </div>
                    </Card>

                    <Button
                      type="submit"
                      variant="primary"
                      className="me-2"
                      size="md"
                    >
                      <i className="bi bi-save"></i> Guardar
                    </Button>
                    <Link
                      to={{
                        pathname: "/administracion/empresas",
                        state: { empresa: empresa },
                      }}
                      className="col-12 text-end"
                    >
                      <Button variant="secondary" className="me-2" size="md">
                        <i className="bi bi-box-arrow-in-left"></i> Regresar
                      </Button>
                    </Link>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </section>
      </main>
    </>
  );
};

export default EmpresasVista;
