import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const tiposDocumentosApi = createApi({
    reducerPath: 'tiposDocumentosApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "tipos/documentos",
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),




    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        
        obtenerTipoDocumento: build.query({
            query: (idTipoDocumento) => `/${idTipoDocumento}`,
        }),
        obtenerTiposDocumentos: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        guardarTipoDocumento: build.mutation({
            query: (tipoDocumento) => ({
                url: '',
                method: tipoDocumento.idTipoDocumento ? 'PUT' : 'POST',
                body: tipoDocumento,
            }),
        }),
        eliminarTipoDocumento: build.mutation({
            query: (tipoDocumento) => ({
                url: '',
                method: 'DELETE',
                body: tipoDocumento,
            }),
        }),
    }),
});

export const {   
               useObtenerTipoDocumentoQuery,
               useObtenerTiposDocumentosQuery,
               useLazyObtenerTiposDocumentosQuery,               
               useGuardarTipoDocumentoMutation,
               useEliminarTipoDocumentoMutation
} = tiposDocumentosApi;
