import React from "react";
import './FAB.css';
import {Link} from "react-router-dom";

const FAB = ({ handleClose, handleShow, show }) => {
    return (
        <>
            <div className="fab-container">
                <div className="fab shadow">
                    <div className="fab-content">
                        <i className="bi bi-headset"></i>
                    </div>
                </div>
                <div className="sub-button shadow">
                    <Link to="tel://9931141004">
                        <i className="bi bi-telephone-fill"></i>
                    </Link>
                </div>
                <div className="sub-button shadow">
                    <Link to="mailto:contacto@insigniait.com.mx">
                        <i className="bi bi-envelope"></i>
                    </Link>
                </div>
                <div className="sub-button shadow">
                    <Link onClick={handleShow} to={null}>
                        <i className="bi bi-question-diamond"></i>
                    </Link>
                </div>
            </div>
        </>
    );
}

export default FAB;