import React from "react";
import { Link, NavLink } from "react-router-dom";
import accesoRestringido from '../img/restricted-access.webp';

const Unauthorized = () => {
  return (
    <>
      <main>
        <div className="container">

          <section className="section error-404 min-vh-100 d-flex flex-column align-items-center justify-content-center">
            <h1>Error</h1>
            <h2>No está autorizado para ver esta página.</h2>
            <p>Si usted tiene usuario y contraseña puede acceder en{" "}</p>
            <NavLink className="btn" to="./">Acceder al sistema</NavLink>
            <img src={accesoRestringido} className="img-fluid py-5" alt="Acceso restringido"/>            
          </section>

          </div>
      </main>
      <Link className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></Link>
    </>
  );
};

export default Unauthorized;
