import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const tiposMantenimientosApi = createApi({
    reducerPath: 'tiposMantenimientosApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "tipos/mantenimientos",
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),




    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        
        obtenerTipoMantenimiento: build.query({
            query: (idTipoMantenimiento) => `/${idTipoMantenimiento}`,
        }),
        obtenerTiposMantenimientos: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        guardarTipoMantenimiento: build.mutation({
            query: (tipoMantenimiento) => ({
                url: '',
                method: tipoMantenimiento.idTipoMantenimiento ? 'PUT' : 'POST',
                body: tipoMantenimiento,
            }),
        }),
        eliminarTipoMantenimiento: build.mutation({
            query: (tipoMantenimiento) => ({
                url: '',
                method: 'DELETE',
                body: tipoMantenimiento,
            }),
        }),
    }),
});

export const {   
               useObtenerTipoMantenimientoQuery,
               useObtenerTiposMantenimientosQuery,
               useLazyObtenerTiposMantenimientosQuery,               
               useGuardarTipoMantenimientoMutation,
               useEliminarTipoMantenimientoMutation
} = tiposMantenimientosApi;
