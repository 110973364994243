import React from "react";

const Copyright = (props) => {
    return(
        <footer id="footer" className="footer fixed-bottom" {...props}>
        <div className="copyright">
          &copy; Copyright <strong><span>MORSA by VSMéxico</span></strong>. All Rights Reserved
        </div>
        
      </footer>
        
    );
}

export default Copyright;