import {
    useObtenerUnidadesMedidasQuery,
    useEliminarUnidadMedidaMutation,
    useLazyObtenerUnidadesMedidasQuery
} from '../../../services/unidadesMedidas.service';
import React, { useState } from "react";
import { Button, Row, Col, Card } from "react-bootstrap";
import SeverPagination from "../../../components/base/SeverPagination";
import UnidadesMedidasForm from './UnidadesMedidasForm';
import BootstrapTable from "../../../components/base/BootstrapTable";
import ConfirmDialog from "../../../components/base/ConfirmDialog";
import { ExportToExcel } from "../../../components/base/ExportToExcel";

const UnidadesMedidas = () => {
    const [pageNumber, setPageNumber] = useState(1);
    const [elementsPerPage, setElementsPerPage] = useState(10);

    const [formUnidadesMedidas, setFormUnidadesMedidas] = useState(null);
    const [confirmDialog, setConfirmDialog] = useState({});

    const {
        data: unidadesMedidas, refetch
    } = useObtenerUnidadesMedidasQuery([pageNumber, elementsPerPage], { refetchOnMountOrArgChange: true })
    const [trigger] = useLazyObtenerUnidadesMedidasQuery()

    const [eliminarUnidadMedida] = useEliminarUnidadMedidaMutation()

    return (<div id="main" className="main">
        <section className="section">
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Body>
                            <div className="btn-toolbar justify-content-between" role="toolbar">
                                <div className="pagetitle">
                                    <h1>Unidades Medidas</h1>
                                </div>
                            </div>
                            {unidadesMedidas &&
                                <BootstrapTable striped
                                    toolbar={({ columns }) => (
                                        <div>
                                            <Button href="#" className="me-2" variant="primary" onClick={(e) => {
                                                setFormUnidadesMedidas({})
                                            }}>
                                                <i className="bi bi-plus-square"></i>&nbsp;Nuevo
                                            </Button>
                                            <ExportToExcel columns={columns} onClick={trigger} fileName={'unidadesMedidas'} />
                                        </div>
                                    )}
                                    data = {unidadesMedidas.data}
                                    columns={[{
                                        header: 'Unidad Medida', accessorKey: 'unidadMedida'
                                    }, {
                                        header: 'Estatus', accessorKey: 'estatus.estatus', enableGlobalFilter: false
                                    }, {
                                        header: 'Acciones',
                                        enableGlobalFilter: false,
                                        cell: ({ row: { original } }) => (
                                            <div className="w-100 d-grid d-sm-block gap-1">
                                                <Button variant="warning" className="me-2" size="sm"
                                                    onClick={e => setFormUnidadesMedidas(original) }>
                                                    <i className="bi bi-pencil-square"> </i>
                                                    Editar
                                                </Button>
                                                <Button variant="danger" className="me-2" size="sm" onClick={e => setConfirmDialog({
                                                    message: '¿Desea eliminar este registro?', onConfirm: async () => {
                                                        await eliminarUnidadMedida({ idUnidadMedida: original.idUnidadMedida });
                                                        refetch();
                                                        setConfirmDialog({});

                                                    }
                                                })}>
                                                    <i className="bi bi-trash"> </i>
                                                    Eliminar
                                                </Button>
                                            </div>)
                                    }]}
                                    onGlobalFilterChange={(e => {
                                        setPageNumber(1);
                                        setElementsPerPage(e ? -1 : 10);
                                    })}
                                />}
                                {unidadesMedidas && <SeverPagination
                                    data={unidadesMedidas.data}
                                    currentPage={pageNumber}
                                    totalElements={unidadesMedidas.totalElements}
                                    elementsPerPage={elementsPerPage}
                                    onPageChange={setPageNumber}
                                    onElementsPerPageChange={setElementsPerPage}
                                /> }
                        </Card.Body>
                        <ConfirmDialog {...confirmDialog} onCancel={() => setConfirmDialog({})}/>
                    </Card>
                </Col>
            </Row>
        </section>
        {formUnidadesMedidas && <UnidadesMedidasForm show={formUnidadesMedidas} onHide={(e) => {
            setFormUnidadesMedidas(false)
        }} onSave={refetch}/>}
    </div> )
}

export default UnidadesMedidas