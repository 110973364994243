
import React, {useState} from "react";
import {Button, Row, Col, Card} from "react-bootstrap";
import SeverPagination from "../../../components/base/SeverPagination";
import BootstrapTable from "../../../components/base/BootstrapTable";
import ConfirmDialog from "../../../components/base/ConfirmDialog";
import { ExportToExcel } from "../../../components/base/ExportToExcel";
import ConfiguracionDocumentoModal from "./ConfiguracionDocumentoModal";
import { useEliminarConfiguracionDocumentoMutation, useLazyObtenerConfiguracionesDocumentosQuery, useObtenerConfiguracionesDocumentosQuery } from "../../../services/configuracionDocumento.service";

const ConfiguracionDocumentoForm = () => {
    const [pageNumber, setPageNumber] = useState(1);
    const [elementsPerPage, setElementsPerPage] = useState(10);

    const [formConfiguracionesDocumentos, setFormConfiguracionesDocumentos] = useState(null);
    const [confirmDialog, setConfirmDialog] = useState({});

    const {
        data: configuracionesDocumentos, refetch
    } = useObtenerConfiguracionesDocumentosQuery([pageNumber, elementsPerPage], {refetchOnMountOrArgChange: true});
    const [ trigger ] = useLazyObtenerConfiguracionesDocumentosQuery();

    const [eliminarConfiguracionDocumento] = useEliminarConfiguracionDocumentoMutation();

    return (<div id="main" className="main">
        <section className="section">
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Body>
                            <div className="btn-toolbar justify-content-between" role="toolbar">
                                <div className="pagetitle">
                                    <h1>Documentacion de empresas</h1>
                                </div>
                            </div>
                            {configuracionesDocumentos &&
                                <BootstrapTable striped
                                    toolbar={({columns}) => (
                                        <div>
                                            <Button href="#" className="me-2" variant="primary" onClick={(e) => {
                                                setFormConfiguracionesDocumentos({})
                                            }}>
                                                <i className="bi bi-plus-square"></i>&nbsp;Nuevo
                                            </Button>
                                            <ExportToExcel columns={columns} onClick={trigger} fileName={'Documentacion empresas'}/>
                                        </div>
                                    )}
                                    data={configuracionesDocumentos.data}
                                    columns={[{
                                        header: 'Nombre del documento', accessorKey: 'tipoDocumento.tipoDocumento'
                                    }, {
                                        header: 'Tipo de persona', accessorKey: 'tipoPersona.tipoPersona', enableGlobalFilter: false
                                    }, {
                                        header: 'Obligatorio',
                                        accessorKey: 'obligatorio',
                                        enableGlobalFilter: false,
                                        cell: info => info?.getValue() == 0 ? "No" : "Si",
                                    }, 
                                    {
                                        header: 'Estatus',
                                        accessorKey: 'estatus.estatus',
                                        enableGlobalFilter: false,
                                        cell: info => info?.getValue().split(' ')[0],
                                    },{
                                        header: 'Acciones',
                                        enableGlobalFilter: false,
                                        cell: ({row: {original}}) => (
                                        <div className="w-100 d-grid d-sm-block gap-1">
                                            <Button variant="warning" className="me-2" size="sm"
                                                    onClick={e => setFormConfiguracionesDocumentos(original)}>
                                                <i className="bi bi-pencil-square"> </i>
                                                Editar
                                            </Button>
                                            <Button variant="danger" className="me-2" size="sm" onClick={e => setConfirmDialog({
                                                message: '¿Desea eliminar este registro?', onConfirm: async () => {
                                                    await eliminarConfiguracionDocumento({idConfiguracionDocumento: original.idConfiguracionDocumento});
                                                    refetch();
                                                    setConfirmDialog({});

                                                }
                                            })}>
                                                <i className="bi bi-trash"> </i>
                                                Eliminar
                                            </Button>
                                        </div>)
                                    }]}
                                    onGlobalFilterChange={(e => {
                                        setPageNumber(1);
                                        setElementsPerPage(e ? -1 : 10);
                                    })}
                                />}
                            {configuracionesDocumentos && <SeverPagination
                                data={configuracionesDocumentos.data}
                                currentPage={pageNumber}
                                totalElements={configuracionesDocumentos.totalElements}
                                elementsPerPage={elementsPerPage}
                                onPageChange={setPageNumber}
                                onElementsPerPageChange={setElementsPerPage}
                            />}
                        </Card.Body>

                        <ConfirmDialog {...confirmDialog} onCancel={() => setConfirmDialog({})}/>
                    </Card>
                </Col>
            </Row>
        </section>
        {formConfiguracionesDocumentos && <ConfiguracionDocumentoModal show={formConfiguracionesDocumentos} onHide={(e) => {
            setFormConfiguracionesDocumentos(false);
        }} onSave={refetch}/>}
    </div>);
};

export default ConfiguracionDocumentoForm;
