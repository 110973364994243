import {
    useObtenerConfiguracionesAlmacenesQuery,
    useLazyObtenerConfiguracionesAlmacenesQuery,
} from "../../../services/configuracionAlmacen.service";
import React, { useState } from "react";
import { Button, Row, Col, Card } from "react-bootstrap";
import SeverPagination from "../../../components/base/SeverPagination";
import ConfiguracionesAlmacenesForm from "./ConfiguracionesAlmacenesForm";
import BootstrapTable from "../../../components/base/BootstrapTable";
import ConfirmDialog from "../../../components/base/ConfirmDialog";
import { ExportToExcel } from "../../../components/base/ExportToExcel";

const ConfiguracionesAlmacenes = () => {
    const [pageNumber, setPageNumber] = useState(1);
    const [elementsPerPage, setElementsPerPage] = useState(10);

    const [formConfiguracionesAlmacenes, setFormConfiguracionesAlmacenes] =
        useState(null);
    const [confirmDialog, setConfirmDialog] = useState({});

    const { data: configuracionesAlmacenes, refetch } =
        useObtenerConfiguracionesAlmacenesQuery([pageNumber, elementsPerPage], {
            refetchOnMountOrArgChange: true,
        });
    const [trigger] = useLazyObtenerConfiguracionesAlmacenesQuery();

    return (
        <div id="main" className="main">
            <section className="section">
                <Row>
                    <Col lg={12}>
                        <Card>
                            <Card.Body>
                                <div
                                    className="btn-toolbar justify-content-between"
                                    role="toolbar"
                                >
                                    <div className="pagetitle">
                                        <h1>Configuraciones de almacenes</h1>
                                    </div>
                                </div>
                                {configuracionesAlmacenes && (
                                    <BootstrapTable
                                        striped
                                        toolbar={({ columns }) => (
                                            <div>
                                                <ExportToExcel
                                                    columns={columns}
                                                    onClick={trigger}
                                                    fileName={"configuracionesAlmacenes"}
                                                />
                                            </div>
                                        )}
                                        data={configuracionesAlmacenes.data}
                                        columns={[
                                            {
                                                header: "Tipo",
                                                accessorKey: "tipo",
                                            },
                                            {
                                                header: "Almacen",
                                                accessorKey: "almacen.almacen",
                                            },
                                            {
                                                header: "Sección",
                                                accessorKey: "seccionAlmacen.seccionAlmacen",
                                            },
                                            {
                                                header: "Origen de entrada",
                                                accessorKey: "origenEntrada.origenEntrada",
                                            },
                                            {
                                                header: "Impuesto",
                                                accessorKey: "impuesto.impuesto",
                                            },
                                            {
                                                header: "Estatus",
                                                accessorKey: "estatus.estatus",
                                                enableGlobalFilter: false,
                                            },
                                            {
                                                header: "Acciones",
                                                enableGlobalFilter: false,
                                                cell: ({ row: { original } }) => (
                                                    <div className="w-100 d-grid d-sm-block gap-1">
                                                        <Button
                                                            variant="warning"
                                                            className="me-2"
                                                            size="sm"
                                                            onClick={(e) =>
                                                                setFormConfiguracionesAlmacenes(original)
                                                            }
                                                        >
                                                            <i className="bi bi-pencil-square"> </i>
                                                            Editar
                                                        </Button>
                                                    </div>
                                                ),
                                            },
                                        ]}
                                        onGlobalFilterChange={(e) => {
                                            setPageNumber(1);
                                            setElementsPerPage(e ? -1 : 10);
                                        }}
                                    />
                                )}
                                {configuracionesAlmacenes && (
                                    <SeverPagination
                                        data={configuracionesAlmacenes.data}
                                        currentPage={pageNumber}
                                        totalElements={configuracionesAlmacenes.totalElements}
                                        elementsPerPage={elementsPerPage}
                                        onPageChange={setPageNumber}
                                        onElementsPerPageChange={setElementsPerPage}
                                    />
                                )}
                            </Card.Body>

                            <ConfirmDialog
                                {...confirmDialog}
                                onCancel={() => setConfirmDialog({})}
                            />
                        </Card>
                    </Col>
                </Row>
            </section>
            {formConfiguracionesAlmacenes && (
                <ConfiguracionesAlmacenesForm
                    show={formConfiguracionesAlmacenes}
                    onHide={(e) => {
                        setFormConfiguracionesAlmacenes(false);
                    }}
                    onSave={refetch}
                />
            )}
        </div>
    );
};

export default ConfiguracionesAlmacenes;
