import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const documentosApi = createApi({
    reducerPath: 'documentosApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "documentos",
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (build) => ({
        eliminarDocumento: build.mutation({
            query: (documento) => ({
                url: '',
                method: 'DELETE',
                body: documento,
            }),
        }),
    }),
});

export const {useEliminarDocumentoMutation } = documentosApi;
