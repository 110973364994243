import React from "react";
import {
    Alert,
    Button,
    Col,
    Form,
    Offcanvas,
    OffcanvasBody,
    OffcanvasHeader,
    OffcanvasTitle,
    Row,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { useGuardarAsuntoMutation } from "../services/soporte.service";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

const MesaDeAyuda = ({ handleClose, show, exportAsImage }) => {
    const { userInfo } = useSelector((state) => state.auth);
    const {
        formState: { errors },
        handleSubmit,
        register,
    } = useForm();

    const handleExportImage = async () => {
        await exportAsImage("captura");
    };

    const [guardarAsunto, { data, error, isLoading }] =
        useGuardarAsuntoMutation();

    const onSubmit = async (formData) => {
        try {
            formData.description = formData.description + " - " + userInfo.userName;
            const result = await guardarAsunto({ data: JSON.stringify(formData) });
            toast.success("el reporte se agregó correctamente");
            // console.log(result)
        } catch (error) {
            toast.error(error.message);
        }
        // console.log(formData);
    };
    return (
        <Offcanvas show={show} onHide={handleClose} placement={"end"}>
            <OffcanvasHeader closeButton>
                <OffcanvasTitle>Levanta un reporte</OffcanvasTitle>
            </OffcanvasHeader>
            <OffcanvasBody>
                {userInfo ? (
                    <>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Button
                                variant="primary"
                                onClick={handleExportImage}
                                className={"mb-3"}
                            >
                                <i className="bi bi-camera"></i>
                                Capturar Pantalla
                            </Button>
                            <Form.Control
                                type={"text"}
                                className={"d-none"}
                                id={"tracker_id"}
                                value={3}
                                {...register("tracker_id")}
                            />
                            <Form.Control
                                type={"text"}
                                className={"d-none"}
                                id={"priority_id"}
                                value={2}
                                {...register("priority_id")}
                            />
                            <Form.Control
                                type={"text"}
                                className={"d-none"}
                                id={"project_id"}
                                value={3}
                                {...register("project_id")}
                            />
                            <Row className={"mb-3"}>
                                <Col sm={"12"}>
                                    <Form.Label htmlFor={"subject"}>Asunto</Form.Label>
                                    <Form.Control
                                        className={"mwx-400"}
                                        id={"subject"}
                                        {...register("subject", {
                                            required: "ingrese un dato válido",
                                        })}
                                    />
                                    {errors.subject && (
                                        <Alert variant="danger" className="mwx-400">
                                            {errors.subject.message}
                                        </Alert>
                                    )}
                                </Col>
                            </Row>
                            <Row className={"mb-3"}>
                                <Col sm={"12"}>
                                    <Form.Label htmlFor={"description"}>Descripción</Form.Label>
                                    <Form.Control
                                        className={"mwx-400"}
                                        id={"description"}
                                        as={"textarea"}
                                        {...register("description", {
                                            required: "ingrese un dato válido",
                                        })}
                                    />
                                    {errors.description && (
                                        <Alert variant="danger" className="mwx-400">
                                            {errors.description.message}
                                        </Alert>
                                    )}
                                </Col>
                            </Row>
                            <Row className={"mb-3"}>
                                <Col sm={"12"}>
                                    <Button type={"submit"}>Enviar Reporte</Button>
                                </Col>
                            </Row>
                        </Form>
                    </>
                ) : (
                    <p>Debes estar loggeado para poder mandar un reporte</p>
                )}
            </OffcanvasBody>
        </Offcanvas>
    );
};

export default MesaDeAyuda;
