import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { Controller, useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import FormLabelCounter from "../../../components/base/FormLabelCounter";
import ConfirmDialog from "../../../components/base/ConfirmDialog";
import _, { inRange } from "lodash";
import { NumericFormat } from 'react-number-format';
import { useObtenerEmpresasEmpleadosQuery } from '../../../services/empresa.service';
import { useObtenerProyectosPorIdEmpresaQuery } from "../../../services/proyecto.service";
import { useObtenerTiposMonedasQuery } from "../../../services/tipoMoneda.service";
import { useObtenerTiposPagosQuery } from "../../../services/tipoPago.service";
import { useObtenerEmpleadosBancosQuery } from "../../../services/empleado.service";
import { useObtenerSolicitudReembolsoQuery } from "../../../services/solicitudReembolso.service";
import { useGuardarSolicitudReembolsoMutation } from "../../../services/solicitudReembolso.service";
import { useObtenerEstatusEtapaQuery } from "../../../services/estatus.service";
import { useEliminarDocumentoMutation } from "../../../services/documento.service";
import { useGuardarProcesoEtapaDocumentoMutation, useObtenerDocumentosArchivosQuery } from "../../../services/procesoEtapaDocumento.service";
import InputFile from "../../../components/base/InputFile";

const SolicitudesReembolsos = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { userInfo } = useSelector((state) => state.auth);
    const {
        control,
        register,
        unregister,
        reset,
        handleSubmit,
        setValue,
        getValues,
        watch,
        formState: { errors }
    } = useForm();

    const { id: idSolicitudReembolso } = useParams();

    const {
        data: solicitudReembolso,
        refetch: refetchSolicitud
    } = useObtenerSolicitudReembolsoQuery({ idSolicitudReembolso }, { skip: !idSolicitudReembolso, refetchOnMountOrArgChange: true });

    const currentButton = useRef(null);

    const {
        data: botones,
        refetch: refetchBotones
    } = useObtenerEstatusEtapaQuery([idSolicitudReembolso || 0, idSolicitudReembolso ? userInfo.etapa : 1], { refetchOnMountOrArgChange: true });

    const {
        data: documentosData,
        refetch: refetchDocumentos
    } = useObtenerDocumentosArchivosQuery([userInfo.proceso, idSolicitudReembolso ? userInfo.etapa : 1, idSolicitudReembolso || 0], { refetchOnMountOrArgChange: true });

    const [guardarSolicitudReembolso] = useGuardarSolicitudReembolsoMutation();
    const refetchAll = async () => {
        await refetchSolicitud();
        refetchBotones();
    }

    const [idEmpresa, setIdEmpresa] = useState(0);
    const [idEmpleado, setIdEmpleado] = useState(userInfo.userId);
    const [estatus, setEstatus] = useState('');
    const [folio, setFolio] = useState('');
    const [fechaHora, setFechaHora] = useState('');
    const [documentos, setDocumentos] = useState(null);
    const [confirmDialog, setConfirmDialog] = useState({});

    const [formObservaciones, setFormObservaciones] = useState(null);

    const [solicita, setSolicita] = useState(userInfo.userName);

    const { data: empresas } = useObtenerEmpresasEmpleadosQuery();
    const { data: proyectos } = useObtenerProyectosPorIdEmpresaQuery(idEmpresa, { skip: !idEmpresa });
    const { data: tiposPagos } = useObtenerTiposPagosQuery();
    const { data: empleadosBancos } = useObtenerEmpleadosBancosQuery();
    const { data: tiposMonedas } = useObtenerTiposMonedasQuery();

    const [eliminarDocumento] = useEliminarDocumentoMutation();
    const [guardarProcesoEtapaDocumento] = useGuardarProcesoEtapaDocumentoMutation();

    const deshabilitarCampos = getValues('idEstatus') ? ![101, 104].includes(getValues('idEstatus')) : false;

    useEffect(() => {
        if (documentosData) {
            setDocumentos(documentosData);
        }
    }, [setDocumentos, documentosData]);

    useEffect(() => {
        if (Array.isArray(documentos)) {
            documentos.forEach((proceso, indexProceso) => {
                if (proceso.documento.length === 0) {
                    const nuevoDocumento = {
                        archivo: {},
                        idArchivo: 0,
                        idDocumento: 0,
                        editar: true,
                        validarDocumentoEtapa: false
                    };
                    const nuevosDocumentos = _.cloneDeep(documentos);
                    nuevosDocumentos[indexProceso].documento.push(nuevoDocumento);
                    setDocumentos(nuevosDocumentos);
                }
            });
        }
    }, [documentos]);
    

    useEffect(() => {
        if (solicitudReembolso) {
            const {
                idEmpresa,
                idProyecto,
                idEmpleado,
                idEmpleadoBeneficiario,
                idEmpleadoBanco,
                idTipoPago,
                idTipoMoneda,
                idEstatus,
                folio,
                fechaHora,
                justificacion,
                importe,
                fechaVersion,
                estatus,
                empleado,
            } = solicitudReembolso;

            if (empresas)
                setIdEmpresa(idEmpresa);

            setEstatus(estatus.estatus);
            setFechaHora(fechaHora);
            setSolicita(empleado.nombreCustom);

            if (empresas && proyectos) {
                setIdEmpresa(idEmpresa);
                setFolio(folio);
                setValue("idEstatus", idEstatus);
                setValue("idTipoPago", idTipoPago);
                setValue("idTipoMoneda", idTipoMoneda);
                setValue("idProyecto", idProyecto);
                setValue("idEmpleadoBanco", idEmpleadoBanco);
                setValue("idEmpleadoBeneficiario", idEmpleadoBeneficiario);
                setValue("justificacion", justificacion);
                setValue("importe", importe);
                setValue("idEstatus", idEstatus);
                setValue("fechaVersion", fechaVersion);
                setValue('idEmpleado', idEmpleado);
            }
        }
    }, [reset, setValue, solicitudReembolso, empresas, proyectos]);

    const onSubmit = async (formData, urlBase) => {
        let data;
        formData.idSolicitudReembolso = parseInt(idSolicitudReembolso)
        formData.idEmpleadoBanco = parseInt(formData.idEmpleadoBanco ? formData.idEmpleadoBanco : '0')
        formData.idEmpleado = parseInt(idEmpleado);

        let result = await guardarSolicitudReembolso(formData);
        data = result.data;

        if (data) {
            toast.success(currentButton.current.mensajeOk);
        } else {
            toast.error(currentButton.current.mensajeError);
        }

        if (data) {
            if (urlBase) {
                navigate(urlBase);
            } else {
                if (!idSolicitudReembolso) {
                    navigate(`${location.pathname}/${data?.objeto.idSolicitudReembolso}`);
                } else if (data.objeto) {

                    let { idSolicitudReembolso, fechaVersion, idEstatus, fechaHora } = data.objeto;
                    setValue('idSolicitudReembolso', idSolicitudReembolso);
                    setValue('fechaVersion', fechaVersion)
                    setValue('idEstatus', idEstatus)

                    setFechaHora(fechaHora)
                    refetchAll();
                }
            }
        }
    };

    const handleAddInput = async (obj, indexProceso) => {
        const nuevoDocumento = {
            ...obj.documento[0],
            archivo: {},
            idArchivo: 0,
            idDocumento: 0
        };

        const nuevosDocumentos = _.cloneDeep(documentos);
        nuevosDocumentos[indexProceso].documento.push(nuevoDocumento);
        setDocumentos(nuevosDocumentos);
    }

    const handleRemoveInput = (indexDocumento, indexProceso, documento) => {
        console.log('indexDocumento antes: ',indexDocumento);
        setConfirmDialog({
            message: '¿Desea eliminar este registro?',
            onConfirm: async () => {
                if (documento.idDocumento !== 0) {
                    await eliminarDocumento({ idDocumento: documento.idDocumento });
                }
                let nuevosDocumentos = _.cloneDeep(documentos);
                nuevosDocumentos[indexProceso].documento = nuevosDocumentos[indexProceso].documento.filter((_, index) => index !== indexDocumento);
                setDocumentos(nuevosDocumentos);
                unregister(`procesoEtapaDocumento.${indexProceso}.documento.${indexDocumento}`);
                setConfirmDialog(null);
            },
            onCancel: () => setConfirmDialog(null)
        });
    }

    return (
        <>
            <main id="main" className="main">
                <section className="section">
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <Card.Body>
                                    <fieldset>
                                        <legend>
                                            <h2>SOLICITUD DE REEMBOLSO</h2>
                                            <h6> *Los campos en negritas son requeridos</h6>
                                        </legend>
                                    </fieldset>
                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        <Form.Control type="text" id="idSolicitudReembolso" className="d-none" defaultValue={idSolicitudReembolso} {...register("idSolicitudReembolso")} />
                                        <Form.Control type="text" id="idEstatus" className="d-none" {...register("idEstatus")} />
                                        <Form.Control type="text" id="fechaVersion" className="d-none" {...register("fechaVersion")} />
                                        <Row className="mb-3">
                                            <Col md={12}>
                                                <Form.Label htmlFor="folio">Folio</Form.Label>
                                                <Form.Control type="text" className="mwx-250" id="folio" disabled value={folio} />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={12}>
                                                <Form.Label htmlFor="estatus">Estatus</Form.Label>
                                                <Form.Control type="text" className="mwx-250" id="estatus" disabled
                                                    value={estatus} />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={12}>
                                                <Form.Label htmlFor="fechaHora">Fecha</Form.Label>
                                                <Form.Control type="text" className="mwx-200" id="fechaHora" disabled
                                                    value={fechaHora} />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={12}>
                                                <Form.Label htmlFor="solicita">Solicita</Form.Label>
                                                <Form.Control type="text" className="mwx-400" id="solicita" disabled
                                                    value={solicita} />
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={12}>
                                                <Form.Label htmlFor="idEmpresa" className="fw-bold">Empresa</Form.Label>
                                                <Form.Select
                                                    className="mwx-400"
                                                    value={idEmpresa}
                                                    id="idEmpresa"
                                                    disabled=""
                                                    {...register("idEmpresa", {
                                                        required: "Ingrese un dato válido",
                                                        onChange: (event) => {
                                                            setIdEmpresa(event.target.value);
                                                        },
                                                    })}
                                                >
                                                    <option value="">Seleccionar</option>
                                                    {empresas &&
                                                        empresas.map((empresa) => (
                                                            <option key={'empresa.' + empresa.idEmpresa}
                                                                value={empresa.idEmpresa}>
                                                                {empresa.empresa}
                                                            </option>
                                                        ))}
                                                </Form.Select>
                                                {errors.idEmpresa && (
                                                    <Alert variant="danger" className="mwx-400">
                                                        {errors.idEmpresa.message}
                                                    </Alert>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={12}>
                                                <Form.Label htmlFor="idProyecto"
                                                    className="fw-bold">Proyecto</Form.Label>
                                                <Form.Select
                                                    id="idProyecto"
                                                    className="mwx-400"
                                                    disabled=""
                                                    {...register("idProyecto", {
                                                        required: "Ingrese un dato válido"
                                                    })}
                                                >
                                                    <option value="">Seleccionar</option>
                                                    {proyectos &&
                                                        proyectos.map((proyecto) => (
                                                            <option key={'proyecto.' + proyecto.idProyecto}
                                                                value={proyecto.idProyecto}>
                                                                {proyecto.proyecto.proyecto}
                                                            </option>
                                                        ))}
                                                </Form.Select>
                                                {errors.idProyecto && (
                                                    <Alert variant="danger" className="mwx-400">
                                                        {errors.idProyecto.message}
                                                    </Alert>
                                                )}

                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={12}>
                                                <FormLabelCounter
                                                    htmlFor="justificacion"
                                                    className="fw-bold mwx-400"
                                                    input={watch('justificacion')}
                                                    maxLength={4000}
                                                >
                                                    Justificación del reembolso
                                                </FormLabelCounter>

                                                <Form.Control
                                                    as="textarea"
                                                    className="mwx-400"
                                                    rows="8"
                                                    id="justificacion"
                                                    disabled=""
                                                    {...register("justificacion", {
                                                        required: "Ingrese un dato válido",
                                                        maxLength: {
                                                            value: 4000,
                                                            message: 'Ha excedido el máximo de caracteres'
                                                        }
                                                    })}
                                                />
                                                {errors.justificacion && (
                                                    <Alert variant="danger" className="mwx-400">
                                                        {errors.justificacion.message}
                                                    </Alert>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={12}>
                                                <Form.Label htmlFor="idEmpleadoBanco" className="fw-bold">Cuenta bancaria</Form.Label>
                                                <Form.Select
                                                    className="mwx-400"
                                                    id="idEmpleadoBanco"
                                                    {...register("idEmpleadoBanco", {
                                                        required: "Ingrese un dato válido"
                                                    })}
                                                >
                                                    <option value="">Seleccionar</option>
                                                    {empleadosBancos && empleadosBancos.map((empleadoBanco) => (
                                                        <option key={'empleadoBanco.' + empleadoBanco.idEmpleadoBanco}
                                                            // value={empleadoBanco.idEmpleadoBanco} selected={empleadoBanco.predeterminada === 1 ? empleadoBanco.idEmpleadoBanco : ""}>
                                                            value={empleadoBanco.idEmpleadoBanco}>
                                                            {empleadoBanco.banco.banco} - {empleadoBanco.clabe}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                                {errors.idEmpresaBanco && (
                                                    <Alert variant="danger" className="mwx-400">
                                                        {errors.idEmpresaBanco.message}
                                                    </Alert>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={12}>
                                                <Form.Label htmlFor="idTipoMoneda" className="fw-bold">Tipo de
                                                    moneda</Form.Label>
                                                <Form.Select
                                                    className="mwx-250"
                                                    id="idTipoMoneda"
                                                    disabled=""
                                                    {...register("idTipoMoneda", {
                                                        required: "Ingrese un dato válido"
                                                    })}
                                                >
                                                    <option value="">Seleccionar</option>
                                                    {tiposMonedas && tiposMonedas.map((tipoMoneda) => (
                                                        <option key={'tipoMoneda.' + tipoMoneda.idTipoMoneda}
                                                            value={tipoMoneda.idTipoMoneda}>
                                                            {tipoMoneda.tipoMoneda}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                                {errors.idTipoMoneda && (
                                                    <Alert variant="danger" className="mwx-400">
                                                        {errors.idTipoMoneda.message}
                                                    </Alert>
                                                )}
                                            </Col>
                                        </Row>
                                        <Row className="mb-3">
                                            <Col md={12}>
                                                <Form.Label htmlFor="importe" className="fw-bold">Importe a reembolsar
                                                </Form.Label>
                                                <Controller
                                                    render={({ field }) => (
                                                        <NumericFormat
                                                            className="form-control mwx-400"
                                                            id="importe"
                                                            thousandSeparator={true}
                                                            prefix={'$ '}
                                                            decimalScale={2}
                                                            fixedDecimalScale
                                                            allowNegative={false}
                                                            value={field.value || ''}
                                                            onValueChange={(v) => {
                                                                field.onChange(Number(v.value) > 0 ? Number(v.value) : null);
                                                            }}
                                                            isAllowed={({ value }) => (value?.split(".")[0].length <= 10)}
                                                            disabled=""
                                                        />
                                                    )}
                                                    name="importe"
                                                    control={control}
                                                    rules={{ required: "Ingrese un dato válido" }}
                                                />
                                                {errors.importe && (
                                                    <Alert variant="danger" className="mwx-400">
                                                        {errors.importe.message}
                                                    </Alert>
                                                )}
                                            </Col>
                                        </Row>
                                        <div className="row mb-3">
                                            <div className="col-md-12">
                                                <Form.Group controlId="idTipoPago">
                                                    <Form.Label className="fw-bold">Tipo de reembolso</Form.Label>
                                                    <Col>
                                                        <Controller
                                                            name="idTipoPago"
                                                            control={control}
                                                            defaultValue={getValues("idTipoPago")} // Asigna el valor seleccionado a defaultValue
                                                            rules={{ required: "Ingrese un dato válido", }}
                                                            render={({ field }) => (
                                                                tiposPagos?.map((tipoPago) => (
                                                                    <div className="form-check form-check-inline ps-0" key={'tipoPago.' + tipoPago.idTipoPago}>
                                                                        <Form.Check
                                                                            type="radio"
                                                                            label={tipoPago.tipoPago}
                                                                            id={`tipoPago.${tipoPago.idTipoPago}`}
                                                                            value={tipoPago.idTipoPago}
                                                                            checked={tipoPago.idTipoPago === field.value}
                                                                            onChange={(e) => {
                                                                                field.onChange(parseInt(e.target.value))
                                                                            }} // send value to hook form
                                                                            disabled=""
                                                                        />
                                                                    </div>
                                                                ))
                                                            )}
                                                        />
                                                        {errors.idTipoPago && (
                                                            <Alert variant="danger" className="mwx-400">
                                                                {errors.idTipoPago.message}
                                                            </Alert>
                                                        )}
                                                    </Col>
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <fieldset className="mb-3">
                                            <legend>Documentos digitales (PDF o Imagen)</legend>
                                            {documentos?.map((obj, indexProceso) => (
                                                <div
                                                    key={`procesoEtapaDocumento.${obj.idProcesoEtapaTipoDocumento}`}
                                                    className="mwx-450">
                                                    <Form.Control type="text" className="d-none" value={obj.idProcesoEtapaTipoDocumento}
                                                        {...register(`procesoEtapaDocumento.${indexProceso}.idProcesoEtapaTipoDocumento`)} />
                                                    <Form.Control type="text" className="d-none" value={obj.idOrigen}
                                                        {...register(`procesoEtapaDocumento.${indexProceso}.idOrigen`)} />
                                                    <Form.Control type="text" className="d-none" value={obj.fechaVersion || ''}
                                                        {...register(`procesoEtapaDocumento.${indexProceso}.fechaVersion`)} />
                                                    <fieldset className="border rounded-3 p-3 py-1 mb-2">
                                                        <legend className="float-none ps-3 mt-0 mb-2 fs-5">
                                                            <Form.Label>
                                                                {obj.procesoEtapaTipoDocumento.tipoDocumento.tipoDocumento}
                                                                {
                                                                    <Controller
                                                                        name={`procesoEtapaDocumento.${indexProceso}.requerido`}
                                                                        control={control}
                                                                        render={({ field }) => (<>
                                                                            <Form.Check
                                                                                reverse
                                                                                id={`procesoEtapaDocumento.${indexProceso}.requerido`}
                                                                                type="checkbox"
                                                                                className={!obj.procesoEtapaTipoDocumento.mostrar ? "d-none" : "d-sm-inline pe-sm-0 ps-sm-4"}
                                                                                label={`¿Genera ${obj.procesoEtapaTipoDocumento.tipoDocumento.tipoDocumento}?`}
                                                                                checked={Boolean(field.value)}
                                                                                disabled={!obj.editarRequeridoTipoDocumento}
                                                                                onChange={async (e) => {
                                                                                    field.onChange(Boolean(e.target.checked))
                                                                                    await guardarProcesoEtapaDocumento({
                                                                                        idProcesoEtapaTipoDocumento: obj.idProcesoEtapaTipoDocumento,
                                                                                        requerido: Boolean(e.target.checked)
                                                                                    })
                                                                                    refetchDocumentos()
                                                                                }
                                                                                }
                                                                            />
                                                                        </>

                                                                        )}
                                                                        defaultValue={obj.requerido}
                                                                    />
                                                                }
                                                            </Form.Label>
                                                            <Button className="float-end" variant="primary"
                                                                disabled={deshabilitarCampos}
                                                                onClick= {() => handleAddInput(obj, indexProceso)}
                                                                >
                                                                <i className="bi bi-plus-square"></i>
                                                            </Button>
                                                        </legend>
                                                        {obj.documento.map((documento, indexDocumento) => (
                                                            <div
                                                                key={`documento.${indexDocumento}`}>
                                                                <InputGroup className="flex-sm-nowrap">
                                                                    <InputFile
                                                                        name={`procesoEtapaDocumento.${indexProceso}.documento.${indexDocumento}.idArchivo`}
                                                                        archivo={documento.archivo}
                                                                        ruta={'documentos'}
                                                                        required={documento.validarDocumentoEtapa ? 'Ingrese un documento válido' : false}
                                                                        disabled={!documento.editar}
                                                                        register={register}
                                                                        setValue={setValue} // TODO: parche por posible incompatiblidad react 18
                                                                    />
                                                                    {<Button type="button"
                                                                        className="mb-3 rounded ms-sm-2"
                                                                        variant={"danger"} title="Eliminar archivo"
                                                                        //disabled={!documento.editar || !(obj.documento.length > 1)}
                                                                        onClick={()=> handleRemoveInput(indexDocumento, indexProceso, documento)}>
                                                                        <i className="bi bi-x-lg" title="Eliminar archivo"> </i>
                                                                    </Button>}
                                                                </InputGroup>
                                                                <Form.Control
                                                                    type="text"
                                                                    className='d-none'
                                                                    value={documento.idProcesoEtapaTipoDocumento}
                                                                    {...register(`procesoEtapaDocumento.${indexProceso}.documento.${indexDocumento}.idProcesoEtapaTipoDocumento`)}
                                                                />
                                                                {errors?.procesoEtapaDocumento?.[indexProceso]?.documento?.[indexDocumento] && (
                                                                    <Alert variant="danger" className="mwx-450"
                                                                        role="alert">
                                                                        {errors.procesoEtapaDocumento[indexProceso].documento[indexDocumento].idArchivo.message}
                                                                    </Alert>
                                                                )}
                                                            </div>

                                                        ))
                                                        }

                                                    </fieldset>
                                                </div>
                                            ))}
                                            <ConfirmDialog {...confirmDialog} onCancel={() => setConfirmDialog({})} />
                                        </fieldset>
                                        <Row className="mt-3">
                                            <Col md={12}>
                                                <div className="d-grid gap-2 d-md-block">
                                                    <Link className="btn btn-secondary me-md-3" to={"/buscador/reembolsos"}>
                                                        <i className="bi bi-box-arrow-in-left"></i> Regresar
                                                    </Link>
                                                    {botones?.map((boton) => (
                                                        <Button
                                                            key={'btn.' + boton.idEstatus}
                                                            className={'btn ' + boton.css + ' me-md-3'}
                                                            onClick={() => {
                                                                if (boton.idEstatus) {
                                                                    setValue("idEstatus", boton.idEstatus);
                                                                }

                                                                currentButton.current = boton;

                                                                if (boton.confirmar) {
                                                                    setConfirmDialog({
                                                                        message: boton.confirmarMensaje || '.',
                                                                        onConfirm: () => {
                                                                            handleSubmit(onSubmit)();
                                                                            setConfirmDialog({});
                                                                        }
                                                                    })

                                                                } else if (boton.modal) {
                                                                    setFormObservaciones({
                                                                        accion: boton.accion,
                                                                        idSolicitudReembolso: idSolicitudReembolso,
                                                                        idEstatus: boton.idEstatus,
                                                                        idEtapa: userInfo.etapa,
                                                                        idProceso: 1,
                                                                        urlBase: boton.urlBase
                                                                    });

                                                                } else {
                                                                    handleSubmit(onSubmit)();
                                                                }
                                                            }}
                                                        >
                                                            <i className={`bi bi-${boton.icono}`}></i> {boton.accion}
                                                        </Button>
                                                    ))}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>

                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </section>
            </main>
        </>
    )
}

export default SolicitudesReembolsos;
