import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { useGuardarRegimenFiscalMutation, useObtenerRegimenFiscalQuery } from "../../../services/regimenFiscal.service";

const RegimenesFiscalesForm = ({ show, onHide, onSave }) => {

    const handleClose = (e) => {
        onHide(e);
    };

    const { control, handleSubmit, setValue, register, reset, formState: { errors } } = useForm();

    const { data: regimenFiscal } = useObtenerRegimenFiscalQuery(show.idRegimenFiscal, { skip: !show.idRegimenFiscal, refetchOnMountOrArgChange: true });
    const [guardarRegimenFiscal] = useGuardarRegimenFiscalMutation();

    useEffect(() => {
        if (regimenFiscal) {
            reset({
                idRegimenFiscal: regimenFiscal.idRegimenFiscal,
                regimenFiscal: regimenFiscal.regimenFiscal,
                idEstatus: regimenFiscal.idEstatus,
                clave: regimenFiscal.clave
            });
        } else {
            //default values
            reset({ idEstatus: 1 })
        }
    }, [regimenFiscal, reset]);

    const onSubmit = async (formData) => {
        let { data } = await guardarRegimenFiscal(formData);
        if (data) {
            setValue('idRegimenFiscal', data.id);

            onSave();
        }
    };

    return (<> {
        <Modal show={show} onHide={handleClose} centered backdrop="static" keyboard={false}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>{show.idRegimenFiscal ? 'Editar' : 'Nuevo'} [ Regimen Fiscal ]</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="text" id="idRegimenFiscal" className="d-none" {...register("idRegimenFiscal")} />
                    <Form.Control type='text' id='clave' className='d-none' {...register('clave')} />
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="clave" className="fw-bold">
                            Clave
                        </Form.Label>
                        <Form.Control
                            {...register('clave', {
                                required: 'Ingrese un dato válido',
                                maxLength: { value: 4, message: 'Ha excedido el máximo de caracteres' }
                            })}
                        />
                        <Form.Label htmlFor='regimenFiscal' className='fw-bold'>
                            Regimen Fiscal
                        </Form.Label>
                        <Form.Control
                            {...register('regimenFiscal', {
                                required: 'Ingrese un dato valido',
                                maxLength: { value: 100, message: 'Ha excedido el máximo de caracteres' }
                            })} />
                        {errors.regimenFiscal && (<Alert variant="danger" role="alert">
                            {errors.regimenFiscal.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Controller
                            name="idEstatus"
                            control={control}
                            render={({ field }) => (<>
                                <Form.Label htmlFor="idEstatus">Estatus</Form.Label>
                                <Form.Check
                                    id="idEstatus"
                                    type="switch"
                                    label={Boolean(field.value) ? 'Activo' : 'Inactivo'}
                                    checked={Boolean(field.value)}
                                    onChange={(e) => {
                                        field.onChange(Number(e.target.checked))
                                    }}
                                />
                            </>

                            )}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant="primary" className="me-2">
                        <i className="bi bi-save"></i> Guardar
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>}
    </>);
};

export default RegimenesFiscalesForm;









