import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const entradasProductosApi = createApi({
    reducerPath: 'entradasProductosApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "entradas/productos",
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        obtenerEntradaProducto: build.query({
            query: (idEntradaProducto) => `/${idEntradaProducto}`,
        }),
        obtenerEntradasProductos: build.query({
            query: (idEntrada) => `/${idEntrada}`,
        }),
        obtenerEntradasProductosPorAlmacen: build.query({
            query: (idAlmacen) => `/almacen/${idAlmacen}`,
            transformResponse: (response, meta, arg) => (response?.map((ep) => ({ ...ep.producto, idEntrada: ep.idEntrada, idProducto: ep.idProducto }))),
        }),
        guardarEntradaProducto: build.mutation({
            query: (entradaProducto) => ({
                url: '',
                method: entradaProducto.idEntradaProducto ? 'PATCH' : 'POST',
                body: entradaProducto,
            }),
        }),
        eliminarEntradaProducto: build.mutation({
            query: (entradaProducto) => ({
                url: '',
                method: 'DELETE',
                body: entradaProducto,
            }),
        }),
    }),
});
export const {
    useObtenerEntradaProductoQuery,
    useObtenerEntradasProductosQuery,
    useLazyObtenerEntradasProductosQuery,
    useObtenerEntradasProductosPorAlmacenQuery,
    useGuardarEntradaProductoMutation,
    useEliminarEntradaProductoMutation
} = entradasProductosApi;
