import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const bancosApi = createApi({
    reducerPath: 'bancosApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "bancos",
        prepareHeaders: (headers, {getState}) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        obtenerBanco: build.query({
            query: (idBanco) => `/${idBanco}`,
        }),
        obtenerBancos: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        guardarBanco: build.mutation({
            query: (banco) => ({
                url: '',
                method: banco.idBanco ? 'PUT' : 'POST',
                body: banco,
            }),
        }),
        eliminarBanco: build.mutation({
            query: (banco) => ({
                url: '',
                method: 'DELETE',
                body: banco,
            }),
        }),
    }),
});
export const {
    useObtenerBancoQuery,
    useObtenerBancosQuery,
    useLazyObtenerBancosQuery,
    useGuardarBancoMutation,
    useEliminarBancoMutation
} = bancosApi;
