import {
    useObtenerTiposMantenimientosQuery,
    useEliminarTipoMantenimientoMutation,
    useLazyObtenerTiposMantenimientosQuery
} from "../../../services/tipoMantenimiento.service";
import React, {useState} from "react";
import {Button, Row, Col, Card} from "react-bootstrap";
import SeverPagination from "../../../components/base/SeverPagination";
import TiposMantenimientosForm from "./TiposMantenimientosForm.js";
import BootstrapTable from "../../../components/base/BootstrapTable";
import ConfirmDialog from "../../../components/base/ConfirmDialog";
import {ExportToExcel} from "../../../components/base/ExportToExcel";

const TiposMantenimientos = () => {
    const [pageNumber, setPageNumber] = useState(1);
    const [elementsPerPage, setElementsPerPage] = useState(10);

    const [formTiposMantenimientos, setFormTiposMantenimientos] = useState(null);
    const [confirmDialog, setConfirmDialog] = useState({});

    const {
        data: tiposMantenimientos, refetch
    } = useObtenerTiposMantenimientosQuery([pageNumber, elementsPerPage], {refetchOnMountOrArgChange: true});
    const [ trigger ] = useLazyObtenerTiposMantenimientosQuery();

    const [eliminarTipoMantenimiento] = useEliminarTipoMantenimientoMutation();

    return (<div id="main" className="main">
        <section className="section">
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Body>
                            <div className="btn-toolbar justify-content-between" role="toolbar">
                                <div className="pagetitle">
                                    <h1>Tipos de mantenimientos</h1>
                                </div>
                            </div>
                            {tiposMantenimientos &&
                                <BootstrapTable striped
                                    toolbar={({columns}) => (
                                        <div>
                                            <Button href="#" className="me-2" variant="primary" onClick={(e) => {
                                                setFormTiposMantenimientos({})
                                            }}>
                                                <i className="bi bi-plus-square"></i>&nbsp;Nuevo
                                            </Button>
                                            <ExportToExcel columns={columns} onClick={trigger} fileName={'tiposMantenimientos'}/>
                                        </div>
                                    )}
                                    data={tiposMantenimientos.data}
                                    columns={[{
                                        header: 'Tipo de Mantenimiento', accessorKey: 'tipoMantenimiento'
                                    }, {
                                        header: 'Estatus', accessorKey: 'estatus.estatus', enableGlobalFilter: false
                                    }, {
                                        header: 'Acciones',
                                        enableGlobalFilter: false,
                                        cell: ({row: {original}}) => (
                                        <div className="w-100 d-grid d-sm-block gap-1">
                                            <Button variant="warning" className="me-2" size="sm"
                                                    onClick={e => setFormTiposMantenimientos(original)}>
                                                <i className="bi bi-pencil-square"> </i>
                                                Editar
                                            </Button>
                                            <Button variant="danger" className="me-2" size="sm" 
                                            onClick={e => setConfirmDialog({
                                                message: '¿Desea eliminar este registro?', onConfirm: async () => {
                                                    await eliminarTipoMantenimiento({idTipoMantenimiento: original.idTipoMantenimiento});
                                                    refetch();
                                                    setConfirmDialog({});

                                                }
                                            })}>
                                                <i className="bi bi-trash"> </i>
                                                Eliminar
                                            </Button>
                                        </div>)
                                    }]}
                                    onGlobalFilterChange={(e => {
                                        setPageNumber(1);
                                        setElementsPerPage(e ? -1 : 10);
                                    })}
                                />}
                            {tiposMantenimientos && <SeverPagination
                                data={tiposMantenimientos.data}
                                currentPage={pageNumber}
                                totalElements={tiposMantenimientos.totalElements}
                                elementsPerPage={elementsPerPage}
                                onPageChange={setPageNumber}
                                onElementsPerPageChange={setElementsPerPage}
                            />}
                        </Card.Body>

                        <ConfirmDialog {...confirmDialog} onCancel={() => setConfirmDialog({})}/>
                    </Card>
                </Col>
            </Row>
        </section>
        {formTiposMantenimientos && <TiposMantenimientosForm show={formTiposMantenimientos} onHide={(e) => {
            setFormTiposMantenimientos(false);
        }} onSave={refetch}/>}
    </div>);
};

export default TiposMantenimientos;
