import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const puestosApi = createApi({
    reducerPath: 'puestosApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + 'puestos',
        prepareHeaders: (headers, {getState}) => {
            const token = getState().auth.userInfo.token
            if (token){
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers
        }
    }),

    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        obtenerPuesto: build.query({
            query: (idPuesto) => `/${idPuesto}`
        }),
        obtenerPuestos: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        guardarPuesto: build.mutation({
            query: (puesto) => ({
                url: '',
                method: puesto.idPuesto ? 'PUT' : 'POST',
                body: puesto,
            }),
        }),
        eliminarPuesto: build.mutation({
            query: (puesto) => ({
                url: '',
                method: 'DELETE',
                body: puesto,
            })
        })
    })    
})

export const {
    useObtenerPuestoQuery,
    useObtenerPuestosQuery,
    useLazyObtenerPuestosQuery,
    useGuardarPuestoMutation,
    useEliminarPuestoMutation
} = puestosApi