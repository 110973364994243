import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useObtenerObservacionesQuery } from '../../services/observacion.service';

const ObservacionInfo = ({ show, onHide }) => {
    const handleClose = () => {
        onHide();
    };
    const { data: observaciones } = useObtenerObservacionesQuery([show.idProceso, show.id], {
        refetchOnMountOrArgChange: true,
    });

    return (
        <>
            {observaciones && (
                <Modal show={show} centered onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Observaciones</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {observaciones.map((observacion) => (
                            <div key={observacion.idObservacion} className="mb-3">
                                <div>{observacion.fechaVersion}</div>
                                <div>{observacion.usuarioCustom.nombreCompleto}</div>
                                <Form.Control
                                    as="textarea"
                                    value={observacion.observacion}
                                    disabled
                                />
                            </div>
                        ))}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
};

export default ObservacionInfo;
