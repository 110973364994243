import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const mantenimientosApi = createApi({
    reducerPath: 'mantenimientosApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "mantenimientos",
        prepareHeaders: (headers, {getState}) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        obtenerMantenimiento: build.query({
            query: (idMantenimiento) => `/${idMantenimiento}`,
        }),
        obtenerMantenimientos: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        buscarMantenimientos: build.query({
            query: ({ pageNumber, elementsPerPage, searchParams }) => `/buscador?page=${pageNumber}&size=${elementsPerPage}&` + new URLSearchParams(searchParams).toString(),
        }),
        guardarMantenimiento: build.mutation({
            query: (mantenimiento) => ({
                url: '',
                method: mantenimiento.idMantenimiento ? 'PUT' : 'POST',
                body: mantenimiento,
            }),
        }),
        eliminarMantenimiento: build.mutation({
            query: (mantenimiento) => ({
                url: '',
                method: 'DELETE',
                body: mantenimiento,
            }),
        }),
    }),
});
export const {
    useObtenerMantenimientoQuery,
    useObtenerMantenimientosQuery,
    useLazyObtenerMantenimientosQuery,
    useGuardarMantenimientoMutation,
    useEliminarMantenimientoMutation,
    useBuscarMantenimientosQuery
} = mantenimientosApi;
