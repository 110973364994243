import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {Alert, Button, Form, InputGroup, Modal} from "react-bootstrap";
import { useGuardarProductoMutation, useObtenerProductoQuery } from '../../../services/producto.service';
import {useObtenerTiposProductosQuery} from "../../../services/tipoProducto.service";
import {useObtenerCategoriasProductosQuery} from "../../../services/categoriaProducto.service";
import {useObtenerUnidadesMedidasQuery} from "../../../services/unidadesMedidas.service";
import FormLabelCounter from "../../../components/base/FormLabelCounter";
import InputFile from "../../../components/base/InputFile";
import Html5QrcodePlugin from "../../../components/Html5QrcodePlugin";
import {NumericFormat} from "react-number-format";

const ProductosForm = ({ show, onHide, onSave }) => {
    const handleClose = (e) => {
        onHide(e);
    };

    const { control, handleSubmit, setValue, register, reset, watch, setFocus, formState: { errors } } = useForm();

    const { data: producto } = useObtenerProductoQuery(show.idProducto, { skip: !show.idProducto, refetchOnMountOrArgChange: true });
    const [guardarProducto] = useGuardarProductoMutation();

    const { data: tiposProductos } = useObtenerTiposProductosQuery();
    const { data: categoriasProductos } = useObtenerCategoriasProductosQuery();
    const { data: unidadesMedidas } = useObtenerUnidadesMedidasQuery();

    useEffect(() => {
        if (producto && tiposProductos && categoriasProductos && unidadesMedidas ) {
            reset({
                idProducto: producto.idProducto,
                producto: producto.producto,
                codigoProducto: producto.codigoProducto,
                codigoBarras: producto.codigoBarras,
                precioVenta: producto.precioVenta,
                idTipoProducto: producto.idTipoProducto,
                idCategoriaProducto: producto.idCategoriaProducto,
                idUnidadMedida: producto.idUnidadMedida,
                idUnidadMedidaCompra: producto.idUnidadMedidaCompra,
                idArchivo: producto.idArchivo,
                idEstatus: producto.idEstatus,
                fechaVersion: producto.fechaVersion
            });
        }
    }, [producto, tiposProductos, categoriasProductos, unidadesMedidas, reset]);

    useEffect(() => {
        if(!show.idProducto ){
            setFocus("producto");
        }
    }, [show, setFocus]);


    const onSubmit = async (formData) => {
        let { data } = await guardarProducto(formData);
        if (data) {
            setValue('idProducto', data.id);
            setValue('fechaVersion', data.fechaVersion);
            onSave();
        }
    };

    const onNewScanResult = (decodedText, decodedResult) => {
        if(decodedText){
            setValue("codigoBarras", decodedText);
            document.getElementById("html5-qrcode-button-camera-stop").click();
        }

    };

    return (<> {
        < Modal className="modal-fixed" show={show} onHide={handleClose} centered backdrop="static" keyboard={false}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>{show.idProducto ? 'Editar' : 'Nuevo'} [Producto]</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h6> *Los campos en negritas son requeridos</h6>
                    <Form.Control type="text" id="idProducto" className='d-none' {...register("idProducto")} />
                    <Form.Control type="text" id='fechaVersion' className='d-none' {...register("fechaVersion")} />
                    <Form.Group className='mb-3'>
                        <FormLabelCounter
                            htmlFor="producto"
                            className="fw-bold mwx-350"
                            input={watch('producto')}
                            maxLength={1000}
                        >
                            Descripción
                        </FormLabelCounter>
                        <Form.Control
                            as="textarea"
                            rows="2"
                            className="mwx-350"
                            {...register('producto', {
                                required: 'Ingrese un dato valido',
                                maxLength: { value: 1000, message: 'Ha excedido el máximo de caracteres' }
                            })}
                        />
                        {errors.producto && (<Alert variant='danger' role='alert'>
                            {errors.producto.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className='mb-3'>
                        <Form.Label htmlFor='idTipoProducto' className='fw-bold' >
                            Tipo de producto
                        </Form.Label>
                        <Form.Select
                            className="mwx-350"
                            id="idTipoProducto"
                            {...register("idTipoProducto", {
                                required: "Ingrese un dato válido"
                            })}
                        >
                            <option value="">Seleccionar</option>
                            {tiposProductos?.data && tiposProductos.data.map((tipoProducto) => (
                                <option key={'tipoProducto.' + tipoProducto.idTipoProducto}
                                        value={tipoProducto.idTipoProducto}>
                                    {tipoProducto.tipoProducto}
                                </option>
                            ))}
                        </Form.Select>
                        {errors.idTipoProducto && (<Alert variant='danger' role='alert'>
                            {errors.idTipoProducto.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className='mb-3'>
                        <Form.Label htmlFor='idCategoriaProducto' className='fw-bold' >
                            Categoría
                        </Form.Label>
                        <Form.Select
                            className="mwx-350"
                            id="idCategoriaProducto"
                            {...register("idCategoriaProducto", {
                                required: "Ingrese un dato válido"
                            })}
                        >
                            <option value="">Seleccionar</option>
                            {categoriasProductos?.data && categoriasProductos.data.map((categoriaProducto) => (
                                <option key={'categoriaProducto.' + categoriaProducto.idCategoriaProducto}
                                        value={categoriaProducto.idCategoriaProducto}>
                                    {categoriaProducto.categoriaProducto}
                                </option>
                            ))}
                        </Form.Select>
                        {errors.idCategoriaProducto && (<Alert variant='danger' role='alert'>
                            {errors.idCategoriaProducto.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className='mb-3'>
                        <Form.Label htmlFor='codigoProducto' className='fw-bold' >
                            Código de producto
                        </Form.Label>
                        <Form.Control
                            className="mwx-350"
                            {...register('codigoProducto', {
                                required: 'Ingrese un dato valido',
                                maxLength: { value: 100, message: 'Ha excedido el máximo de caracteres' }
                            })}
                        />
                        {errors.codigoProducto && (<Alert variant='danger' role='alert'>
                            {errors.codigoProducto.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className='mb-3'>
                        <Form.Label htmlFor='idUnidadMedidaCompra' className='fw-bold' >
                            Unidad de medida de compra
                        </Form.Label>
                        <Form.Select
                            className="mwx-350"
                            id="idUnidadMedidaCompra"
                            {...register("idUnidadMedidaCompra", {
                                required: "Ingrese un dato válido"
                            })}
                        >
                            <option value="">Seleccionar</option>
                            {unidadesMedidas?.data && unidadesMedidas.data.map((unidadMedida) => (
                                <option key={'unidadMedida.' + unidadMedida.idUnidadMedida}
                                        value={unidadMedida.idUnidadMedida}>
                                    {unidadMedida.unidadMedida}
                                </option>
                            ))}
                        </Form.Select>
                        {errors.idUnidadMedidaCompra && (<Alert variant='danger' role='alert'>
                            {errors.idUnidadMedidaCompra.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className='mb-3'>
                        <Form.Label htmlFor='idUnidadMedida' className='fw-bold' >
                            Unidad de medida de salida
                        </Form.Label>
                        <Form.Select
                            className="mwx-350"
                            id="idUnidadMedida"
                            {...register("idUnidadMedida", {
                                required: "Ingrese un dato válido"
                            })}
                        >
                            <option value="">Seleccionar</option>
                            {unidadesMedidas?.data && unidadesMedidas.data.map((unidadMedida) => (
                                <option key={'unidadMedida.' + unidadMedida.idUnidadMedida}
                                        value={unidadMedida.idUnidadMedida}>
                                    {unidadMedida.unidadMedida}
                                </option>
                            ))}
                        </Form.Select>
                        {errors.idUnidadMedida && (<Alert variant='danger' role='alert'>
                            {errors.idUnidadMedida.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="precioVenta" className="fw-bold">
                            Precio de venta
                        </Form.Label>
                        <Controller
                            render={({field}) => (
                                <NumericFormat
                                    className="form-control mwx-350"
                                    id="precioVenta"
                                    thousandSeparator={true}
                                    prefix={'$ '}
                                    decimalScale={2}
                                    fixedDecimalScale
                                    allowNegative={false}
                                    value={field.value || ''}
                                    onValueChange={(v) => {
                                        field.onChange(Number(v.value) > 0 ? Number(v.value) : '0');
                                    }}
                                    isAllowed={({value}) => (value?.split(".")[0].length <= 10)}
                                />
                            )}
                            name="precioVenta"
                            control={control}
                            rules={{required: "Ingrese un dato válido"}}
                        />
                        {errors.precioVenta && (<Alert variant="danger" role="alert">
                            {errors.precioVenta.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className='mb-3'>
                        <Form.Label htmlFor='codigoBarras'>
                            Código de barras
                        </Form.Label>
                        <Form.Control
                            className="mwx-350"
                            {...register('codigoBarras', {
                                maxLength: { value: 100, message: 'Ha excedido el máximo de caracteres' }
                            })}
                        />
                        {errors.codigoBarras && (<Alert variant='danger' role='alert'>
                            {errors.codigoBarras.message}
                        </Alert>)}
                    </Form.Group>

                        <Form.Label >
                            Escanear producto
                        </Form.Label>
                        <Html5QrcodePlugin
                            fps={10}
                            qrbox={250}
                            disableFlip={false}
                            qrCodeSuccessCallback={onNewScanResult}
                            verbose
                        />

                    <Form.Group className='mb-3'>
                        <Form.Label htmlFor='idArchivo'>
                            Foto del producto
                        </Form.Label>
                        <InputGroup className="flex-sm-nowrap mwx-350">
                            <InputFile
                                name={`idArchivo`}
                                archivo={show.archivo ? show.archivo : '' }
                                ruta={'productos'}
                                register={register}
                                setValue={setValue}
                            />
                        </InputGroup>
                        {errors.idArchivo && (<Alert variant='danger' role='alert'>
                            {errors.idArchivo.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className='mb-3'>
                        <Controller
                            name='idEstatus'
                            control={control}
                            defaultValue={1}
                            render={({ field }) => (<>
                                <Form.Label htmlFor='idEstatus' > Estatus </Form.Label>
                                <Form.Check
                                    id='idEstatus'
                                    type='switch'
                                    label={Boolean(field.value) ? 'Activo' : 'Inactivo'}
                                    checked={Boolean(field.value)}
                                    onChange={(e) => {
                                        field.onChange(Number(e.target.checked))
                                    }}
                                />
                            </>
                            )}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button type='submit' variant='primary' className='me-2'>
                        <i className='bi bi-save'></i> Guardar
                    </Button>
                    <Button variant='secondary' onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>}
    </>);
}

export default ProductosForm;