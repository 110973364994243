import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const configuracionesCorreosApi = createApi({
    reducerPath: 'configuracionesCorreosApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "configuraciones/correos",
        prepareHeaders: (headers, {getState}) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        obtenerConfiguracionCorreo: build.query({
            query: (idConfiguracionCorreo) => `/${idConfiguracionCorreo}`,
        }),
        obtenerConfiguracionesCorreos: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        guardarConfiguracionCorreo: build.mutation({
            query: (configuracionCorreo) => ({
                url: '',
                method: configuracionCorreo.idConfiguracionCorreo ? 'PUT' : 'POST',
                body: configuracionCorreo,
            }),
        }),
        eliminarConfiguracionCorreo: build.mutation({
            query: (configuracionCorreo) => ({
                url: '',
                method: 'DELETE',
                body: configuracionCorreo,
            }),
        }),
    }),
});
export const {
    useObtenerConfiguracionCorreoQuery,
    useObtenerConfiguracionesCorreosQuery,
    useLazyObtenerConfiguracionesCorreosQuery,
    useGuardarConfiguracionCorreoMutation,
    useEliminarConfiguracionCorreoMutation
} = configuracionesCorreosApi;
