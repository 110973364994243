import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const tiposMonedasApi = createApi({
    reducerPath: 'tiposMonedasApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (build) => ({
        obtenerTiposMonedas: build.query({
            query: () => 'tipos/monedas',
        }),

    }),
});

export const { useObtenerTiposMonedasQuery } = tiposMonedasApi;
