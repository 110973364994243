import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Alert, Button, Card, Form } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import {
    useGuardarSalidaMutation,
    useObtenerSalidaQuery,
} from "../../../services/salida.service";
import { Link, useParams } from "react-router-dom";
import { useObtenerAlmacenesQuery } from "../../../services/almacen.service";
import { useObtenerOrigenesSalidasQuery } from "../../../services/origenSalida.service";
import FormLabelCounter from "../../../components/base/FormLabelCounter";
import { NumericFormat } from "react-number-format";
import BootstrapTable from "../../../components/base/BootstrapTable";
import {
    useEliminarSalidaProductoMutation,
    useObtenerSalidasProductosQuery,
} from "../../../services/salidaProducto.service";
import ConfirmDialog from "../../../components/base/ConfirmDialog";
import SalidasProductosModal from "./SalidasProductosModal";
import _ from "lodash";
import { toNumber } from "../../../helpers/toNumber";
import { toCurrency } from "../../../helpers/toCurrency";
import { useObtenerConfiguracionAlmacenPorTipoQuery } from "../../../services/configuracionAlmacen.service";
import { toast } from "react-toastify";

const SalidasProductosForm = () => {
    const { id: idSalida } = useParams();

    const {
        control,
        handleSubmit,
        setValue,
        register,
        reset,
        watch,
        formState: { errors },
    } = useForm();

    const { data: salida } = useObtenerSalidaQuery(idSalida, {
        skip: !idSalida,
        refetchOnMountOrArgChange: true,
    });
    const [guardarSalida] = useGuardarSalidaMutation();

    const { data: configuracion } =
        useObtenerConfiguracionAlmacenPorTipoQuery("s");
    const { data: almacenes } = useObtenerAlmacenesQuery();
    const { data: origenes } = useObtenerOrigenesSalidasQuery();

    const { data: productosData } = useObtenerSalidasProductosQuery(
        watch("idSalida"),
        { skip: !watch("idSalida") }
    );

    const [eliminarSalidaProducto] = useEliminarSalidaProductoMutation();

    const [confirmDialog, setConfirmDialog] = useState({});
    const [formProductos, setFormProductos] = useState(null);
    const [productos, setProductos] = useState([]);

    useEffect(() => {
        if (salida && almacenes && origenes) {
            reset({
                idSalida: salida.idSalida,
                numero: salida.numero,
                referencia: salida.referencia,
                fechaRegistro: salida.fechaRegistro,
                idAlmacen: salida.idAlmacen,
                idOrigenSalida: salida.idOrigenSalida,
                idEstatus: salida.idEstatus,
                fechaVersion: salida.fechaVersion,
            });
        } else if (almacenes && origenes && configuracion) {
            //default values
            reset({
                idEstatus: 1,
                idAlmacen: configuracion.idAlmacen,
                idOrigenSalida: configuracion.idOrigenSalida,
            });
        }
    }, [salida, almacenes, origenes, configuracion, reset]);

    //carga productos
    useEffect(() => {
        if (productosData) {
            setProductos(
                productosData.map((e) => {
                    return { ...e, uuid: uuidv4() };
                })
            );
        } else {
            //TODO: cargar guardados en store localstorage
        }
    }, [productosData, setProductos]);

    //actualiza cantidades
    useEffect(() => {
        if (productos) {
            let subTotal = 0;
            let impuesto = 0;
            let total = 0;

            //calcular cantidades
            productos.forEach((producto) => {
                let cantidad = toNumber(producto.cantidad);
                let precioUnitario = toNumber(producto.precioUnitario);
                let importeImpuesto = toNumber(producto.importeImpuesto);
                let importeNeto = toNumber(producto.importeNeto);
                let subProducto = _.multiply(cantidad, precioUnitario);

                subTotal = _.add(subTotal, subProducto);
                impuesto = _.add(impuesto, importeImpuesto);
                total = _.add(total, importeNeto);
            });

            setValue("subtotal", subTotal || "0");
            setValue("iva", impuesto || "0");
            setValue("total", total || "0");
        }
    }, [productos, setValue]);
    const actualizarProductos = (uuid, nuevoProducto) => {
        setProductos((prevProductos) => {
            // Verificar si idAReemplazar no es null
            if (prevProductos.find((e) => e.uuid === uuid)) {
                // Actualizar el producto existente si el ID coincide
                return prevProductos.map((producto) =>
                    producto.uuid === uuid ? nuevoProducto : producto
                );
            } else {
                // Agregar un nuevo producto al final del array
                return [...prevProductos, nuevoProducto];
            }
        });

        if (nuevoProducto.nuevo) {
            setFormProductos({});
        } else {
            setFormProductos(false);
        }
    };
    const onSubmit = async (formData) => {
        formData.salidasProductos = productos.map((producto) => {
            let data = { ...producto };
            data.idAlmacen = formData.idAlmacen;
            data.idEstatus = formData.idEstatus;
            return data;
        });

        //limpiar storage

        let { data } = await guardarSalida(formData);
        if (data) {
            setValue("idSalida", data.id);
            setValue("fechaVersion", data.fechaVersion);

            if (data.numero) {
                setValue("numero", data.numero);
                setValue("fechaRegistro", data.fechaRegistro);
            }
        }
    };

    return (
        <main id="main" className="main">
            <section className="section">
                <Card>
                    <Card.Body>
                        <fieldset>
                            <legend className="d-flex flex-column flex-md-row justify-content-between align-items-right">
                                <div className="fw-bold">
                                    <p className="fs-5 mb-0"> Salida de productos </p>
                                </div>

                                <div>
                                    <p className="fs-6 mb-0">
                                        *Los campos en negritas son requeridos{" "}
                                    </p>
                                </div>
                            </legend>
                        </fieldset>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Form.Control
                                type="text"
                                id="idSalida"
                                className="d-none"
                                {...register("idSalida")}
                            />
                            <Form.Control
                                type="text"
                                id="fechaVersion"
                                className="d-none"
                                {...register("fechaVersion")}
                            />

                            <Card>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="fw-bold">
                                                <p className="fs-3"> Información de registro </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <Form.Group className="mb-3">
                                                <Form.Label htmlFor="numero">
                                                    Número de salida
                                                </Form.Label>
                                                <Form.Control
                                                    {...register("numero")}
                                                    className="mwx-350"
                                                    disabled
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="col-md-3">
                                            <Form.Group className="mb-3">
                                                <Form.Label htmlFor="idAlmacen" className="fw-bold">
                                                    Almacen
                                                </Form.Label>
                                                <Form.Select
                                                    className="mwx-350"
                                                    id="idAlmacen"
                                                    {...register("idAlmacen", {
                                                        required: "Ingrese un dato válido",
                                                    })}
                                                    disabled={productos?.length}
                                                >
                                                    <option value="">Seleccionar</option>
                                                    {almacenes?.data &&
                                                        almacenes.data.map((almacen) => (
                                                            <option
                                                                key={"almacenes." + almacen.idAlmacen}
                                                                value={almacen.idAlmacen}
                                                            >
                                                                {almacen.almacen}
                                                            </option>
                                                        ))}
                                                </Form.Select>
                                                {errors.idAlmacen && (
                                                    <Alert
                                                        variant="danger"
                                                        role="alert"
                                                        className="mwx-350"
                                                    >
                                                        {errors.idAlmacen.message}
                                                    </Alert>
                                                )}
                                            </Form.Group>
                                        </div>

                                        <div className="col-md-3">
                                            <Form.Group className="mb-3">
                                                <Form.Label
                                                    htmlFor="idOrigenSalida"
                                                    className="fw-bold"
                                                >
                                                    Origen
                                                </Form.Label>
                                                <Form.Select
                                                    className="mwx-350"
                                                    id="idOrigenSalida"
                                                    {...register("idOrigenSalida", {
                                                        required: "Ingrese un dato válido",
                                                    })}
                                                >
                                                    <option value="">Seleccionar</option>
                                                    {origenes?.data &&
                                                        origenes.data.map((origen) => (
                                                            <option
                                                                key={"origenes." + origen.idOrigenSalida}
                                                                value={origen.idOrigenSalida}
                                                            >
                                                                {origen.origenSalida}
                                                            </option>
                                                        ))}
                                                </Form.Select>
                                                {errors.idOrigenSalida && (
                                                    <Alert
                                                        variant="danger"
                                                        role="alert"
                                                        className="mwx-350"
                                                    >
                                                        {errors.idOrigenSalida.message}
                                                    </Alert>
                                                )}
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                            <Card>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="fw-bold">
                                                <p className="fs-3">Detalles</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <Form.Group className="mb-3">
                                                <FormLabelCounter
                                                    htmlFor="referencia"
                                                    className="fw-bold mwx-350"
                                                    input={watch("referencia")}
                                                    maxLength={200}
                                                >
                                                    Referencia
                                                </FormLabelCounter>
                                                <Form.Control
                                                    as="textarea"
                                                    rows="4"
                                                    className="mwx-350"
                                                    {...register("referencia", {
                                                        required: "Ingrese un dato válido",
                                                        maxLength: {
                                                            value: 200,
                                                            message: "Ha excedido el máximo de caracteres",
                                                        },
                                                    })}
                                                />
                                                {errors.referencia && (
                                                    <Alert
                                                        variant="danger"
                                                        role="alert"
                                                        className="mwx-350"
                                                    >
                                                        {errors.referencia.message}
                                                    </Alert>
                                                )}
                                            </Form.Group>
                                        </div>

                                        <div className="col-md-3">
                                            <Form.Group className="mb-3">
                                                <Form.Label htmlFor="fechaRegistro">
                                                    Fecha de registro
                                                </Form.Label>
                                                <Form.Control
                                                    {...register("fechaRegistro")}
                                                    className="mwx-350"
                                                    disabled
                                                />
                                            </Form.Group>
                                        </div>

                                        <div className="col-md-3">
                                            <Form.Group className="mb-3">
                                                <Controller
                                                    name="idEstatus"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <>
                                                            <Form.Label htmlFor="idEstatus">
                                                                Estatus
                                                            </Form.Label>
                                                            <Form.Check
                                                                id="idEstatus"
                                                                type="switch"
                                                                label={
                                                                    Boolean(field.value) ? "Activo" : "Inactivo"
                                                                }
                                                                checked={Boolean(field.value)}
                                                                onChange={(e) => {
                                                                    field.onChange(Number(e.target.checked));
                                                                }}
                                                            />
                                                        </>
                                                    )}
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                            </Card>

                            <Card>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="fw-bold">
                                                <p className="fs-3">Productos</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="container">
                                    <div className="row">
                                        <fieldset className="mb-2 me-sm-5">
                                            <BootstrapTable
                                                striped
                                                search={false}
                                                toolbar={({ columns }) => (
                                                    <div className="me-auto">
                                                        <Button
                                                            className="me-2"
                                                            variant="primary"
                                                            onClick={(e) => {
                                                                if (watch("idAlmacen")) {
                                                                    setFormProductos({});
                                                                } else {
                                                                    toast.warn(
                                                                        <>
                                                                            <strong>Advertencia.</strong> <br />{" "}
                                                                            Seleccione un almacén{" "}
                                                                        </>
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <i className="bi bi-plus-square"></i>&nbsp;Añadir
                                                        </Button>
                                                    </div>
                                                )}
                                                data={productos || []}
                                                columns={[
                                                    {
                                                        header: "Producto",
                                                        accessorKey: "producto.producto",
                                                        wrapText: true,
                                                    },
                                                    {
                                                        header: "Código",
                                                        accessorKey: "producto.codigoProducto",
                                                    },
                                                    {
                                                        header: "Unidad de medida",
                                                        accessorKey: "unidadMedida.unidadMedida",
                                                    },
                                                    {
                                                        header: "Cantidad",
                                                        accessorKey: "cantidad",
                                                        className: "text-end",
                                                    },
                                                    {
                                                        header: "Precio unitario",
                                                        accessorKey: "precioUnitario",
                                                        className: "text-end",
                                                        cell: ({ row: { original } }) =>
                                                            toCurrency(original.precioUnitario),
                                                    },
                                                    {
                                                        header: "Impuesto",
                                                        accessorKey: "impuesto.impuesto",
                                                        className: "text-end",
                                                    },
                                                    {
                                                        header: "Importe impuesto",
                                                        accessorKey: "importeImpuesto",
                                                        className: "text-end",
                                                        cell: ({ row: { original } }) =>
                                                            toCurrency(original.importeImpuesto),
                                                    },
                                                    {
                                                        header: "Importe neto",
                                                        accessorKey: "importeNeto",
                                                        className: "text-end",
                                                        cell: ({ row: { original } }) =>
                                                            toCurrency(original.importeNeto),
                                                    },
                                                    {
                                                        header: "Acciones",
                                                        enableGlobalFilter: false,
                                                        cell: ({ row: { original } }) => (
                                                            <>
                                                                <Button
                                                                    variant="warning"
                                                                    className="me-2"
                                                                    size="sm"
                                                                    title="Editar"
                                                                    onClick={(e) => {
                                                                        if (watch("idAlmacen")) {
                                                                            setFormProductos(original);
                                                                        } else {
                                                                            toast.warn(
                                                                                <>
                                                                                    <strong>Advertencia.</strong> <br />{" "}
                                                                                    Seleccione un almacén{" "}
                                                                                </>
                                                                            );
                                                                        }
                                                                    }}
                                                                >
                                                                    <i className="bi bi-pencil-square"> </i>
                                                                </Button>
                                                                <Button
                                                                    variant="danger"
                                                                    className="me-2"
                                                                    size="sm"
                                                                    title="Eliminar"
                                                                    onClick={(e) =>
                                                                        setConfirmDialog({
                                                                            message: "¿Desea eliminar este registro?",
                                                                            onConfirm: async () => {
                                                                                if (original.idSalidaProducto) {
                                                                                    await eliminarSalidaProducto({
                                                                                        idSalidaProducto:
                                                                                            original.idSalidaProducto,
                                                                                    });
                                                                                }

                                                                                setProductos((prevProductos) => {
                                                                                    return prevProductos.filter(
                                                                                        (producto) =>
                                                                                            producto.uuid !== original.uuid
                                                                                    );
                                                                                });

                                                                                setConfirmDialog({});
                                                                            },
                                                                        })
                                                                    }
                                                                >
                                                                    <i className="bi bi-trash"> </i>
                                                                </Button>
                                                            </>
                                                        ),
                                                    },
                                                ]}
                                            />
                                            <ConfirmDialog
                                                {...confirmDialog}
                                                onCancel={() => setConfirmDialog({})}
                                            />
                                        </fieldset>
                                        <Form.Group className="mb-3 me-sm-5 text-end">
                                            <Form.Label column htmlFor="subtotal" className="fw-bold">
                                                Subtotal
                                            </Form.Label>
                                            <Controller
                                                render={({ field }) => (
                                                    <NumericFormat
                                                        className="form-control mwx-150 d-inline-block ms-2 text-end"
                                                        id="subtotal"
                                                        thousandSeparator={true}
                                                        prefix={"$ "}
                                                        decimalScale={2}
                                                        fixedDecimalScale
                                                        allowNegative={false}
                                                        value={field.value || ""}
                                                        onValueChange={(v) => {
                                                            field.onChange(
                                                                Number(v.value) > 0 ? Number(v.value) : "0"
                                                            );
                                                        }}
                                                        isAllowed={({ value }) =>
                                                            value?.split(".")[0].length <= 10
                                                        }
                                                        readOnly
                                                    />
                                                )}
                                                name="subtotal"
                                                control={control}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3 me-sm-5 text-end">
                                            <Form.Label column htmlFor="subtotal" className="fw-bold">
                                                Impuesto
                                            </Form.Label>
                                            <Controller
                                                render={({ field }) => (
                                                    <NumericFormat
                                                        className="form-control mwx-150 d-inline-block ms-2 text-end"
                                                        id="iva"
                                                        thousandSeparator={true}
                                                        prefix={"$ "}
                                                        decimalScale={2}
                                                        fixedDecimalScale
                                                        allowNegative={false}
                                                        value={field.value || ""}
                                                        onValueChange={(v) => {
                                                            field.onChange(
                                                                Number(v.value) > 0 ? Number(v.value) : "0"
                                                            );
                                                        }}
                                                        isAllowed={({ value }) =>
                                                            value?.split(".")[0].length <= 10
                                                        }
                                                        readOnly
                                                    />
                                                )}
                                                name="iva"
                                                control={control}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3 me-sm-5 text-end">
                                            <Form.Label column htmlFor="total" className="fw-bold">
                                                Total
                                            </Form.Label>
                                            <Controller
                                                render={({ field }) => (
                                                    <NumericFormat
                                                        className="form-control mwx-150 d-inline-block ms-2 text-end"
                                                        id="total"
                                                        thousandSeparator={true}
                                                        prefix={"$ "}
                                                        decimalScale={2}
                                                        fixedDecimalScale
                                                        allowNegative={false}
                                                        value={field.value || ""}
                                                        onValueChange={(v) => {
                                                            field.onChange(
                                                                Number(v.value) > 0 ? Number(v.value) : "0"
                                                            );
                                                        }}
                                                        isAllowed={({ value }) =>
                                                            value?.split(".")[0].length <= 10
                                                        }
                                                        readOnly
                                                    />
                                                )}
                                                name="total"
                                                control={control}
                                            />
                                        </Form.Group>
                                    </div>
                                </div>
                            </Card>
                            <Link
                                className="btn btn-secondary me-md-3 me-2"
                                to={"/salidas/productos"}
                            >
                                <i className="bi bi-box-arrow-in-left"></i> Regresar
                            </Link>
                            <Button type="submit" variant="primary" className="me-2">
                                <i className="bi bi-save"></i> Guardar
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            </section>
            {formProductos && (
                <SalidasProductosModal
                    show={formProductos}
                    idAlmacen={watch("idAlmacen")}
                    onHide={(e) => {
                        setFormProductos(false);
                    }}
                    onSave={actualizarProductos}
                />
            )}
        </main>
    );
};

export default SalidasProductosForm;
