import {
    useObtenerAlmacenesQuery,
    useEliminarAlmacenMutation,
    useLazyObtenerAlmacenesQuery,
} from "../../../services/almacen.service";
import React, { useState } from "react";
import { Button, Row, Col, Card } from "react-bootstrap";
import SeverPagination from "../../../components/base/SeverPagination";
import AlmacenesForm from "./AlmacenesForm";
import BootstrapTable from "../../../components/base/BootstrapTable";
import ConfirmDialog from "../../../components/base/ConfirmDialog";
import { ExportToExcel } from "../../../components/base/ExportToExcel";

const Almacenes = () => {
    const [pageNumber, setPageNumber] = useState(1);
    const [elementsPerPage, setElementsPerPage] = useState(10);

    const [formAlmacenes, setFormAlmacenes] = useState(null);
    const [confirmDialog, setConfirmDialog] = useState({});

    const { data: almacenes, refetch } = useObtenerAlmacenesQuery( [pageNumber, elementsPerPage], { refetchOnMountOrArgChange: true });
    const [trigger] = useLazyObtenerAlmacenesQuery();

    const [eliminarAlmacen] = useEliminarAlmacenMutation();

    return (
        <div id="main" className="main">
            <section className="section">
                <Row>
                    <Col lg={12}>
                        <Card>
                            <Card.Body>
                                <div className="btn-toolbar justify-content-between" role="toolbar"  >
                                    <div className="pagetitle">
                                        <h1>Almacenes</h1>
                                    </div>
                                </div>
                                {almacenes && (
                                    <BootstrapTable
                                        striped
                                        toolbar={({ columns }) => (
                                            <div>
                                                <Button href="#" className="me-2" variant="primary" onClick={(e) => {
                                                        setFormAlmacenes({});
                                                    }} >
                                                    <i className="bi bi-plus-square"></i>&nbsp;Nuevo
                                                </Button>
                                                <ExportToExcel columns={columns} onClick={trigger} fileName={"almacenes"}/>
                                            </div>
                                        )}
                                        data={almacenes.data}
                                        columns={[{
                                                header: "Nombre",
                                                accessorKey: "almacen",
                                            }, {
                                                header: "Clave",
                                                accessorKey: "clave",
                                            }, {
                                                header: "Dirección",
                                                accessorKey: "direccion",
                                                cell: ({ row: { original } }) => (
                                                    <>
                                                        {`Colonia: ${original.colonia}, Calle: ${original.calle} No.Ext.: ${original.numeroExterior}`}
                                                        {original.numeroInterior && ` No.Int.: ${original.numeroInterior}`}
                                                        {` ${original.municipio.municipio}, ${original.entidadFederativa.entidadFederativa}, ${original.pais.pais}`}
                                                    </>

                                                )
                                            }, {
                                                header: "Estatus",
                                                accessorKey: "estatus.estatus",
                                                enableGlobalFilter: false,
                                            },   {
                                                header: "Acciones",
                                                enableGlobalFilter: false,
                                                cell: ({ row: { original } }) => (
                                                    <div className="w-100 d-grid d-sm-block gap-1">
                                                        <Button
                                                            variant="warning"  className="me-2" size="sm" 
                                                            onClick={(e) => setFormAlmacenes(original)} >
                                                            <i className="bi bi-pencil-square"> </i>
                                                            Editar
                                                        </Button>
                                                        <Button
                                                            variant="danger" className="me-2"  size="sm" onClick={(e) => setConfirmDialog({
                                                                    message: "¿Desea eliminar este registro?",
                                                                    onConfirm: async () => {
                                                                        await eliminarAlmacen({
                                                                            idAlmacen: original.idAlmacen,
                                                                        });
                                                                        refetch();
                                                                        setConfirmDialog({});
                                                                    }
                                                                })} >
                                                            <i className="bi bi-trash"> </i>
                                                            Eliminar
                                                        </Button>
                                                    </div>
                                                ),
                                            },
                                        ]}
                                        onGlobalFilterChange={(e) => {
                                            setPageNumber(1);
                                            setElementsPerPage(e ? -1 : 10);
                                        }}
                                    />
                                )}
                                {almacenes && (
                                    <SeverPagination
                                        data={almacenes.data}
                                        currentPage={pageNumber}
                                        totalElements={almacenes.totalElements}
                                        elementsPerPage={elementsPerPage}
                                        onPageChange={setPageNumber}
                                        onElementsPerPageChange={setElementsPerPage}
                                    />
                                )}
                            </Card.Body>
                            <ConfirmDialog
                                {...confirmDialog} onCancel={() => setConfirmDialog({})} />
                        </Card>
                    </Col>
                </Row>
            </section>
            {formAlmacenes && ( <AlmacenesForm show={formAlmacenes} onHide={(e) => {
                        setFormAlmacenes(false);
                    }}
                                               onSave={refetch}
                />
            )}
        </div>
    );
};

export default Almacenes;
