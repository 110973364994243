import React, {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Alert, Button, Form, Modal} from "react-bootstrap";
import { useGuardarPrioridadMutation , useObtenerPrioridadQuery } from '../../../services/prioridades.service';


const PrioridadForm = ({show, onHide, onSave}) =>{
    const handleClose = (e) => {
        onHide(e);
    };

    const {control, handleSubmit, setValue, register, reset, formState: {errors}} = useForm();

    const {data: prioridad} = useObtenerPrioridadQuery(show.idPrioridad, {skip: !show.idPrioridad, refetchOnMountOrArgChange: true})
    const [guardarPrioridad] = useGuardarPrioridadMutation();

    useEffect(() => {
        if(prioridad) {
            reset ({
                idPrioridad: prioridad.idPrioridad,
                prioridad: prioridad.prioridad,
                color: prioridad.color,
                orden: prioridad.orden,
                idEstatus: prioridad.idEstatus,
                fechaVersion: prioridad.fechaVersion
            })
        } else {
            //default values
            reset({idEstatus: 1})
        }
    }, [prioridad, reset])

    const onSubmit = async (formData) => {
        let {data} = await guardarPrioridad(formData);
        if(data){
            setValue('idPrioridad', data.id)
            setValue('fechaVersion', data.fechaVersion)
            onSave();
        }
    }

    return(<>{
        <Modal show={show} onHide={handleClose} centered backdrop="static" keyboard={false}>
            <Form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header closeButton>
                    <Modal.Title>{show.idPrioridad ? 'Editar' : 'Nuevo'} [ Prioridad ]</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form.Control type="text" id="idPrioridad" className="d-none" {...register("idPrioridad")} />
                <Form.Control type="text" id="fechaVersion" className="d-none" {...register("fechaVersion")} />
                <Form.Group className="mb-3">
                        <Form.Label htmlFor="prioridad" className="fw-bold">
                            Prioridad
                        </Form.Label>
                        <Form.Control
                            {...register('prioridad', {
                                required: 'Ingrese un dato válido',
                                maxLength: {value: 20, message: 'Ha excedido el máximo de caracteres'}
                            })}

                        />
                        {errors.prioridad && (<Alert variant="danger" role="alert">
                        {errors.prioridad.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="color" className="fw-bold">
                            Color
                        </Form.Label>
                        <Form.Control
                            {...register('color', {
                                required: 'Ingrese un dato válido',
                                maxLength: {value: 20, message: 'Ha excedido el máximo de caracteres'}
                            })}

                        />
                        {errors.color && (<Alert variant="danger" role="alert">
                        {errors.color.message}
                        </Alert>)}
                    </Form.Group>
                        <Form.Group className="mb-3">
                        <Form.Label htmlFor="orden" className="fw-bold">
                            Orden
                        </Form.Label>
                        <Form.Control
                            {...register('orden', {
                                required: 'Ingrese un dato válido',
                                maxLength: {value: 2, message: 'Ha excedido el máximo de caracteres'}
                            })}
                        />
                        {errors.orden && (<Alert variant="danger" role="alert">
                            {errors.orden.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Controller
                            name="idEstatus"
                            control={control}
                            render={({field}) => (<>
                                    <Form.Label htmlFor="idEstatus">Estatus</Form.Label>
                                    <Form.Check
                                        id="idEstatus"
                                        type="switch"
                                        label={Boolean(field.value) ? 'Activo' : 'Inactivo'}
                                        checked={Boolean(field.value)}
                                        onChange={(e) => {
                                            field.onChange(Number(e.target.checked))
                                        }}
                                    />
                                </>

                            )}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                <Button type="submit" variant="primary" className="me-2">
                        <i className="bi bi-save"></i> Guardar
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal> }
    </>)
}

export default PrioridadForm