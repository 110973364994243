import React from 'react';
import {useObtenerReporteSolicitudPagoQuery} from '../../services/reporte.service';
import FileViewer from "../base/FileViewer";

const SolicitudPagoReporte = ({show, name, onHide}) => {
    const handleClose = () => {
        onHide();
    };

    const {datos: reporte} =
    useObtenerReporteSolicitudPagoQuery(show, {
        refetchOnMountOrArgChange: true,
    })?.data || {};

    return (
        <>
            {
                <FileViewer
                    title="Reporte de solicitud de pago"
                    file={reporte}
                    fileName={name}
                    showModal={reporte}
                    closeModal={handleClose}
                />
            }
        </>
    );
};

export default SolicitudPagoReporte;
