import React from "react";
import {Link} from "react-router-dom";

const SideBarItem = ({modulo, onClick: toggleSidebar}) => {
    return (<li className="nav-item">
        {!modulo.modulos?.length > 0 ? (
            <Link onClick={toggleSidebar} className="nav-link" to={modulo.url} >
                <i className={`bi bi-${modulo.icono}`}></i>
                <span>{modulo.modulo}</span>
            </Link>
        ) : (<>
            <Link className="nav-link collapsed" data-bs-target={`#mrs${modulo.idModulo}-nav`} data-bs-toggle="collapse"
                  aria-expanded="false" to={"#"}>
                <i className={`bi bi-${modulo.icono}`}></i><span>{modulo.modulo}</span>
                <i className="bi bi-chevron-down ms-auto"></i>
            </Link>
            <ul id={`mrs${modulo.idModulo}-nav`} className="nav-content collapse" data-bs-parent="#sidebar-nav">
                {
                    modulo.modulos.map((submodulo) => (
                        <SideBarItem
                            key={`modulo.${submodulo.idModulo}`}
                            modulo={submodulo}
                            onClick={toggleSidebar}/>
                    ))
                }
            </ul>
        </>)}
    </li>)
}

export default SideBarItem;