import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const coloresApi = createApi({
    reducerPath: 'coloresApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "colores",
        prepareHeaders: (headers, {getState}) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        obtenerColor: build.query({
            query: (idColor) => `/${idColor}`,
        }),
        obtenerColores: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        guardarColor: build.mutation({
            query: (color) => ({
                url: '',
                method: color.idColor ? 'PUT' : 'POST',
                body: color,
            }),
        }),
        eliminarColor: build.mutation({
            query: (color) => ({
                url: '',
                method: 'DELETE',
                body: color,
            }),
        }),
    }),
});
export const {
    useObtenerColorQuery,
    useObtenerColoresQuery,
    useLazyObtenerColoresQuery,
    useGuardarColorMutation,
    useEliminarColorMutation
} = coloresApi;
