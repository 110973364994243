import React from 'react';
import {Modal, Button, Form, Row} from 'react-bootstrap';
import {useObtenerTransporteQuery} from "../../../services/transporte.service";

const TransporteInfo = ({ show, onHide }) => {
    const handleClose = () => {
        onHide();
    };
    const { data: transporte } = useObtenerTransporteQuery(show);

    return (
        <>
            {transporte && (
                <Modal show={show} centered onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Información del transporte</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="tipoTransporte">Tipo de transporte</Form.Label>
                                <Form.Control type="text" id="tipoTransporte" disabled value={transporte.tipoTransporte?.tipoTransporte} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="claseTransporte">Clase de transporte</Form.Label>
                                <Form.Control type="text" id="claseTransporte" disabled value={transporte.claseTransporte?.claseTransporte} />
                            </Form.Group>
                            <Row>
                                <Form.Group className="mb-3 col-sm-6">
                                    <Form.Label htmlFor="marcaTransporte">Marca/Fabricante</Form.Label>
                                    <Form.Control  id="marcaTransporte" disabled value={transporte.marcaTransporte?.marcaTransporte
                                        + ((transporte.fabricanteTransporte?.fabricanteTransporte)? " / " + transporte.fabricanteTransporte.fabricanteTransporte : "")} />
                                </Form.Group>
                                <Form.Group className="mb-3 col-sm-6">
                                    <Form.Label htmlFor="modelo">Modelo</Form.Label>
                                    <Form.Control id="modelo" disabled value={transporte.modelo} />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Form.Group className="mb-3 col-sm-6">
                                    <Form.Label htmlFor="numeroPlaca">Placa</Form.Label>
                                    <Form.Control type="text" id="numeroPlaca" disabled value={transporte.numeroPlaca} />
                                </Form.Group>
                                <Form.Group className="mb-3 col-sm-6">
                                    <Form.Label htmlFor="anioFabricacion">Año de fabricación</Form.Label>
                                    <Form.Control type="text" id="anioFabricacion" disabled value={transporte.anioFabricacion} />
                                </Form.Group>
                            </Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
};

export default TransporteInfo;
