import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const configuracionesAlmacenesApi = createApi({
    reducerPath: 'configuracionesAlmacenesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "configuraciones/almacenes",
        prepareHeaders: (headers, {getState}) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        obtenerConfiguracionAlmacen: build.query({
            query: (idConfiguracionAlmacen) => `/${idConfiguracionAlmacen}`,
        }),
        obtenerConfiguracionAlmacenPorTipo: build.query({
            query: (tipo) => `/tipo/${tipo}`,
        }),
        obtenerConfiguracionesAlmacenes: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        guardarConfiguracionAlmacen: build.mutation({
            query: (configuracionAlmacen) => ({
                url: '',
                method: configuracionAlmacen.idConfiguracionAlmacen ? 'PUT' : 'POST',
                body: configuracionAlmacen,
            }),
        }),
        eliminarConfiguracionAlmacen: build.mutation({
            query: (configuracionAlmacen) => ({
                url: '',
                method: 'DELETE',
                body: configuracionAlmacen,
            }),
        }),
    }),
});
export const {
    useObtenerConfiguracionAlmacenQuery,
    useObtenerConfiguracionAlmacenPorTipoQuery,
    useObtenerConfiguracionesAlmacenesQuery,
    useLazyObtenerConfiguracionesAlmacenesQuery,
    useGuardarConfiguracionAlmacenMutation,
    useEliminarConfiguracionAlmacenMutation
} = configuracionesAlmacenesApi;
