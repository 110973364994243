import {toNumber} from "./toNumber";

export const toCurrency = (value) => {
    const val = toNumber(value);
    if (value === null || isNaN(val)) {
        return null;
    }
    const formatter = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN'
    });

    return formatter.format(val);
};
