import React, {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Alert, Button, Form, InputGroup, Modal} from "react-bootstrap";
import {useGuardarAlmacenMutation, useObtenerAlmacenQuery} from '../../../services/almacen.service';
import {useObtenerPaisesQuery} from "../../../services/pais.service";
import {useObtenerEntidadesFederativasPorPaisQuery} from "../../../services/entidadFederativa.service";
import {useObtenerMunicipiosPorEstadoQuery} from "../../../services/municipio.service";

const AlmacenesForm = ({show, onHide, onSave}) => {
    const handleClose = (e) => {
        onHide(e);
    };

    const {control, handleSubmit, setValue, register, reset, watch, setFocus, formState: {errors}} = useForm();

    const {data: almacen} = useObtenerAlmacenQuery(show.idAlmacen, {
        skip: !show.idAlmacen,
        refetchOnMountOrArgChange: true
    });
    const [guardarAlmacen] = useGuardarAlmacenMutation();

    const { data: paises } = useObtenerPaisesQuery();
    const { data: entidadesFederativas} = useObtenerEntidadesFederativasPorPaisQuery(watch("idPais"), {skip: !watch("idPais")});
    const { data: municipios} = useObtenerMunicipiosPorEstadoQuery(watch("idEntidadFederativa"), {skip: !watch("idEntidadFederativa")});

    //carga inicial
    useEffect(() => {
        if (almacen && paises) {
            reset({
                idAlmacen: almacen.idAlmacen,
                almacen: almacen.almacen,
                clave: almacen.clave,
                idPais: almacen.idPais,
                colonia: almacen.colonia,
                calle: almacen.calle,
                numeroExterior: almacen.numeroExterior,
                numeroInterior: almacen.numeroInterior,
                calle1: almacen.calle1,
                calle2: almacen.calle2,
                idEstatus: almacen.idEstatus,
                fechaVersion: almacen.fechaVersion
            });
        } else {
            // default values
            reset({idEstatus: 1})
        }
        setFocus("almacen")
    }, [almacen, paises, reset]);

    //carga entidades
    useEffect(() => {
        if (almacen && entidadesFederativas) {
            setValue('idEntidadFederativa', almacen.idEntidadFederativa);
        }
    }, [almacen, entidadesFederativas, setValue]);

    //carga municipios
    useEffect(() => {
        if (almacen && municipios) {
            setValue('idMunicipio', almacen.idMunicipio);
        }
    }, [almacen, municipios, setValue]);

    const onSubmit = async (formData) => {
        let {data} = await guardarAlmacen(formData);
        if (data) {
            setValue('idAlmacen', data.id);
            setValue('fechaVersion', data.fechaVersion);
            onSave();
        }
    };


    return (<> {
        < Modal className="modal-fixed" show={show} onHide={handleClose} centered backdrop="static" keyboard={false}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>{show.idAlmacen ? 'Editar' : 'Nuevo'} [Almacen]</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h6> *Los campos en negritas son requeridos</h6>
                    <Form.Control type="text" id="idAlmacen" className='d-none' {...register("idAlmacen")} />
                    <Form.Control type="text" id='fechaVersion' className='d-none' {...register("fechaVersion")} />

                    <Form.Group className='mb-3'>
                        <Form.Label htmlFor='almacen' className='fw-bold'>
                            Nombre
                        </Form.Label>
                        <Form.Control
                            className="mwx-350"
                            {...register('almacen', {
                                required: 'Ingrese un dato valido',
                                maxLength: {value: 200, message: 'Ha excedido el máximo de caracteres'}
                            })}
                        />
                        {errors.almacen && (<Alert variant='danger' role='alert'>
                            {errors.almacen.message}
                        </Alert>)}
                    </Form.Group>

                    <Form.Group className='mb-3'>
                        <Form.Label htmlFor='clave' className='fw-bold'>
                            Clave (Llave única)
                        </Form.Label>
                        <Form.Control
                            className="mwx-350"
                            {...register('clave', {
                                required: 'Ingrese un dato valido',
                                maxLength: {value: 100, message: 'Ha excedido el máximo de caracteres'}
                            })}
                        />
                        {errors.clave && (<Alert variant='danger' role='alert'>
                            {errors.clave.message}
                        </Alert>)}
                    </Form.Group>
                    <fieldset className="border rounded-3 p-3 py-1 mb-2">
                        <legend className="float-none px-2 mt-0 mb-2 fs-5 w-auto"> Domicilio</legend>
                        <Form.Group className='mb-3'>
                            <Form.Label htmlFor='idPais' className='fw-bold' >
                                País
                            </Form.Label>
                            <Form.Select
                                className="mwx-350"
                                id="idPais"
                                {...register("idPais", {
                                    required: "Ingrese un dato válido"
                                })}
                            >
                                <option value="">Seleccionar</option>
                                {paises?.data && paises.data.map((pais) => (
                                    <option key={'paises.' + pais.idPais}
                                            value={pais.idPais}>
                                        {pais.pais}
                                    </option>
                                ))}
                            </Form.Select>
                            {errors.idPais && (<Alert variant='danger' role='alert'>
                                {errors.idPais.message}
                            </Alert>)}
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label htmlFor='idEntidadFederativa' className='fw-bold' >
                                Estado
                            </Form.Label>
                            <Form.Select
                                className="mwx-350"
                                id="idEntidadFederativa"
                                {...register("idEntidadFederativa", {
                                    required: "Ingrese un dato válido"
                                })}
                            >
                                <option value="">Seleccionar</option>
                                {entidadesFederativas && entidadesFederativas.map((entidadFederativa) => (
                                    <option key={'entidadesFederativas.' + entidadFederativa.idEntidadFederativa}
                                            value={entidadFederativa.idEntidadFederativa}>
                                        {entidadFederativa.entidadFederativa}
                                    </option>
                                ))}
                            </Form.Select>
                            {errors.idEntidadFederativa && (<Alert variant='danger' role='alert'>
                                {errors.idEntidadFederativa.message}
                            </Alert>)}
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label htmlFor='idMunicipio' className='fw-bold' >
                                Municipio / Alcaldía
                            </Form.Label>
                            <Form.Select
                                className="mwx-350"
                                id="idMunicipio"
                                {...register("idMunicipio", {
                                    required: "Ingrese un dato válido"
                                })}
                            >
                                <option value="">Seleccionar</option>
                                {municipios && municipios.map((municipio) => (
                                    <option key={'municipio.' + municipio.idMunicipio}
                                            value={municipio.idMunicipio}>
                                        {municipio.municipio}
                                    </option>
                                ))}
                            </Form.Select>
                            {errors.idMunicipio && (<Alert variant='danger' role='alert'>
                                {errors.idMunicipio.message}
                            </Alert>)}
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label htmlFor='colonia' className='fw-bold'>
                                Colonia
                            </Form.Label>
                            <Form.Control
                                className="mwx-350"
                                {...register('colonia', {
                                    required: 'Ingrese un dato valido',
                                    maxLength: {value: 100, message: 'Ha excedido el máximo de caracteres'}
                                })}
                            />
                            {errors.colonia && (<Alert variant='danger' role='alert'>
                                {errors.colonia.message}
                            </Alert>)}
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label htmlFor='calle' className='fw-bold'>
                                Calle
                            </Form.Label>
                            <Form.Control
                                className="mwx-350"
                                {...register('calle', {
                                    required: 'Ingrese un dato valido',
                                    maxLength: {value: 100, message: 'Ha excedido el máximo de caracteres'}
                                })}
                            />
                            {errors.calle && (<Alert variant='danger' role='alert'>
                                {errors.calle.message}
                            </Alert>)}
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label htmlFor='numeroExterior' className='fw-bold'>
                                Número exterior
                            </Form.Label>
                            <Form.Control
                                className="mwx-350"
                                {...register('numeroExterior', {
                                    required: 'Ingrese un dato valido',
                                    maxLength: {value: 10, message: 'Ha excedido el máximo de caracteres'}
                                })}
                            />
                            {errors.numeroExterior && (<Alert variant='danger' role='alert'>
                                {errors.numeroExterior.message}
                            </Alert>)}
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label htmlFor='numeroInterior'>
                                Número interior
                            </Form.Label>
                            <Form.Control
                                className="mwx-350"
                                {...register('numeroInterior', {
                                    maxLength: {value: 10, message: 'Ha excedido el máximo de caracteres'}
                                })}
                            />
                            {errors.numeroInterior && (<Alert variant='danger' role='alert'>
                                {errors.numeroInterior.message}
                            </Alert>)}
                        </Form.Group>
                    </fieldset>
                    <fieldset className="border rounded-3 p-3 py-1 mb-2">
                        <legend className="float-none px-2 mt-0 mb-2 fs-5 w-auto"> ¿Entre qué calles está?</legend>
                        <Form.Group className='mb-3'>
                            <Form.Label htmlFor='calle1'>
                                Calle 1
                            </Form.Label>
                            <Form.Control
                                className="mwx-350"
                                {...register('calle1', {
                                    maxLength: {value: 100, message: 'Ha excedido el máximo de caracteres'}
                                })}
                            />
                            {errors.calle1 && (<Alert variant='danger' role='alert'>
                                {errors.calle1.message}
                            </Alert>)}
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label htmlFor='calle2'>
                                Calle 2
                            </Form.Label>
                            <Form.Control
                                className="mwx-350"
                                {...register('calle2', {
                                    maxLength: {value: 100, message: 'Ha excedido el máximo de caracteres'}
                                })}
                            />
                            {errors.calle2 && (<Alert variant='danger' role='alert'>
                                {errors.calle2.message}
                            </Alert>)}
                        </Form.Group>
                    </fieldset>
                    <Form.Group className='mb-3'>
                        <Controller
                            name='idEstatus'
                            control={control}
                            render={({field}) => (<>
                                    <Form.Label htmlFor='idEstatus'> Estatus </Form.Label>
                                    <Form.Check
                                        id='idEstatus'
                                        type='switch'
                                        label={Boolean(field.value) ? 'Activo' : 'Inactivo'}
                                        checked={Boolean(field.value)}
                                        onChange={(e) => {
                                            field.onChange(Number(e.target.checked))
                                        }}
                                    />
                                </>
                            )}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button type='submit' variant='primary' className='me-2'>
                        <i className='bi bi-save'></i> Guardar
                    </Button>
                    <Button variant='secondary' onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>}
    </>);
}

export default AlmacenesForm;