import React, {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Alert, Button, Form, Modal} from "react-bootstrap";
import {useGuardarBancoMutation, useObtenerBancoQuery} from "../../../services/banco.service";

const BancosForm = ({show, onHide, onSave}) => {

    const handleClose = (e) => {
        onHide(e);
    };

    const {control, handleSubmit, setValue, register, reset, watch, setFocus, formState: {errors}} = useForm();

    const {data: banco} = useObtenerBancoQuery(show.idBanco, {skip: !show.idBanco, refetchOnMountOrArgChange: true});
    const [guardarBanco] = useGuardarBancoMutation();

    useEffect(() => {
        if (banco) {
            reset({
                idBanco: banco.idBanco,
                banco: banco.banco,
                idEstatus: banco.idEstatus,
                fechaVersion: banco.fechaVersion
            });
        }

    }, [banco, reset]);

    useEffect(() => {
        if(!show.idBanco){
            setFocus("banco");
        }
    }, [show, setFocus]);

    const onSubmit = async (formData) => {
        let {data} = await guardarBanco(formData);
        if(data){
            setValue('idBanco', data.id);
            setValue('fechaVersion', data.fechaVersion);
            onSave();
        }
    };

    return (<> {
        <Modal show={show} onHide={handleClose} centered backdrop="static" keyboard={false}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>{watch("idBanco") ? 'Editar' : 'Nuevo'} [ Banco ]</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="text" id="idBanco" className="d-none" {...register("idBanco")} />
                    <Form.Control type="text" id="fechaVersion" className="d-none" {...register("fechaVersion")} />
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="banco" className="fw-bold">
                            Banco
                        </Form.Label>
                        <Form.Control
                            {...register('banco', {
                                required: 'Ingrese un dato válido',
                                maxLength: {value: 30, message: 'Ha excedido el máximo de caracteres'}
                            })}
                        />
                        {errors.banco && (<Alert variant="danger" role="alert">
                            {errors.banco.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Controller
                            name="idEstatus"
                            control={control}
                            defaultValue="1"
                            render={({field}) => (<>
                                    <Form.Label htmlFor="idEstatus">Estatus</Form.Label>
                                    <Form.Check
                                        id="idEstatus"
                                        type="switch"
                                        label={Boolean(field.value) ? 'Activo' : 'Inactivo'}
                                        checked={Boolean(field.value)}
                                        onChange={(e) => {
                                            field.onChange(Number(e.target.checked))
                                        }}
                                    />
                                </>

                            )}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant="primary" className="me-2">
                        <i className="bi bi-save"></i> Guardar
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>}
    </>);
};

export default BancosForm;
