import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const estatusApi = createApi({
    reducerPath: 'estatusApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "estatus",
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (build) => ({
        obtenerEstatus: build.query({
            query: () => '/procesos/E',
        }),
        obtenerEstatusPorTipo: build.query({
            query: (idTipoEstatus) => `/${idTipoEstatus}`,
        }),
        obtenerEstatusEtapa: build.query({
            query: ([idSolicitudPago, idEtapa]) => `/${idSolicitudPago}/${idEtapa}`,
        }),
        obtenerEstatusProcesoEtapa: build.query({
            query: ([idSolicitud, idProceso, idEtapa]) => `/procesos/${idSolicitud}/${idProceso}/${idEtapa}`,
        })

    }),
});

export const { useObtenerEstatusQuery , useObtenerEstatusPorTipoQuery, useObtenerEstatusEtapaQuery, useObtenerEstatusProcesoEtapaQuery } = estatusApi;
