import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const entidadesFederativasApi = createApi({
    reducerPath: 'entidadesFederativasApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "entidades/federativas",
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        obtenerEntidadFederativa: build.query({
            query: (idEntidadFederativa) => `/${idEntidadFederativa}`,
        }),
        obtenerEntidadesFederativas: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        obtenerEntidadesFederativasPorPais: build.query({
            query: (idPais) => `/pais/${idPais}`,
        }),
        guardarEntidadFederativa: build.mutation({
            query: (entidadFederativa) => ({
                url: '',
                method: entidadFederativa.idEntidadFederativa ? 'PUT' : 'POST',
                body: entidadFederativa,
            }),
        }),
        eliminarEntidadFederativa: build.mutation({
            query: (entidadFederativa) => ({
                url: '',
                method: 'DELETE',
                body: entidadFederativa,
            }),
        }),
    }),
});

export const {
    useObtenerEntidadFederativaQuery,
    useObtenerEntidadesFederativasQuery,
    useObtenerEntidadesFederativasPorPaisQuery,
    useLazyObtenerEntidadesFederativasQuery,
    useGuardarEntidadFederativaMutation,
    useEliminarEntidadFederativaMutation
} = entidadesFederativasApi;
