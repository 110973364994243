import React from 'react';
import {
    useGuardarObservacionesMutation,
} from "../../services/observacion.service";
import {useForm} from 'react-hook-form';
import {Alert, Button, Form, Modal} from "react-bootstrap";
import FormLabelCounter from "../base/FormLabelCounter";

const ObservacionForm = ({show, onHide, onSubmit: solicitudSubmit}) => {
    const handleClose = () => {
        onHide();
    };
    const [guardarObservaciones] = useGuardarObservacionesMutation();

    const onSubmit = async (formData) => {
        formData.idOrigen = show.idSolicitudPago;
        formData.idEtapa = show.idEtapa;
        formData.idEstatus = show.idEstatus;
        formData.idProceso = show.idProceso;

        await guardarObservaciones(formData);
        solicitudSubmit(show.urlBase);
        handleClose();
    };

    const {handleSubmit, register, watch, formState: {errors}} = useForm();

    return (
        <> {
               <Modal show={show} onHide={handleClose} centered backdrop="static" keyboard={false}>
                   <Form onSubmit={handleSubmit(onSubmit)}>
                       <Modal.Header closeButton>
                           <Modal.Title>Observaciones ({show.accion})</Modal.Title>
                       </Modal.Header>
                       <Modal.Body>
                           <Form.Group className="mb-3">
                               <FormLabelCounter
                                   htmlFor="observacion"
                                   className="fw-bold"
                                   input={watch('observacion')}
                                   maxLength={2000}
                               >
                                   Observaciones
                               </FormLabelCounter>
                               <Form.Control
                                   as="textarea"
                                   rows="4"
                                   {...register('observacion', {
                                       required: 'Ingrese un dato válido',
                                       maxLength: {value: 2000, message: 'Ha excedido el máximo de caracteres'}
                                   })}
                               />
                               {errors.observacion && (
                                   <Alert variant="danger"  role="alert">
                                       {errors.observacion?.message}
                                   </Alert>
                               )}
                           </Form.Group>
                       </Modal.Body>
                       <Modal.Footer>
                           <Button type="submit" variant="primary" className="me-4">
                               <i className="bi bi-save"></i> Guardar
                           </Button>
                           <Button variant="secondary" onClick={handleClose}>
                               Cerrar
                           </Button>
                       </Modal.Footer>
                   </Form>
               </Modal>
        }
        </>
    );
};

export default ObservacionForm;
