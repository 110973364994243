import React, {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Alert, Button, Form, Modal} from "react-bootstrap";
import {useGuardarConfiguracionCorreoMutation, useObtenerConfiguracionCorreoQuery} from "../../../services/configuracionCorreo.service";

const ConfiguracionesCorreosForm = ({show, onHide, onSave}) => {

    const handleClose = (e) => {
        onHide(e);
    };

    const {control, handleSubmit, setValue, register, reset, formState: {errors}} = useForm();

    const {data: configuracionCorreo} = useObtenerConfiguracionCorreoQuery(show.idConfiguracionCorreo, {skip: !show.idConfiguracionCorreo, refetchOnMountOrArgChange: true});
    const [guardarConfiguracionCorreo] = useGuardarConfiguracionCorreoMutation();

    useEffect(() => {
        if (configuracionCorreo) {
            reset({
                idConfiguracionCorreo: configuracionCorreo.idConfiguracionCorreo,
                idProtocoloCorreo:configuracionCorreo.idProtocoloCorreo,
                servidor:configuracionCorreo.servidor,
                puerto:configuracionCorreo.puerto,
                usuarioCorreo:configuracionCorreo.usuarioCorreo,
                password:configuracionCorreo.password,
                predeterminado:configuracionCorreo.predeterminado,
                configuracionCorreo: configuracionCorreo.configuracionCorreo,
                fechaVersion: configuracionCorreo.fechaVersion
            });
        } else {
            //default values
            reset({predeterminado: true})
        }
    }, [configuracionCorreo, reset]);

    const onSubmit = async (formData) => {
        let {data} = await guardarConfiguracionCorreo(formData);
        if(data){
            setValue('idConfiguracionCorreo', data.id);
            setValue('fechaVersion', data.fechaVersion);
            onSave();
        }
    };

    return (<> {
        <Modal show={show} onHide={handleClose} centered backdrop="static" keyboard={false}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>{show.idConfiguracionCorreo ? 'Editar' : 'Nuevo'} [ ConfiguracionCorreo ]</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="text" id="idConfiguracionCorreo" className="d-none" {...register("idConfiguracionCorreo")} />
                    <Form.Control type="number" id="idProtocoloCorreo" className="d-none" {...register("idProtocoloCorreo")} />
                    <Form.Control type="text" id="servidor" className="d-none" {...register("servidor")} />
                    <Form.Control type="number" id="puerto" className="d-none" {...register("puerto")} />
                    <Form.Control type="text" id="usuarioCorreo" className="d-none" {...register("usuarioCorreo")} />
                    <Form.Control type="text" id="password" className="d-none" {...register("password")} />
                    <Form.Control type="text" id="fechaVersion" className="d-none" {...register("fechaVersion")} />
                    <Form.Group className="mb-3">
                    <Form.Label htmlFor="configuracionCorreo" className="fw-bold">
                            Protocolo Correo
                        </Form.Label>
                        <Form.Control
                            {...register('idProtocoloCorreo', {
                                required: 'Ingrese un dato válido',
                                maxLength: {value: 4, message: 'Ha excedido el máximo de caracteres'}
                            })}
                        />
                        <Form.Label htmlFor="configuracionCorreo" className="fw-bold">
                            Servidor
                        </Form.Label>
                        <Form.Control
                            {...register('servidor', {
                                required: 'Ingrese un dato válido',
                                maxLength: {value: 50, message: 'Ha excedido el máximo de caracteres'}
                            })}
                        />
                        <Form.Label htmlFor="configuracionCorreo" className="fw-bold">
                            Puerto
                        </Form.Label>
                        <Form.Control
                            {...register('puerto', {
                                required: 'Ingrese un dato válido',
                                maxLength: {value: 4, message: 'Ha excedido el máximo de caracteres'}
                            })}
                        />
                        <Form.Label htmlFor="configuracionCorreo" className="fw-bold">
                            Usuario Correo
                        </Form.Label>
                        <Form.Control
                            {...register('usuarioCorreo', {
                                required: 'Ingrese un dato válido',
                                maxLength: {value: 60, message: 'Ha excedido el máximo de caracteres'}
                            })}
                        />
                        <Form.Label htmlFor="configuracionCorreo" className="fw-bold">
                            Password
                        </Form.Label>
                        <Form.Control
                            {...register('password', {
                                required: 'Ingrese un dato válido',
                                maxLength: {value: 255, message: 'Ha excedido el máximo de caracteres'}
                            })}
                        />
                        {errors.configuracionCorreo && (<Alert variant="danger" role="alert">
                            {errors.configuracionCorreo.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Controller
                            name="predeterminado"
                            control={control}
                            render={({field}) => (<>
                                    <Form.Label htmlFor="predeterminado">Predeterminado</Form.Label>
                                    <Form.Check
                                        id="predeterminado"
                                        type="switch"
                                        label={Boolean(field.value) ? 'Verdadero' : 'Falso'}
                                        checked={Boolean(field.value)}
                                        onChange={(e) => {
                                            field.onChange(Number(e.target.checked))
                                        }}
                                    />
                                </>

                            )}
                        />
                        
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant="primary" className="me-2">
                        <i className="bi bi-save"></i> Guardar
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>}
    </>);
};

export default ConfiguracionesCorreosForm;
