import React, {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Alert, Button, Col, Form, Modal, Row} from "react-bootstrap";
import {
    useGuardarUsuarioMutation,
    useLazyObtenerUsuarioQuery,
    useObtenerUsuarioQuery
} from "../../../services/usuario.service";
import {useObtenerRolesQuery} from "../../../services/rol.service";
import dayjs from "dayjs";

const UsuariosForm = ({show, onHide, onSave}) => {

    const handleClose = () => {
        onHide();
    };

    const {control, handleSubmit, setValue, register, reset, watch, formState: {errors}} = useForm();

    const {data: usuario} = useObtenerUsuarioQuery(show.usuario, {skip: !show.usuario});
    const [guardarUsuario] = useGuardarUsuarioMutation();

    const [getUsuario] = useLazyObtenerUsuarioQuery();
    const {data: roles} = useObtenerRolesQuery();

    useEffect(() => {
        if (usuario && roles) {
            reset({
                fechaVersion: usuario.fechaVersion,
                usuario: usuario.usuario,
                nombre: usuario.nombre,
                primerApellido: usuario.primerApellido,
                segundoApellido: usuario.segundoApellido,
                idRol: usuario.idRol,
                idEstatus: usuario.idEstatus
            });
        } else {
            //default values
            reset({idEstatus: 1})
        }
    }, [usuario, roles, reset]);

    const onSubmit = async (formData) => {
        delete formData.rawPassword;
        if(!formData.password){
            delete formData.password;
        }
        let {data} = await guardarUsuario(formData);
        if(data){
            setValue('fechaVersion', data.fechaVersion);
            onSave && onSave();
        }

    };

    return (<> {<Modal show={show} onHide={handleClose} centered backdrop="static" keyboard={false}>
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header closeButton>
                <Modal.Title>{show.usuario ? 'Editar' : 'Nuevo'} [ Usuario ]</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Control type="text" id="super" className="d-none" value="N"  {...register("super")} />
                <Form.Control type="text" id="fechaPassword" className="d-none" value={dayjs().add(3, 'day').format('DD/MM/YYYY')}  {...register("fechaPassword")} />
                <Form.Control type="text" id="fechaVersion" className="d-none" {...register("fechaVersion")} />
                <Form.Group className="mb-3 mwx-250">
                    <Form.Label htmlFor="usuario" className="fw-bold">
                        Usuario
                    </Form.Label>
                    <Form.Control
                        disabled={watch("fechaVersion")}
                        {...register('usuario', {
                            required: 'Ingrese un dato válido',
                            maxLength: {value: 30, message: 'Ha excedido el máximo de caracteres'},
                            validate: async (value) => {
                                if(watch("fechaVersion")) return true;

                                let {data} = await getUsuario(value);
                                return !data || "El usuario ya existe"
                            }
                        })}
                    />
                    {errors.usuario && (<Alert variant="danger" role="alert">
                        {errors.usuario.message}
                    </Alert>)}
                </Form.Group>
                <Form.Group className="mb-3 mwx-250">
                    <Form.Label htmlFor="nombre" className="fw-bold">
                        Nombre(s)
                    </Form.Label>
                    <Form.Control
                        {...register('nombre', {
                            required: 'Ingrese un dato válido',
                            maxLength: {value: 30, message: 'Ha excedido el máximo de caracteres'}
                        })}
                    />
                    {errors.nombre && (<Alert variant="danger" role="alert">
                        {errors.nombre.message}
                    </Alert>)}
                </Form.Group>
                <Form.Group className="mb-3 mwx-250">
                    <Form.Label htmlFor="primerApellido" className="fw-bold">
                        Primer Apellido
                    </Form.Label>
                    <Form.Control
                        {...register('primerApellido', {
                            required: 'Ingrese un dato válido',
                            maxLength: {value: 30, message: 'Ha excedido el máximo de caracteres'}
                        })}
                    />
                    {errors.primerApellido && (<Alert variant="danger" role="alert">
                        {errors.primerApellido.message}
                    </Alert>)}
                </Form.Group>
                <Form.Group className="mb-3 mwx-250">
                    <Form.Label htmlFor="segundoApellido">
                        Segundo Apellido
                    </Form.Label>
                    <Form.Control
                        {...register('segundoApellido', {
                            maxLength: {value: 30, message: 'Ha excedido el máximo de caracteres'}
                        })}
                    />
                    {errors.segundoApellido && (<Alert variant="danger" role="alert">
                        {errors.segundoApellido.message}
                    </Alert>)}
                </Form.Group>
                <Row className="mb-3">
                    <Form.Group as={Col} sm={6}>
                        <Form.Label htmlFor="password" className={show.nuevo ? 'fw-bold' : ''}>
                            Contraseña
                        </Form.Label>
                        <Form.Control
                            className="mwx-250"
                            {...register('password', {
                                required: show.nuevo ? 'Ingrese un dato válido' : false
                            })}
                        />
                        {errors.password && (<Alert variant="danger" role="alert">
                            {errors.password.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group as={Col} sm={6}>
                        <Form.Label htmlFor="rawPassword" className={show.nuevo ? 'fw-bold' : ''}>
                            Confirmar contraseña
                        </Form.Label>
                        <Form.Control
                            className="mwx-250"
                            {...register('rawPassword', {
                                required: show.nuevo ? 'Ingrese un dato válido' : false,
                                validate: (value, formValues) => show.nuevo ?
                                    value === formValues.password || "Las contraseñas deben coincidir" : true
                            })}
                        />
                        {errors.rawPassword && (<Alert variant="danger" role="alert">
                            {errors.rawPassword.message}
                        </Alert>)}
                    </Form.Group>
                </Row>
                <Form.Group className="mb-3">
                    <Form.Label htmlFor="idRol" className="fw-bold">Rol</Form.Label>
                    <Form.Select
                        className="mwx-250"
                        id="idRol"
                        {...register("idRol", {
                            required: "Ingrese un dato válido"
                        })}
                    >
                        <option value="">Seleccionar</option>
                        {roles?.data &&
                            roles.data?.map((rol) => (
                                <option key={'rol.' + rol.idRol}
                                        value={rol.idRol}>
                                    {rol.rol}
                                </option>
                            ))}
                    </Form.Select>
                    {errors.idRol && (
                        <Alert variant="danger" className="mwx-400">
                            {errors.idRol.message}
                        </Alert>
                    )}
                </Form.Group>
                <Form.Group className="mb-3">
                    <Controller
                        name="idEstatus"
                        control={control}
                        render={({field}) => (<>
                                <Form.Label htmlFor="idEstatus">Estatus</Form.Label>
                                <Form.Check
                                    id="idEstatus"
                                    type="switch"
                                    label={Boolean(field.value) ? 'Activo' : 'Inactivo'}
                                    checked={Boolean(field.value)}
                                    onChange={(e) => {
                                        field.onChange(Number(e.target.checked))
                                    }}
                                />
                            </>

                        )}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button type="submit" variant="primary" className="me-4">
                    <i className="bi bi-save"></i> Guardar
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Form>
    </Modal>}
    </>);
};

export default UsuariosForm;
