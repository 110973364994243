import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { useGuardarLocalidadMutation, useObtenerLocalidadQuery } from '../../../services/localidad.service';

const LocalidadesForm = ({ show, onHide, onSave }) => {
    const handleClose = (e) => {
        onHide(e)
    }

    const { control, handleSubmit, setValue, register, reset, formState: { errors } } = useForm()

    const {data: localidad} = useObtenerLocalidadQuery(show.idLocalidad, {skip:! show.idLocalidad, refetchOnMountOrArgChange:true})
    const [guardarLocalidad] = useGuardarLocalidadMutation()

    useEffect(() => {
        if (localidad) {
            reset({
                idLocalidad: localidad.idLocalidad,
                localidad: localidad.localidad,
                idEstatus: localidad.idEstatus
            })
        } else {
            // default values
            reset({ idEstatus: 1})
        }
    }, [localidad, reset])

    const onSubmit = async (formData) =>{
        let {data} = await guardarLocalidad(formData)
        if (data) {
            setValue('idLocalidad', data.id)
            setValue('fechaVersion', data.fechaVersion)
            onSave()
        }
    }

    return (<> {
        <Modal show={show} onHide={handleClose} centered backdrop='static' keyboard={false}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {show.idLocalidad ? 'Editar' : 'Nuevo'} [Localidad]
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type='text' id='idLocalidad' className='d-none' {...register('idLocalidad')} />
                    <Form.Control type='text' id='fechaVersion' className='d-none' {...register('fechaVersion')} />
                    <Form.Group className='mb-3' >
                        <Form.Label>
                            Nombre
                        </Form.Label>
                        <Form.Control
                            {...register('localidad', {
                                required:'Ingrese un dato valido',
                                maxLength: { value: 255, message: 'Ha excedido el máximo de caracteres'}
                            })}
                        />
                        {errors.localidad && (<Alert variant='danger' role='alert'>
                            {errors.localidad.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className='mb-3'>
                        <Controller 
                            name='idEstatus'
                            control={control}
                            render={({ field }) => ( <>
                                <Form.Label htmlFor='idEstatus'> Estatus </Form.Label>
                                <Form.Check  
                                    id='idEstatus'
                                    type='switch'
                                    label={Boolean(field.value) ? 'Activo' : 'Inactivo'}
                                    checked={Boolean(field.value)}
                                    onChange={(e) => {
                                        field.onChange(Number(e.target.checked))
                                    }}
                                />
                            </>
                            )}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button type='submit' variant='primary' className='me-2'>
                        <i className='bi bi-save'></i> Guardar
                    </Button>
                    <Button variant='secondary' onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal> }
    </>)
}

export default LocalidadesForm