import React from "react";
import {Link, useParams} from "react-router-dom";
import {useObtenerBandejaSolicitudesQuery} from "../services/consulta.service";
import {Card, Col, Row} from "react-bootstrap";

const Bandejas = () => {

    const {id: idEstatus} = useParams();
    const {data: bandejaSolicitudes} = useObtenerBandejaSolicitudesQuery(idEstatus, {refetchOnMountOrArgChange: true});

    return (<>
            <main id="main" className="main">
                <div className="pagetitle">
                    <h1>Bandeja Principal de Autorización</h1>
                </div>
                <section className="section">
                    <Row>
                    {bandejaSolicitudes?.map( (bandeja) => (
                        <Col key={bandeja.empresa} lg={3} md={4} sm={12}>
                            <Card>
                                <Card.Body className="text-center">
                                    <Card.Img src={bandeja.logo} className="card-img-top" fluid={"true"} />
                                    <Card.Title>{bandeja.empresa}</Card.Title>
                                    <Link className="btn btn-primary"  to={"/solicitudes/pagos"} state={{ buscador: bandeja.empresa, idEstatus: idEstatus}} >
                                        {bandeja.numero} solicitud{bandeja.numero > 1 ? 'es' : ''}
                                    </Link>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                    </Row>
                </section>
            </main>
        </>);
};

export default Bandejas;
