import React, {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Alert, Button, Form, Modal} from "react-bootstrap";
import {
    useGuardarConfiguracionAlmacenMutation,
    useObtenerConfiguracionAlmacenQuery
} from "../../../services/configuracionAlmacen.service";
import {useObtenerAlmacenesQuery} from "../../../services/almacen.service";
import {
    useObtenerSeccionesAlmacenesPorAlmacenQuery,
} from "../../../services/seccionAlmacen.service";
import {useObtenerOrigenesEntradasQuery} from "../../../services/origenEntrada.service";
import {useObtenerImpuestosQuery} from "../../../services/impuesto.service";
import {useObtenerOrigenesSalidasQuery} from "../../../services/origenSalida.service";

const ConfiguracionesAlmacenesForm = ({show, onHide, onSave}) => {

    const handleClose = (e) => {
        onHide(e);
    };

    const {control, handleSubmit, setValue, register, reset, watch, formState: {errors}} = useForm();

    const {data: configuracionAlmacen} = useObtenerConfiguracionAlmacenQuery(show.idConfiguracionAlmacen, {
        skip: !show.idConfiguracionAlmacen,
        refetchOnMountOrArgChange: true
    });
    const [guardarConfiguracionAlmacen] = useGuardarConfiguracionAlmacenMutation();

    const {data: almacenes} = useObtenerAlmacenesQuery();
    const {data: secciones} = useObtenerSeccionesAlmacenesPorAlmacenQuery(watch("idAlmacen"), {skip: !watch("idAlmacen")});
    const {data: origenesEntradas} = useObtenerOrigenesEntradasQuery();
    const {data: origenesSalidas} = useObtenerOrigenesSalidasQuery();
    const {data: impuestos} = useObtenerImpuestosQuery();

    useEffect(() => {
        if (configuracionAlmacen && almacenes && origenesEntradas && origenesSalidas &&impuestos) {
            reset({
                idConfiguracionAlmacen: configuracionAlmacen.idConfiguracionAlmacen,
                tipo: configuracionAlmacen.tipo,
                idAlmacen: configuracionAlmacen.idAlmacen,
                idOrigenEntrada: configuracionAlmacen.idOrigenEntrada > 0 ? configuracionAlmacen.idOrigenEntrada : null,
                idOrigenSalida: configuracionAlmacen.idOrigenSalida > 0 ? configuracionAlmacen.idOrigenSalida : null,
                idImpuesto: configuracionAlmacen.idImpuesto,
                idEstatus: configuracionAlmacen.idEstatus,
                fechaVersion: configuracionAlmacen.fechaVersion
            });
        } else {
            //default values
            reset({idEstatus: 1})
        }
    }, [configuracionAlmacen, almacenes, origenesEntradas, origenesSalidas, impuestos, reset]);

    //carga secciones
    useEffect(() => {
        if (secciones?.data && configuracionAlmacen) {
            setValue('idSeccionAlmacen', configuracionAlmacen.idSeccionAlmacen);

        }
    }, [configuracionAlmacen, secciones, setValue]);

    const onSubmit = async (formData) => {
        let {data} = await guardarConfiguracionAlmacen(formData);
        if (data) {
            setValue('idConfiguracionAlmacen', data.id);
            setValue('fechaVersion', data.fechaVersion);
            onSave();
        }
    };

    return (<> {
        <Modal show={show} onHide={handleClose} centered backdrop="static" keyboard={false}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>{watch("idConfiguracionAlmacen") ? 'Editar' : 'Nuevo'} [ Configuración de almacen
                        ]</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="text" id="idConfiguracionAlmacen"
                                  className="d-none" {...register("idConfiguracionAlmacen")} />
                    <Form.Control type="text" id="fechaVersion" className="d-none" {...register("fechaVersion")} />
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="tipo" className="fw-bold">
                            Tipo
                        </Form.Label>
                        <Form.Control
                            className="mwx-350"
                            {...register('tipo', {
                                required: 'Ingrese un dato válido',
                                maxLength: {value: 2, message: 'Ha excedido el máximo de caracteres'}
                            })}
                            readOnly
                        />
                        {errors.tipo && (<Alert variant="danger" role="alert">
                            {errors.tipo.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className='mb-3'>
                        <Form.Label htmlFor='idAlmacen' className='fw-bold'>
                            Almacen
                        </Form.Label>
                        <Form.Select
                            className="mwx-350"
                            id="idAlmacen"
                            {...register("idAlmacen", {
                                required: "Ingrese un dato válido"
                            })}
                        >
                            <option value="">Seleccionar</option>
                            {almacenes?.data && almacenes.data.map((almacen) => (
                                <option key={'almacenes.' + almacen.idAlmacen}
                                        value={almacen.idAlmacen}>
                                    {almacen.almacen}
                                </option>
                            ))}
                        </Form.Select>
                        {errors.idAlmacen && (<Alert variant='danger' role='alert' className="mwx-350">
                            {errors.idAlmacen.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className='mb-3'>
                        <Form.Label htmlFor='idSeccionAlmacen' className='fw-bold'>
                            Sección
                        </Form.Label>
                        <Form.Select
                            className="mwx-350"
                            id="idSeccionAlmacen"
                            {...register("idSeccionAlmacen", {
                                required: "Ingrese un dato válido"
                            })}
                        >
                            <option value="">Seleccionar</option>
                            {secciones?.data && secciones.data.map((seccion) => (
                                <option key={'secciones.' + seccion.idSeccionAlmacen}
                                        value={seccion.idSeccionAlmacen}>
                                    {seccion.seccionAlmacen}
                                </option>
                            ))}
                        </Form.Select>
                        {errors.idSeccionAlmacen && (<Alert variant='danger' role='alert' className="mwx-350">
                            {errors.idSeccionAlmacen.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className='mb-3'>
                        <Form.Label htmlFor='idImpuesto' className='fw-bold'>
                            Impuesto
                        </Form.Label>
                        <Form.Select
                            className="mwx-350"
                            id="idImpuesto"
                            {...register("idImpuesto", {
                                required: "Ingrese un dato válido"
                            })}
                        >
                            <option value="">Seleccionar</option>
                            {impuestos?.data && impuestos.data.map((impuesto) => (
                                <option key={'impuestos.' + impuesto.idImpuesto}
                                        value={impuesto.idImpuesto}>
                                    {impuesto.impuesto} -   {impuesto.valor}%
                                </option>
                            ))}
                        </Form.Select>
                        {errors.idImpuesto && (<Alert variant='danger' role='alert'>
                            {errors.idImpuesto.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className='mb-3'>
                        <Form.Label htmlFor='idOrigenEntrada'>
                            Origen de entrada
                        </Form.Label>
                        <Form.Select
                            className="mwx-350"
                            id="idOrigenEntrada"
                            {...register("idOrigenEntrada")}
                        >
                            <option value="">Seleccionar</option>
                            {origenesEntradas?.data && origenesEntradas.data.map((origen) => (
                                <option key={'origenesEntradas.' + origen.idOrigenEntrada}
                                        value={origen.idOrigenEntrada}>
                                    {origen.origenEntrada}
                                </option>
                            ))}
                        </Form.Select>
                        {errors.idOrigenEntrada && (<Alert variant='danger' role='alert' className="mwx-350">
                            {errors.idOrigenEntrada.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className='mb-3'>
                        <Form.Label htmlFor='idOrigenSalida' >
                            Origen de salida
                        </Form.Label>
                        <Form.Select
                            className="mwx-350"
                            id="idOrigenSalida"
                            {...register("idOrigenSalida")}
                        >
                            <option value="">Seleccionar</option>
                            {origenesSalidas?.data && origenesSalidas.data.map((origenSalida) => (
                                <option key={'origenesSalidas.' + origenSalida.idOrigenSalida}
                                        value={origenSalida.idOrigenSalida}>
                                    {origenSalida.origenSalida}
                                </option>
                            ))}
                        </Form.Select>
                        {errors.idOrigenSalida && (<Alert variant='danger' role='alert' className="mwx-350">
                            {errors.idOrigenSalida.message}
                        </Alert>)}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Controller
                            name="idEstatus"
                            control={control}
                            render={({field}) => (<>
                                    <Form.Label htmlFor="idEstatus">Estatus</Form.Label>
                                    <Form.Check
                                        id="idEstatus"
                                        type="switch"
                                        label={Boolean(field.value) ? 'Activo' : 'Inactivo'}
                                        checked={Boolean(field.value)}
                                        onChange={(e) => {
                                            field.onChange(Number(e.target.checked))
                                        }}
                                    />
                                </>

                            )}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant="primary" className="me-2">
                        <i className="bi bi-save"></i> Guardar
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>}
    </>);
};

export default ConfiguracionesAlmacenesForm;
