import {
    useEliminarEmpleadoMutation,
    useBuscarEmpleadosQuery,
} from "../../../services/empleado.service";
import React, { useEffect, useState } from "react";
import { Button, Row, Col, Card, Form } from "react-bootstrap";
import SeverPagination from "../../../components/base/SeverPagination";
import EmpleadoCard from "../../../components/empleados/EmpleadoCard";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { cleanProperties } from "../../../helpers/cleanProperties";
import { Controller, useForm } from "react-hook-form";
import { useObtenerEstatusQuery } from "../../../services/estatus.service";
import EmpleadosVista from "./EmpleadosVista";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";

const Empleados = () => {
    const { control, register, reset, handleSubmit } = useForm();

    //Funcionalidades propias del Proceso Empleados /EDITAR/ELIMINAR
    const [vistaEmpleados, setVistaEmpleados] = useState(null);
    const [eliminarEmpleado] = useEliminarEmpleadoMutation();

    const { state, pathname } = useLocation();
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useState({});

    const [pageNumber, setPageNumber] = useState(1);
    const [elementsPerPage, setElementsPerPage] = useState(10);

    const { data: empleados, refetch } = useBuscarEmpleadosQuery(
        { pageNumber, elementsPerPage, searchParams },
        { refetchOnMountOrArgChange: true }
    );

    // const { data: estatus } = useObtenerEstatusQuery();

    const onSubmit = async (formData) => {
        setSearchParams(cleanProperties(formData));
        refetch();
    };

    const clearForm = () => {
        navigate(pathname, {});
        setSearchParams({});
        reset();
    };

    useEffect(() => {
        setSearchParams(state || {});
        reset({
            buscador: state?.buscador || "",
            idEstatus: state?.idEstatus || "1",
        });
    }, [reset, setSearchParams, state]);

    return (
        <>
            <main id="main" className="main">
                <Form
                    id="formulario"
                    className="row g-3"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div className="input-group">
                        <Form.Control
                            type="text"
                            placeholder="Buscar por: Nombre, RFC"
                            {...register("buscador")}
                        />
                        <Button type="submit" variant="primary" title={"Buscar"}>
                            <i className="bi bi-search"></i>
                        </Button>
                        <Button
                            className="ms-2"
                            variant="primary"
                            title={"Limpiar"}
                            onClick={(e) => clearForm()}
                        >
                            <i className="bi bi-eraser"></i>
                        </Button>
                        <Button
                            variant="primary"
                            className="ms-2"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#filtros"
                            aria-expanded="false"
                            aria-controls="filtros"
                            title={"Filtros"}
                        >
                            <i className="bi bi-sliders"></i>
                        </Button>
                    </div>
                    <div className="collapse" id="filtros">
                        <Card className="card-body mb-0">
                            <h5 className="mb-3 mt-3 fw-bold">Criterios de filtrado.</h5>
                            <Row>
                                <Col className="border-end">
                                    <Form.Label htmlFor="fechaIngreso" className="form-label">
                                        Fecha ingreso
                                    </Form.Label>
                                    <Col xs={6} className="mb-2">
                                        <Controller
                                            render={({ field }) => (
                                                <DatePicker
                                                    className="form-control"
                                                    id="fechaIngreso"
                                                    dateFormat="dd/MM/yyyy"
                                                    value={field.value}
                                                    onChange={(date) => {
                                                        field.onChange(dayjs(date).format("DD/MM/YYYY"));
                                                    }}
                                                />
                                            )}
                                            name="fechaIngreso"
                                            control={control}
                                        />
                                    </Col>
                                </Col>

                                <Col className="border-end">
                                    <Form.Label htmlFor="numeroTelefono" className="form-label">
                                        Teléfono de contacto
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        className="form-control mb-3"
                                        id="numeroTelefono"
                                        {...register("numeroTelefono")}
                                    />
                                </Col>

                                <Col className="border-end">
                                    <Controller
                                        name="idEstatus"
                                        control={control}
                                        render={({ field }) => (
                                            <>
                                                <Form.Label htmlFor="idEstatus">
                                                    Estatus de la solicitud
                                                </Form.Label>
                                                <Form.Check
                                                    id="estatus.-0"
                                                    type="radio"
                                                    label="Todas"
                                                    value=""
                                                    {...register("idEstatus")}
                                                    defaultChecked={!state?.idEstatus}
                                                />
                                                <Form.Check
                                                    id="estatus.1"
                                                    type="radio"
                                                    label="Activo"
                                                    value="1" // Valor fijo para 'Activo'
                                                    {...register("idEstatus")}
                                                    defaultChecked={parseInt(state?.idEstatus) === 1}
                                                />
                                                <Form.Check
                                                    id="estatus.0"
                                                    type="radio"
                                                    label="Inactivo"
                                                    value="0" // Valor fijo para 'Inactivo'
                                                    {...register("idEstatus")}
                                                    defaultChecked={parseInt(state?.idEstatus) === 0}
                                                />
                                            </>
                                        )}
                                    />
                                </Col>
                            </Row>

                            <Row className="text-end mt-3">
                                <Button type="submit" variant="primary">
                                    Aplicar
                                </Button>
                            </Row>
                        </Card>
                    </div>
                </Form>
                <Row className="mt-3">
                    <Link
                        className="col-12 text-end"
                        to={"/administracion/empleados/view"}
                    >
                        <Button type="submit" variant="primary">
                            <i className="bi bi-file-earmark-plus-fill"></i> Nuevo
                        </Button>
                    </Link>
                </Row>

                {empleados?.data &&
                    empleados.data.map((empleado) => (
                        <EmpleadoCard
                            key={"empleado." + empleado.idEmpleado}
                            empleado={empleado}
                            refetch={refetch}
                            eliminarEmpleado={eliminarEmpleado}
                        />
                    ))}

                {empleados && (
                    <SeverPagination
                        data={empleados.data}
                        currentPage={pageNumber}
                        totalElements={empleados.totalElements}
                        elementsPerPage={elementsPerPage}
                        onPageChange={setPageNumber}
                        onElementsPerPageChange={setElementsPerPage}
                    />
                )}

                {vistaEmpleados && (
                    <EmpleadosVista
                        show={vistaEmpleados}
                        onHide={(e) => {
                            setVistaEmpleados(false);
                        }}
                        onSave={refetch}
                    />
                )}
            </main>
        </>
    );
};

export default Empleados;
