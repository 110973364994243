import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const tiposProductosApi = createApi({
    reducerPath: 'tiposProductosApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "tipos/productos",
        prepareHeaders: (headers, {getState}) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        obtenerTipoProducto: build.query({
            query: (idTipoProducto) => `/${idTipoProducto}`,
        }),
        obtenerTiposProductos: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        guardarTipoProducto: build.mutation({
            query: (tipoProducto) => ({
                url: '',
                method: tipoProducto.idTipoProducto ? 'PUT' : 'POST',
                body: tipoProducto,
            }),
        }),
        eliminarTipoProducto: build.mutation({
            query: (tipoProducto) => ({
                url: '',
                method: 'DELETE',
                body: tipoProducto,
            }),
        }),
    }),
});
export const {
    useObtenerTipoProductoQuery,
    useObtenerTiposProductosQuery,
    useLazyObtenerTiposProductosQuery,
    useGuardarTipoProductoMutation,
    useEliminarTipoProductoMutation
} = tiposProductosApi;
