import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {Badge, Button, Card} from "react-bootstrap";
import {NumericFormat} from "react-number-format";
import ObservacionInfo from "../solicitudes_pagos/ObservacionInfo";

const SolicitudReembolsoCard = ({solicitud}) => {

    const [modalObservaciones, setModalObservaciones] = useState(null);
    
    return (
        <Card.Header className="row mt-3">
             <Card.Header className="col-sm-12 col-md-12">
                <Card className={`mb-0`}>
                    <Card.Header className={`pb-0 fw-bold alert ${solicitud.estatus.color}`}>
                        <h5 className="card-title pt-1 pb-0 text-primary">{solicitud.folio.toString().padStart(7, '0')} | {solicitud.empresa.empresa}</h5>
                    </Card.Header>
                    <div className="row card-body">
                        <p className="card-text mb-0">
                            <span className="text-muted">Fecha de modificación: <b>{solicitud.fechaVersion?.split(" ")[0]}</b></span> | Estatus: <b>{solicitud.estatus.estatus}</b>
                        </p>
                        <div className="col-md-12 mb-2 pb-0">
                            <p className="card-text mb-1">
                                <strong>Solicita: </strong>{solicitud.empleado.nombreCustom}
                            </p>
                            <p className="card-text mb-1">
                                <strong>Empresa: </strong>{solicitud.empresa.empresa}
                            </p>
                            <p className="card-text mb-1">
                                <strong>Proyecto: </strong>{solicitud.proyecto.proyecto}
                            </p>
                            <p className="card-text mb-1">
                            <strong>Monto: </strong>
                            <NumericFormat
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$ '}
                                decimalScale={2}
                                fixedDecimalScale
                                value={solicitud.importe}/>
                            &nbsp;{solicitud.tipoMoneda.clave}
                        </p>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="d-inline mb-2">
                            <Link className="btn btn-sm btn-outline-primary me-2 mb-2 py-0"
                                  data-bs-toggle="tooltip" data-bs-title="Visualizar Expediente"
                                  to={"/solicitudes/reembolsos/view/" + solicitud.idSolicitudReembolso}>
                                <i className="bi bi-file-text fs-5"></i> Visualizar solicitud
                            </Link>
                        </div>
                        <div className="d-inline mb-2">
                            <Button
                                onClick={() => setModalObservaciones({idProceso: 2, id: solicitud.idSolicitudReembolso})}
                                variant="outline-primary"
                                size="sm"
                                className="me-2 mb-2 py-0"
                                disabled={solicitud.numeroObservacion === 0}
                            >
                                <i className="bi bi-book fs-5"></i> Observaciones{' '}
                                <Badge
                                    bg={solicitud.numeroObservacion > 0 ? 'danger' : 'secondary'}
                                >
                                    {solicitud.numeroObservacion}
                                </Badge>
                            </Button>
                            {modalObservaciones && (
                                <ObservacionInfo
                                    show={modalObservaciones}
                                    onHide={() => setModalObservaciones(null)}
                                />
                            )}
                        </div>
                    </div>                    
                </Card>
             </Card.Header>
        </Card.Header>
    )
};

export default SolicitudReembolsoCard; 