import React, {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Alert, Button, Form, Modal} from "react-bootstrap";
import {useGuardarColorMutation, useObtenerColorQuery} from "../../../services/color.service";

const ColoresForm = ({show, onHide, onSave}) => {

    const handleClose = (e) => {
        onHide(e);
    };

    const {control, handleSubmit, setValue, register, reset, watch, formState: {errors}} = useForm();

    const {data: color} = useObtenerColorQuery(show.idColor, {skip: !show.idColor, refetchOnMountOrArgChange: true});
    const [guardarColor] = useGuardarColorMutation();

    useEffect(() => {
        if (color) {
            reset({
                idColor: color.idColor,
                color: color.color,
                codigo: color.codigo,
                idEstatus: color.idEstatus,
                fechaVersion: color.fechaVersion
            });
        } else {
            //default values
            reset({idEstatus: 1})
        }
    }, [color, reset]);

    const onSubmit = async (formData) => {
        let {data} = await guardarColor(formData);
        if(data){
            setValue('idColor', data.id);
            setValue('fechaVersion', data.fechaVersion);
            onSave();
        }
    };

    return (<> {
        <Modal show={show} onHide={handleClose} centered backdrop="static" keyboard={false}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>{watch("idColor") ? 'Editar' : 'Nuevo'} [ Color ]</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="text" id="idColor" className="d-none" {...register("idColor")} />
                    <Form.Control type="text" id="fechaVersion" className="d-none" {...register("fechaVersion")} />
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="color" className="fw-bold">
                            Color
                        </Form.Label>
                        <Form.Control
                            {...register('color', {
                                required: 'Ingrese un dato válido',
                                maxLength: {value: 100, message: 'Ha excedido el máximo de caracteres'}
                            })}
                        />
                        {errors.color && (<Alert variant="danger" role="alert">
                            {errors.color.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="color" className="fw-bold">
                            Código
                        </Form.Label>
                        <Form.Control
                            type="color"
                            {...register('codigo', {
                                required: 'Ingrese un dato válido',
                                maxLength: {value: 20, message: 'Ha excedido el máximo de caracteres'}
                            })}
                        />
                        {errors.codigo && (<Alert variant="danger" role="alert">
                            {errors.codigo.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Controller
                            name="idEstatus"
                            control={control}
                            render={({field}) => (<>
                                    <Form.Label htmlFor="idEstatus">Estatus</Form.Label>
                                    <Form.Check
                                        id="idEstatus"
                                        type="switch"
                                        label={Boolean(field.value) ? 'Activo' : 'Inactivo'}
                                        checked={Boolean(field.value)}
                                        onChange={(e) => {
                                            field.onChange(Number(e.target.checked))
                                        }}
                                    />
                                </>

                            )}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant="primary" className="me-2">
                        <i className="bi bi-save"></i> Guardar
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>}
    </>);
};

export default ColoresForm;
