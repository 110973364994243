import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const tiposPersonasApi = createApi({
    reducerPath: 'tiposPersonasApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "tipos/personas",
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),




    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        
        obtenerTipoPersona: build.query({
            query: (idTipoPersona) => `/${idTipoPersona}`,
        }),
        obtenerTiposPersonasByBase: build.query({
            query: (idTipoPersonaBase) => `/filtro/base/${idTipoPersonaBase}`,
        }),
        obtenerTiposPersonas: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        guardarTipoPersona: build.mutation({
            query: (tipoPersona) => ({
                url: '',
                method: tipoPersona.idTipoPersona ? 'PUT' : 'POST',
                body: tipoPersona,
            }),
        }),
        eliminarTipoPersona: build.mutation({
            query: (tipoPersona) => ({
                url: '',
                method: 'DELETE',
                body: tipoPersona,
            }),
        }),
    }),
});

export const {   
               useObtenerTipoPersonaQuery,
               useObtenerTiposPersonasQuery,
               useObtenerTiposPersonasByBaseQuery,
               useLazyObtenerTiposPersonasQuery,               
               useGuardarTipoPersonaMutation,
               useEliminarTipoPersonaMutation
} = tiposPersonasApi;
