import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { useGuardarPuestoMutation, useObtenerPuestoQuery } from '../../../services/puesto.service';

const PuestoForm = ({ show, onHide, onSave }) => {
    const handleClose = (e) => {
        onHide(e)
    }

    const { control, handleSubmit, setValue, register, reset, formState: { errors } } = useForm()

    const { data: puesto } = useObtenerPuestoQuery(show.idPuesto, { skip: !show.idPuesto, refetchOnMountOrArgChange: true })
    const [guardarPuesto] = useGuardarPuestoMutation()
    
    useEffect(() => {
        if (puesto) {
            reset({
                idPuesto: puesto.idPuesto,
                puesto: puesto.puesto,
                clave: puesto.clave,
                descripcion: puesto.descripcion,
                idEstatus: puesto.idEstatus
            })
        } else {
            // default values
            reset({ idEstatus: 1 })
        }
    }, [puesto, reset])

    const onSubmit = async (formData) => {
        let { data } = await guardarPuesto(formData)
        if (data) {
            setValue('idPuesto', data.id)
            setValue('fechaVersion', data.fechaVersion)
            onSave()
        }
    }

    return (<> {
        <Modal show={show} onHide={handleClose} centered backdrop='static' keyboard={false}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {show.idPuesto ? 'Editar' : 'Nuevo'} [Puesto]
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type='text' id='idPuesto' className='d-none' {...register('idPuesto')} />
                    <Form.Control type='text' id='fechaVersion' className='d-none' {...register('fechaVersion')} />
                    <Form.Group className='mb-3' >
                        <Form.Label>
                           Puesto
                        </Form.Label>
                        <Form.Control
                            {...register('puesto', {
                                required: 'Ingrese un dato valido',
                                maxLength: { value: 50, message: 'Ha excedido el máximo de caracteres' }
                            })}
                        />
                        {errors.puesto && (<Alert variant='danger' role='alert'>
                            {errors.puesto.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className='mb-3' >
                        <Form.Label>
                            Clave
                        </Form.Label>
                        <Form.Control
                            {...register('clave', {
                                required: 'Ingrese un dato valido',
                                maxLength: { value: 10, message: 'Ha excedido el máximo de caracteres' }
                            })}
                        />
                        {errors.clave && (<Alert variant='danger' role='alert'>
                            {errors.clave.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className='mb-3' >
                        <Form.Label>
                            Descripción
                        </Form.Label>
                        <Form.Control
                            as="textarea"
                            rows='3'
                            className='mwx-500'
                            {...register('descripcion', {
                                required: 'Ingrese un dato valido',
                                maxLength: { value: 4000, message: 'Ha excedido el máximo de caracteres' }
                            })}
                        />
                        {errors.descripcion && (<Alert variant='danger' role='alert'>
                            {errors.descripcion.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className='mb-3'>
                        <Controller
                            name='idEstatus'
                            control={control}
                            render={({ field }) => (<>
                                <Form.Label htmlFor='idEstatus'>Estatus </Form.Label>
                                <Form.Check
                                    id='idEstatus'
                                    type='switch'
                                    label={Boolean(field.value) ? 'Activo' : 'Inactivo'}
                                    checked={Boolean(field.value)}
                                    onChange={(e) => {
                                        field.onChange(Number(e.target.checked))

                                    }}
                                />
                            </>
                            )}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button type='submit' variant='primary' className='me-2'>
                        <i className='bi bi-save'></i> Guardar
                    </Button>
                    <Button variant='secondary' onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    }

    </>)

}

export default PuestoForm
