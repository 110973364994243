import React, { useCallback, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Alert, Button, Form, InputGroup, Modal } from "react-bootstrap";
import {
    useGuardarMantenimientoMutation,
    useObtenerMantenimientoQuery,
} from "../../../services/mantenimiento.service";
import { useObtenerTiposMantenimientosQuery } from "../../../services/tipoMantenimiento.service";
import { useBuscarTransporteQuery } from "../../../services/transporte.service";
import { useObtenerEmpresasQuery } from "../../../services/empresa.service";
import Select from "react-select";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import TransporteInfo from "./TransporteInfo";
import { NumericFormat } from "react-number-format";
import { debounce } from "lodash";

const MantenimientosForm = ({ show, onHide, onSave }) => {
    const handleClose = (e) => {
        onHide(e);
    };

    const {
        handleSubmit,
        setValue,
        register,
        reset,
        control,
        watch,
        formState: { errors },
    } = useForm();

    const { data: mantenimiento } = useObtenerMantenimientoQuery(
        show.idMantenimiento || watch("idMantenimiento"),
        {
            skip: !show.idMantenimiento && !watch("idMantenimiento"),
            refetchOnMountOrArgChange: true,
        }
    );
    const [guardarMantenimiento] = useGuardarMantenimientoMutation();

    const { data: tiposMantenimientos } = useObtenerTiposMantenimientosQuery();
    const { data: empresas } = useObtenerEmpresasQuery();

    const transporteRef = useRef(null);
    const [searchTransporte, setSearchTransporte] = useState("");
    const [buscadorTransporte, setBuscadorTransporte] = useState("");
    const { data: transportes } = useBuscarTransporteQuery({
        buscador: buscadorTransporte,
    });

    const [showModalTransporte, setShowModalTransporte] = useState(null);

    const handleShowModalTransporte = () => {
        setShowModalTransporte(watch("idTransporte"));
    };

    const handleCloseModalTransporte = () => {
        setShowModalTransporte(null);
    };

    // Declaración de la función debounce
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSetBuscadorTransporte = useCallback(
        debounce((value) => {
            setBuscadorTransporte(value);
        }, 500),
        []
    );
    // Limpiar la función de debounce cuando el componente se desmonta
    useEffect(() => {
        return () => {
            debouncedSetBuscadorTransporte.cancel();
        };
    }, [debouncedSetBuscadorTransporte]);

    useEffect(() => {
        if (mantenimiento && tiposMantenimientos && transportes) {
            reset({
                idMantenimiento: mantenimiento.idMantenimiento,
                idTransporte: mantenimiento.idTransporte,
                idTipoMantenimiento: mantenimiento.idTipoMantenimiento,
                idTipoTransporte: mantenimiento.idTipoTransporte,
                idClaseTransporte: mantenimiento.idClaseTransporte,
                idFabricanteTransporte: mantenimiento.idFabricanteTransporte,
                idEmpresa: mantenimiento.idEmpresa,
                idMarcaTransporte: mantenimiento.idMarcaTransporte,
                fechaMantenimiento: mantenimiento.fechaMantenimiento,
                kilometraje: mantenimiento.kilometraje,
                idEstatus: mantenimiento.idEstatus,
                estatus: mantenimiento.estatus,
                fechaVersion: mantenimiento.fechaVersion,
            });
        } else {
            //default values
            reset({ idEstatus: 119 }); //programada
        }
    }, [mantenimiento, tiposMantenimientos, transportes, reset]);

    const onSubmit = async (formData) => {
        let { data } = await guardarMantenimiento(formData);
        if (data) {
            setValue("idMantenimiento", data.id);
            setValue("fechaVersion", data.fechaVersion);
            onSave();
        }
    };

    const labelByTrasporte = (transporte) => {
        if (!transporte) return null;
        return `Placa: ${transporte.numeroPlaca} - Marca: ${transporte.marcaTransporte.marcaTransporte} - Modelo: ${transporte.modelo}`;
    };

    return (
        <>
            {" "}
            {
                <Modal
                    show={show}
                    onHide={handleClose}
                    centered
                    backdrop="static"
                    keyboard={false}
                >
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {show.idMantenimiento ? "Editar" : "Nueva"} [Programación]{" "}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Control
                                type="text"
                                id="idMantenimiento"
                                className="d-none"
                                {...register("idMantenimiento")}
                            />
                            <Form.Control
                                type="text"
                                id="fechaVersion"
                                className="d-none"
                                {...register("fechaVersion")}
                            />

                            <Form.Group className="mb-3">
                                <Form.Label htmlFor="idTransporte" className="fw-bold">
                                    Vehículo
                                </Form.Label>
                                <InputGroup>
                                    <Controller
                                        render={({ field }) =>
                                            transportes?.data && (
                                                <Select
                                                    ref={transporteRef}
                                                    value={{
                                                        value: field.value,
                                                        label:
                                                            labelByTrasporte(
                                                                transportes.data.find(
                                                                    (t) => t.idTransporte === field.value
                                                                )
                                                            ) ?? "Seleccionar",
                                                    }}
                                                    inputValue={searchTransporte}
                                                    menuIsOpen={searchTransporte}
                                                    isSearchable={true}
                                                    placeholder="Seleccionar"
                                                    className="form-floating"
                                                    options={transportes.data.map((transporte) => ({
                                                        value: transporte.idTransporte,
                                                        label: labelByTrasporte(transporte),
                                                    }))}
                                                    onInputChange={(v) => {
                                                        setSearchTransporte(v);
                                                        if (v) {
                                                            debouncedSetBuscadorTransporte(v);
                                                        }
                                                    }}
                                                    onChange={(v) => {
                                                        field.onChange(
                                                            Number(v.value) > 0 ? Number(v.value) : "0"
                                                        );
                                                    }}
                                                    onMenuOpen={(v) => {
                                                        setSearchTransporte(undefined);
                                                    }}
                                                />
                                            )
                                        }
                                        name="idTransporte"
                                        control={control}
                                        rules={{ required: "Ingrese un dato válido" }}
                                    />

                                    {watch("idTransporte") ? (
                                        <>
                                            {showModalTransporte && (
                                                <TransporteInfo
                                                    show={showModalTransporte}
                                                    onHide={handleCloseModalTransporte}
                                                />
                                            )}
                                            <Button
                                                className="text-secondary"
                                                variant="outline-secondary"
                                                onClick={handleShowModalTransporte}
                                                title="Ver vehículo"
                                            >
                                                <i className="bi bi-eye"></i>
                                            </Button>
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </InputGroup>

                                {errors.idTransporte && (
                                    <Alert variant="danger" role="alert">
                                        {errors.idTransporte.message}
                                    </Alert>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3 col-sm-8">
                                <Form.Label htmlFor="idTipoMantenimiento" className="fw-bold">
                                    Tipo de mantenimiento
                                </Form.Label>
                                <Form.Select
                                    id="idTipoMantenimiento"
                                    {...register("idTipoMantenimiento", {
                                        required: "Ingrese un dato válido",
                                    })}
                                >
                                    <option value="">Seleccionar</option>
                                    {tiposMantenimientos?.data &&
                                        tiposMantenimientos.data.map((tipoMantenimiento) => (
                                            <option
                                                key={
                                                    "tiposMantenimientos." +
                                                    tipoMantenimiento.idTipoMantenimiento
                                                }
                                                value={tipoMantenimiento.idTipoMantenimiento}
                                            >
                                                {tipoMantenimiento.tipoMantenimiento}
                                            </option>
                                        ))}
                                </Form.Select>
                                {errors.idTipoMantenimiento && (
                                    <Alert variant="danger" role="alert">
                                        {errors.idTipoMantenimiento.message}
                                    </Alert>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3 col-sm-8">
                                <Form.Label htmlFor="fechaMantenimiento" className="fw-bold">
                                    Fecha de mantenimiento y hora
                                </Form.Label>
                                <Controller
                                    render={({ field }) => (
                                        <DatePicker
                                            className="form-control"
                                            id="fechaMantenimiento"
                                            dateFormat="dd/MM/yyyy HH:mm"
                                            showTimeSelect
                                            timeIntervals={10}
                                            selected={
                                                field.value
                                                    ? dayjs(field.value, "DD/MM/YYYY HH:mm").toDate()
                                                    : null
                                            }
                                            onChange={(date) => {
                                                field.onChange(dayjs(date).format("DD/MM/YYYY HH:mm"));
                                            }}
                                        />
                                    )}
                                    name="fechaMantenimiento"
                                    control={control}
                                    rules={{ required: "Ingrese un dato válido" }}
                                />
                                {errors.fechaMantenimiento && (
                                    <Alert variant="danger" role="alert">
                                        {errors.fechaMantenimiento.message}
                                    </Alert>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3 col-sm-5">
                                <Form.Label htmlFor="kilometraje" className="fw-bold">
                                    Kilometraje
                                </Form.Label>
                                <Controller
                                    render={({ field }) => (
                                        <NumericFormat
                                            className="form-control mwx-150"
                                            id="kilometraje"
                                            thousandSeparator={true}
                                            decimalScale={2}
                                            fixedDecimalScale
                                            allowNegative={false}
                                            value={field.value || ""}
                                            onValueChange={(v) => {
                                                field.onChange(
                                                    Number(v.value) > 0 ? Number(v.value) : "0"
                                                );
                                            }}
                                            isAllowed={({ value }) =>
                                                value?.split(".")[0].length <= 10
                                            }
                                        />
                                    )}
                                    name="kilometraje"
                                    control={control}
                                />
                                {errors.kilometraje && (
                                    <Alert variant="danger" role="alert">
                                        {errors.kilometraje.message}
                                    </Alert>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3 col-sm-8">
                                <Form.Label htmlFor="idEmpresa" className="fw-bold">
                                    Proveedor
                                </Form.Label>
                                <Form.Select
                                    id="idEmpresa"
                                    {...register("idEmpresa", {
                                        required: "Ingrese un dato válido",
                                    })}
                                >
                                    <option value="">Seleccionar</option>
                                    {empresas?.data &&
                                        empresas.data.map((empresa) => (
                                            <option
                                                key={"empresas." + empresa.idEmpresa}
                                                value={empresa.idEmpresa}
                                            >
                                                {empresa.empresa}
                                            </option>
                                        ))}
                                </Form.Select>
                                {errors.idEmpresa && (
                                    <Alert variant="danger" role="alert">
                                        {errors.idEmpresa.message}
                                    </Alert>
                                )}
                            </Form.Group>

                            <Form.Group className="mb-3 col-sm-5">
                                <Form.Label htmlFor="estatus" className="fw-bold">
                                    Estatus
                                </Form.Label>
                                <Form.Control
                                    disabled
                                    value={mantenimiento?.estatus?.estatus || ""}
                                />
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button type="submit" variant="primary" className="me-2">
                                <i className="bi bi-save"></i> Guardar
                            </Button>
                            <Button variant="secondary" onClick={handleClose}>
                                Cerrar
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            }
        </>
    );
};
export default MantenimientosForm;
