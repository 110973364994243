import React, {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Alert, Button, Form, Modal} from "react-bootstrap";
import {useGuardarMetodoSalidaAlmacenMutation, useObtenerMetodoSalidaAlmacenQuery} from "../../../services/metodoSalidaAlmacen.service";

const MetodosSalidasAlmacenesForm = ({show, onHide, onSave}) => {

    const handleClose = (e) => {
        onHide(e);
    };

    const {control, handleSubmit, setValue, register, reset, formState: {errors}} = useForm();

    const {data: metodoSalidaAlmacen} = useObtenerMetodoSalidaAlmacenQuery(show.idMetodoSalidaAlmacen, {skip: !show.idMetodoSalidaAlmacen, refetchOnMountOrArgChange: true});
    const [guardarMetodoSalidaAlmacen] = useGuardarMetodoSalidaAlmacenMutation();

    useEffect(() => {
        if (metodoSalidaAlmacen) {
            reset({
                idMetodoSalidaAlmacen: metodoSalidaAlmacen.idMetodoSalidaAlmacen,
                metodoSalidaAlmacen: metodoSalidaAlmacen.metodoSalidaAlmacen,
                idEstatus: metodoSalidaAlmacen.idEstatus,
                fechaVersion: metodoSalidaAlmacen.fechaVersion
            });
        } else {
            //default values
            reset({idEstatus: 1})
        }
    }, [metodoSalidaAlmacen, reset]);

    const onSubmit = async (formData) => {
        let {data} = await guardarMetodoSalidaAlmacen(formData);
        if(data){
            setValue('idMetodoSalidaAlmacen', data.id);
            setValue('fechaVersion', data.fechaVersion);
            onSave();
        }
    };

    return (<> {
        <Modal show={show} onHide={handleClose} centered backdrop="static" keyboard={false}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>{show.idMetodoSalidaAlmacen ? 'Editar' : 'Nuevo'} [ Método de salida ]</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="text" id="idMetodoSalidaAlmacen" className="d-none" {...register("idMetodoSalidaAlmacen")} />
                    <Form.Control type="text" id="fechaVersion" className="d-none" {...register("fechaVersion")} />
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="metodoSalidaAlmacen" className="fw-bold">
                            Nombre
                        </Form.Label>
                        <Form.Control
                            {...register('metodoSalidaAlmacen', {
                                required: 'Ingrese un dato válido',
                                maxLength: {value: 100, message: 'Ha excedido el máximo de caracteres'}
                            })}
                        />
                        {errors.metodoSalidaAlmacen && (<Alert variant="danger" role="alert">
                            {errors.metodoSalidaAlmacen.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Controller
                            name="idEstatus"
                            control={control}
                            render={({field}) => (<>
                                    <Form.Label htmlFor="idEstatus">Estatus</Form.Label>
                                    <Form.Check
                                        id="idEstatus"
                                        type="switch"
                                        label={Boolean(field.value) ? 'Activo' : 'Inactivo'}
                                        checked={Boolean(field.value)}
                                        onChange={(e) => {
                                            field.onChange(Number(e.target.checked))
                                        }}
                                    />
                                </>

                            )}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant="primary" className="me-2">
                        <i className="bi bi-save"></i> Guardar
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>}
    </>);
};

export default MetodosSalidasAlmacenesForm;
