import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const departamentosApi = createApi({
    reducerPath: 'departamentosApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "departamentos",
        prepareHeaders: (headers, {getState}) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        obtenerDepartamento: build.query({
            query: (idDepartamento) => `/${idDepartamento}`,
        }),
        obtenerDepartamentos: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        guardarDepartamento: build.mutation({
            query: (departamento) => ({
                url: '',
                method: departamento.idDepartamento ? 'PUT' : 'POST',
                body: departamento,
            }),
        }),
        eliminarDepartamento: build.mutation({
            query: (departamento) => ({
                url: '',
                method: 'DELETE',
                body: departamento,
            }),
        }),
    }),
});
export const {
    useObtenerDepartamentoQuery,
    useObtenerDepartamentosQuery,
    useLazyObtenerDepartamentosQuery,
    useGuardarDepartamentoMutation,
    useEliminarDepartamentoMutation
} = departamentosApi;
