import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const transportesApi = createApi({
    reducerPath: 'transportesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "transportes",
        prepareHeaders: (headers, {getState}) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        obtenerTransporte: build.query({
            query: (idTransporte) => `/${idTransporte}`,
        }),
        buscarTransporte: build.query({
            query: ({pageNumber = 1, elementsPerPage = -1, buscador = ""}) => `/buscador?page=${pageNumber}&size=${elementsPerPage}${buscador? "&buscador=" + buscador : ""}`,
        }),
        obtenerTransportes: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        guardarTransporte: build.mutation({
            query: (transporte) => ({
                url: '',
                method: transporte.idTransporte ? 'PUT' : 'POST',
                body: transporte,
            }),
        }),
        eliminarTransporte: build.mutation({
            query: (transporte) => ({
                url: '',
                method: 'DELETE',
                body: transporte,
            }),
        }),
    }),
});
export const {
    useObtenerTransporteQuery,
    useBuscarTransporteQuery,
    useObtenerTransportesQuery,
    useLazyObtenerTransportesQuery,
    useGuardarTransporteMutation,
    useEliminarTransporteMutation
} = transportesApi;
