import {
    useEliminarMantenimientoMutation,
    useBuscarMantenimientosQuery,
} from '../../../services/mantenimiento.service';
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Form } from "react-bootstrap";
import SeverPagination from "../../../components/base/SeverPagination";
import ControlMantenimientoCard from "../../../components/control_mantenimientos/ControlMantenimientoCard";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { cleanProperties } from "../../../helpers/cleanProperties";
import { Controller, useForm } from "react-hook-form";
//import { useObtenerEstatusQuery } from "../../../services/estatus.service";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import { NumericFormat } from "react-number-format";
//import { ExportToExcel } from "../../../components/base/ExportToExcel";
//import {toCurrency} from "../../../helpers/toCurrency";

const ControlMantenimientos = () => {
    const { control, register, reset, handleSubmit } = useForm();

    //Funcionalidades propias de ControlMantenimiento /EDITAR/ELIMINAR
    const [eliminarControlMantenimiento] = useEliminarMantenimientoMutation();

    const { state, pathname } = useLocation();
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useState({});

    const [pageNumber, setPageNumber] = useState(1);
    const [elementsPerPage, setElementsPerPage] = useState(10);

    const { data: mantenimientos, refetch } = useBuscarMantenimientosQuery(
        { pageNumber, elementsPerPage, searchParams },
        { refetchOnMountOrArgChange: true }
    );

    //const { data: estatus } = useObtenerEstatusQuery();

    const onSubmit = async (formData) => {
        setSearchParams(cleanProperties(formData));
        refetch();
    };

    const clearForm = () => {
        navigate(pathname, {});
        setSearchParams({});
        reset();
    };

    useEffect(() => {
        setSearchParams(state || {});
        reset({
            buscador: state?.buscador || "",
            idEstatus: state?.idEstatus || "1",
        });
    }, [reset, setSearchParams, state]);

    return (
        <>
            <main id="main" className="main">
                <Form
                    id="formulario"
                    className="row g-3"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div className="input-group">
                        {/*Placeholder text del buscador*/}
                        <Form.Control
                            type="text"
                            placeholder="Buscar por: Modelo, Matricula, Año de fabricación"
                            {...register("buscador")}
                        />
                        {/*Botón buscar*/}
                        <Button type="submit" variant="primary" title={"Buscar"}>
                            <i className="bi bi-search"></i>
                        </Button>
                        {/*Botón Limpiar*/}
                        <Button
                            className="ms-2"
                            variant="primary"
                            title={"Limpiar"}
                            onClick={(e) => clearForm()}
                        >
                            <i className="bi bi-eraser"></i>
                        </Button>
                        {/*Botón Filtros*/}
                        <Button
                            variant="primary"
                            className="ms-2"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#filtros"
                            aria-expanded="false"
                            aria-controls="filtros"
                            title={"Filtros"}
                        >
                            <i className="bi bi-sliders"></i>
                        </Button>
                    </div>
                    {/*Criterios de filtrado*/}
                    <div className="collapse" id="filtros">
                        <Card className="card-body mb-0">
                            <h5 className="mb-3 mt-3 fw-bold">Criterios de filtrado.</h5>
                            <Row>
                                {/*Fecha Conclusion*/}
                                <Col className="border-end col">
                                    <Form.Label htmlFor="fechaConclusion" className="form-label">
                                        Fecha Conclusión
                                    </Form.Label>
                                    <Col className="mb-2">
                                        <Controller
                                            render={({ field }) => (
                                                <DatePicker
                                                    className="form-control"
                                                    id="fechaConclusion"
                                                    dateFormat="dd/MM/yyyy"
                                                    value={field.value}
                                                    onChange={(date) => {
                                                        field.onChange(dayjs(date).format("DD/MM/YYYY"));
                                                    }}
                                                />
                                            )}
                                            name="fechaConclusion"
                                            control={control}
                                        />
                                    </Col>
                                </Col>

                                {/*Importe mínimo*/}
                                <Col className="border-end col">
                                    <Col>
                                        <div>
                                            <Form.Label htmlFor="importeMinimo">
                                                Importe mínimo
                                            </Form.Label>
                                            <Controller
                                                render={({ field }) => (
                                                    <NumericFormat
                                                        className="form-control mwx-400"
                                                        id="importeMinimo"
                                                        thousandSeparator={true}
                                                        prefix={"$ "}
                                                        decimalScale={2}
                                                        fixedDecimalScale
                                                        allowNegative={false}
                                                        value={field.value || ""}
                                                        onValueChange={(v) => {
                                                            field.onChange(
                                                                Number(v.value) > 0 ? Number(v.value) : null
                                                            );
                                                        }}
                                                        isAllowed={({ value }) =>
                                                            value?.split(".")[0].length <= 10
                                                        }
                                                    />
                                                )}
                                                name="importeMinimo"
                                                control={control}
                                            />
                                        </div>
                                    </Col>
                                </Col>

                                {/*Importe máximo*/}
                                <Col className="border-end col">
                                    <Col>
                                        <div>
                                            <Form.Label htmlFor="importeMaximo">
                                                Importe máximo
                                            </Form.Label>
                                            <Controller
                                                render={({ field }) => (
                                                    <NumericFormat
                                                        className="form-control mwx-400"
                                                        id="importeMaximo"
                                                        thousandSeparator={true}
                                                        prefix={"$ "}
                                                        decimalScale={2}
                                                        fixedDecimalScale
                                                        allowNegative={false}
                                                        value={field.value || ""}
                                                        onValueChange={(v) => {
                                                            field.onChange(
                                                                Number(v.value) > 0 ? Number(v.value) : null
                                                            );
                                                        }}
                                                        isAllowed={({ value }) =>
                                                            value?.split(".")[0].length <= 10
                                                        }
                                                    />
                                                )}
                                                name="importeMaximo"
                                                control={control}
                                            />
                                        </div>
                                    </Col>
                                </Col>

                                {/*Radio button estatus*/}
                                <Col className="border-end col">
                                    <Controller
                                        name="idEstatus"
                                        control={control}
                                        render={({ field }) => (
                                            <>
                                                <Form.Label htmlFor="idEstatus">
                                                    Estatus
                                                </Form.Label>
                                                {/*Opciones, es fijo, recuperandose de la BD*/}
                                                <Form.Check
                                                    id="estatus.-0"
                                                    type="radio"
                                                    label="Todos"
                                                    value=""
                                                    {...register("idEstatus")}
                                                    defaultChecked={!state?.idEstatus}
                                                />
                                                <Form.Check
                                                    id="estatus.120"
                                                    type="radio"
                                                    label="Atendida"
                                                    value="120" // Valor fijo para 'Atendida'
                                                    {...register("idEstatus")}
                                                    defaultChecked={parseInt(state?.idEstatus) === 120}
                                                />
                                                <Form.Check
                                                    id="estatus.121"
                                                    type="radio"
                                                    label="Cancelada"
                                                    value="121" // Valor fijo para 'Cancelada'
                                                    {...register("idEstatus")}
                                                    defaultChecked={parseInt(state?.idEstatus) === 121}
                                                />
                                                <Form.Check
                                                    id="estatus.119"
                                                    type="radio"
                                                    label="Programada"
                                                    value="119" // Valor fijo para 'Programada'
                                                    {...register("idEstatus")}
                                                    defaultChecked={parseInt(state?.idEstatus) === 119}
                                                />
                                            </>
                                        )}
                                    />
                                </Col>
                            </Row>

                            <Row className="text-end mt-3">
                                <Button type="submit" variant="primary">
                                    Aplicar
                                </Button>
                            </Row>
                        </Card>
                    </div>
                </Form>
                <Row className="mt-3">
                    <Link className="col-12 text-end" to={"/mantenimientos/programaciones/mantenimientos/view/"}>
                        <Button type="submit" variant="primary">
                            <i className="bi bi-file-earmark-plus-fill"></i> Nuevo
                        </Button>

                    </Link>
                </Row>

                {mantenimientos?.data &&
                    mantenimientos.data.map((mantenimiento) => (
                        <ControlMantenimientoCard
                            key={"mantenimiento." + mantenimiento.idMantenimiento}
                            mantenimiento={mantenimiento}
                            refetch={refetch}
                            eliminarControlMantenimiento={eliminarControlMantenimiento}
                        />
                    ))}

                {mantenimientos && (
                    <SeverPagination
                        data={mantenimientos.data}
                        currentPage={pageNumber}
                        totalElements={mantenimientos.totalElements}
                        elementsPerPage={elementsPerPage}
                        onPageChange={setPageNumber}
                        onElementsPerPageChange={setElementsPerPage}
                    />
                )}
            </main>
        </>
    );
};

export default ControlMantenimientos;
