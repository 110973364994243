import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Alert, Button, Card, Form, Col, Row } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import {
  useGuardarEntradaMutation,
  useObtenerEntradaQuery,
} from "../../../services/entrada.service";
import { Link, useParams } from "react-router-dom";
import { useObtenerAlmacenesQuery } from "../../../services/almacen.service";
import { useObtenerSeccionesAlmacenesPorAlmacenQuery } from "../../../services/seccionAlmacen.service";
import { useObtenerOrigenesEntradasQuery } from "../../../services/origenEntrada.service";
import FormLabelCounter from "../../../components/base/FormLabelCounter";
import { NumericFormat } from "react-number-format";
import BootstrapTable from "../../../components/base/BootstrapTable";
import {
  useEliminarEntradaProductoMutation,
  useObtenerEntradasProductosQuery,
} from "../../../services/entradaProducto.service";
import ConfirmDialog from "../../../components/base/ConfirmDialog";
import EntradasProductosModal from "./EntradasProductosModal";
import _ from "lodash";
import { toNumber } from "../../../helpers/toNumber";
import { toCurrency } from "../../../helpers/toCurrency";
import { useObtenerConfiguracionAlmacenPorTipoQuery } from "../../../services/configuracionAlmacen.service";

const EntradasProductosForm = () => {
  const { id: idEntrada } = useParams();

  const {
    control,
    handleSubmit,
    setValue,
    register,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const { data: entrada } = useObtenerEntradaQuery(idEntrada, {
    skip: !idEntrada,
    refetchOnMountOrArgChange: true,
  });
  const [guardarEntrada] = useGuardarEntradaMutation();

  const { data: configuracion } =
    useObtenerConfiguracionAlmacenPorTipoQuery("e");

  const { data: almacenes } = useObtenerAlmacenesQuery();
  const { data: secciones } = useObtenerSeccionesAlmacenesPorAlmacenQuery(
    watch("idAlmacen"),
    { skip: !watch("idAlmacen") }
  );
  const { data: origenes } = useObtenerOrigenesEntradasQuery();

  const { data: productosData } = useObtenerEntradasProductosQuery(
    watch("idEntrada"),
    { skip: !watch("idEntrada") }
  );

  const [eliminarEntradaProducto] = useEliminarEntradaProductoMutation();

  const [confirmDialog, setConfirmDialog] = useState({});
  const [formProductos, setFormProductos] = useState(null);
  const [productos, setProductos] = useState([]);

  useEffect(() => {
    if (entrada && almacenes && origenes) {
      reset({
        idEntrada: entrada.idEntrada,
        numero: entrada.numero,
        referencia: entrada.referencia,
        fechaRegistro: entrada.fechaRegistro,
        idAlmacen: entrada.idAlmacen,
        idOrigenEntrada: entrada.idOrigenEntrada,
        idEstatus: entrada.idEstatus,
        fechaVersion: entrada.fechaVersion,
      });
    } else if (almacenes && origenes && configuracion) {
      //default values
      reset({
        idEstatus: 1,
        idAlmacen: configuracion.idAlmacen,
        idOrigenEntrada: configuracion.idOrigenEntrada,
      });
    }
  }, [entrada, almacenes, origenes, configuracion, reset]);

  //carga secciones
  useEffect(() => {
    if (secciones?.data) {
      if (entrada) {
        setValue("idSeccionAlmacen", entrada.idSeccionAlmacen);
      } else if (configuracion) {
        setValue("idSeccionAlmacen", configuracion.idSeccionAlmacen);
      }
    }
  }, [entrada, secciones, configuracion, setValue]);

  //carga productos
  useEffect(() => {
    if (productosData) {
      setProductos(
        productosData.map((e) => {
          return { ...e, uuid: uuidv4() };
        })
      );
    } else {
      //TODO: cargar guardados en store localstorage
    }
  }, [productosData, setProductos]);

  //actualiza cantidades
  useEffect(() => {
    if (productos) {
      let subTotal = 0;
      let impuesto = 0;
      let total = 0;

      //calcular cantidades
      productos.forEach((producto) => {
        let cantidad = toNumber(producto.cantidad);
        let precioUnitario = toNumber(producto.precioUnitario);
        let importeImpuesto = toNumber(producto.importeImpuesto);
        let importeNeto = toNumber(producto.importeNeto);
        let subProducto = _.multiply(cantidad, precioUnitario);

        subTotal = _.add(subTotal, subProducto);
        impuesto = _.add(impuesto, importeImpuesto);
        total = _.add(total, importeNeto);
      });

      setValue("subtotal", subTotal || "0");
      setValue("iva", impuesto || "0");
      setValue("total", total || "0");
    }
  }, [productos, setValue]);
  const actualizarProductos = (uuid, nuevoProducto) => {
    setProductos((prevProductos) => {
      // Verificar si idAReemplazar no es null
      if (prevProductos.find((e) => e.uuid === uuid)) {
        // Actualizar el producto existente si el ID coincide
        return prevProductos.map((producto) =>
          producto.uuid === uuid ? nuevoProducto : producto
        );
      } else {
        // Agregar un nuevo producto al final del array
        return [...prevProductos, nuevoProducto];
      }
    });

    if (nuevoProducto.nuevo) {
      setFormProductos({});
    } else {
      setFormProductos(false);
    }
  };

  const onSubmit = async (formData) => {
    formData.entradasProductos = productos.map((producto) => {
      let data = { ...producto };
      data.idAlmacen = formData.idAlmacen;
      data.idSeccionAlmacen = formData.idSeccionAlmacen;
      data.idEstatus = formData.idEstatus;
      return data;
    });

    //limpiar storage

    let { data } = await guardarEntrada(formData);
    if (data) {
      setValue("idEntrada", data.id);
      setValue("fechaVersion", data.fechaVersion);

      if (data.numero) {
        setValue("numero", data.numero);
        setValue("fechaRegistro", data.fechaRegistro);
      }
    }
  };

  return (
    <div id="main" className="main">
      <section className="section">
        <Row>
          <Col lg={12}>
            <Card>
              <Card.Body>
                <fieldset>
                  <legend className="d-flex flex-column flex-md-row justify-content-between align-items-right">
                    <div className="fw-bold">
                      <p className="fs-5 mb-0"> Entrada de productos </p>
                    </div>

                    <div>
                      <p className="fs-6 mb-0">
                        *Los campos en negritas son requeridos{" "}
                      </p>
                    </div>
                  </legend>
                </fieldset>

                <Form onSubmit={handleSubmit(onSubmit)}>
                  <Form.Control
                    type="text"
                    id="idEntrada"
                    className="d-none"
                    {...register("idEntrada")}
                  />
                  <Form.Control
                    type="text"
                    id="fechaVersion"
                    className="d-none"
                    {...register("fechaVersion")}
                  />

                  <Card>
                    <div className="container">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="fw-bold">
                            <p className="fs-3"> Información de registro </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="container">
                      <div className="row">
                        <div className="col-md-3">
                          <Form.Group className="mb-3">
                            <Form.Label htmlFor="numero">
                              Número de entrada
                            </Form.Label>
                            <Form.Control
                              {...register("numero")}
                              className="mwx-350"
                              disabled
                            />
                          </Form.Group>
                        </div>

                        <div className="col-md-3">
                          <Form.Group className="mb-3">
                            <Form.Label htmlFor="idAlmacen" className="fw-bold">
                              Almacen
                            </Form.Label>
                            <Form.Select
                              className="mwx-350"
                              id="idAlmacen"
                              {...register("idAlmacen", {
                                required: "Ingrese un dato válido",
                              })}
                            >
                              <option value="">Seleccionar</option>
                              {almacenes?.data &&
                                almacenes.data.map((almacen) => (
                                  <option
                                    key={"almacenes." + almacen.idAlmacen}
                                    value={almacen.idAlmacen}
                                  >
                                    {almacen.almacen}
                                  </option>
                                ))}
                            </Form.Select>
                            {errors.idAlmacen && (
                              <Alert
                                variant="danger"
                                role="alert"
                                className="mwx-350"
                              >
                                {errors.idAlmacen.message}
                              </Alert>
                            )}
                          </Form.Group>
                        </div>

                        <div className="col-md-3">
                          <Form.Group className="mb-3">
                            <Form.Label
                              htmlFor="idSeccionAlmacen"
                              className="fw-bold"
                            >
                              Sección
                            </Form.Label>
                            <Form.Select
                              className="mwx-350"
                              id="idSeccionAlmacen"
                              {...register("idSeccionAlmacen", {
                                required: "Ingrese un dato válido",
                              })}
                            >
                              <option value="">Seleccionar</option>
                              {secciones?.data &&
                                secciones.data.map((seccion) => (
                                  <option
                                    key={
                                      "secciones." + seccion.idSeccionAlmacen
                                    }
                                    value={seccion.idSeccionAlmacen}
                                  >
                                    {seccion.seccionAlmacen}
                                  </option>
                                ))}
                            </Form.Select>
                            {errors.idSeccionAlmacen && (
                              <Alert
                                variant="danger"
                                role="alert"
                                className="mwx-350"
                              >
                                {errors.idSeccionAlmacen.message}
                              </Alert>
                            )}
                          </Form.Group>
                        </div>

                        <div className="col-md-3">
                          <Form.Group className="mb-3">
                            <Form.Label
                              htmlFor="idOrigenEntrada"
                              className="fw-bold"
                            >
                              Origen
                            </Form.Label>
                            <Form.Select
                              className="mwx-350"
                              id="idOrigenEntrada"
                              {...register("idOrigenEntrada", {
                                required: "Ingrese un dato válido",
                              })}
                            >
                              <option value="">Seleccionar</option>
                              {origenes?.data &&
                                origenes.data.map((origen) => (
                                  <option
                                    key={"origenes." + origen.idOrigenEntrada}
                                    value={origen.idOrigenEntrada}
                                  >
                                    {origen.origenEntrada}
                                  </option>
                                ))}
                            </Form.Select>
                            {errors.idOrigenEntrada && (
                              <Alert
                                variant="danger"
                                role="alert"
                                className="mwx-350"
                              >
                                {errors.idOrigenEntrada.message}
                              </Alert>
                            )}
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </Card>

                  <Card>
                    <div className="container">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="fw-bold">
                            <p className="fs-3">Detalles</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="container">
                      <div className="row">
                        <div className="col-md-3">
                          <Form.Group className="mb-3">
                            <FormLabelCounter
                              htmlFor="referencia"
                              className="fw-bold mwx-350"
                              input={watch("referencia")}
                              maxLength={200}
                            >
                              Referencia
                            </FormLabelCounter>
                            <Form.Control
                              as="textarea"
                              rows="4"
                              className="mwx-350"
                              {...register("referencia", {
                                required: "Ingrese un dato válido",
                                maxLength: {
                                  value: 200,
                                  message:
                                    "Ha excedido el máximo de caracteres",
                                },
                              })}
                            />
                            {errors.referencia && (
                              <Alert
                                variant="danger"
                                role="alert"
                                className="mwx-350"
                              >
                                {errors.referencia.message}
                              </Alert>
                            )}
                          </Form.Group>
                        </div>

                        <div className="col-md-3">
                          <Form.Group className="mb-3">
                            <Form.Label htmlFor="fechaRegistro">
                              Fecha de registro
                            </Form.Label>
                            <Form.Control
                              {...register("fechaRegistro")}
                              className="mwx-350"
                              disabled
                            />
                          </Form.Group>
                        </div>

                        <div className="col-md-3">
                          <Form.Group className="mb-3">
                            <Controller
                              name="idEstatus"
                              control={control}
                              render={({ field }) => (
                                <>
                                  <Form.Label htmlFor="idEstatus">
                                    Estatus
                                  </Form.Label>
                                  <Form.Check
                                    id="idEstatus"
                                    type="switch"
                                    label={
                                      Boolean(field.value)
                                        ? "Activo"
                                        : "Inactivo"
                                    }
                                    checked={Boolean(field.value)}
                                    onChange={(e) => {
                                      field.onChange(Number(e.target.checked));
                                    }}
                                  />
                                </>
                              )}
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </Card>

                  <Card>
                    <div className="container">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="fw-bold">
                            <p className="fs-3">Productos</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="container">
                      <div className="row">
                        <fieldset className="mb-2 me-sm-5">
                          <BootstrapTable
                            striped
                            search={false}
                            toolbar={({ columns }) => (
                              <div className="me-auto">
                                <Button
                                  className="me-2"
                                  variant="primary"
                                  onClick={(e) => {
                                    setFormProductos({});
                                  }}
                                >
                                  <i className="bi bi-plus-square"></i>
                                  &nbsp;Añadir
                                </Button>
                              </div>
                            )}
                            data={productos || []}
                            columns={[
                              {
                                header: "Producto",
                                accessorKey: "producto.producto",
                                wrapText: true,
                              },
                              {
                                header: "Código",
                                accessorKey: "producto.codigoProducto",
                              },
                              {
                                header: "Unidad de medida",
                                accessorKey: "unidadMedida.unidadMedida",
                              },
                              {
                                header: "Cantidad",
                                accessorKey: "cantidad",
                                className: "text-end",
                              },
                              {
                                header: "Precio unitario",
                                accessorKey: "precioUnitario",
                                className: "text-end",
                                cell: ({ row: { original } }) =>
                                  toCurrency(original.precioUnitario),
                              },
                              {
                                header: "Impuesto",
                                accessorKey: "impuesto.impuesto",
                              },
                              {
                                header: "Importe impuesto",
                                accessorKey: "importeImpuesto",
                                className: "text-end",
                                cell: ({ row: { original } }) =>
                                  toCurrency(original.importeImpuesto),
                              },
                              {
                                header: "Importe neto",
                                accessorKey: "importeNeto",
                                className: "text-end",
                                cell: ({ row: { original } }) =>
                                  toCurrency(original.importeNeto),
                              },
                              {
                                header: "Acciones",
                                enableGlobalFilter: false,
                                cell: ({ row: { original } }) => (
                                  <>
                                    <Button
                                      variant="warning"
                                      className="me-2"
                                      size="sm"
                                      title="Editar"
                                      onClick={(e) =>
                                        setFormProductos(original)
                                      }
                                    >
                                      <i className="bi bi-pencil-square"> </i>
                                    </Button>
                                    <Button
                                      variant="danger"
                                      className="me-2"
                                      size="sm"
                                      title="Eliminar"
                                      onClick={(e) =>
                                        setConfirmDialog({
                                          message:
                                            "¿Desea eliminar este registro?",
                                          onConfirm: async () => {
                                            if (original.idEntradaProducto) {
                                              await eliminarEntradaProducto({
                                                idEntradaProducto:
                                                  original.idEntradaProducto,
                                              });
                                            }

                                            setProductos((prevProductos) => {
                                              return prevProductos.filter(
                                                (producto) =>
                                                  producto.uuid !==
                                                  original.uuid
                                              );
                                            });

                                            setConfirmDialog({});
                                          },
                                        })
                                      }
                                    >
                                      <i className="bi bi-trash"> </i>
                                    </Button>
                                  </>
                                ),
                              },
                            ]}
                          />
                          <ConfirmDialog
                            {...confirmDialog}
                            onCancel={() => setConfirmDialog({})}
                          />
                        </fieldset>
                        <Form.Group className="mb-3 me-sm-5 text-end">
                          <Form.Label
                            column
                            htmlFor="subtotal"
                            className="fw-bold"
                          >
                            Subtotal
                          </Form.Label>
                          <Controller
                            render={({ field }) => (
                              <NumericFormat
                                className="form-control mwx-150 d-inline-block ms-2 text-end"
                                id="subtotal"
                                thousandSeparator={true}
                                prefix={"$ "}
                                decimalScale={2}
                                fixedDecimalScale
                                allowNegative={false}
                                value={field.value || ""}
                                onValueChange={(v) => {
                                  field.onChange(
                                    Number(v.value) > 0 ? Number(v.value) : "0"
                                  );
                                }}
                                isAllowed={({ value }) =>
                                  value?.split(".")[0].length <= 10
                                }
                                readOnly
                              />
                            )}
                            name="subtotal"
                            control={control}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3 me-sm-5 text-end">
                          <Form.Label
                            column
                            htmlFor="subtotal"
                            className="fw-bold"
                          >
                            Impuesto
                          </Form.Label>
                          <Controller
                            render={({ field }) => (
                              <NumericFormat
                                className="form-control mwx-150 d-inline-block ms-2 text-end"
                                id="iva"
                                thousandSeparator={true}
                                prefix={"$ "}
                                decimalScale={2}
                                fixedDecimalScale
                                allowNegative={false}
                                value={field.value || ""}
                                onValueChange={(v) => {
                                  field.onChange(
                                    Number(v.value) > 0 ? Number(v.value) : "0"
                                  );
                                }}
                                isAllowed={({ value }) =>
                                  value?.split(".")[0].length <= 10
                                }
                                readOnly
                              />
                            )}
                            name="iva"
                            control={control}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3 me-sm-5 text-end">
                          <Form.Label
                            column
                            htmlFor="total"
                            className="fw-bold"
                          >
                            Total
                          </Form.Label>
                          <Controller
                            render={({ field }) => (
                              <NumericFormat
                                className="form-control mwx-150 d-inline-block ms-2 text-end"
                                id="total"
                                thousandSeparator={true}
                                prefix={"$ "}
                                decimalScale={2}
                                fixedDecimalScale
                                allowNegative={false}
                                value={field.value || ""}
                                onValueChange={(v) => {
                                  field.onChange(
                                    Number(v.value) > 0 ? Number(v.value) : "0"
                                  );
                                }}
                                isAllowed={({ value }) =>
                                  value?.split(".")[0].length <= 10
                                }
                                readOnly
                              />
                            )}
                            name="total"
                            control={control}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </Card>

                  <Button type="submit" variant="primary" className="me-2">
                    <i className="bi bi-save"></i> Guardar
                  </Button>
                  <Link
                    className="btn btn-secondary me-md-3 me-2"
                    to={"/entradas/productos"}
                  >
                    <i className="bi bi-box-arrow-in-left"></i> Regresar
                  </Link>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>
      {formProductos && (
        <EntradasProductosModal
          show={formProductos}
          onHide={(e) => {
            setFormProductos(false);
          }}
          onSave={actualizarProductos}
        />
      )}
    </div>
  );
};

export default EntradasProductosForm;
