import React, {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Alert, Button, Form, Modal} from "react-bootstrap";
import {useGuardarFabricanteTransporteMutation, useObtenerFabricanteTransporteQuery} from "../../../services/fabricanteTransporte.service";

const FabricantesTransportesForm = ({show, onHide, onSave}) => {

    const handleClose = (e) => {
        onHide(e);
    };

    const {control, handleSubmit, setValue, register, reset, watch, setFocus, formState: {errors}} = useForm();

    const {data: fabricanteTransporte} = useObtenerFabricanteTransporteQuery(show.idFabricanteTransporte, {skip: !show.idFabricanteTransporte, refetchOnMountOrArgChange: true});
    const [guardarFabricanteTransporte] = useGuardarFabricanteTransporteMutation();

    useEffect(() => {
        if (fabricanteTransporte) {
            reset({
                idFabricanteTransporte: fabricanteTransporte.idFabricanteTransporte,
                fabricanteTransporte: fabricanteTransporte.fabricanteTransporte,
                idEstatus: fabricanteTransporte.idEstatus,
                fechaVersion: fabricanteTransporte.fechaVersion
            });
        }

    }, [fabricanteTransporte, reset]);

    useEffect(() => {
        if(!show.idFabricanteTransporte){
            setFocus("fabricanteTransporte");
        }
    }, [show, setFocus]);

    const onSubmit = async (formData) => {
        let {data} = await guardarFabricanteTransporte(formData);
        if(data){
            setValue('idFabricanteTransporte', data.id);
            setValue('fechaVersion', data.fechaVersion);
            onSave();
        }
    };

    return (<> {
        <Modal show={show} onHide={handleClose} centered backdrop="static" keyboard={false}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>{watch("idFabricanteTransporte") ? 'Editar' : 'Nuevo'} [ Fabricante de transporte ]</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="text" id="idFabricanteTransporte" className="d-none" {...register("idFabricanteTransporte")} />
                    <Form.Control type="text" id="fechaVersion" className="d-none" {...register("fechaVersion")} />
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="fabricanteTransporte" className="fw-bold">
                            Fabricante de transporte
                        </Form.Label>
                        <Form.Control
                            {...register('fabricanteTransporte', {
                                required: 'Ingrese un dato válido',
                                maxLength: {value: 100, message: 'Ha excedido el máximo de caracteres'}
                            })}
                        />
                        {errors.fabricanteTransporte && (<Alert variant="danger" role="alert">
                            {errors.fabricanteTransporte.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Controller
                            name="idEstatus"
                            control={control}
                            defaultValue="1"
                            render={({field}) => (<>
                                    <Form.Label htmlFor="idEstatus">Estatus</Form.Label>
                                    <Form.Check
                                        id="idEstatus"
                                        type="switch"
                                        label={Boolean(field.value) ? 'Activo' : 'Inactivo'}
                                        checked={Boolean(field.value)}
                                        onChange={(e) => {
                                            field.onChange(Number(e.target.checked))
                                        }}
                                    />
                                </>

                            )}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant="primary" className="me-2">
                        <i className="bi bi-save"></i> Guardar
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>}
    </>);
};

export default FabricantesTransportesForm;
