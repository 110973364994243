import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const marcasTransportesApi = createApi({
    reducerPath: 'marcasTransportesApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL + "marcas/transportes",
        prepareHeaders: (headers, {getState}) => {
            const token = getState().auth.userInfo.token;
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
            return headers;
        },
    }),
    refetchOnMountOrArgChange: true,
    endpoints: (build) => ({
        obtenerMarcaTransporte: build.query({
            query: (idMarcaTransporte) => `/${idMarcaTransporte}`,
        }),
        obtenerMarcasTransportes: build.query({
            query: ([pageNumber, elementsPerPage] = [1, -1]) => `?page=${pageNumber}&size=${elementsPerPage}`,
        }),
        guardarMarcaTransporte: build.mutation({
            query: (marcaTransporte) => ({
                url: '',
                method: marcaTransporte.idMarcaTransporte ? 'PUT' : 'POST',
                body: marcaTransporte,
            }),
        }),
        eliminarMarcaTransporte: build.mutation({
            query: (marcaTransporte) => ({
                url: '',
                method: 'DELETE',
                body: marcaTransporte,
            }),
        }),
    }),
});
export const {
    useObtenerMarcaTransporteQuery,
    useObtenerMarcasTransportesQuery,
    useLazyObtenerMarcasTransportesQuery,
    useGuardarMarcaTransporteMutation,
    useEliminarMarcaTransporteMutation
} = marcasTransportesApi;
