import {
    useObtenerTurnosQuery,
    useEliminarTurnoMutation,
    useLazyObtenerTurnosQuery
} from "../../../services/turno.service";
import React, { useState } from "react";
import { Button, Row, Col, Card } from "react-bootstrap";
import SeverPagination from "../../../components/base/SeverPagination";
import TurnosForm from "./TurnosForm";
import BootstrapTable from "../../../components/base/BootstrapTable";
import ConfirmDialog from "../../../components/base/ConfirmDialog";
import { ExportToExcel } from "../../../components/base/ExportToExcel";

const Turnos = () => {
    const [pageNumber, setPageNumber] = useState(1);
    const [elementsPerPage, setElementsPerPage] = useState(10);

    const [formTurnos, setFormTurnos] = useState(null);
    const [confirmDialog, setConfirmDialog] = useState({});

    const {
        data: turnos, refetch
    } = useObtenerTurnosQuery([pageNumber, elementsPerPage], { refetchOnMountOrArgChange: true });
    const [trigger] = useLazyObtenerTurnosQuery();

    const [eliminarTurno] = useEliminarTurnoMutation();

    return (<div id="main" className="main">
        <section className="section">
        <Row>
            <Col lg={12}>
                <Card>
                    <Card.Body>
                        <div className="btn-toolbar justify-content-between" role="toolbar">
                            <div className="pagetitle">
                                <h1>Turnos</h1>
                            </div>
                        </div>
                            {turnos &&
                                <BootstrapTable striped
                                    toolbar={({ columns }) => (
                                        <div>
                                            <Button href="#" className="me-2" variant="primary" onClick={(e) => {
                                                setFormTurnos({})
                                            }}>
                                                <i className="bi bi-plus-square"></i>&nbsp;Nuevo
                                            </Button>
                                            <ExportToExcel columns={columns} onClick={trigger} fileName={'turnos'} />
                                        </div>
                                    )}
                                    data={turnos.data}
                                    columns={[{
                                        header: 'Clave', accessorKey: 'clave'
                                    }, {
                                        header: 'Turno', accessorKey: "turno"
                                    }, {
                                        header: 'Estatus', accessorKey: 'estatus.estatus', enableGlobalFilter: false
                                    }, {
                                        header: 'Acciones',
                                        enableGlobalFilter: false,
                                        cell: ({ row: { original } }) => (
                                            <div className="w-100 d-grid d-sm-block gap-1" >
                                                <Button variant="warning" className="me-2" size="sm"
                                                    onClick={e => setFormTurnos(original)}>
                                                    <i className="bi bi-pencil-square"></i>
                                                    Editar
                                                </Button>

                                                <Button variant="danger" className="me-2" size="sm"
                                                    onClick={e => setConfirmDialog({
                                                        message: '¿Desea eliminar este registro?', onConfirm: async () => {
                                                            await eliminarTurno({ idTurno: original.idTurno });
                                                            refetch();
                                                            setConfirmDialog({});
                                                        }
                                                    })}>
                                                    <i className="bi bi-trash"></i>
                                                    Eliminar
                                                </Button>
                                            </div>)
                                        }]}
                                        onGlobalFilterChange={(e => {
                                            setPageNumber(1);
                                            setElementsPerPage(e ? -1: 10);
                                        })}
                                />}
                                {turnos && <SeverPagination
                                    data={turnos.data}
                                    currentPage={pageNumber}
                                    totalElements={turnos.totalElements}
                                    elementsPerPage={elementsPerPage}
                                    onPageChange={setPageNumber}
                                    onElementsPerPageChange={setElementsPerPage}
                                />}
                    </Card.Body>
                        <ConfirmDialog {...confirmDialog} onCancel={() => setConfirmDialog({})} />
                </Card>
            </Col>
        </Row>
        </section>
        {formTurnos && <TurnosForm show={formTurnos} onHide={(e) => {
            setFormTurnos(false);
        }} onSave={refetch}/>}
    </div>);
}

export default Turnos;
