import React, {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Alert, Button, Form, Modal} from "react-bootstrap";
import {
    useGuardarSeccionAlmacenMutation,
    useObtenerSeccionAlmacenQuery,
    useObtenerSeccionesAlmacenesQuery
} from '../../../services/seccionAlmacen.service';
import {useObtenerAlmacenesQuery} from "../../../services/almacen.service";
import {useObtenerTiposSeccionesAlmacenesQuery} from "../../../services/tipoSeccionAlmacen.service";
import {useObtenerMetodosSalidasAlmacenesQuery} from "../../../services/metodoSalidaAlmacen.service";
import {useObtenerMetodosTrasladosAlmacenesQuery} from "../../../services/metodoTrasladoAlmacen.service";
import {useObtenerPasillosAlmacenesQuery} from "../../../services/pasilloAlmacen.service";
import {useObtenerEstanteriasAlmacenesQuery} from "../../../services/estanteriaAlmacen.service";

const SeccionesAlmacenesForm = ({show, onHide, onSave}) => {
    const handleClose = (e) => {
        onHide(e);
    };

    const {control, handleSubmit, setValue, register, reset, watch, formState: {errors}} = useForm();

    const {data: seccionAlmacen} = useObtenerSeccionAlmacenQuery(show.idSeccionAlmacen, {skip: !show.idSeccionAlmacen, refetchOnMountOrArgChange: true});
    const [guardarSeccionAlmacen] = useGuardarSeccionAlmacenMutation();

    const { data: seccionesAlmacenes } = useObtenerSeccionesAlmacenesQuery();
    const { data: almacenes } = useObtenerAlmacenesQuery();
    const { data: tiposSeccionesAlmacenes } = useObtenerTiposSeccionesAlmacenesQuery();
    const { data: metodosSalidasAlmacenes } = useObtenerMetodosSalidasAlmacenesQuery();
    const { data: metodosTrasladosAlmacenes } = useObtenerMetodosTrasladosAlmacenesQuery();
    const { data: pasillosAlmacenes } = useObtenerPasillosAlmacenesQuery();
    const { data: estanteriasAlmacenes } = useObtenerEstanteriasAlmacenesQuery();

    useEffect(() => {
        if (seccionAlmacen && seccionesAlmacenes && almacenes && tiposSeccionesAlmacenes && metodosSalidasAlmacenes && metodosTrasladosAlmacenes && pasillosAlmacenes && estanteriasAlmacenes) {
            reset({
                idSeccionAlmacen: seccionAlmacen.idSeccionAlmacen,
                idSeccionAlmacenPadre: seccionAlmacen.idSeccionAlmacenPadre,
                idAlmacen: seccionAlmacen.idAlmacen,
                idTipoSeccionAlmacen: seccionAlmacen.idTipoSeccionAlmacen,
                idMetodoSalidaAlmacen: seccionAlmacen.idMetodoSalidaAlmacen,
                idMetodoTrasladoAlmacen: seccionAlmacen.idMetodoTrasladoAlmacen,
                idPasilloAlmacen: seccionAlmacen.idPasilloAlmacen,
                idEstanteriaAlmacen: seccionAlmacen.idEstanteriaAlmacen,
                seccionAlmacen: seccionAlmacen.seccionAlmacen,
                altura: seccionAlmacen.altura,
                idEstatus: seccionAlmacen.idEstatus,
                fechaVersion: seccionAlmacen.fechaVersion
            });
        } else {
            // default values
            reset({idEstatus: 1})
        }
    }, [seccionAlmacen, seccionesAlmacenes, almacenes, tiposSeccionesAlmacenes, metodosSalidasAlmacenes, metodosTrasladosAlmacenes, pasillosAlmacenes, estanteriasAlmacenes, reset]);

    const onSubmit = async (formData) => {
        let {data} = await guardarSeccionAlmacen(formData);
        if (data) {
            setValue('idSeccionAlmacen', data.id);
            setValue('fechaVersion', data.fechaVersion);
            onSave();
        }
    };

    return (<> {
        < Modal className="modal-fixed" show={show} onHide={handleClose} centered backdrop="static" keyboard={false}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>{watch("idSeccionAlmacen") ? 'Editar' : 'Nuevo'} [Seccion]</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h6> *Los campos en negritas son requeridos</h6>
                    <Form.Control type="text" id="idSeccionAlmacen" className='d-none' {...register("idSeccionAlmacen")} />
                    <Form.Control type="text" id='fechaVersion' className='d-none' {...register("fechaVersion")} />

                    <Form.Group className='mb-3'>
                        <Form.Label htmlFor='seccionAlmacen' className='fw-bold'>
                            Nombre
                        </Form.Label>
                        <Form.Control
                            className="mwx-350"
                            {...register('seccionAlmacen', {
                                required: 'Ingrese un dato valido',
                                maxLength: {value: 100, message: 'Ha excedido el máximo de caracteres'}
                            })}
                        />
                        {errors.seccionAlmacen && (<Alert variant='danger' role='alert'>
                            {errors.seccionAlmacen.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className='mb-3'>
                        <Form.Label htmlFor='idSeccionAlmacenPadre' className='fw-bold' >
                            Sección padre
                        </Form.Label>
                        <Form.Select
                            className="mwx-350"
                            id="idSeccionAlmacenPadre"
                            {...register("idSeccionAlmacenPadre", {
                                required: "Ingrese un dato válido"
                            })}
                        >
                            <option value="">Seleccionar</option>
                            {seccionesAlmacenes?.data && seccionesAlmacenes.data.map((seccionAlmacen) => (
                                <option key={'seccionAlmacen.' + seccionAlmacen.idSeccionAlmacen}
                                        value={seccionAlmacen.idSeccionAlmacen}>
                                    {seccionAlmacen.seccionAlmacen}
                                </option>
                            ))}
                        </Form.Select>
                        {errors.idSeccionAlmacenPadre && (<Alert variant='danger' role='alert'>
                            {errors.idSeccionAlmacenPadre.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className='mb-3'>
                        <Form.Label htmlFor='idAlmacen' className='fw-bold' >
                            Almacén
                        </Form.Label>
                        <Form.Select
                            className="mwx-350"
                            id="idAlmacen"
                            {...register("idAlmacen", {
                                required: "Ingrese un dato válido"
                            })}
                        >
                            <option value="">Seleccionar</option>
                            {almacenes?.data && almacenes.data.map((almacen) => (
                                <option key={'almacenes.' + almacen.idAlmacen}
                                        value={almacen.idAlmacen}>
                                    {almacen.almacen}
                                </option>
                            ))}
                        </Form.Select>
                        {errors.idAlmacen && (<Alert variant='danger' role='alert'>
                            {errors.idAlmacen.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className='mb-3'>
                        <Form.Label htmlFor='idTipoSeccionAlmacen' className='fw-bold' >
                            Tipo de sección
                        </Form.Label>
                        <Form.Select
                            className="mwx-350"
                            id="idTipoSeccionAlmacen"
                            {...register("idTipoSeccionAlmacen", {
                                required: "Ingrese un dato válido"
                            })}
                        >
                            <option value="">Seleccionar</option>
                            {tiposSeccionesAlmacenes?.data && tiposSeccionesAlmacenes.data.map((tipoSeccionAlmacen) => (
                                <option key={'tipoSeccionAlmacen.' + tipoSeccionAlmacen.idTipoSeccionAlmacen}
                                        value={tipoSeccionAlmacen.idTipoSeccionAlmacen}>
                                    {tipoSeccionAlmacen.tipoSeccionAlmacen}
                                </option>
                            ))}
                        </Form.Select>
                        {errors.idTipoSeccionAlmacen && (<Alert variant='danger' role='alert'>
                            {errors.idTipoSeccionAlmacen.message}
                        </Alert>)}
                    </Form.Group>
                    <fieldset className="border rounded-3 p-3 py-1 mb-2">
                        <legend className="float-none px-2 mt-0 mb-2 fs-5 w-auto"> Logistica</legend>
                        <Form.Group className='mb-3'>
                            <Form.Label htmlFor='idMetodoSalidaAlmacen' className='fw-bold' >
                                Método de salida
                            </Form.Label>
                            <Form.Select
                                className="mwx-350"
                                id="idMetodoSalidaAlmacen"
                                {...register("idMetodoSalidaAlmacen", {
                                    required: "Ingrese un dato válido"
                                })}
                            >
                                <option value="">Seleccionar</option>
                                {metodosSalidasAlmacenes?.data && metodosSalidasAlmacenes.data.map((metodoSalidaAlmacen) => (
                                    <option key={'metodoSalidaAlmacen.' + metodoSalidaAlmacen.idMetodoSalidaAlmacen}
                                            value={metodoSalidaAlmacen.idMetodoSalidaAlmacen}>
                                        {metodoSalidaAlmacen.metodoSalidaAlmacen}
                                    </option>
                                ))}
                            </Form.Select>
                            {errors.idMetodoSalidaAlmacen && (<Alert variant='danger' role='alert'>
                                {errors.idMetodoSalidaAlmacen.message}
                            </Alert>)}
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label htmlFor='idMetodoTrasladoAlmacen' >
                                Método de traslado
                            </Form.Label>
                            <Form.Select
                                className="mwx-350"
                                id="idMetodoTrasladoAlmacen"
                                {...register("idMetodoTrasladoAlmacen")}
                            >
                                <option value="">Seleccionar</option>
                                {metodosTrasladosAlmacenes?.data && metodosTrasladosAlmacenes.data.map((metodoTrasladoAlmacen) => (
                                    <option key={'metodoTrasladoAlmacen.' + metodoTrasladoAlmacen.idMetodoTrasladoAlmacen}
                                            value={metodoTrasladoAlmacen.idMetodoTrasladoAlmacen}>
                                        {metodoTrasladoAlmacen.metodoTrasladoAlmacen}
                                    </option>
                                ))}
                            </Form.Select>
                            {errors.idMetodoTrasladoAlmacen && (<Alert variant='danger' role='alert'>
                                {errors.idMetodoTrasladoAlmacen.message}
                            </Alert>)}
                        </Form.Group>
                    </fieldset>
                    <fieldset className="border rounded-3 p-3 py-1 mb-2">
                        <legend className="float-none px-2 mt-0 mb-2 fs-5 w-auto"> Localización</legend>
                        <Form.Group className='mb-3'>
                            <Form.Label htmlFor='idPasilloAlmacen'>
                                Pasillo
                            </Form.Label>
                            <Form.Select
                                className="mwx-350"
                                id="idPasilloAlmacen"
                                {...register("idPasilloAlmacen")}
                            >
                                <option value="">Seleccionar</option>
                                {pasillosAlmacenes?.data && pasillosAlmacenes.data.map((pasilloAlmacen) => (
                                    <option key={'pasilloAlmacen.' + pasilloAlmacen.idPasilloAlmacen}
                                            value={pasilloAlmacen.idPasilloAlmacen}>
                                        {pasilloAlmacen.pasilloAlmacen}
                                    </option>
                                ))}
                            </Form.Select>
                            {errors.idPasilloAlmacen && (<Alert variant='danger' role='alert'>
                                {errors.idPasilloAlmacen.message}
                            </Alert>)}
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label htmlFor='idEstanteriaAlmacen'>
                                Estantería
                            </Form.Label>
                            <Form.Select
                                className="mwx-350"
                                id="idEstanteriaAlmacen"
                                {...register("idEstanteriaAlmacen")}
                            >
                                <option value="">Seleccionar</option>
                                {estanteriasAlmacenes?.data && estanteriasAlmacenes.data.map((estanteriaAlmacen) => (
                                    <option key={'estanteriaAlmacen.' + estanteriaAlmacen.idEstanteriaAlmacen}
                                            value={estanteriaAlmacen.idEstanteriaAlmacen}>
                                        {estanteriaAlmacen.estanteriaAlmacen}
                                    </option>
                                ))}
                            </Form.Select>
                            {errors.idEstanteriaAlmacen && (<Alert variant='danger' role='alert'>
                                {errors.idEstanteriaAlmacen.message}
                            </Alert>)}
                        </Form.Group>
                        <Form.Group className='mb-3'>
                            <Form.Label htmlFor='altura'>
                                Altura
                            </Form.Label>
                            <Form.Control
                                type="number"
                                step=".01"
                                className="mwx-350"
                                {...register('altura', {
                                    maxLength: {value: 10, message: 'Ha excedido el máximo de caracteres'}
                                })}
                            />
                            {errors.altura && (<Alert variant='danger' role='alert'>
                                {errors.altura.message}
                            </Alert>)}
                        </Form.Group>

                    </fieldset>
                
                    <Form.Group className='mb-3'>
                        <Controller
                            name='idEstatus'
                            control={control}
                            render={({field}) => (<>
                                    <Form.Label htmlFor='idEstatus'> Estatus </Form.Label>
                                    <Form.Check
                                        id='idEstatus'
                                        type='switch'
                                        label={Boolean(field.value) ? 'Activo' : 'Inactivo'}
                                        checked={Boolean(field.value)}
                                        onChange={(e) => {
                                            field.onChange(Number(e.target.checked))
                                        }}
                                    />
                                </>
                            )}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button type='submit' variant='primary' className='me-2'>
                        <i className='bi bi-save'></i> Guardar
                    </Button>
                    <Button variant='secondary' onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>}
    </>);
}

export default SeccionesAlmacenesForm;