import {
    useEliminarMantenimientoMutation,
    useBuscarMantenimientosQuery
} from '../../../services/mantenimiento.service';
import React, { useState, useEffect } from "react";
import { Button, Row, Col, Card, Form } from "react-bootstrap";
import SeverPagination from "../../../components/base/SeverPagination";
//import { ExportToExcel } from "../../../components/base/ExportToExcel";
import { useForm, Controller } from 'react-hook-form';
import { useLocation, useNavigate } from "react-router-dom";
import { cleanProperties } from "../../../helpers/cleanProperties";
import MantenimientoCard from '../../../components/mantenimientos/MantenimientoCard'
import MantenimientosForm from './MantenimientosForm';
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import { NumericFormat } from "react-number-format";


const Mantenimientos = () => {
    const { control, register, reset, handleSubmit } = useForm();

    const [formMantenimientos, setFormMantenimientos] = useState(null)
    const [eliminarMantenimiento] = useEliminarMantenimientoMutation()

    const { state, pathname } = useLocation();
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useState({});

    const [pageNumber, setPageNumber] = useState(1);
    const [elementsPerPage, setElementsPerPage] = useState(10);

    const { data: mantenimientos, refetch } =
        useBuscarMantenimientosQuery(
            { pageNumber, elementsPerPage, searchParams },
            { refetchOnMountOrArgChange: true }
        );

    const onSubmit = async (formData) => {
        setSearchParams(cleanProperties(formData));
        refetch();
    };

    const clearForm = () => {
        navigate(pathname, {});
        setSearchParams({});
        reset();
    }

    useEffect(() => {
        setSearchParams(state || {});
        reset({ buscador: state?.buscador || '', idEstatus: state?.idEstatus || '1' });
    }, [reset, setSearchParams, state]);

    return (<>
        <main id="main" className="main">
            <Form id="formulario" className="row g-3" onSubmit={handleSubmit(onSubmit)}>
                <div className="input-group">
                    <Form.Control
                        type="text"
                        placeholder="Buscar por: Modelo, Matrícula, Año de fabricación"
                        {...register("buscador")}
                    />
                    <Button type="submit" variant="primary" title={"Buscar"}>
                        <i className="bi bi-search"></i>
                    </Button>
                    <Button
                        className="ms-2"
                        variant="primary"
                        title={"Limpiar"}
                        onClick={(e) => clearForm()}
                    >
                        <i className="bi bi-eraser"></i>
                    </Button>
                    <Button
                        variant="primary"
                        className="ms-2"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#filtros"
                        aria-expanded="false"
                        aria-controls="filtros"
                        title={"Filtros"}
                    >
                        <i className="bi bi-sliders"></i>
                    </Button>
                </div>
                <div className="collapse" id="filtros">
                    <Card className="card-body mb-0">
                        <h5 className="mb-3 mt-3 fw-bold">Criterios de filtrado.</h5>
                        <Row>
                            <Col className="border-end">
                                <Form.Label htmlFor="fechaMantenimiento" className="form-label">
                                    Fecha mantenimiento
                                </Form.Label>
                                <Col xs={12} className="mb-2">
                                    <Controller
                                        render={({ field }) => (
                                            <DatePicker
                                                className="form-control"
                                                id="fechaMantenimiento"
                                                dateFormat="dd/MM/yyyy"
                                                value={field.value}
                                                onChange={(date) => {
                                                    field.onChange(
                                                        dayjs(date).format("DD/MM/YYYY")
                                                        
                                                    );
                                                }}                                                
                                            />
                                            
                                        )}
                                        name="fechaMantenimiento"
                                        control={control}
                                    />
                                </Col>
                            </Col>

                            <Col className="border-end col">
                                <Col>
                                    <div>
                                        <Form.Label htmlFor="kilometraje">
                                            Kilometraje
                                        </Form.Label>
                                        <Controller
                                            render={({ field }) => (
                                                <NumericFormat
                                                    className="form-control mwx-400"
                                                    thousandSeparator={true}
                                                    suffix={" KM"}
                                                    decimalScale={2}
                                                    fixedDecimalScale
                                                    allowNegative={false}
                                                    value={field.value || ""}
                                                    onValueChange={(v) => {
                                                        field.onChange(
                                                            Number(v.value) > 0 ? Number(v.value) : null
                                                        );
                                                    }}
                                                    isAllowed={({ value }) =>
                                                        value?.split(".")[0].length <= 10
                                                    }
                                                />
                                            )}
                                            name="kilometraje"
                                            control={control}
                                        />
                                    </div>
                                </Col>
                            </Col>

                            <Col className="border-end">
                                <Controller
                                    name="idEstatus"
                                    control={control}
                                    render={({ field }) => (
                                        <>
                                            <Form.Label htmlFor="idEstatus">
                                                Estatus
                                            </Form.Label>
                                            <Form.Check
                                                id="estatus.-0"
                                                type="radio"
                                                label="Todas"
                                                value=""
                                                {...register("idEstatus")}
                                                defaultChecked={!state?.idEstatus}
                                            />
                                            <Form.Check
                                                id="estatus.120"
                                                type="radio"
                                                label="Atendida"
                                                value="120" // Valor fijo para 'Atendida'
                                                {...register("idEstatus")}
                                                defaultChecked={parseInt(state?.idEstatus) === 1}
                                            />
                                            <Form.Check
                                                id="estatus.121"
                                                type="radio"
                                                label="Cancelada"
                                                value="121" // Valor fijo para 'Cancelada'
                                                {...register("idEstatus")}
                                                defaultChecked={parseInt(state?.idEstatus) === 0}
                                            />
                                            <Form.Check
                                                id="estatus.119"
                                                type="radio"
                                                label="Programada"
                                                value="119" // Valor fijo para 'Programada'
                                                {...register("idEstatus")}
                                                defaultChecked={parseInt(state?.idEstatus) === 0}
                                            />
                                        </>
                                    )}
                                />
                            </Col>
                        </Row>
                        <Row className="text-end mt-3">
                            <Button type="submit" variant="primary">
                                Aplicar
                            </Button>
                        </Row>
                    </Card>
                </div>
            </Form>
            <div className="col-12 text-end">
                <Button
                    className="mt-3"
                    href="#"
                    variant="primary"
                    onClick={(e) => {
                        setFormMantenimientos({});
                    }}
                >
                    <i className="bi bi-plus-square"></i>&nbsp;Nuevo
                </Button>
            </div>

            {mantenimientos?.data &&
                mantenimientos.data.map((mantenimiento) => (
                    <MantenimientoCard
                        key={'mantenimiento.' + mantenimiento.idMantenimiento}
                        mantenimiento={mantenimiento}
                        refetch={refetch}
                        eliminarMantenimiento={eliminarMantenimiento}
                    />
                ))}

            {mantenimientos && (
                <SeverPagination
                    data={mantenimientos.data}
                    currentPage={pageNumber}
                    totalElements={mantenimientos.totalElements}
                    elementsPerPage={elementsPerPage}
                    onPageChange={setPageNumber}
                    onElementsPerPageChange={setElementsPerPage}
                />)}

            {formMantenimientos && (
                <MantenimientosForm
                    show={setFormMantenimientos}
                    onHide={(e) => {
                        setFormMantenimientos(false);
                    }} onSave={refetch} />)}
        </main>
    </>)
}


export default Mantenimientos