import React, {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Alert, Button, Form, Modal} from "react-bootstrap";
import {useGuardarClaseTransporteMutation, useObtenerClaseTransporteQuery} from "../../../services/claseTransporte.service";

const ClasesTransportesForm = ({show, onHide, onSave}) => {

    const handleClose = (e) => {
        onHide(e);
    };

    const {control, handleSubmit, setValue, register, reset, watch, setFocus, formState: {errors}} = useForm();

    const {data: claseTransporte} = useObtenerClaseTransporteQuery(show.idClaseTransporte, {skip: !show.idClaseTransporte, refetchOnMountOrArgChange: true});
    const [guardarClaseTransporte] = useGuardarClaseTransporteMutation();

    useEffect(() => {
        if (claseTransporte) {
            reset({
                idClaseTransporte: claseTransporte.idClaseTransporte,
                claseTransporte: claseTransporte.claseTransporte,
                idEstatus: claseTransporte.idEstatus,
                fechaVersion: claseTransporte.fechaVersion
            });
        }

    }, [claseTransporte, reset]);

    useEffect(() => {
        if(!show.idClaseTransporte){
            setFocus("claseTransporte");
        }
    }, [show, setFocus]);

    const onSubmit = async (formData) => {
        let {data} = await guardarClaseTransporte(formData);
        if(data){
            setValue('idClaseTransporte', data.id);
            setValue('fechaVersion', data.fechaVersion);
            onSave();
        }
    };

    return (<> {
        <Modal show={show} onHide={handleClose} centered backdrop="static" keyboard={false}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>{watch("idClaseTransporte") ? 'Editar' : 'Nuevo'} [ Clase de transporte ]</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="text" id="idClaseTransporte" className="d-none" {...register("idClaseTransporte")} />
                    <Form.Control type="text" id="fechaVersion" className="d-none" {...register("fechaVersion")} />
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="claseTransporte" className="fw-bold">
                            Clase de transporte
                        </Form.Label>
                        <Form.Control
                            {...register('claseTransporte', {
                                required: 'Ingrese un dato válido',
                                maxLength: {value: 100, message: 'Ha excedido el máximo de caracteres'}
                            })}
                        />
                        {errors.claseTransporte && (<Alert variant="danger" role="alert">
                            {errors.claseTransporte.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Controller
                            name="idEstatus"
                            control={control}
                            defaultValue="1"
                            render={({field}) => (<>
                                    <Form.Label htmlFor="idEstatus">Estatus</Form.Label>
                                    <Form.Check
                                        id="idEstatus"
                                        type="switch"
                                        label={Boolean(field.value) ? 'Activo' : 'Inactivo'}
                                        checked={Boolean(field.value)}
                                        onChange={(e) => {
                                            field.onChange(Number(e.target.checked))
                                        }}
                                    />
                                </>

                            )}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant="primary" className="me-2">
                        <i className="bi bi-save"></i> Guardar
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>}
    </>);
};

export default ClasesTransportesForm;
