import React from 'react';
import { Form, Badge } from 'react-bootstrap';

const FormLabelCounter = ({ htmlFor, input, maxLength, className, children }) => {
    return (
        <Form.Label htmlFor={htmlFor} className={`d-flex justify-content-between align-items-center ${className}`}>
            <span>{children}</span>
            {input && (
                <Badge bg={`${input.length > maxLength ? 'danger' : 'primary'}`}>
                    {input.length}/{maxLength}
                </Badge>
            )}
        </Form.Label>
    );
};

export default FormLabelCounter;
