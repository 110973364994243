import React, {useEffect, useRef, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {Alert, Button, Form, Modal} from "react-bootstrap";
import {useObtenerUnidadesMedidasQuery} from "../../../services/unidadesMedidas.service";
import {useObtenerImpuestosQuery} from "../../../services/impuesto.service";
import {NumericFormat} from "react-number-format";
import _ from "lodash";
import {toNumber} from "../../../helpers/toNumber";
import {v4 as uuidv4} from "uuid";
import Select from 'react-select';
import Html5QrcodePlugin from "../../../components/Html5QrcodePlugin";
import {useObtenerConfiguracionAlmacenPorTipoQuery} from "../../../services/configuracionAlmacen.service";
import {useObtenerEntradasProductosPorAlmacenQuery} from "../../../services/entradaProducto.service";

const SalidasProductosModal = ({show: salidaProducto, idAlmacen, onHide, onSave}) => {

    const handleClose = (e) => {
        onHide(e);
    };

    const {control, handleSubmit, register, reset, setValue, watch, formState: {errors}} = useForm();

    const {data: configuracion} = useObtenerConfiguracionAlmacenPorTipoQuery('s');
    const {data: productos} = useObtenerEntradasProductosPorAlmacenQuery(idAlmacen);
    const {data: unidadesMedidas} = useObtenerUnidadesMedidasQuery();
    const {data: impuestos} = useObtenerImpuestosQuery();

    const productoRef = useRef(null);
    const [escanear, setEscanear] = useState(false);
    const [searchProducto, setSearchProducto] = useState("");


    useEffect(() => {
        if (salidaProducto?.uuid  && productos && unidadesMedidas && impuestos) {
            reset({
                uuid: salidaProducto.uuid,
                idSalidaProducto: salidaProducto.idSalidaProducto,
                idProducto: salidaProducto.idProducto,
                idUnidadMedida: salidaProducto.idUnidadMedida,
                idImpuesto: salidaProducto.idImpuesto,
                cantidad: salidaProducto.cantidad || 1,
                precioUnitario: salidaProducto.precioUnitario || '0',
                importeImpuesto: salidaProducto.importeImpuesto,
                importeNeto: salidaProducto.importeNeto,
                fechaVersion: salidaProducto.fechaVersion
            });
        } else if(impuestos && configuracion){
            //default values
            reset({
                idImpuesto: configuracion.idImpuesto
            });
        }
        if(!salidaProducto.idSalidaProducto && productos){
            productoRef.current?.focus();
        }
    }, [productoRef, salidaProducto, productos, unidadesMedidas, impuestos, configuracion, reset]);

    const onSubmit = async (formData) => {
        //valores embedidos de la tabla
        formData.producto = productos.find(e => e.idProducto === parseInt(formData.idProducto) );
        formData.unidadMedida = unidadesMedidas.data.find(e => e.idUnidadMedida === parseInt(formData.idUnidadMedida) );
        formData.impuesto = impuestos.data.find(e => e.idImpuesto === parseInt(formData.idImpuesto) );

        //calcular cantidades
        let valorImpuesto = _.divide(toNumber(formData.impuesto.valor), 100);
        let cantidad = toNumber(formData.cantidad);
        let precioUnitario = toNumber(formData.precioUnitario);
        let subTotal = _.multiply(cantidad, precioUnitario);
        formData.importeImpuesto =  _.round(_.multiply(subTotal, valorImpuesto),2);
        formData.importeNeto = _.floor(_.add(subTotal, formData.importeImpuesto),2);
        //generar id unico
        if(!formData.uuid){
            formData.uuid = uuidv4();
        }
        onSave(formData.uuid, formData);

        if(formData.nuevo){
            reset({
                uuid: null,
                idSalidaProducto: null,
                idProducto: null,
                idUnidadMedida: null,
                idImpuesto: null,
                cantidad: 1,
                precioUnitario: '0',
                importeImpuesto: null,
                importeNeto: null,
                fechaVersion: null
            });
        }
    };
    const labelByProducto = (producto)  => {
        if(!producto) return null;
        return `${producto.producto} - Bar.: ${producto.codigoBarras}`
    }
    const onNewScanResult = (decodedText, decodedResult) => {
        if(decodedText){
            setSearchProducto(decodedText)
            document.getElementById("html5-qrcode-button-camera-stop").click();
            setEscanear(false);
            productoRef.current?.focus();
        }
    };

    return (<> {
        <Modal show={salidaProducto} onHide={handleClose} centered backdrop="static" keyboard={false}>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Header closeButton>
                    <Modal.Title>{watch("idSalidaProducto") ? 'Editar' : 'Nuevo'} [ Producto ]</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="text" id="uuid" className="d-none" {...register("uuid")} />
                    <Form.Control type="text" id="idSalidaProducto" className="d-none" {...register("idSalidaProducto")} />
                    <Form.Control type="text" id="fechaVersion" className="d-none" {...register("fechaVersion")} />

                    <Form.Group className='mb-3'>
                        <Form.Label htmlFor='idProducto' className='fw-bold'>
                            Producto
                        </Form.Label>
                        <Button  className="ms-2 text-secondary" variant="outline-light" onClick={e => setEscanear(s => !s)} title="Escanear código de barras">
                            <i className="bi bi-upc-scan"></i>
                        </Button>
                        <Controller
                            render={({field}) => (
                                productos &&
                                <Select
                                    ref={productoRef}
                                    value={{value: field.value, label: labelByProducto(productos.find((p) => p.idProducto === field.value)) ?? "Seleccionar" }}
                                    inputValue={searchProducto}
                                    menuIsOpen={searchProducto}
                                    isSearchable={true}
                                    placeholder="Seleccionar"
                                    options={productos.map((producto) => ({
                                        value: producto.idProducto,
                                        label: labelByProducto(producto)
                                    }))}
                                    onInputChange={(v) => {setSearchProducto(v)}}
                                    onChange={(v) => {
                                        field.onChange(Number(v.value) > 0 ? Number(v.value) : '0');
                                        setValue("idUnidadMedida", productos.find((p) => p.idProducto === v.value).idUnidadMedida)
                                    }}
                                    onMenuOpen={(v) => {setSearchProducto(undefined)}}
                                />

                            )}
                            name="idProducto"
                            control={control}
                            rules={{required: "Ingrese un dato válido"}}
                        />
                        {errors.idProducto && (<Alert variant='danger' role='alert'>
                            {errors.idProducto.message}
                        </Alert>)}
                    </Form.Group>

                    <div className={escanear ? '' : 'd-none'}>
                        <Form.Label >
                            Escanear producto
                        </Form.Label>
                        <Html5QrcodePlugin
                            fps={10}
                            qrbox={250}
                            disableFlip={false}
                            qrCodeSuccessCallback={onNewScanResult}
                            verbose
                        />
                    </div>

                    <Form.Group className='mb-3'>
                        <Form.Label htmlFor='idProducto' className='fw-bold'>
                            Unidad de medida
                        </Form.Label>
                        <Form.Select
                            id="idUnidadMedida"
                            value={productos?.find((p) => p.idProducto === watch("idProducto"))?.idUnidadMedida}
                            disabled
                            {...register("idUnidadMedida", {
                                required: "Ingrese un dato válido"
                            })}
                        >
                            <option value="">Seleccionar</option>
                            {unidadesMedidas?.data && unidadesMedidas.data.map((unidadMedida) => (
                                <option key={'unidadesMedidas.' + unidadMedida.idUnidadMedida}
                                        value={unidadMedida.idUnidadMedida}>
                                    {unidadMedida.unidadMedida}
                                </option>
                            ))}
                        </Form.Select>
                        {errors.idUnidadMedida && (<Alert variant='danger' role='alert'>
                            {errors.idUnidadMedida.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="cantidad" className="fw-bold">
                            Cantidad
                        </Form.Label>
                        <Controller
                            render={({field}) => (
                                <NumericFormat
                                    className="form-control"
                                    id="cantidad"
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    fixedDecimalScale
                                    allowNegative={false}
                                    value={field.value || ''}
                                    onValueChange={(v) => {
                                        field.onChange(Number(v.value) > 0 ? Number(v.value) : '0');
                                    }}
                                    isAllowed={({value}) => (value?.split(".")[0].length <= 10)}
                                />
                            )}
                            name="cantidad"
                            control={control}
                            rules={{required: "Ingrese un dato válido"}}
                        />
                        {errors.cantidad && (<Alert variant="danger" role="alert">
                            {errors.cantidad.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label htmlFor="precioUnitario" className="fw-bold">
                            Precio unitario
                        </Form.Label>
                        <Controller
                            render={({field}) => (
                                <NumericFormat
                                    className="form-control"
                                    id="precioUnitario"
                                    thousandSeparator={true}
                                    prefix={'$ '}
                                    decimalScale={2}
                                    fixedDecimalScale
                                    allowNegative={false}
                                    value={field.value || ''}
                                    onValueChange={(v) => {
                                        field.onChange(Number(v.value) > 0 ? Number(v.value) : '0');
                                    }}
                                    isAllowed={({value}) => (value?.split(".")[0].length <= 10)}
                                />
                            )}
                            name="precioUnitario"
                            control={control}
                            rules={{required: "Ingrese un dato válido"}}
                        />
                        {errors.precioUnitario && (<Alert variant="danger" role="alert">
                            {errors.precioUnitario.message}
                        </Alert>)}
                    </Form.Group>
                    <Form.Group className='mb-3'>
                        <Form.Label htmlFor='idImpuesto' className='fw-bold'>
                            Impuesto
                        </Form.Label>
                        <Form.Select
                            id="idImpuesto"
                            {...register("idImpuesto", {
                                required: "Ingrese un dato válido"
                            })}
                        >
                            <option value="">Seleccionar</option>
                            {impuestos?.data && impuestos.data.map((impuesto) => (
                                <option key={'impuestos.' + impuesto.idImpuesto}
                                        value={impuesto.idImpuesto}>
                                    {impuesto.impuesto} -   {impuesto.valor}%
                                </option>
                            ))}
                        </Form.Select>
                        {errors.idImpuesto && (<Alert variant='danger' role='alert'>
                            {errors.idImpuesto.message}
                        </Alert>)}
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant="primary" className="me-2" >
                        <i className="bi bi-save"></i> Añadir y cerrar
                    </Button>
                    <Button type="submit" variant="success" className="me-2" onClick={handleSubmit((d) => {d.nuevo = true; onSubmit(d)})}>
                        <i className="bi bi-save"></i> Añadir y nuevo
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>}
    </>);
};

export default SalidasProductosModal;
