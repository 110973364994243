import React, { useState } from "react";
import { Card } from "react-bootstrap";
import DocumentacionLegalForm from "../../views/catalogos/documentacionLegal/DocumentacionLegalVista";
import { Link } from "react-router-dom";

const DocumentacionCard = ({ empresa, refetch }) => {
    const [vistaDocumentacion, setVistaDocumentacion] = useState(null);

    return (
        <Card className={`mb-0 mt-3`}>
            <Card.Header className={`pb-0 mb-0 fw-bold alert alert-primary`}>
                <h5 className="card-title pt-1 pb-0 text-primary">
                    {empresa.empresa}{" "}
                </h5>
            </Card.Header>
            <div className="row card-body pt-2">
                <p className="card-text mb-1">
                    <span className="text-muted">
                        Fecha de modificación: <b>{empresa.fechaVersion?.split(" ")[0]}</b>
                    </span>
                </p>

                <div className="col-md-12 mb-2 pb-0">
                    <p className="card-text mb-1">
                        <strong>RFC: </strong>
                        {empresa.rfc}
                    </p>
                    <p className="card-text mb-1">
                        <strong>Tipo persona: </strong>
                        {empresa.tipoPersona.tipoPersona}
                    </p>
                    <p className="card-text mb-1">
                        <strong>Representante Legal: </strong>
                        {empresa.representanteLegal}
                        <span className="mx-4"></span>
                        <strong>Documentos: </strong>
                        {"10 / 10"}
                    </p>
                </div>

                <div className="col-md-12">
                    <div className="d-inline mb-2">
                        <Link
                            className="btn btn-sm btn-outline-primary me-2 mb-2 py-0"
                            to={"/catalogos/documentacion/legal/view/" + empresa.idEmpresa}
                        >
                            <i className="bi bi-file-text fs-5"></i> Editar documentación
                        </Link>
                    </div>
                </div>
            </div>

            {vistaDocumentacion && (
                <DocumentacionLegalForm
                    show={vistaDocumentacion}
                    onHide={(e) => {
                        setVistaDocumentacion(false);
                    }}
                    onSave={refetch}
                />
            )}
        </Card>
    );
};

export default DocumentacionCard;
