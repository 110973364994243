import {
    flexRender, getCoreRowModel, getFilteredRowModel, getExpandedRowModel, getSortedRowModel, useReactTable,
} from '@tanstack/react-table'
import React, {useEffect, useState} from 'react'
import {Form, Table} from "react-bootstrap";

export default function BootstrapTable({
                                           striped,
                                           layoutFixed,
                                           toolbar,
                                           data,
                                           columns,
                                           getSubRows,
                                           onGlobalFilterChange,
                                           setTable,
                                           search = true,
                                           sort = true
                                       }) {

    const [sorting, setSorting] = useState([])
    const [filtering, setFiltering] = useState('')
    const [expanded, setExpanded] = useState({})

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        state: {
            sorting: sorting, globalFilter: filtering, expanded,
        },
        onExpandedChange: setExpanded,
        getSubRows: getSubRows ?? undefined,
        onSortingChange: setSorting,
        onGlobalFilterChange: setFiltering,
        manualPagination: true,
    })

    useEffect(() => {
        if (setTable && table) {
            setTable(table);
        }
    }, [setTable, table]);
    return (<>
        <div className="btn-toolbar justify-content-between mb-2" role="toolbar">
            {search ? <Form.Control
                className="mwx-250"
                value={filtering}
                placeholder="Buscar:"
                onChange={({target}) => {
                    setFiltering(target.value);
                    if (onGlobalFilterChange) {
                        onGlobalFilterChange(target.value);
                    }

                }}
            /> : <div> </div>}
            {toolbar && toolbar({
                columns: columns
            })}
        </div>
        <Table striped={striped} bordered hover responsive size="sm"
               style={{tableLayout: layoutFixed ? 'fixed' : 'auto'}}
               className={getSubRows ? "table-tree" : 'table-basic'}
        >
            <thead>
            {table.getHeaderGroups().map(headerGroup => (<tr key={headerGroup.id}>
                {headerGroup.headers.map(header => (<th
                    key={header.id}
                    colSpan={header.colSpan}
                    onClick={sort ? header.column.getToggleSortingHandler() : null}
                    style={{
                        width: header.column.columnDef.width ?? null
                    }}

                >
                    {header.isPlaceholder ? null : (<div>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                            asc: (<i className="bi bi-sort-down ms-2"></i>),
                            desc: (<i className="bi bi-sort-up ms-2"></i>)
                        }[header.column.getIsSorted() ?? null]}
                    </div>)}
                </th>))}
            </tr>))}
            </thead>
            <tbody>
            {table.getRowModel().rows.map(row => (<tr key={row.id}>
                {row.getVisibleCells().map(cell => (<td className={`${cell.column.columnDef.wrapText? "text-wrap" : "text-sm-nowrap"} ${cell.column.columnDef.className || ''}`} data-th={cell.column.columnDef.header} key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())} {!cell.getValue() && cell.column.columnDef.accessorKey ? <>&nbsp;</> : ''}
                </td>))}
            </tr>))}
            </tbody>
        </Table>
    </>)
}

export function getTreeKey(id){
    return id.split('.').map(e => ( `modulos.${e}`)).join('.');
}